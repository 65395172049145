(function(angular) {
	function OutputController($scope, $element, ajaxInProgress, customFormat) {
		var ctrl = this;
		// ctrl.$scope = $scope;
		ctrl.ajaxInProgress = ajaxInProgress;

		ctrl.availableIcons = [
			{
				value: "0",
				img: "img/assets/icon-output/icon-output-enchufe.svg"
			},
			{
				value: "1",
				img: "img/assets/icon-output/icon-output-lampara.svg"
			},
			{
				value: "2",
				img: "img/assets/icon-output/icon-output-cerradura.svg"
			},
			{
				value: "3",
				img: "img/assets/icon-output/icon-output-electrodomestico.svg"
			},
			{
				value: "4",
				img: "img/assets/icon-output/icon-output-hielo.svg"
			},
			{
				value: "5",
				img: "img/assets/icon-output/icon-output-termometro.svg"
			},
			{
				value: "6",
				img: "img/assets/icon-output/icon-output-porton.svg"
			},
			{
				value: "7",
				img: "img/assets/icon-output/icon-output-velador.svg"
			},
			{
				value: "8",
				img: "img/assets/icon-output/icon-output-riego.svg"
			}
		];

		ctrl.$onInit = function() {
			if(ctrl.output.number > 8){
				ctrl.output.editedName = ctrl.output.name || "PGM-W " + (ctrl.output.number - 8);
				ctrl.output.type = "Salida inalámbrica "
				ctrl.output.showedNumber = ctrl.output.number -8
			} else{
				ctrl.output.editedName = ctrl.output.name || "PGM  " + (ctrl.output.number);
				ctrl.output.type = "Salida cableada "
				ctrl.output.showedNumber = ctrl.output.number
			}
			// ctrl.output.editedName = ctrl.output.name || "Salida " + ctrl.output.number;
			ctrl.output.editedIcon = ctrl.output.icon || "0";
			ctrl.outputIconClass = "icon-output-" + ctrl.output.icon;
			ctrl.outputEditedIconClass = "icon-output-" + ctrl.output.icon;
		};

		ctrl.$onChanges = function(changesObj) {
			// console.log("$onChanges");
			ctrl.parseStatus(ctrl.status);
		};

		ctrl.encender = function() {
			ctrl.onEncender(customFormat.format(2, ctrl.output.number.toString(16).toUpperCase()));
		};

		ctrl.apagar = function() {
			ctrl.onApagar(customFormat.format(2, ctrl.output.number.toString(16).toUpperCase()));
		};

		ctrl.selectIcon = function(value) {
			// console.log(ctrl.zone);
			ctrl.output.editedIcon = value;
			ctrl.outputEditedIconClass = "icon-output-" + value;
		};

		ctrl.parseStatus = function(status) {
			if (status) {
				if (ctrl.output.number < 5) {
					var mask2 = Math.pow(2, ctrl.output.number);

					if (status.estadoDeSalidas & mask2) {
						// Salida encendida
						ctrl.estadoOutput = "ENCENDIDA";
						ctrl.estadoOutputClass = "output-class-encendida";
						ctrl.estadoOutputText = "Encendido";
						ctrl.estadoOutputColor = "verde";
					} else {
						// Salida apagada
						ctrl.estadoOutput = "APAGADA";
						ctrl.estadoOutputClass = "output-class-apagada";
						ctrl.estadoOutputText = "Apagado";
						ctrl.estadoOutputColor = "gris";
					}
				} else {
					var mask = Math.pow(2, 4 - ctrl.output.number + 8);

					if (status.estadoDeSalidasInalambricas & mask) {
						// Salida encendida
						ctrl.estadoOutput = "ENCENDIDA";
						ctrl.estadoOutputClass = "output-class-encendida";
						ctrl.estadoOutputText = "Encendido";
						ctrl.estadoOutputColor = "verde";
					} else {
						// Salida apagada
						ctrl.estadoOutput = "APAGADA";
						ctrl.estadoOutputClass = "output-class-apagada";
						ctrl.estadoOutputText = "Apagado";
						ctrl.estadoOutputColor = "gris";
					}
				}
			} else {
				ctrl.estadoOutputText = "";
				ctrl.estadoOutputClass = "output-class-desconocida";
				ctrl.estadoOutputColor = "gris";
			}
		};

		// ctrl.parseStatus(ctrl.status);
	}

	angular.module("app").component("output", {
		templateUrl: "components/Output/output.html",
		controller: OutputController,
		bindings: {
			status: "<",
			output: "<",
			onEncender: "=",
			onApagar: "=",
			editMode: "<"
		}
	});
})(window.angular);
