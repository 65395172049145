(function(angular) {
  function CommunityUserController($scope, $element, ajaxInProgress) {
    var ctrl = this;
    // ctrl.$scope = $scope;
    ctrl.ajaxInProgress = ajaxInProgress;

    ctrl.$onInit = function() {
      // ctrl.zone.editedName = ctrl.zone.name || "Cámara " + ctrl.zone.number;
    };
    ctrl.avatarSrc = function() {
      if (ctrl.user && ctrl.user.disabled) {
        return "img/assets/icon-avatar/icon-avatar-0-circle.svg";
      }
      return ctrl.user.avatarSrc;
    };

    ctrl.removerUsuario = function() {
      ctrl.onRemoverUsuario(ctrl.user);
    };

    ctrl.editarUsuario = function() {
      ctrl.onEditarUsuario(ctrl.user, ctrl.type);
    };
  }

  angular.module("app").component("communityUser", {
    templateUrl: "components/CommunityUser/CommunityUser.html",
    controller: CommunityUserController,
    bindings: {
      user: "<",
      type: "<",
      onRemoverUsuario: "=",
      onEditarUsuario: "="
    }
  });
})(window.angular);
