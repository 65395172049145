(function() {
  function SystemVideoController(
    $scope,
    $state,
    $rootScope,
    system,
    ajaxInProgress,
    BUILD_MODE,
    $timeout,
    editMode,
    alertService,
    userDataService,
    $window,
    actionBarService,
    videoService,
    $document,
    $interval,
    liveVideoBridgeService,
    buildModes,
    $stateParams,
    ymSidenavService,
    BUILD_PRODUCT,
    BUILD_PLATFORM,
    __env
  ) {
    "use strict";

    console.log("SystemVideoController created");

    $scope.$on("$destroy", function() {
      console.log("SystemVideoController destroyed");
      self.closeVideoStream();
    });

    editMode.state.value = false;

    if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
      ymSidenavService.closeYmSidenav();
    }

    var self = this;

    self.userData = userDataService.getUserData();

    self.CameraLinkButtonEnabled;

    self.viewInfo;

    self.externalAppSetted = true;

    self.changeViewInfo = function(){
      self.viewInfo = !self.viewInfo
    };

    if(BUILD_PRODUCT == "titanium_control"){
      self.productName = "Alarm Home";
    }
    if(BUILD_PRODUCT == "garnet_control"){
      self.productName = "Garnet Control";
    }
    if(BUILD_PRODUCT == "yo_monitoreo"){
      self.productName = "Yo Monitoreo";
    }

    self.appKey = "99c34ab3d8244bceadff64388e45d67f"; //ALONSO2000
    //self.appKey = "bfa5983763914216855a5dc98f2c264d"; //JONY
    

    self.$scope = $scope;

    self.editModeValue = function() {
      return editMode.state.value;
    };

    self.cameraList = [];

    self.cameraAppList = [];

    if(BUILD_PLATFORM === "iOS"){
      self.cameraAppList = [__env.appArray[0]];
      self.linkedName = "Linkear Ezviz"
    } else {
      self.cameraAppList = __env.appArray;
      self.linkedName = "Linkear App"
    };

    self.state = {
      action: "init"
    };


    self.newCameraData = {
      cameraId: "",
      validateCode: ""
    };

    self.ajaxInProgress = ajaxInProgress;
    self.agregarCamaraVisible = false;

    self.configWifiButtonDisabled = false;

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value == "deletingCameraLink") {
          self.CameraLinkButtonEnabled = false;
          ajaxInProgress.setState({
            value: false
          });
          self.volverALaLista();
        } else if (ajaxInProgress.state.value == "linkingVideo") {
          self.CameraLinkButtonEnabled = true;
          ajaxInProgress.setState({
            value: false
          });
          self.volverALaLista();
        } else if (ajaxInProgress.state.value == "obteniendoSubAccountAccessToken") {
          self.subAccountAccessToken = res.data.subAccessToken;
          self.listarCamaras();
        } else if (ajaxInProgress.state.value == "listandoCamaras") {
          ajaxInProgress.setState({
            value: false
          });
          self.procesarCameraList(res.data.cameraList);
        } else if (ajaxInProgress.state.value == "agregandoCamara") {
          self.procesarCameraList(res.data.cameraList, self.newCameraData.cameraId);
          self.state.action = "init";
        } else if (ajaxInProgress.state.value == "eliminandoCamara") {
          ajaxInProgress.setState({
            value: false
          });
          self.procesarCameraList(res.data.cameraList);
          self.state.action = "init";
          alertService.showToast("Cámara eliminada");
        } else if(ajaxInProgress.state.value == "editandoCamara"){
          ajaxInProgress.setState({
            value: false
          });
          self.procesarCameraList(res.data.cameraList);
          self.state.action = "init";
          alertService.showToast("Edición Completa");
        }
      } else {
        if (res.status == -1) {
          ajaxInProgress.setState({
            value: false
          });
          $timeout(function() {
            if(!self.especificId){
              alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
            }
          }, 0);
          return;
        }
        if (res.status == -2) {
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
        ajaxInProgress.setState({
          value: false
        });
        $timeout(function() {
          self.state.action = "init";
          alertService.showAlert("Atención", res.data.message, "OK");
        });
      }
    }

    self.retry = 0;

    self.procesarCameraList = function(cameraList, especificId) {
      self.cameraList = cameraList.sort((function (a, b) {
        if (a.nombre > b.nombre) {
          return 1;
        }
        if (a.nombre < b.nombre) {
          return -1;
        }
        // a must be equal to b
        return 0;
      }));

      self.cameraList.forEach(function(camera) {
        videoService.capturePic(self.sistemaElegido, self.subAccountAccessToken, camera.deviceSerial).then(
          function(body) {
            camera.picUrl = body.data.picUrl;
          },
          function(err) {
          }
        );
      });

      if(especificId){
        self.cameraList.forEach(function(camera) {
            if(camera.deviceSerial == especificId){
              if(camera.status == 1){
                // console.log("Se configuró con éxito", camera);
                ajaxInProgress.setState({
                  value: false
                });
                alertService.showToast("Red Wi-Fi configurada con éxito.");
                self.especificId = null;
                self.retry = 0;
              } else {
                // console.log("Se Falló reintento número: ", self.retry);
                self.retry = self.retry + 1;
                if(self.retry == 6){
                  ajaxInProgress.setState({
                    value: false
                  });
                  self.retry = 0;
                  self.especificId = null;
                  alertService.showAlert("Atención", "Se ha agotado el tiempo de espera y la cámara no se encuentra conectada a la red Wifi", "OK");
                } else{
                  $timeout(function(){
                    self.especificId = especificId
                    self.listarCamaras("Verificando conexión Wifi");
                  }, 10000);
                }
              }
            } 
          })
      }

      if (self.sistemaElegido.owner && typeof self.sistemaElegido.owner.userType == "number" && self.sistemaElegido.owner.userType == 0) {
        if (BUILD_MODE == "MOBILE") {
          self.agregarCamaraVisible = true;
        }
      } else {
        self.agregarCamaraVisible = false;
      }
    };

    self.liveVideoCallTime;

    self.waitForWiFiConf = function() {
      self.state.action = "waitingForWifiConf";
      self.configWifiButtonDisabled = false;
    };

    self.volverALaLista = function() {
      self.state.action = "init";
    };

    $rootScope.$on('videoEvent', function () {
      //do stuff
      $timeout(function(){
        self.liveVideoCallTime = null
      }, 500)
      console.log("Reset Función trigger Controller");
 })

    self.verLive = function(cameraId, validateCode) {
        self.liveVideoCallTime = Date.now();
        liveVideoBridgeService.triggerLivePlay(self.appKey, self.subAccountAccessToken, cameraId, validateCode);
    };

    self.closeVideoStream = function() {
      if (self.videoRef && !self.videoRef.ended) {
        self.videoRef.pause();
        self.videoRef.srcObject = undefined;
        // self.listarCamaras();
      }
    };

    self.eliminarCamara = function(cameraId) {
      alertService
        .showConfirmAlert("Eliminar cámara", "¿Estás seguro de eliminar?", "¡Sí, estoy seguro!", "NO")
        .then(function() {
          var ajax = videoService.remove(self.sistemaElegido, self.subAccountAccessToken, cameraId);
          ajax.then(handleRequest, handleRequest);
          ajaxInProgress.setState({
            value: "eliminandoCamara",
            label: "Eliminando cámara",
            // cancelable: true,
            ajax: ajax
          });
        })
        .catch(function(error) {
          console.log("SystemVideoController::eliminarCamara(error)", error);
        });
    };

    self.editarCamara = function(nombre, deviceSerial){
      var ajax = videoService.editCameraName(nombre, deviceSerial, self.sistemaElegido);
      // var ajax = videoService.getSubAccessToken(self.sistemaElegido);
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "editandoCamara",
        label: "Editando Cámaras",
        ajax: ajax
      });
    }

    self.pedirSubAccountAccessToken = function() {
      var ajax = videoService.getSubAccessToken(self.sistemaElegido);
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "obteniendoSubAccountAccessToken",
        label: "Cargando cámaras",
        cancelable: true,
        ajax: ajax
      });
    };

    self.addCameraLink = function(){
      if(self.cameraAppSelected){
        self.appSelectedObj = __env.appArray.find(function(app){
          return app.appName == self.cameraAppSelected
        })

        var ajax = system.setSystemVideoLinkData(self.sistemaElegido, self.appSelectedObj);

        ajax.then(handleRequest, handleRequest);
        ajaxInProgress.setState({
          value: "linkingVideo",
          label: "Guardando link de video",
          cancelable: true,
          ajax: ajax
        });
      }
    };

    self.listarCamaras = function(message) {
      if(message){
        var ajax = videoService.list(self.sistemaElegido, self.subAccountAccessToken);
        ajax.then(handleRequest, handleRequest);
        //Al no setear estado se recibe el request por agregando camara creo.
      } else{
        var ajax = videoService.list(self.sistemaElegido, self.subAccountAccessToken);
        ajax.then(handleRequest, handleRequest);
        ajaxInProgress.setState({
          value: "listandoCamaras",
          label: "Cargando cámaras",
          cancelable: true,
          ajax: ajax
        });
      }

    };

    self.agregarCamara = function() {
      // if (!ajaxInProgress.state.value) {
      var ajax = videoService.add(self.sistemaElegido, self.subAccountAccessToken, self.newCameraData.cameraId, self.newCameraData.validateCode);
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "agregandoCamara",
        label: "Agregando cámara",
        cancelable: true,
        ajax: ajax
      });
      // }
    };

    self.cargarManualmente = function() {
      // var ajax = videoService.add(self.sistemaElegido, self.subAccountAccessToken, self.newCameraData.cameraId, self.newCameraData.validateCode);
      // ajax.then(handleRequest, handleRequest);
      // ajaxInProgress.setState({
      //   value: "agregandoCamara",
      //   label: "Agregando cámara",
      //   // cancelable: true,
      //   ajax: ajax
      // });
      self.waitForWiFiConf();
    };

    self.programarWifi = function() {
      self.configWifiButtonDisabled = true;

      var configWifiPromise = liveVideoBridgeService.configWifi(
        self.appKey,
        self.subAccountAccessToken,
        self.newWifiData.ssid,
        self.newWifiData.password,
        self.newCameraData.cameraId,
        self.newCameraData.validateCode,
        self.newCameraData.deviceModel
      );

      ajaxInProgress.setState({
        value: "configurandoWifi",
        label: "Agregando cámara"
        // cancelable: true
      });

      configWifiPromise.then(function(result) {
        if (result === "DEVICE_WIFI_CONNECTED") {
        // self.agregarCamara();
          $timeout(function() {
           self.onWifiProgrammed(true);           
          }, 20);
        } else if (result === "DEVICE_WIFI_TIMEOUT") {
          self.onWifiProgrammed(false);
        } 
      });
    };

    self.onWifiProgrammed = function(success, message) {
      if (success) {
        // alertService.showToast(message || "Red Wi-Fi configurada con éxito.");
        self.state.action = "addingCamera";
        self.agregarCamara();
      } else {
        ajaxInProgress.setState({
          value: false
        });
        self.configWifiButtonDisabled = false;
        alertService.showAlert("Atención", "No fue posible configurar la red Wi-Fi, por favor revisa los datos e inicia el proceso nuevamente.", "OK");
      }
    };

    self.selectCamBrand = function(){
      if (BUILD_MODE == "MOBILE") {
        // self.state.action = "waitingForManualInput"
        self.state.action = "selectingCamBrand"
      }
    };

    self.selectGarnetIntegrationBrand = function(){
      self.leerQRCode();
    };

    self.externalCamBrand = function(){
      if(BUILD_PLATFORM === "iOS"){
        self.cameraAppSelected = "Ezviz"
        self.addCameraLink()
      } else {
        self.state.action = "selectingCamApp"
      };
   
    };

    self.deleteCameraLink = function(){
      var ajax = system.deleteCameraLink(self.sistemaElegido);
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "deletingCameraLink",
        label: "Eliminando link de cámaras",
        cancelable: true,
        ajax: ajax
      });
    }

    self.leerQRCode = function() {
      if (BUILD_MODE == "WEB") {
        return self.leerQRCodeWeb();
      } else {
        // self.state.action = "waitingForQR"; COMENTADO PARA PROBAR.
        self.state.action = "waitingForManualInput"

        liveVideoBridgeService
          .scanQRCode()
          .then(function(jsonResult) {
            var result = JSON.parse(jsonResult);

            if (result.success == false) {
              return $timeout(function() {
                self.state.action = "waitingForManualInput";
              }, 0);
            }

            $timeout(function() {

              self.newCameraData.cameraId = result.cameraId;
              self.newCameraData.validateCode = result.validateCode;
              self.newCameraData.deviceModel = result.deviceModel;

              // self.state.action = "askingForWifiConf";
              self.waitForWiFiConf();

              // self.agregarCamara(self.newCameraData.cameraId, self.newCameraData.validateCode);
            }, 0);
          })
          .catch(function(err) {
            $timeout(function() {
              self.state.action = "waitingForManualInput";
            }, 0);
          });
      }
    };

    self.leerQRCodeWeb = function() {
      self.state.action = "readingQR";

      $timeout(function() {
        self.videoRef = $document.find("video")[0];
        var canvas = $document.find("canvas")[0];

        var constraints = {
          audio: false,
          video: { facingMode: "environment" }
        };

        function handleSuccess(stream) {
          self.videoRef.srcObject = stream;

          var readingTimeout;

          var readingInterval = $interval(function() {
            canvas.width = self.videoRef.videoWidth;
            canvas.height = self.videoRef.videoHeight;

            if (canvas.width == 0) {
              return;
            }

            canvas.getContext("2d").drawImage(self.videoRef, 0, 0, canvas.width, canvas.height);
            var code = jsQR(canvas.getContext("2d").getImageData(0, 0, canvas.width, canvas.height).data, canvas.width, canvas.height);

            if (code !== null) {
              // alert(code.data);
              var cameraData = code.data.split("\r");

              if (cameraData.length !== 5) {
                // Se leyó un QR pero no tiene 5 elementos
                // console.log("// Se leyó un QR pero no tiene 5 elementos");
                return;
              }

              if (cameraData[0] !== "ezviz") {
                // Se leyó un QR pero el primer elemento no dice ezviz
                // console.log("// Se leyó un QR pero el primer elemento no dice ezviz");
                return;
              }

              if (!/^\d{9}$/.test(cameraData[1])) {
                // Se leyó un QR pero el ID no cumple con la expresión regular
                // console.log("// Se leyó un QR pero el ID no cumple con la expresión regular");
                return;
              }

              if (!/^[\dA-Z]{6}$/.test(cameraData[2])) {
                // Se leyó un QR pero el validate Code no cumple con la expresión regular
                // console.log("// Se leyó un QR pero el validate Code no cumple con la expresión regular");
                return;
              }

              $timeout.cancel(readingTimeout);
              $interval.cancel(readingInterval);
              self.closeVideoStream();

              self.newCameraData.cameraId = cameraData[1];
              self.newCameraData.validateCode = cameraData[2];

              self.waitForWiFiConf();

              //self.agregarCamara(self.newCameraData.cameraId, self.newCameraData.validateCode);
            }
          }, 5);

          readingTimeout = $timeout(function() {
            if (self.state.action == "readingQR") {
              $interval.cancel(readingInterval);
              self.closeVideoStream();
              self.state.action = "waitingForManualInput";
            }
          }, 10000);
        }

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then(handleSuccess)
          .catch(function(error) {
            $timeout(function() {
              self.state.action = "waitingForManualInput";
            }, 0);
          });
      });
    };

    self.editMode = editMode;

    self.openVideo = function(){
      if(BUILD_MODE != "WEB"){
        //distinto de web
        console.log("Open Video");
        if(self.cameraAppSelected){
          __env.openVideoApp(self.cameraAppSelected);
        }
      } else {
        alertService.showAlert("Atención", "Esta función solo se encuentra disponible en su versión mobile", "OK");
      }
    };

    self.setSaveAction = function() {
      actionBarService.setActionBarActions("SystemVideoController", [
        {
          label: "Guardar",
          aria: "Guardar",
          icon: "save",
          onClickHandler: function() {
            // Secuencia de guardado :D

            var toSaveZones = [];

            self.zoneList.forEach(function(zone) {
              var toSaveZone = Object.assign({}, zone);
              toSaveZone.name = toSaveZone.editedName;
              toSaveZone.icon = toSaveZone.editedIcon;
              delete toSaveZone.editedName;
              delete toSaveZone.editedIcon;
              toSaveZones.push(toSaveZone);
            });

            var programation = Object.assign({}, self.sistemaElegido.programation);

            programation.data.zones = toSaveZones;

            $scope.systemController.setSystemProgramation(programation.data);

            editMode.state.value = false;
            self.setEditAction();

            $state.go("system");
          }
        },
        {
          label: "Cancelar",
          aria: "Cancelar",
          icon: "clear",
          onClickHandler: function() {
            editMode.state.value = false;
            self.setEditAction();
          }
        }
      ]);
    };

    self.setEditAction = function() {
      var buttons = [
        {
          label: "Actualizar",
          aria: "Actualizar",
          icon: "refresh",
          onClickHandler: function() {
            self.listarCamaras();
          }
        }
      ];

      if (self.sistemaElegido.owner && typeof self.sistemaElegido.owner.userType == "number" && self.sistemaElegido.owner.userType == 0) {
        if (BUILD_MODE == "MOBILE" ) {
          buttons.push({
            label: "Agregar",
            aria: "agregar",
            icon: "add",
            onClickHandler: function() {
              // editMode.state.value = true;
              // self.leerQRCode(); 
              self.selectCamBrand();

            }
          });
        }
      }

      actionBarService.setActionBarActions("SystemVideoController", buttons);
    };


    // Tareas para cuando el controller se instancia
    system.getSystem($stateParams.idSistemaElegido, true)
    .then(function(sistemaElegido) {
      self.sistemaElegido = sistemaElegido;
      console.log("Sistema Elegido: ", self.sistemaElegido);
      system.setCommandTimeout(sistemaElegido.id);
			system.setSelectedIndex('/system/video');

      if (self.userData.sistemas){
        self.isOwner = self.userData.sistemas.some(function(sistema){
          return sistema.id == self.sistemaElegido.id;
         });
      };

      if (self.sistemaElegido.estadoDeCarga === "verificado") {
        self.cameraAppSelected = self.sistemaElegido && self.sistemaElegido.cameraLink && self.sistemaElegido.cameraLink.appName;
        self.appSelectedObj = __env.appArray.find(function(app){
          return app.appName === self.cameraAppSelected
        });
       
        if(self.cameraAppSelected){
          if(BUILD_PLATFORM === "iOS" && self.cameraAppSelected === "Ezviz"){
            self.CameraLinkButtonEnabled = true;
          } else if(BUILD_PLATFORM === "Android") {
            self.CameraLinkButtonEnabled = true;
          }  else {
            self.CameraLinkButtonEnabled = false;
          }
        } else {
          self.CameraLinkButtonEnabled = false;
        }
        if (self.sistemaElegido.programation.data) {
          self.zoneList = self.sistemaElegido.programation.data.zones;
        }

        self.setEditAction();
        self.pedirSubAccountAccessToken();
      }
    });
  }

  angular.module("app").controller("SystemVideoController", SystemVideoController);
})();
