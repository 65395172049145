(function() {
  function deferredOperations($q, $window) {
    var self = this;

    self.operations = [];

    self.do = function(toDo) {
      var operationId = guid();
      var deferred = $q.defer();
      toDo(operationId);
      self.operations.push({
        id: operationId,
        deferred: deferred,
        timestamp: Date.now()
      });
      return deferred.promise;
    };

    $window.onResolveOperation = function(operationId, result) {
      if (result !== null) {
        // console.log("onResolveOperation('" + operationId + "', '" + result + "')");
      } else {
        // console.log("onResolveOperation('" + operationId + "', null)");
      }

      if((operationId == "null" || operationId == null) && self.operations.length == 1) {
        self.operations[0].deferred.resolve(result);
        return self.operations.pop();
      };

      var operation = self.operations.find(function(mOperation) {
        return mOperation.id === operationId;
      });


      self.operations = self.operations.filter(function(mOperation) {
        return mOperation.id !== operationId;
      });

      //QUE HACER CUANDO DEFERRED ES NULL

      operation && operation.deferred.resolve(result);
    };

    function guid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
    }
  }

  angular.module("app").service("deferredOperations", deferredOperations);
})();
