(function() {
  function ControlPanelController(
    __env,
    $q,
    $scope,
    ajaxInProgress,
    alertService,
    ymSidenavService,
    editMode,
    $rootScope,
    $stateParams,
    user,
    $state,
    BUILD_MODE,
    buildModes,
    notificationHookService,
    $window,
    deviceService,
    $timeout,
    userDataService,
    BUILD_PRODUCT,
    buildProducts
  ) {
    var self = this;
    
    editMode.state.value = false;

    self.editModeValue = function() {
      return editMode.state.value;
    };

    // Ym Sidenav functions
    self.toggleYmSidenav = ymSidenavService.toggleYmSidenav;
    self.ymSidenavOpenStateClass = ymSidenavService.ymSidenavOpenStateClass;
    self.closeYmSidenav = ymSidenavService.closeYmSidenav;
    self.openYmSidenav = ymSidenavService.openYmSidenav;

    self.notificationsData = notificationHookService.notificationsData;
    self.deviceDataObtained

    self.gotoSystemList = function() {
      // Si estoy en MODE_BUILD == WEB Simulo un OnResume para obtener las notificaciones de la Comunidad
      if (BUILD_MODE == buildModes.WEB) {
        $rootScope && $rootScope.$emit("appLifeCycle.onResume", {});
      }
      self
        .checkForLastTimeUpdated()
        .then(function() {
          $state.go("systemlist", {urlToRedirect: $stateParams.urlToRedirect}, { reload: "systemlist" });
          if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
            self.closeYmSidenav();
          }
        })
        .catch(function(err) {
          alertService.showToast(err);
        });
    };

    self.openPlanDialog = function (){
      ajaxInProgress.setState({
        value: false
      });
      alertService.showAlert("Plan Actual", "Quedan " + $rootScope.daysLeft + " días para que finalice el plan actual" , "OK");
    }

    self.gotoAccountConfig = function(id) {
      self
        .checkForLastTimeUpdated()
        .then(function() {
          if (BUILD_MODE == buildModes.WEB) {
            $rootScope && $rootScope.$emit("appLifeCycle.onResume", {});
          }
          $state.go("account_config");
          if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
            self.closeYmSidenav();
          }
        })
        .catch(function(err) {
          alertService.showToast(err);
        });
    };

    self.gotoPaymentData = function() {
      self
        .checkForLastTimeUpdated()
        .then(function() {
          $state.go("payment_data");
          if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
            self.closeYmSidenav();
          }
        })
        .catch(function(err) {
          alertService.showToast(err);
        });
    };

    self.gotoAboutDevice = function() {
      self
        .checkForLastTimeUpdated()
        .then(function() {
          $state.go("about_device");
          if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
            self.closeYmSidenav();
          }
        })
        .catch(function(err) {
          alertService.showToast(err);
        });
    };

    self.gotoNotifications = function() {
      self
        .checkForLastTimeUpdated()
        .then(function() {
          $state.go("notifications");
          if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
            self.closeYmSidenav();
          }
        })
        .catch(function(err) {
          alertService.showToast(err);
        });
    };

    self.gotoRegisterConfirmed = function() {
      self
        .checkForLastTimeUpdated()
        .then(function() {
          $state.go("register-confirmed");
          if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
            self.closeYmSidenav();
          }
        })
        .catch(function(err) {
          alertService.showToast(err);
        });
    };

    self.gotoSusbscriptionFlow = function() {
      self
        .checkForLastTimeUpdated()
        .then(function() {
          $state.go("subscription_systemlist");
          if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
            self.closeYmSidenav();
          }
        })
        .catch(function(err) {
          alertService.showToast(err);
        });
    };

    // __env.setLastTimeUpdated(Date.now().toString());

    self.checkForLastTimeUpdated = function() {
      var deferred = $q.defer();

      if (BUILD_MODE == "MOBILE") {
        __env
          .getDeviceData()
          .then(function(deviceData) {
            self.deviceDataObtained = deviceData;
            if (parseInt(deviceData.lastTimeUpdated)) {
              // Si deviceData está actualizado
              return;
            }

            // Si deviceData no está actualizado
            ajaxInProgress.setState({
              value: "patchingDeviceData",
              label: "Sincronizando datos",
              // cancelable: true
            });

            return deviceService.patch(deviceData);
          })
          .then(function(res) {
            if (!res) {
              return;
            }

            if (res.data && res.data.success) {
              if (ajaxInProgress.state.value == "patchingDeviceData") {
                // $timeout(function(){
                ajaxInProgress.setState({
                  value: false
                });
                __env.setLastTimeUpdated(Date.now().toString());
                // }, 0);
                return;
              }
            } else {
              if (res.status == -1) {
                alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
                ajaxInProgress.setState({
                  value: false
                });
                return "Error de conexión, comprueba tu conexión a internet y vuelve a intentarlo";
              }

              alertService.showAlert("Atención", res.data.message, "OK");

              ajaxInProgress.setState({
                value: false
              });

              return res.data.message;
            }
          })
          .then(function(msg) {
            if (msg) {
              // Si hay un mensaje de error, rejecto con el mensaje
              return deferred.reject(msg);
            }

            // Si no hay mensaje de error, resuelvo
            deferred.resolve();
          })
          .catch(function(err) {
            //console.log(err);
            ajaxInProgress.setState({
              value: false
            });
            deferred.reject(err);
          });

        return deferred.promise;
      }

      // Si no estoy en mobile resuelvo
      deferred.resolve();

      return deferred.promise;
    };

    $scope.$on("$viewContentLoaded", function() {
      // Here your view content is fully loaded !!
      console.log("ControlPanelController::viewContentLoaded()");
      __env.viewContentLoaded();
    });

    self.sidenavElements = [];

    self.sidenavElements.push({
      onClick: self.gotoSystemList,
      iconName: "home",
      text: "Sistemas de alarma",
      id: "systems"
    });

    self.sidenavElements.push({
      onClick: self.gotoAccountConfig,
      iconName: "person",
      text: "Mi cuenta",
      id: "account"
    });

    self.sidenavElements.push({
      onClick: self.gotoNotifications,
      iconName: "notifications",
      text: "Notificaciones",
      id: "notifications",
      notificationsData: notificationHookService.notificationsData
    });

    // if (BUILD_PRODUCT === buildProducts.YO_MONITOREO) {
    //   self.sidenavElements.push({
    //     onClick: self.gotoSusbscriptionFlow,
    //     iconName: "card_membership",
    //     text: "Suscripción y planes",
    //     id: "Subscriptions"
    //   });
    // }
    
    // sidenavElements.push({
    //   onClick: self.gotoAboutDevice(),
    //   iconName: "",
    //   text: "Acerca del dispositivo"
    // });

    // self.checkForLastTimeUpdated();
    
    var currentState = $state.current.name;

    self.customizedCode =  userDataService.getAppCustomizationCode();

    self.appIsCustomized = userDataService.getAppIsCustomized();

    if(self.customizedCode != "Alarm Home" && self.customizedCode != "Garnet Control"){
      self.appIsCustomized = true
    }

    if(self.appIsCustomized){
      // self.systemListImage = user.getSystemlistImage();
      self.attributes = user.getCustomizationAttributes();
      // self.attributes = self.attributes && JSON.parse(self.attributes);
      // console.log("ATRIBUTOS", self.attributes);
      self.systemListImage = user.getSystemlistImage();
    }
    
    if(self.deviceDataObtained && self.deviceDataObtained.urlToRedirect == "users_api"){
      if(self.deviceDataObtained && self.deviceDataObtained.verifyAccount){
        self.gotoRegisterConfirmed();
      }
    }
  
    if(self.deviceDataObtained && self.deviceDataObtained.urlToRedirect == "notifications"){
      self.gotoNotifications();
    }

    if (currentState !== "controlpanel") {
      // Si el estado actual no coincide exactamente con el nombre del control panel
      return;
    }

    // Sino redirigo a systemlist
    self.gotoSystemList();
  }

  angular.module("app").controller("ControlPanelController", ControlPanelController);
})();
