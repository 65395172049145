(function() {
	function systemService($q, $http, BASE_API, SYSTEMS_API, auth, $rootScope, $state, userDataService, BUILD_MODE) {
		var self = this;

		var TIMEOUT_CONSTANT = 5000;

		self.isWeb = BUILD_MODE == "WEB";

		if(!self.isWeb) {
			$http.defaults.headers.common.phoneLanguage = "spanish"
		};

		self.setCommandTimeout = function(systemId){
            $http.get(BASE_API + SYSTEMS_API + "/" + systemId + "/timeout")
			.then(function successCallback(response) {
                TIMEOUT_CONSTANT = response.data.message.timeout
                if(sistemaElegido.programation && sistemaElegido.programation.data && sistemaElegido.programation.data.alarmPanel.brand == 0){
                    if(TIMEOUT_CONSTANT && TIMEOUT_CONSTANT > 5000){
                        $rootScope.wifiIconToShow = "mobile-network"
                    } else {
                        $rootScope.wifiIconToShow = "wifi-network"
                    }
                } else{
                    if(TIMEOUT_CONSTANT && TIMEOUT_CONSTANT > 8500){
                        $rootScope.wifiIconToShow = "mobile-network"
                    } else {
                        $rootScope.wifiIconToShow = "wifi-network"
                    }
                }
		
				return response
			  }, function errorCallback(response) {
				  $rootScope.wifiIconToShow = "errorclassdontshowicon"
				  return response
			  });
		}

		var sistemas = [];

		var tabsToSwipe;

		var selectedIndex = '/system/control';
		
		self.phoneLanguage = 'en';

		
		self.setSelectedIndex = function(index){
			selectedIndex = index
		}

		self.getSelectedIndex = function(){
			return selectedIndex
		}

		// System delayed Panic Functions
		self.setSystemTimeToPanic = function(sistema,timeToGeneratePanic, partition, seq){
			self.setCommandTimeout(sistema.id)
			return self.retry(function() {
				return $http.post(BASE_API + SYSTEMS_API  + "/" + sistema.id + "/setpanic",
				{
					timeToGeneratePanic: timeToGeneratePanic,
					partition: partition,
					seq: seq,
					timeout: TIMEOUT_CONSTANT
				},
				{
					timeout: TIMEOUT_CONSTANT
				});
			}, 4);		
		}

		self.resetSystemTimeToPanic = function(sistema, partition, seq) {
			self.setCommandTimeout(sistema.id)
			return self.retry(function() {
				return $http.post(BASE_API + SYSTEMS_API + "/" + sistema.id + "/resetpanic",
				{
					partition: partition,
					seq: seq,
					timeout: TIMEOUT_CONSTANT,
				},
				{
					timeout: TIMEOUT_CONSTANT
				});
			}, 4);		
		}

		self.delaySystemTimeToPanic = function(sistema, partition){
			self.setCommandTimeout(sistema.id)
			return self.retry(function() {
				return $http.post(BASE_API + SYSTEMS_API  + "/" + sistema.id + "/delaypanic",
				{
					partition: partition,
					timeout: TIMEOUT_CONSTANT,
				},
				{
					timeout: TIMEOUT_CONSTANT
				});
			}, 4);
		}

		self.setSystemVideoLinkData = function(sistema, cameraData){
			return $http.post(
				BASE_API + SYSTEMS_API + "/" + sistema.id + "/video_link_data" ,
				{
					sistema: sistema.id,
					cameraData: cameraData 
				}
			);
		}

		self.deleteCameraLink = function(sistema){
			return $http.delete(
				BASE_API + SYSTEMS_API + "/" + sistema.id + "/video_link_data" ,
				{
					sistema: sistema.id,
				}
			);
		}

		self.generateEmergency = function(sistema, emergencyType, partition){
			self.setCommandTimeout(sistema.id)
			return self.retry(function() {
				return $http.post(
					BASE_API + SYSTEMS_API  + "/" + sistema.id + "/commands/emergency",
					{
						partition: partition,
						emergencyType: emergencyType,
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 4);
		};

		self.consultarEstado = function(sistema, seq, partNumber) {
			self.setCommandTimeout(sistema.id)
			return self.retry(function() {
				return $http.post(
					BASE_API + SYSTEMS_API + "/" + sistema.id + "/commands/state",
					{
						seq: seq,
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 4);
		};

		self.consultarPanicos = function (id){
			return self.retry(function() {
				return $http.get(BASE_API + SYSTEMS_API + "/" + id + "/panics",
					{
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 4);
		}

		self.getSistemas = function() {
			return sistemas;
		};

		var sistemasCompartidos = [];

		self.getSistemasCompartidos = function() {
			return sistemasCompartidos;
		};

		self.procesarListaDeSistemasYCompartidos = function(message) {
			if (
				message.sistemas !== undefined &&
				message.sistemasCompartidos !== undefined
			) {
				sistemas = message.sistemas;
				sistemasCompartidos = message.sistemasCompartidos;
			}
		};

		self.listarSistemas = function() {
			return $q.all([userDataService.getPhoneLenguage()])
			.then(function(result){
				if(result[0] == "pt"){
					self.phoneLanguage  = "por"
				  } else if(result[0] == "en"){
					self.phoneLanguage  = "eng"
				  } else if(result[0] == "es"){
					self.phoneLanguage  = "spa"
				  } else {
					self.notProcessedLanguage = window.navigator.userLanguage || window.navigator.language;
	
					if(self.notProcessedLanguage.includes("en")){
						self.phoneLanguage =  "eng";
					} else if(self.notProcessedLanguage.includes("pt")){
						self.phoneLanguage =  "por";
					} else {
						self.phoneLanguage =  "spa";
					}
				  }
			}).then(()=>{
				const req = !self.isWeb ? {
					method: 'GET',
					url: BASE_API + SYSTEMS_API + "/",
					headers: {
						'phonelanguage': self.phoneLanguage,
					},
				} : 
				{
					method: 'GET',
					url: BASE_API + SYSTEMS_API + "/",
				}
				return $http(req);
			})
	
		};

		self.addSystem = function(sistema) {
			return $q.all([userDataService.getPhoneLenguage()])
			.then(function(result){
				if(result[0] == "pt"){
					self.phoneLanguage  = "por";
				  } else if(result[0] == "en"){
					self.phoneLanguage  = "eng";
				  } else if(result[0] == "es"){
					self.phoneLanguage  = "spa";
				  } else {
					self.notProcessedLanguage = window.navigator.userLanguage || window.navigator.language;
					if(self.notProcessedLanguage.includes("en")){
						self.phoneLanguage =  "eng";
					} else if(self.notProcessedLanguage.includes("pt")){
						self.phoneLanguage =  "por";
					} else {
						self.phoneLanguage =  "spa";
					}
				  }
			}).then(()=>{
				const req = !self.isWeb ? {
					method: 'POST',
					url: BASE_API + SYSTEMS_API + "/" + sistema.id,
					headers: {
						'phonelanguage': self.phoneLanguage,
					},
					data: { nombre: sistema.nombre }
				} : {
					method: 'POST',
					url: BASE_API + SYSTEMS_API + "/" + sistema.id,
					data: { nombre: sistema.nombre }
				}
				return $http(req);
			});
		};

		self.verifySystem = function(sistema) {
			return $q.all([userDataService.getPhoneLenguage()])
			.then(function(result){
				if(result[0] == "pt"){
					self.phoneLanguage  = "por"
				  } else if(result[0] == "en"){
					self.phoneLanguage  = "eng"
				  } else if(result[0] == "es"){
					self.phoneLanguage  = "spa"
				  } else {
					self.notProcessedLanguage = window.navigator.userLanguage || window.navigator.language;
	
					if(self.notProcessedLanguage.includes("en")){
						self.phoneLanguage =  "eng";
					} else if(self.notProcessedLanguage.includes("pt")){
						self.phoneLanguage =  "por";
					} else {
						self.phoneLanguage =  "spa";
					}
				  }
			}).then(()=>{
				const req = !self.isWeb ? {
					method: 'POST',
					url: BASE_API + SYSTEMS_API + "/" + sistema.id + "/verify/",
					headers: {
						'phonelanguage': self.phoneLanguage,
					},
					data: { "verificationCode": sistema.verificationCode }
				} : {
					method: 'POST',
					url: BASE_API + SYSTEMS_API + "/" + sistema.id + "/verify/",
					data: { "verificationCode": sistema.verificationCode }
				}
				return $http(req);
			});
		};

		self.deleteSystem = function(sistema) {
			return $http.delete(BASE_API + SYSTEMS_API + "/" + sistema.id, sistema);
		};

		var getSystem = function(id) {
			return $http.get(BASE_API + SYSTEMS_API + "/" + id + "/");
		};

		var sistemaElegido;

		var handleSystemRequest = function(resolve, reject) {
			return function(res) {
				if (res.data && res.data.success) {

					var sistemaDeRespuesta = Object.assign({}, res.data.message.sistema);
		
					if (sistemaDeRespuesta.owner !== undefined) {
						sistemaDeRespuesta.estadoDeCarga = "verificado";
						sistemaElegido = sistemaDeRespuesta;
						return resolve(sistemaElegido);
					}
		
					if (sistemaDeRespuesta.user !== undefined) {
						sistemaDeRespuesta.estadoDeCarga = "verificado";
						sistemaElegido = sistemaDeRespuesta;
						return resolve(sistemaElegido);
					}
					sistemaDeRespuesta.estadoDeCarga = "cargandoVerificationCode";
					sistemaElegido = sistemaDeRespuesta;
					resolve(sistemaElegido);
				} else {
					if (res.status == -1) {
						alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
					}
					return reject(res.data.message);
				}
			};
		};

		self.getSystem = function(id, forced) {
			var deferred = $q.defer();

			if(!forced && sistemaElegido && sistemaElegido.id === id){
				// console.log('Dentro de pido sistema, sin forzar');
				deferred.resolve(sistemaElegido);
				return deferred.promise;
			}

			getSystem(id).then(handleSystemRequest(deferred.resolve, deferred.reject), handleSystemRequest(deferred.resolve, deferred.reject));

			return deferred.promise;
		};

		self.getLastEventReport = function(sistema) {
			return $http.get(
				BASE_API + SYSTEMS_API + "/" + sistema.id + "/lastEventReport"
			);
		};

		self.getTimeZone = function(systemId) {
			return $http.get(
				BASE_API + SYSTEMS_API + "/" + systemId + "/timeZone"
			);
		};

		self.getLastUpdate = function(sistema) {
			return $http.get(
				BASE_API + SYSTEMS_API + "/" + sistema.id + "/lastUpdate"
			);
		};

		self.addSystemUser = function(sistema, user, atributos, eventos) {
			return $http.post(BASE_API + SYSTEMS_API + "/" + sistema.id + "/users/", {
				sistema: sistema,
				systemUserEmail: user.email,
				systemUserType: user.type,
				atributos: atributos,
				eventos: eventos
			});
		};

		self.setSystemUserAtributos = function(sistema, systemUser) {
			return $http.put(
				BASE_API +
					SYSTEMS_API +
					"/" +
					sistema.id +
					"/users/" +
					systemUser.email,
				{
					sistema: sistema,
					systemUser: systemUser
				}
			);
		};

		self.removeSystemUser = function(sistema, systemUserEmail) {
			return $http.delete(
				BASE_API + SYSTEMS_API + "/" + sistema.id + "/users/" + systemUserEmail,
				{
					sistema: sistema,
					systemUserEmail: systemUserEmail
				}
			);
		};

		self.subscribeSystem = function(sistema, systemUserEmail, customerData) {
			return $http.post(
				BASE_API + SYSTEMS_API + "/" + sistema.id + "/subscription/subscribe",
				{
					sistema: sistema,
					systemUserEmail: systemUserEmail,
					customerData: customerData
				}
			);
		};

		self.setSystemProgramation = function(
			sistema,
			programationData,
			timeToLastUpdate
		) {
			return self.retry(function() {
				return $http.post(
					BASE_API + SYSTEMS_API + "/" + sistema.id + "/programation",
					{
						programationData: programationData,
						timeToLastUpdate: timeToLastUpdate,
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 4);
		};

		self.querySystemVersion = function(sistema, seq) {
			return self.retry(function() {
				return $http.post(
					BASE_API + SYSTEMS_API + "/" + sistema.id + "/commands/version",
					{
						seq: seq,
						timeout: 15000
					},
					{
						timeout: 15000
					}
				);
			}, 4);
		};

		self.querySystemProgramation = function(sistema, seq, modoFunc, isYoMonitoreo) {
			return self.retry(function() {
				return $http.post(
					BASE_API + SYSTEMS_API + "/" + sistema.id + "/commands/programation",
					{
						seq: seq,
						modoFunc: modoFunc,
						isYoMonitoreo: isYoMonitoreo,
						timeout: 15000
					},
					{
						timeout: 15000
					}
				);
			}, 4);
		};

		self.queryCommunicatorProgramation = function(sistema, seq) {
			return self.retry(function() {
				return $http.post(
					BASE_API + SYSTEMS_API + "/" + sistema.id + "/commands/communicator_programation",
					{
						seq: seq,
						timeout: 15000
					},
					{
						timeout: 15000
					}
				);
			}, 4);
		};

		self.armarSistema = function(sistema, seq, modo, partNumber) {
			self.setCommandTimeout(sistema.id)
			return self.retry(function() {
				return $http.post(
					BASE_API + SYSTEMS_API + "/" + sistema.id + "/commands/arm/" + modo,
					{
						seq: seq,
						partNumber: partNumber,
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 4);
		};

		self.desarmarSistema = function(sistema, seq, partNumber) {
			self.setCommandTimeout(sistema.id)
			return self.retry(function() {
				return $http.post(
					BASE_API + SYSTEMS_API + "/" + sistema.id + "/commands/disarm",
					{
						seq: seq,
						partNumber: partNumber,
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 4);
		};

		self.inhibirZona = function(sistema, seq, numero, partNumber) {
			self.setCommandTimeout(sistema.id)
			return self.retry(function() {
				return $http.post(
					BASE_API +
						SYSTEMS_API +
						"/" +
						sistema.id +
						"/commands/bypass/" +
						numero,
					{
						seq: seq,
						partNumber: partNumber,
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 4);
		};

		self.desinhibirZona = function(sistema, seq, numero, partNumber) {
			self.setCommandTimeout(sistema.id)
			return self.retry(function() {
				return $http.post(
					BASE_API +
						SYSTEMS_API +
						"/" +
						sistema.id +
						"/commands/unbypass/" +
						numero,
					{
						seq: seq,
						partNumber: partNumber,
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 4);
		};

		self.encenderOutput = function(sistema, seq, numero) {
			self.setCommandTimeout(sistema.id)
			return self.retry(function() {
				return $http.post(
					BASE_API +
						SYSTEMS_API +
						"/" +
						sistema.id +
						"/commands/set_output/" +
						numero,
					{
						seq: seq,
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 4);
		};

		self.apagarOutput = function(sistema, seq, numero) {
			self.setCommandTimeout(sistema.id)
			return self.retry(function() {
				return $http.post(
					BASE_API +
						SYSTEMS_API +
						"/" +
						sistema.id +
						"/commands/unset_output/" +
						numero,
					{
						seq: seq,
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 4);
		};

		self.encenderBell = function(sistema, seq, numero) {
			self.setCommandTimeout(sistema.id)
			return self.retry(function() {
				return $http.post(
					BASE_API + SYSTEMS_API + "/" + sistema.id + "/commands/set_bell",
					{
						seq: seq,
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 4);
		};

		self.apagarBell = function(sistema, seq, numero) {
			self.setCommandTimeout(sistema.id)
			return self.retry(function() {
				return $http.post(
					BASE_API + SYSTEMS_API + "/" + sistema.id + "/commands/unset_bell",
					{
						seq: seq,
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 4);
		};

		self.consultarPunteroEventos = function(sistema, seq) {
			return self.retry(function() {
				return $http.post(
					BASE_API + SYSTEMS_API + "/" + sistema.id + "/commands/last_event",
					{
						seq: seq,
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 4);
		};

		self.consultarEventos = function(sistema, seq, initPos, quantity) {
			return self.retry(function() {
				return $http.post(
					BASE_API + SYSTEMS_API + "/" + sistema.id + "/commands/events",
					{
						seq: seq,
						initPos: initPos,
						quantity: quantity,
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 8);
		};

		self.consultarEventosNuevo = function(sistema) {
			return self.retry(function() {
				return $http.post(
					BASE_API + SYSTEMS_API + "/" + sistema.id + "/commands/newevents",
					{
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 8);
		};

		self.setAutomation = function(sistema, seq, initPos, data) {
			self.setCommandTimeout(sistema.id)
			return self.retry(function() {
				return $http.post(
					BASE_API +
						SYSTEMS_API +
						"/" +
						sistema.id +
						"/commands/automations/set",
					{
						seq: seq,
						initPos: initPos,
						data: data,
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 4);
		};

		self.sendStartProgramation = function(sistema, seq) {
			self.setCommandTimeout(sistema.id)
			return self.retry(function() {
				return $http.post(
					BASE_API +
						SYSTEMS_API +
						"/" +
						sistema.id +
						"/commands/programation/start",
					{
						seq: seq,
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 4);
		};

		self.sendExitProgramation = function(sistema, seq) {
			self.setCommandTimeout(sistema.id)
			return self.retry(function() {
				return $http.post(
					BASE_API +
						SYSTEMS_API +
						"/" +
						sistema.id +
						"/commands/programation/end",
					{
						seq: seq,
						timeout: TIMEOUT_CONSTANT
					},
					{
						timeout: TIMEOUT_CONSTANT
					}
				);
			}, 4);
		};

		self.retry = function(request, counter) {
			var deferred = $q.defer();
			var state = {
				cancel: false,
				request: request
			};

			var doQuery = function() {

				var executedRequest = request();

				state.request = executedRequest;

				executedRequest.then(
					function(response) {
						if (response.status === -1) {
							if (--counter) {
								if (state.cancel) {
									return deferred.reject({
										status: -2 // Cancelado por el usuario
									});
								}
								return doQuery();
							}
							return deferred.reject(response);
						}
						if (response.data && response.data.success == false) {
							if (--counter) {
								if (state.cancel) {
									return deferred.reject({
										status: -2 // Cancelado por el usuario
									});
								}
								return doQuery();
							}
							return deferred.reject(response);
						}
						deferred.resolve(response);
					},
					function(response) {
						deferred.reject(response);
					}
				);
			};

			doQuery();

			var promise = deferred.promise;

			promise.cancelRetry = function() {
				state.cancel = true;
			};

			return promise;
		};

		self.setTabsToSwipe = function(tabs){
			self.tabsToSwipe = tabs
		}

		self.getTabsToSwipe= function(){
			return self.tabsToSwipe
		}
	}

	angular.module("app").service("system", systemService);
})();
