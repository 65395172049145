(function() {
  function MainController(
    $scope,
    ajaxInProgress,
    ymSidenavService,
    deviceService,
    system,
    auth,
    user,
    userDataService,
    $state,
    $window,
    alertService,
    $mdSidenav,
    $log,
    $location,
    editMode,
    __env,
    $q,
    $timeout,
    buildModes,
    BUILD_MODE,
    BUILD_PRODUCT
  ) {
    var self = this;

    if(BUILD_PRODUCT == "titanium_control"){
      self.productName = "Alarm Home";
    };
    
    if(BUILD_PRODUCT == "garnet_control"){
      self.productName = "Garnet Control";
    };

    if(BUILD_PRODUCT == "yo_monitoreo"){
      self.productName = "Yo Monitoreo";
    };

    self.$scope = $scope;

    self.BUILD_MODE = BUILD_MODE;

    self.$state = $state;

    self.__env = __env;

    $q.all([__env.getDeviceData()])
    .then(function(result) {
        self.deviceData = result[0];

        //Si tengo splash y éste es distinto de undefined, seteo el splash en el estado.
        if(result[0].splashImage && result[0].splashImage != "undefined") {
          //Seteo localmente si la app está customizada o no, y su códgigo.
          self.customizationCode = result[0].customizationCode;

          userDataService.setAppCustomizedStateLS(true);
          userDataService.setAppCustomizedCode(self.customizationCode);
          // self.customizationCode = userDataService.getAppCustomizationCode();
          self.splashImage = result[0].splashImage;
          user.setSplashImage(self.setSplashImage);
          self.appbarImageData = result[0].customizationAppbar;
          user.setAppbarImage(self.appbarImageData);
          self.systemlistImage = result[0].customizationSystemlist;
          user.setSystemlistImage(self.systemlistImage);
          self.systemlistImage = result[0].customizationSystemlist;
          user.setCustomizationAttributes(result[0].attributes);
          //
          return
        }

        // Se setea el codigo de empresa en LocalStorage y el estado local.
        userDataService.setAppCustomizedCode(result[0].customizationCode && result[0].customizationCode != "undefined" ? result[0].customizationCode : self.productName);
        //

        //Setear que la app está customizada o no. Para ello me fijo si es igual al nombre de empresa o no.
        userDataService.setAppCustomizedStateLS(result[0].customizationCode != self.productName ? true : false);
        //
    })
    .then(function(){
      self.customizationCode = self.deviceData.customizationCode && self.deviceData.customizationCode != "undefined" ? self.deviceData.customizationCode : self.productName;
      return user.fetchSplash(self.customizationCode);
    })
    .then(function(res){
      if(!res || !res.data) return
      self.splashImage = res.data.message.data;

      user.setSplashImageLS(res.data.message.data);
      user.setSplashImage(res.data.message.data);

      return user.fetchAppbarImage(self.customizationCode);
    })
    .then(function(res){
      if(!res || !res.data) return
        self.appbarImageData = res.data.message.data;
        user.setAppbarImageLS(res.data.message.data);
        user.setAppbarImage(res.data.message.data);
        return user.fetchSystemlistImage(self.customizationCode);
    })
    .then(function(res){
      if(!res || !res.data) return
        user.setSystemlistImageLS(res.data.message.data);
        user.setSystemlistImage(res.data.message.data);
        return user.fetchCustomizationAttributes(self.customizationCode);
    })
    .then(function(res){
      if(!res || !res.data) return
        user.setCustomizationAttributesLS(res.data.message.attributes);
        user.setCustomizationAttributes(res.data.message.attributes);
        self.attributes = res.data.message.attributes;
    });

    self.ajaxInProgressValue = function() {
      return ajaxInProgress.state.value;
    };

    self.editModeValue = function() {
      return editMode.state.value;
    };

    self.systemListActionBarLeftButton = {
      onClickHandler: function() {
        $state.go("controlpanel");
      },
      disabled: function() {
        return self.editModeValue();
      },
      icon: "chevron_left",
      aria: "home"
    };

    self.toggleLeftSidenav = buildToggler("left-sidenav");

    self.sistemaElegido;

    self.pantallaUsuarios = {
      estado: "mostrandoLista"
    };

    $scope.$watch(
      function() {
        return editMode.state.value;
      },
      function(newVal, oldVal) {
        if (newVal === false) {
          if (BUILD_MODE != "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth > 960)) {
             return ymSidenavService.openYmSidenav(); //(ymSidenavOpenStateClass = "ym-sidenav-open");
            }
        }
        ymSidenavService.closeYmSidenav(); // ymSidenavOpenStateClass = "";
      }
    );

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value == "getSharedSystem") {
          system.procesarSistemaCompartidoDeRespuesta(res.data.message.sistema);
          // ajaxInProgress.state.value = false;
          ajaxInProgress.setState({
            value: false
          });
          // $mdSidenav("left-sidenav").close();
        } else if (ajaxInProgress.state.value === "addingDevice") {
          var accessToken = res.data ? res.data.accessToken : null;
          if (accessToken) {
            self.$state.go("controlpanel");

            var userData = res.data ? res.data.userData : null;
            if (userData) {
              self.userData = userData;
            }

            system.listarSistemas().then(handleRequest, handleRequest);

            ajaxInProgress.setState({
              value: "list"
            });
          }
        }

      self.message = res.data.message;
      } else {
        if (res.status == -1) {
          alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
          // ajaxInProgress.state.value = false;
          ajaxInProgress.setState({
            value: false
          });
          return;
        }

        alertService.showAlert("Atención", res.data.message, "OK");
        // ajaxInProgress.state.value = false;
        ajaxInProgress.setState({
          value: false
        });
      }
    }

    self.logout = function() {
      auth.logout && auth.logout();
    };

    function buildToggler(navID) {
      return function() {
        // Component lookup should always be available since we are not using `ng-if`
        $mdSidenav(navID)
          .toggle()
          .then(function() {
            $log.debug("toggle " + navID + " is done");
          });
      };
    }

    self.getTabName = function() {
      // console.log($location.path());
      return $location.path();
    };

    self.gotoState = function(newState) {
      self.$state.go(newState);
    };

    if ($location.path() === "/reset") {
      return;
    }

    if ($location.path() === "/login") {
      return;
    }

    if ($location.path() === "/register") {
      return;
    }

    if ($location.path() === "/register-confirmed") {
      return;
    }

    if ($location.path() === "/registerac4-confirmed") {
      return;
    }

    if ($location.path() === "/register-rejected") {
      return;
    }
  }

  angular.module("app").controller("MainController", MainController);
})();
