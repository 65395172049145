(function() {
  function deviceService($q, $http, BASE_API, auth) {
    var self = this;

    var devicesList = [];

    self.setDevicesList = function(list) {
      devicesList = list;
    };

    self.getDevicesList = function() {
      return devicesList;
    };

    self.list = function(email, password) {
      return $http({
        method: "GET",
        url: BASE_API + "/devices/unauth",
        headers: { "x-user-email": email, "x-user-password": password }
      });
      // return $http.get(BASE_API + "/devices/unauth", {
      //   // List devices
      //   email: email,
      //   password: password
      // });
    };

    self.add = function(email, password, device) {
      return $http.post(BASE_API + "/devices/unauth", {
        email: email,
        password: password,
        device: device
      });
    };

    self.deleteUnauth = function(email, password, deviceId) {
      return $http({
        method: "DELETE",
        url: BASE_API + "/devices/unauth/" + deviceId,
        headers: { "x-user-email": email, "x-user-password": password }
      });
    };

		self.deleteAccount = function() {
			return $http({
				method: "DELETE",
				url: BASE_API + "/user_data/delete_account"
			});
		};

    self.obtainToken = function(email, deviceId, refreshToken) {
      return $http.post(BASE_API + "/devices/refresh", {
        email: email,
        deviceId: deviceId,
        refreshToken: refreshToken
      });
    };

    self.patch = function(device) {
      return $http.patch(BASE_API + "/devices/" + device.id, {
        device: device
      });
    };

    self.delete = function(deviceId) {
      return $http({
        method: "DELETE",
        url: BASE_API + "/devices/" + deviceId
      });
    };
  }

  angular.module("app").service("deviceService", deviceService);
})();
