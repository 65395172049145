(function() {
  function SubscriptionConfirmController($scope, system, $state, ajaxInProgress, editMode, payments, $timeout, alertService, $q, customFormat) {
    console.log("SubscriptionConfirmController created");

    $scope.$on("$destroy", function() {
      console.log("SubscriptionConfirmController destroyed");
    });

    var self = this;
    
    editMode.state.value = false;

    self.$scope = $scope;

    self.$state = $state;

    self.sistemaElegido = system.getSistemaElegido();

    self.planElegido = payments.getPlanElegido();

    self.tarjetaElegida = payments.getTarjetaElegida();

    self.editModeValue = function() {
      return editMode.state.value;
    };

    self.actionBarLeftButton = {
      onClickHandler: function() {
        $state.go("subscription_systemlist");
      },
      disabled: function() {
        return self.editModeValue();
      },
      icon: "chevron_left",
      aria: "home"
    };

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value === "subscribeUser") {
          ajaxInProgress.setState({
            value: false
          });

          if (res.data.subscriptionInfo) {
            self.sistemaElegido.subscriptionInfo = res.data.subscriptionInfo;
          }

          $state.go("subscription.success");

          // if (res.data.message) {
          //   $timeout(function() {
          //     alertService.showAlert("Suscripción", res.data.message, "OK");
          //   }, 0);
          // }
        }
        self.$scope.mainController.message = res.data.message;
        return;
      } else if (res.data && res.data.success === false) {
        if (ajaxInProgress.state.value === "subscribeUser") {
          ajaxInProgress.setState({
            value: false
          });
          $timeout(function() {
            alertService.showAlert("Suscripción", res.data.message, "OK");
          }, 0);
          return;
        }
      } else {
        if (res.status == -1) {
					alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
      }
      alertService.showAlert("Atención", res.data.message, "OK");
      ajaxInProgress.setState({
        value: false
      });
    }

    self.getPlans = function() {
      $timeout(function() {
        ajaxInProgress.setState({
          value: "getPlans",
          label: "Cargando datos del sistema"
        });
        payments.getPlans().then(handleRequest, handleRequest);
      }, 0);
    };

    self.subscribeUser = function(planId) {
      if (!ajaxInProgress.state.value) {
        ajaxInProgress.setState({
          value: "subscribeUser",
          label: "Suscribiendote"
        });

        // payments.subscribeUser(self.sistemaElegido, planId).then(handleRequest, handleRequest);

        var deferred = $q.defer();

        setTimeout(function(){
          deferred.resolve({ data: { success: true, subscriptionInfo: {} } });
        }, 1000);

        deferred.promise.then(handleRequest, handleRequest);
      }
    };

    self.getSuscriptoHasta = function() {
      var validThru = new Date(self.sistemaElegido.subscriptionInfo.validThru);
      return validThru.getDate() + "/" + (validThru.getMonth() + 1) + "/" + validThru.getFullYear();
    };

    self.isSubscriptionDataVisible = function() {
      if (!self.sistemaElegido) {
        return false;
      }

      if (self.sistemaElegido.owner && typeof self.sistemaElegido.owner.userType == "number" && self.sistemaElegido.owner.userType == 0) {
        return true;
      }

      return false;
    };

    self.isSubscribeButtonVisible = function() {
      if (!self.sistemaElegido) {
        return false;
      }

      if (!self.sistemaElegido.subscriptionInfo) {
        return false;
      }

      if (self.sistemaElegido.subscriptionInfo.planType === 3) {
        //FREE_SUB
        return true;
      }

      return false;
    };

    self.isCancelarButtonVisible = function() {
      if (!self.sistemaElegido) {
        return false;
      }

      if (!self.sistemaElegido.subscriptionInfo) {
        return false;
      }

      var subscriptionStatus = self.sistemaElegido.subscriptionInfo.status;

      if (subscriptionStatus == 1) {
        return true;
      }
      if (subscriptionStatus == 3) {
        return true;
      }
      return false;
    };

    self.format = customFormat.format;
  }
  angular.module("app").controller("SubscriptionConfirmController", SubscriptionConfirmController);
})();
