(function() {
  var camerasMock = [
    {
      deviceSerial: "182077883",
      deviceName: "Mini O(182077883)",
      deviceType: "CS-CV206-C0-1A1WFR",
      status: 1,
      defence: 0,
      deviceVersion: "V5.2.4 build 180323",
      updateTime: "2019-02-27 11:03:08"
      // picUrl: "http://pmssa1.ezvizlife.com:8080/image/pic/0749ff80-41cd-49e7-952a-0171d00e20ef"
    },
    // {
    //   deviceSerial: "182077884",
    //   deviceName: "Mini O(182077883)",
    //   deviceType: "CS-CV206-C0-1A1WFR",
    //   status: 1,
    //   defence: 0,
    //   deviceVersion: "V5.2.4 build 180323",
    //   updateTime: "2019-02-27 11:03:08",
    //   picUrl: "http://pmssa1.ezvizlife.com:8080/image/pic/ec00ddd2-d356-4145-b3be-25c4889482f5"
    // },
    {
      deviceSerial: "182077885",
      deviceName: "Mini O(182077883)",
      deviceType: "CS-CV206-C0-1A1WFR",
      status: 1,
      defence: 0,
      deviceVersion: "V5.2.4 build 180323",
      updateTime: "2019-02-27 11:03:08"
      // picUrl: "http://pmssa1.ezvizlife.com:8080/image/pic/13a08d2c-b461-4cae-b258-bbac5c3bae3e"
    }
  ];

  var fullCamerasMock = [
    {
      deviceSerial: "182077883",
      deviceName: "Mini O(182077883)",
      deviceType: "CS-CV206-C0-1A1WFR",
      status: 1,
      defence: 0,
      deviceVersion: "V5.2.4 build 180323",
      updateTime: "2019-02-27 11:03:08"
      // picUrl: "http://pmssa1.ezvizlife.com:8080/image/pic/0749ff80-41cd-49e7-952a-0171d00e20ef"
    },
    {
      deviceSerial: "182077884",
      deviceName: "Mini O(182077883)",
      deviceType: "CS-CV206-C0-1A1WFR",
      status: 1,
      defence: 0,
      deviceVersion: "V5.2.4 build 180323",
      updateTime: "2019-02-27 11:03:08"
      // picUrl: "http://pmssa1.ezvizlife.com:8080/image/pic/ec00ddd2-d356-4145-b3be-25c4889482f5"
    },
    {
      deviceSerial: "182077885",
      deviceName: "Mini O(182077883)",
      deviceType: "CS-CV206-C0-1A1WFR",
      status: 1,
      defence: 0,
      deviceVersion: "V5.2.4 build 180323",
      updateTime: "2019-02-27 11:03:08"
      // picUrl: "http://pmssa1.ezvizlife.com:8080/image/pic/13a08d2c-b461-4cae-b258-bbac5c3bae3e"
    }
  ];

  function videoService($q, $http, BASE_API, auth) {
    var self = this;

    self.getSubAccessToken = function(sistema) {
      return $http({ method: "GET", url: BASE_API + "/video/" + sistema.id + "/access_token" });
    };

    self.editCameraName = function(nombre, deviceSerial, sistema){
      return $http.put(
        BASE_API + "/video/" + sistema.id + "/" + deviceSerial + "/edit_name",
        {
          nombre: nombre,
        }
      );
      // return $http({ method: "PUT", url: BASE_API + "/video/" + sistema.id + "/edit_name" });
    }

    self.list = function(sistema, subAccountAccessToken) {
      return $http({ method: "GET", url: BASE_API + "/video/" + sistema.id + "/", headers: { "x-sub-account-access-token": subAccountAccessToken } });
      // return $q.resolve({
      //   data: {
      //     success: true,
      //     cameraList: camerasMock
      //   }
      // });
    };

    self.add = function(sistema, subAccountAccessToken, cameraId, validateCode) {
			return self.retry(function() {
        return $http({
          method: "POST",
          url: BASE_API + "/video/" + sistema.id + "/" + cameraId,
          headers: { "x-sub-account-access-token": subAccountAccessToken },
          data: {
            validateCode: validateCode
          }
        });
			}, 20);
      // return $q.resolve({
      //   data: {
      //     success: true,
      //     cameraList: fullCamerasMock
      //   }
      // });
    };

    self.remove = function(sistema, subAccountAccessToken, cameraId) {
      return $http({ method: "DELETE", url: BASE_API + "/video/" + sistema.id + "/" + cameraId, headers: { "x-sub-account-access-token": subAccountAccessToken } });
      // return $q.resolve({
      //   data: {
      //     success: true,
      //     cameraList: camerasMock
      //   }
      // });
    };
    
    self.capturePic = function(sistema, subAccountAccessToken, cameraId) {
      // return $http({
      //   method: "POST",
      //   url: "https://isaopen.ezvizlife.com/api/lapp/device/capture",
      //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
      //   data: { accessToken: subAccountAccessToken, deviceSerial: cameraId, channelNo: "1" }
      // });
      return $http({ method: "GET", url: BASE_API + "/video/" + sistema.id + "/pic/" + cameraId, headers: { "x-sub-account-access-token": subAccountAccessToken } });
    };

    self.retry = function(request, counter) {
			var deferred = $q.defer();
			var state = {
				cancel: false,
				request: request
			};

			var doQuery = function() {
				var executedRequest = request();

				state.request = executedRequest;

				executedRequest.then(
					function(response) {
						if (response.status === -1) {
							if (--counter) {
								if (state.cancel) {
									return deferred.reject({
										status: -2 // Cancelado por el usuario
									});
                }
                return setTimeout(function(){
                  doQuery();
                }, 3000);
								// return doQuery();
							}
							return deferred.reject(response);
						}
						if (response.data && response.data.success == false) {
							if (--counter) {
								if (state.cancel) {
									return deferred.reject({
										status: -2 // Cancelado por el usuario
									});
                }
                return setTimeout(function(){
                  doQuery();
                }, 3000);
								// return doQuery();
							}
							return deferred.reject(response);
						}
						deferred.resolve(response);
					},
					function(response) {
						deferred.reject(response);
					}
				);
			};

			doQuery();
			var promise = deferred.promise;

			promise.cancelRetry = function() {
				state.cancel = true;
			};

			return promise;
		};
  }

  //"https://isaopen.ezvizlife.com/api/lapp/device/capture";
  //application/x-www-form-urlencoded
  //accesstoken
  //deviceSerial
  //channelNo

  angular.module("app").service("videoService", videoService);
})();
