(function() {
  function authService($window, $location, __env, BUILD_MODE, BUILD_PLATFORM, $q, localStorageService, $state) {
    var self = this;

    self.parseJwt = function(accessToken) {
      var base64Url = accessToken.split(".")[1];
      var base64 = base64Url.replace("-", "+").replace("_", "/");
      return JSON.parse($window.atob(base64));
    };

    self.saveUserEmail = function(userEmail) {
      return localStorageService.setItem("userEmail", userEmail);
    };

    self.saveAccessToken = function(accessToken) {
      return localStorageService.setItem("accessToken", accessToken);
    };

    self.saveRefreshToken = function(refreshToken) {
      return localStorageService.setItem("refreshToken", refreshToken);
    };

    self.getUserEmail = function() {
      return localStorageService.getItem("userEmail");
    };

    self.getAccessToken = function() {
      return localStorageService.getItem("accessToken");
    };

    self.getRefreshToken = function() {
      return localStorageService.getItem("refreshToken");
    };

    self.logout = function() {
      // prettier-ignore
      $q.all([localStorageService.removeItem("userEmail"), localStorageService.removeItem("accessToken"), localStorageService.removeItem("refreshToken")])
        .then(function() {
          // $location.url("login"); 
          $state.go("login", undefined, { custom: { forced: true } })
        });
    };
  }

  angular.module("app").service("auth", authService);
})();
