(function() {
	function userService($q, $http, BASE_API, auth,localStorageService) {
		var self = this;

		var customizationAppbar;
		var customizationSystemlist;
		var customizationAtrributes;
		var splashImage;

		// self.getQuote = function() {
		//   return $http.get(BASE_API + "/auth/quote");
		// };
	
		/// Rutas de la customización
		/// FETCH
		self.fetchSplash = function(codigoEmpresa) {
			return $http.get(BASE_API + '/customization/splash/' + codigoEmpresa.replace(" ", ""));
		};

		self.fetchAppbarImage = function(codigoEmpresa) {
			return $http.get(BASE_API + '/customization/isotipo_appbar/' + codigoEmpresa);
		};

		self.fetchSystemlistImage = function(codigoEmpresa) {
			return $http.get(BASE_API + '/customization/isotipo_systemlist/' + codigoEmpresa);
		};

		self.fetchCustomizationAttributes = function(codigoEmpresa) {
			return $http.get(BASE_API + '/customization/attributes/' + codigoEmpresa);
		};

		///LocalStorageSet
		self.setSplashImageLS = function(imageData) {
			localStorageService.setItem("splashImage", imageData);
		};

		self.setAppbarImageLS = function(imageData) {
			localStorageService.setItem("customizationAppbar", imageData);
		};

		self.setSystemlistImageLS = function(imageData) {
			localStorageService.setItem("customizationSystemlist", imageData);
		};

		self.setCustomizationAttributesLS = function(attributes) {
			localStorageService.setItem("attributes", JSON.stringify(attributes));
		};

		/// SET
		self.setSplashImage = function(imageData) {
			splashImage = imageData;
		};

		self.setAppbarImage = function(imageData) {
			customizationAppbar = imageData;
		};

		self.setSystemlistImage = function(imageData) {
			customizationSystemlist = imageData;
		};

		self.setCustomizationAttributes = function(attributes) {
			customizationAtrributes = attributes;
		};

		/// GET
		self.getSplashImage = function(){
			return splashImage;
		}

		self.getAppBarImage = function(){
			return customizationAppbar;
		}
		self.getSystemlistImage = function(){
			return customizationSystemlist;
		}
		self.getCustomizationAttributes = function(){
			return (typeof customizationAtrributes == 'string' && customizationAtrributes != "undefined") ? JSON.parse(customizationAtrributes): customizationAtrributes;
		}
		////


		self.setEmail = function(newEmail) {
			self.email = newEmail;
		};

		self.getEmail = function() {
			return self.email;
		};

		self.setPassword = function(newPassword) {
			self.password = newPassword;
		};

		self.getPassword = function() {
			return self.password;
		};

		self.clearEmailAndPassword = function() {
			self.password = undefined;
			self.email = undefined;
		};

		self.register = function(user, language) {
			return $http.post(BASE_API + "/auth/register", {
				email: user.email.toLowerCase(),
				password: user.password,
				nombre: user.nombre,
				apellido: user.apellido,
				apodo: user.apodo,
				language: language,
				// RECAPCHA
				// reCaptchaToken: user.reCaptchaToken
			});
		};

		self.login = function(email, password) {
			return $http.post(BASE_API + "/auth/login", {
				email: email,
				password: password
			});
		};

		// RECAPCHA
		// self.forgot = function(email, reCaptchaToken) {
		self.forgot = function(email, language) {
			return $http.post(BASE_API + "/auth/forgot", {
				email: email,
				language: language
				// reCaptchaToken: reCaptchaToken
			});
		};

		self.reset = function(token, password) {
			return $http.post(BASE_API + "/auth/reset/" + token, {
				password: password
			});
		};

		self.resetMobile = function(token, password) {
			return $http.post(BASE_API + "/auth/resetMobile/" + token, {
				password: password
			});
		};

		self.getUserData = function() {
			return $http.get(BASE_API + "/user_data/");
		};

		self.code;

		self.verifyUserRegistration = function(){
			return $http.get(BASE_API + "/auth/verifyMobile/" + self.code);
		}

		self.setCode = function(code) {
			self.code = code;
		}

		self.getCode = function() {
			return self.code
		}
		
		self.resetCode = function(){
			self.code = null	
		}

		self.editUserData = function(nombre, apellido, avatar, partKeyInfo, partitionNames,id, icono, codigoEmpresa, codigoEmpresaUpdated) {
			return $http.put(BASE_API + "/user_data/", {
				nombre: nombre,
				apellido: apellido,
				avatar: avatar,
				codigoEmpresa: codigoEmpresa,
				codigoEmpresaUpdated: codigoEmpresaUpdated,
				partKeyInfo: partKeyInfo,
				partitionNames: partitionNames,
				id: id,
				icono: icono
			});
		};

		self.setSystemName = function(sistema, nombre) {
			return $http.post(BASE_API + "/user_data/system_name", {
				sistema: sistema,
				nombre: nombre,
			});
		};

		self.setSystemIcon = function(sistema, icono) {
			return $http.post(BASE_API + "/user_data/system_icon", {
				sistema: sistema,
				icono: icono
			});
		};

		// self.setUserAvatar = function(avatar) {
		//   return $http.put(BASE_API + "/user_data/avatar", {
		//     avatar: avatar
		//   });
		// };
	}

	angular.module("app").service("user", userService);
})();
