(function(angular) {
  function YmStepperController($scope, $element, ajaxInProgress, $transitions, $state) {
    var ctrl = this;
    // $state.includes("contacts");
    ctrl.selectedStep = 0;

    var currentState = $state.current.name;

    if (currentState) {
      if (currentState.search(/subscription\.info/) > -1) {
        ctrl.selectedStep = 0;
      }

      if (currentState.search(/subscription\.plans/) > -1) {
        ctrl.selectedStep = 1;
      }

      if (currentState.search(/subscription\.payment/) > -1) {
        ctrl.selectedStep = 2;
      }

      if (currentState.search(/subscription\.confirm/) > -1) {
        ctrl.selectedStep = 3;
      }

      if (currentState.search(/subscription\.success/) > -1) {
        ctrl.selectedStep = 4;
      }

      if (currentState.search(/subscription\.error/) > -1) {
        ctrl.selectedStep = 5;
      }
    }

    ctrl.isVisible = function() {
      if (ctrl.selectedStep == 0) {
        return false;
      }
      if (ctrl.selectedStep > 3) {
        return false;
      }
      return true;
    };

    $transitions.onSuccess({ to: "subscription.**" }, function(transition) {
      var currentState = transition.to().name;

      if (currentState.search(/subscription\.info/) > -1) {
        ctrl.selectedStep = 0;
      }

      if (currentState.search(/subscription\.plans/) > -1) {
        ctrl.selectedStep = 1;
      }

      if (currentState.search(/subscription\.payment/) > -1) {
        ctrl.selectedStep = 2;
      }

      if (currentState.search(/subscription\.confirm/) > -1) {
        ctrl.selectedStep = 3;
      }

      if (currentState.search(/subscription\.success/) > -1) {
        ctrl.selectedStep = 4;
      }

      if (currentState.search(/subscription\.error/) > -1) {
        ctrl.selectedStep = 5;
      }
    });
  }

  angular.module("app").component("ymStepper", {
    templateUrl: "components/YmStepper/YmStepper.html",
    controller: YmStepperController
    // bindings: {
    // selectedStep: "<",
    // onStepClick: "="
    // }
  });
})(window.angular);
