(function() {
	function ResetController(
		__env,
		ajaxInProgress,
		$state,
		user,
		userDataService,
		alertService,
		$location,
		BUILD_PRODUCT
	) {
		var self = this;

		if(BUILD_PRODUCT == "titanium_control"){
			self.productName = "Alarm Home";
		  }
		  if(BUILD_PRODUCT == "garnet_control"){
			self.productName = "Garnet Control";
		  }
		  if(BUILD_PRODUCT == "yo_monitoreo"){
			self.productName = "Yo Monitoreo";
		  }

		var PASSWORD_LOWWORD_REGEXP = /([a-z])+/;
		var PASSWORD_HIGHWORD_REGEXP = /([A-Z])+/;
		var PASSWORD_NUMBER_REGEXP = /([0-9])+/;
		var PASSWORD_SPECIAL_REGEXP = /([\.\+\-])+/;
		// self.$scope = $scope;
		self.user = {};

		var level;
		self.progressLinearText;

		self.appIsCustomized = userDataService.getAppIsCustomized();
	
		if(self.appIsCustomized){
		  self.appbarImageData = user.getAppBarImage();
		  self.attributes = user.getCustomizationAttributes();
		}

		self.getProgressBarClass = function() {
			// console.log("me estoy ejecutando", level);
			if (level == 0 || level == 25) {
				self.progressLinearText = "Tu contraseña es insegura";
				return { "ym-bar-red": true };
			} else if (level == 50) {
				self.progressLinearText = "Tu contraseña es poco segura";
				return { "ym-bar-yellow": true };
			} else if (level == 75) {
				self.progressLinearText = "Tu contraseña es segura";
				return { "ym-bar-green": true };
			} else if (level == 100) {
				self.progressLinearText = "Tu contraseña es muy segura";
				return { "ym-bar-green-success": true };
			}
		};

		self.checkPasswordLevel = function() {
			// console.log("me estoy ejecutando", level);
			level = 0;
			if (
				!self.user.password ||
				self.user.password.length > 20 ||
				self.user.password.length < 8
			) {
				return level + 25;
			}
			if (PASSWORD_LOWWORD_REGEXP.test(self.user.password)) {
				level = level + 25;
			}
			if (PASSWORD_HIGHWORD_REGEXP.test(self.user.password)) {
				level = level + 25;
			}
			if (PASSWORD_NUMBER_REGEXP.test(self.user.password)) {
				level = level + 25;
			}
			if (PASSWORD_SPECIAL_REGEXP.test(self.user.password)) {
				level = level + 25;
			}
			return level;
		};

		self.resetPassword = function() {
			if (!ajaxInProgress.state.value) {
				ajaxInProgress.setState({
					value: "reset"
				});

		var resetToken = $location.search().token || user.getCode()		
				user
					.reset(resetToken, self.user.password)
					.then(handleRequest, handleRequest);
			}
		};

		self.gotoLogin = function() {
			$state.go("login");
		};

		function handleRequest(res) {
			if (res.data && res.data.success) {
				if (ajaxInProgress.state.value == "reset") {
					$state.go("login");
					self.user = {};
					alertService.showAlert(
						"Contraseña reseteada",
						"Tu contraseña ha sido reseteada. Ya puedes loguearte con tu nueva contraseña.",
						"OK"
					);
					ajaxInProgress.setState({
						value: false
					});
				}
				self.message = res.data.message;
			} else {
				if (res.status == -1) {
					alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");

					// ajaxInProgress.state.value = false;
					ajaxInProgress.setState({
						value: false
					});
					return;
				}
				$state.go("login");
				alertService.showAlert("Atención", res.data.message, "OK");
				ajaxInProgress.setState({
					value: false
				});
			}
		}
	}

	angular.module("app").controller("ResetController", ResetController);
})();
