(function() {
  function HourPickerController($scope, $element, ajaxInProgress, customFormat) {
    var ctrl = this;
    // ctrl.$scope = $scope;
    ctrl.ajaxInProgress = ajaxInProgress;

    ctrl.$onInit = function() {
      var createField = function(initialValue, min, max) {
        var self = {};

        var numberMin = parseInt(min);
        var numberMax = parseInt(max);

        self.value = initialValue;

        self.increaseValue = function() {
          var value = parseInt(self.value) + 1;
          if (value > numberMax) {
            self.value = min;
            return ctrl.handleChange();
          }
          self.value = customFormat.format(2, value);
          ctrl.handleChange();
        };

        self.decreaseValue = function() {
          var value = parseInt(self.value) - 1;
          if (value < numberMin) {
            self.value = max;
            return ctrl.handleChange();
          }
          self.value = customFormat.format(2, value);
          ctrl.handleChange();
        };

        self.keyup = function() {
          if (isNaN(self.value)) {
            self.value = min;
            return ctrl.handleChange();
          }

          var value = parseInt(self.value);

          if (value < numberMin) {
            self.value = min;
            return ctrl.handleChange();
          }

          if (value > numberMax) {
            self.value = max;
            return ctrl.handleChange();
          }
          ctrl.handleChange();
        };

        self.keydown = function($event) {
          //Backspace
          if ($event.keyCode === 8) {
            return;
          }

          //Inicio, fin, pag. up, pag. down, up, right, down, left arrows
          if ($event.keyCode > 32 && $event.keyCode < 41) {
            return;
          }

          //Números del 0 al 9
          if ($event.keyCode > 47 && $event.keyCode < 58) {
            return;
          }

          //Números del 0 al 9 en numpad
          if ($event.keyCode > 95 && $event.keyCode < 106) {
            return;
          }

          $event.preventDefault();
        };

        self.blur = function() {
          if (isNaN(parseInt(self.value))) {
            self.value = min;
            return ctrl.handleChange();
          }
        };

        return self;
      };
      ctrl.hours = createField(customFormat.format(2, ctrl.initialHours), "00", "23");
      ctrl.minutes = createField(customFormat.format(2, ctrl.initialMinutes), "00", "59");
    };

    ctrl.handleChange = function() {
      ctrl.changesHandler({ hours: parseInt(ctrl.hours.value), minutes: parseInt(ctrl.minutes.value) });
    };

    ctrl.$onChanges = function(changesObj) {
      // console.log("HourPickerController $onChanges");
      // ctrl.changesHandler({ hours: parseInt(hours.value), minutes: parseInt(minutes.value) });
    };
  }

  angular.module("app").component("hourpicker", {
    templateUrl: "components/HourPicker/hourPicker.html",
    controller: HourPickerController,
    bindings: {
      initialHours: "<",
      initialMinutes: "<",
      changesHandler: "="
    }
  });
})();
