(function() {
  function ymSidenavService() {
    var self = this;

    var ymSidenavOpenStateClass = "ym-sidenav-open";

    self.ymSidenavOpenStateClass = function() {
      return ymSidenavOpenStateClass;
    };

    self.toggleYmSidenav = function() {
      ymSidenavOpenStateClass = ymSidenavOpenStateClass === "ym-sidenav-open" ? "" : "ym-sidenav-open";
    };

    self.closeYmSidenav = function() {
      ymSidenavOpenStateClass = "";
    };

    self.openYmSidenav = function() {
      ymSidenavOpenStateClass = "ym-sidenav-open";
    };

    self.openYmSidenav();
  }
  angular.module("app").service("ymSidenavService", ymSidenavService);
})();
