(function() {
  function enviromentService($window, BUILD_MODE, BUILD_PLATFORM, localStorageService, $q, APP_VERSION, deferredOperations) {
    var self = this;
    self.deviceDataDebug = "empty";

    self.getDeviceId = function() {
        return localStorageService.getItem("deviceId");        
    };

    self.getDeviceDataDebug = function(){
      return self.deviceDataDebug;
    }

    self.getDeviceOS = function() {
        return localStorageService.getItem("deviceOS");
    };

    self.getDeviceOSVersion = function() {
        return localStorageService.getItem("deviceOSVersion");
    };

    self.getDeviceName = function() {
        return localStorageService.getItem("deviceName");
    };

    self.getAppVersion = function() {
        return localStorageService.getItem("appVersion");
    };

    // RemoteNotificationsId
    self.getDeviceRemoteNotificationsId = function() {
        return localStorageService.getItem("remoteNotificationsId");
    };

    self.getLastTimeUpdated = function() {
        return localStorageService.getItem("lastTimeUpdated");
    };

    self.setLastTimeUpdated = function(when) {
        return localStorageService.setItem("lastTimeUpdated", when);
    };

    self.getDeviceData = function() {
        var deferred = $q.defer();

        $q.all([
          localStorageService.getItem("deviceId"),
          localStorageService.getItem("deviceName"),
          localStorageService.getItem("appVersion"),
          localStorageService.getItem("deviceOS"),
          localStorageService.getItem("deviceOSVersion"),
          localStorageService.getItem("remoteNotificationsId"),
          localStorageService.getItem("lastTimeUpdated"),
          localStorageService.getItem("urlToRedirect"),
          localStorageService.getItem("verifyAccount"),
          localStorageService.getItem("notificationType"),
          localStorageService.getItem("appIsCustomized"),
          localStorageService.getItem("customizationAppbar"),
          localStorageService.getItem("customizationSystemlist"),
          localStorageService.getItem("attributes"),
          localStorageService.getItem("customizationCode"),
          localStorageService.getItem("splashImage"),
          localStorageService.getItem("phoneLanguage"),
        ])
          .then(function(result) {
            self.deviceDataDebug = result;
            var deviceData = {
              id: result[0],
              name: result[1],
              appVersion: result[2],
              os: result[3],
              osVersion: result[4],
              remoteNotificationsId: result[5],
              lastTimeUpdated: result[6],
              urlToRedirect: result[7],
              verifyAccount: result[8],
              notificationType: result[9],
              appIsCustomized: result[10],
              customizationAppbar: result[11],
              customizationSystemlist: result[12],
              attributes: result[13],
              customizationCode: result[14],
              splashImage: result[15],
              phoneLanguage: result[16]
            };

            if(deviceData.phoneLanguage === "pt"){
              deviceData.phoneLanguage = "por"
            }
            if(deviceData.phoneLanguage === "en"){
              deviceData.phoneLanguage = "eng"
            }
            if(deviceData.phoneLanguage === "es"){
              deviceData.phoneLanguage = "spa"
            }
            
            if (deviceData.appVersion === APP_VERSION) {
              // deviceData.appVersion = "1.3.1"
              deferred.resolve(deviceData);
              return;
            }
            console.log("DEVICE DATA", deviceData);
            return $q.all([deviceData, localStorageService.setItem("appVersion", APP_VERSION), localStorageService.setItem("lastTimeUpdated", "0")]);
          })
          .then(function(promises) {
            if (promises) {
              promises[0].appVersion = APP_VERSION;
              deferred.resolve(promises[0]);
            }
          })
          .catch(function(err) {
              console.log("Error en Environment Service", err);
              // deferred.reject(err);
          });

      return deferred.promise;
    };

    self.viewContentLoaded = function() {
      console.log("EnviromentService::viewContentLoaded()");
      var deferred = $q.defer();

      if (BUILD_PLATFORM === "Android") {
        deferred.resolve($window.HostInterface.viewContentLoaded()); //.playLiveVideo(appKey, accessToken, cameraId, validateCode));
        return;
      }

      if (BUILD_PLATFORM === "iOS") {
        return deferredOperations.do(function(operationId) {
          window.webkit.messageHandlers.HostInterface.postMessage("viewContentLoaded()");
        });
      }
    };

    self.appArray = [
      {
        nameToShow: "EZVI‪Z‬",
        appName: "Ezviz",
        appPackage :"com.ezviz",
        appPlayStoreUri: "https://play.google.com/store/apps/details?id=com.ezviz&hl=es_AR&gl=US",
        appStoreUrl:"https://apps.apple.com/mx/app/ezviz/id886947564",
        appBundleId:"com.hikvision.ezviz://",
        picUrl: "img/assets/cameraLink/Ezviz.png",
      },
      {
        nameToShow: "Dahua - GdmssLite",
        appName: "GdmssLite",
        appPackage :"com.mm.android.direct.gdmssphoneLite",
        appPlayStoreUri: "https://play.google.com/store/apps/details?id=com.mm.android.direct.gdmssphoneLite&hl=es_AR&gl=US",
        picUrl: "img/assets/cameraLink/gdmss.png",
      },
      {
        nameToShow: "Dahua - GdmssPlus",
        appName: "GdmssPlus",
        appPackage :"com.mm.android.direct.gdmssphone",
        appPlayStoreUri: "https://play.google.com/store/apps/details?id=com.mm.android.direct.gdmssphone&hl=es_AR&gl=US",
        picUrl: "img/assets/cameraLink/gdmss.png",
      },
      {
        nameToShow: "Hik - IVMS-4500",
        appName: "IVMS-4500", //Hik-Connect
        appPackage :"com.mcu.iVMS",
        appPlayStoreUri: "https://play.google.com/store/apps/details?id=com.mcu.iVMS&hl=es_AR&gl=US",
        picUrl: "img/assets/cameraLink/IVMS4500.png",
      },
      {
        nameToShow: "Hik - IVMS-4500-HD",
        appName: "IVMS-4500-HD", //Hik-Connect-Plus
        appPackage :"com.mcu.iVMSHD",
        appPlayStoreUri: "https://play.google.com/store/apps/details?id=com.mcu.iVMSHD&hl=es_AR&gl=US",
        picUrl: "img/assets/cameraLink/IVMS4500HD.png",
      },
      {
        nameToShow: "Dahua - Imou-life",
        appName: "Imou-life", //	Imou Life (Dahua residencial WiFi)
        appPackage :"com.mm.android.smartlifeiot",
        appPlayStoreUri: "https://play.google.com/store/apps/details?id=com.mm.android.smartlifeiot&hl=es_AR&gl=US",
        picUrl: "img/assets/cameraLink/imoulife.png",
      },
      {
        nameToShow: "Dahua - DMSS",
        appName: "DMSS", // (Dahua Residencial)
        appPackage :"com.mm.android.DMSS",
        appPlayStoreUri: "https://play.google.com/store/apps/details?id=com.mm.android.DMSS&hl=es_AR&gl=US",
        picUrl: "img/assets/cameraLink/DMSS.png",
      },
      {
        nameToShow: "Longse - BitVision",
        appName: "BitVision",
        appPackage :"com.gzch.lsplat.lsbtvapp",
        appPlayStoreUri: "https://play.google.com/store/apps/details?id=com.gzch.lsplat.lsbtvapp&hl=es_AR&gl=US",
        picUrl: "img/assets/cameraLink/bitvision.png",
      }
    ];


    self.openVideoApp = function(appName) {
      console.log("EnviromentService::openApp()");
      console.log("Nombre de la APP: ", appName);
      var deferred = $q.defer();

      var appToOpen = self.appArray.find(function(app){
        return app.appName == appName
      })

      if (BUILD_PLATFORM === "Android") {
        deferred.resolve($window.HostInterface.openApp(appToOpen.appPackage, appToOpen.appPlayStoreUri)); //.playLiveVideo(appKey, accessToken, cameraId, validateCode));
        return;
      }

      if (BUILD_PLATFORM === "iOS") {
        return deferredOperations.do(function(operationId) {
          window.webkit.messageHandlers.HostInterface.postMessage('openApp("' + appToOpen.appBundleId + '", "' + appToOpen.appStoreUrl + '")');
        });
      }
    };
  }
  angular.module("app").service("__env", enviromentService);
})();
