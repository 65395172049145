(function(angular) {
  function emergencyPanelController($scope, $element,BUILD_MODE, ajaxInProgress) {
    var ctrl = this;
    // ctrl.$scope = $scope;
    ctrl.ajaxInProgress = ajaxInProgress;

    ctrl.$onInit = function() {
    };

    ctrl.$onChanges = function(changesObj) {
      ctrl.setPartView(ctrl.cantidad);
      // ctrl.appear = false;
    };

    ctrl.isMobile = BUILD_MODE == "MOBILE";
    ctrl.isWeb = BUILD_MODE == "WEB";

    ctrl.generarEmergenciaPanico = function(){
      ctrl.panicEmergencyGenerator();
    }

    ctrl.generarEmergenciaMedica = function(){
      ctrl.medicEmergencyGenerator();
    }

    ctrl.generarEmergenciaIncendio = function(){
      ctrl.fireEmergencyGenerator();
    }

    ctrl.setPartView = function(cantidad){
      ctrl.Mostrar = cantidad
    }
}

  angular.module("app").component("emergencypanel", {
    templateUrl: "components/EmergencyPanel/emergencyPanel.html",
    controller: emergencyPanelController,
    bindings: {
      particion: "<",
      cantidad : "<",
      panicEmergencyGenerator: "&",
      medicEmergencyGenerator: "&",
      fireEmergencyGenerator: "&"
    }
  });
})(window.angular);
