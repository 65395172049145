(function(angular) {
  function EstadoParticionController($scope, $element, ajaxInProgress, BUILD_MODE) {
    var ctrl = this;
    // ctrl.$scope = $scope;
    ctrl.ajaxInProgress = ajaxInProgress;

    ctrl.$onInit = function() {
      ctrl.partition.editedName =
        ctrl.partition.name || "Partición " + ctrl.partition.number;
    };

    ctrl.isMobile = BUILD_MODE == "MOBILE";
    ctrl.isWeb = BUILD_MODE == "WEB";
    
    ctrl.$onChanges = function() {
      // console.log("$onChanges");
      // ctrl.appear = false;
      ctrl.setPartView(ctrl.cantidad);
      ctrl.parseStatus(ctrl.status);
    };

    ctrl.armar = function(modo) {
      ctrl.onArmar({ modo: modo });
    };

    ctrl.swipeLeftGesture = function(){
      ctrl.swipeLeft()
    }

    ctrl.desarmar = function() {
      ctrl.onDesarmar();
    };

    ctrl.parseStatus = function(status) {
      var oldColor = ctrl.partitionStatusColor;

      if (status) {
        ctrl.currentPartitionStatus =
          status.partitionStatus[ctrl.partition.number - 1];
        if (ctrl.currentPartitionStatus === "DEMORADO") {
          ctrl.partitionStatusText = "ARMADO PRESENTE";
          ctrl.partitionStatusColor = "naranja";
        } else if (ctrl.currentPartitionStatus === "INSTANTANEO") {
          ctrl.partitionStatusText = "ARMADO PRESENTE";
          ctrl.partitionStatusColor = "naranja";
        } else if (ctrl.currentPartitionStatus === "ARMADO") {
          ctrl.partitionStatusText = "ARMADO";
          ctrl.partitionStatusColor = "rojo";
        } else if (ctrl.currentPartitionStatus === "LISTO") {
          ctrl.partitionStatusText = "DESARMADO";
          ctrl.partitionStatusColor = "verde";
        } else if (ctrl.currentPartitionStatus === "NO_LISTO") {
          ctrl.partitionStatusText = "ZONAS ABIERTAS";
          ctrl.partitionStatusColor = "gris";
        }
      } else {
        ctrl.partitionStatusColor = "gris";
      }
    };
    
    ctrl.setPartView = function(cantidad){
      ctrl.Mostrar = cantidad
    };
  }

  angular.module("app").component("estadoParticion", {
    templateUrl: "components/EstadoParticion/estadoParticion.html",
    controller: EstadoParticionController,
    bindings: {
      status: "<",
      onArmar: "&",
      onDesarmar: "&",
      swipeLeft: "&",
      cantidad : "<",
      editMode: "<",
      partition: "<"
    }
  });
})(window.angular);
