(function() {
  function AboutDeviceController($scope, __env, editMode) {
    var self = this;
    self.$scope = $scope;

    self.deviceDataArray = [];

    editMode.state.value = false;

    __env.getDeviceData().then(
      function(deviceData) {
        Object.keys(deviceData).forEach(function(key) {
          self.deviceDataArray.push({
            key: key,
            value: deviceData[key]
          });
        });
      },
      function() {
        // console.log("Local storage rejection");
      }
    );
  }

  angular.module("app").controller("AboutDeviceController", AboutDeviceController);
})();
