(function() {
  function SubscriptionInfoController($scope, system, $state, ajaxInProgress, editMode, payments, $timeout, alertService, subscriptionStatus, planTypes) {
    console.log("SubscriptionInfoController created");

    $scope.$on("$destroy", function() {
      console.log("SubscriptionInfoController destroyed");
    });

    var self = this;

    self.$scope = $scope;

    self.$state = $state;

    self.sistemaElegido = system.getSistemaElegido();

    editMode.state.value = false;    

    self.editModeValue = function() {
      return editMode.state.value;
    };

    self.actionBarLeftButton = {
      onClickHandler: function() {
        $state.go("subscription_systemlist");
      },
      disabled: function() {
        return self.editModeValue();
      },
      icon: "chevron_left",
      aria: "home"
    };

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value === "getPlans") {
          self.availablePlans = res.data.message.plans;
          ajaxInProgress.setState({
            value: false
          });
          self.plan = self.availablePlans.find(function(plan) {
            return plan.id === self.sistemaElegido.subscriptionInfo.planId;
          });
        } else if (ajaxInProgress.state.value === "cancelSubscription") {
          ajaxInProgress.setState({
            value: false
          });

          if (res.data.subscriptionInfo) {
            self.sistemaElegido.subscriptionInfo = res.data.subscriptionInfo;
          }

          if (res.data.message) {
            $timeout(function() {
              alertService.showAlert("Suscripción", res.data.message, "OK");
            }, 0);
          }
        }
        self.$scope.mainController.message = res.data.message;
        return;
      } else if (res.data && res.data.success === false) {
        if (ajaxInProgress.state.value === "cancelSubscription") {
          ajaxInProgress.setState({
            value: false
          });
          $timeout(function() {
            alertService.showAlert("Suscripción", res.data.message, "OK");
          }, 0);
          return;
        }
      } else {
        if (res.status == -1) {
					alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
      }
      alertService.showAlert("Atención", res.data.message, "OK");
      ajaxInProgress.setState({
        value: false
      });
    }

    self.cancelSubscription = function() {
      if (!ajaxInProgress.state.value) {
        alertService
          .showConfirmAlert("Cancelar suscripción", "Estás seguro de cancelar tu suscripción?", "¡Sí, estoy seguro!", "NO")
          .then(function() {
            ajaxInProgress.setState({
              value: "cancelSubscription",
              label: "Cancelando tu suscripción"
            });
            payments.cancelSubscription(self.sistemaElegido).then(handleRequest, handleRequest);
          })
          .catch(function(error) {
            console.log("SystemConfigController::eliminarSistema(error)", error);
          });
      }
    };

    self.isCancelarButtonVisible = function() {
      if (!self.sistemaElegido) {
        return false;
      }

      if (!self.sistemaElegido.subscriptionInfo) {
        return false;
      }

      if (self.sistemaElegido.subscriptionInfo.status == subscriptionStatus.FREE_TRIAL) {
        return true;
      }

      if (self.sistemaElegido.subscriptionInfo.status == subscriptionStatus.ACTIVE) {
        return true;
      }

      return false;
    };

    self.subscriptionStatusString = function() {
      var subscriptionStatusString = undefined;

      if (!self.sistemaElegido) {
        return;
      }

      if (!self.sistemaElegido.subscriptionInfo) {
        return;
      }

      switch (self.sistemaElegido.subscriptionInfo.status) {
        case subscriptionStatus.NONE:
          subscriptionStatusString = undefined;
          break;
        case subscriptionStatus.FREE_TRIAL:
          subscriptionStatusString = "Período de prueba";
          break;
        case subscriptionStatus.PENDING:
          subscriptionStatusString = "Esperando el pago";
          break;
        case subscriptionStatus.ACTIVE:
          subscriptionStatusString = "Activa";
          break;
        case subscriptionStatus.CANCELLED:
          subscriptionStatusString = "Cancelada";
          break;
        case subscriptionStatus.FINISHED:
          subscriptionStatusString = "Finalizada";
          break;
        case subscriptionStatus.REJECTED:
          subscriptionStatusString = "Rechazada";
          break;
        case subscriptionStatus.PENDING_REJECTED:
          subscriptionStatusString = "Esperando el pago (sin servicio)";
          break;
      }
      return subscriptionStatusString;
    };

    self.planPriceString = function() {
      if (!self.plan) {
        return;
      }
      if (!self.plan.auto_recurring) {
        return "Gratis";
      }
      return self.plan.auto_recurring.transaction_amount ? "$" + self.plan.auto_recurring.transaction_amount : "Gratis";
    };

    self.planTypeString = function() {
      if (!self.sistemaElegido.subscriptionInfo) {
        return;
      }

      switch (self.sistemaElegido.subscriptionInfo.planType) {
        case planTypes.MONTHLY:
          return "Suscripción Mensual";
        case planTypes.ANNUAL:
          return "Suscripción Anual";
        case planTypes.FREE_SUB:
          return "Suscripción de prueba";
        case planTypes.SUPER_USER:
          return "Suscripción Premium";
      }
    };

    self.subscriptionValidThruString = function() {
      var validThru = new Date(self.sistemaElegido.subscriptionInfo.validThru);
      return validThru.getDate() + "/" + (validThru.getMonth() + 1) + "/" + validThru.getFullYear();
    };

    self.gotoSystemControl = function() {
      $state.go("systemlist");
    };

    self.getPlans = function() {
      $timeout(function() {
        ajaxInProgress.setState({
          value: "getPlans",
          label: "Cargando datos del sistema"
        });
        payments.getPlans().then(handleRequest, handleRequest);
      }, 0);
    };

    self.getPlans();
  }
  angular.module("app").controller("SubscriptionInfoController", SubscriptionInfoController);
})();
