(function() {
	var actions = [
		{
			value: 1,
			name: "Armar Ausente\n ${option}",
			shortName: "Armar Sistema (Ausente)"
		},
		{
			value: 2,
			name: "Desarmar\n ${option}",
			shortName: "Desarmar Sistema"
		},
		{
			value: 3,
			name: "Encender\n ${option}",
			shortName: "Encender Salida"
		},
		{
			value: 4,
			name: "Apagar\n ${option}",
			shortName: "Apagar Salida"
		},
		{
			value: 5,
			name: "Armar Presente\n ${option}",
			shortName: "Armar Sistema (Presente)"
		}
	];

	angular.module("app").constant("automationActions", actions);
})();
