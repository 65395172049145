(function() {
	function SystemAutomationController(
		$scope,
		system,
		statusCacheService,
		ajaxInProgress,
		editMode,
		alertService,
		seqService,
		ymSidenavService,
		actionBarService,
		buildModes,
		$window,
		$rootScope,
		BUILD_MODE,
    	$stateParams
	) {
		console.log("SystemAutomationController created");

		$scope.$on("$destroy", function() {
			console.log("SystemAutomationController destroyed");
		});

		var self = this;

		self.$scope = $scope;

		if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
			ymSidenavService.closeYmSidenav();
		  }
	  
		  self.isMobile = BUILD_MODE == "MOBILE";
		  self.isWeb = BUILD_MODE == "WEB";

		self.viewInfo = true;

		editMode.state.value = false;

		if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
			ymSidenavService.closeYmSidenav();
		}

		self.outputs = [];
		self.partitions = [];

		self.editModeValue = function() {
			return editMode.state.value;
		};

		self.internalAjaxInProgress = {
			value: false
		};

		self.byteArray = [];

		function handleRequest(res) {
			if (res.data && res.data.success) {
				if (self.internalAjaxInProgress.value === "grabandoAutomation1") {
					self.evaluateRepeatedAutomations(self.toSaveAutomations);
						if(self.repeatedAutomations){
							ajaxInProgress.setState({
								value: false
							});
							self.internalAjaxInProgress.value === "grabandoAutomation6"
						return	alertService.showAlert("Atención", "No es posible grabar dos automatizaciones con mismo dia y hora", "OK");
						}
					self.internalAjaxInProgress.value = "grabandoAutomation2";
					setTimeout(function(){
						var ajax = system.setAutomation(
							self.sistemaElegido,
							seqService.seq(),
							"0B60",
							self.byteArray.slice(0, 32)
						);
						ajax.then(handleRequest, handleRequest);
						ajaxInProgress.setState({
							ajax: ajax
						});
					}, 200);
				} else if (
					self.internalAjaxInProgress.value === "grabandoAutomation2"
				) {
					self.internalAjaxInProgress.value = "grabandoAutomation3";
					setTimeout(function(){
						var ajax = system.setAutomation(
							self.sistemaElegido,
							seqService.seq(),
							"0B80",
							self.byteArray.slice(32, 64)
						);
						ajax.then(handleRequest, handleRequest);
						ajaxInProgress.setState({
							ajax: ajax
						});
					}, 200);
				} else if (
					self.internalAjaxInProgress.value === "grabandoAutomation3"
				) {
					self.internalAjaxInProgress.value = "grabandoAutomation4";
					setTimeout(function() {
						var ajax = system.setAutomation(
							self.sistemaElegido,
							seqService.seq(),
							"0BA0",
							self.byteArray.slice(64)
						);
						ajax.then(handleRequest, handleRequest);
						ajaxInProgress.setState({
							ajax: ajax
						});
					}, 200);
				} else if (
					self.internalAjaxInProgress.value === "grabandoAutomation4"
				) {
					//PIDO PROGRAMACIÓN
					self.internalAjaxInProgress.value = "grabandoAutomation5";
					self.pedirProgramacion();
				} else if (
					self.internalAjaxInProgress.value === "grabandoAutomation5"
				) {
					self.internalAjaxInProgress.value = "grabandoAutomation6";
					var newProg = self.procesarProgramacion(
						res.data.message.response,
						self.sistemaElegido.programation.data
					);
					var timeToLastUpdate = 60000;
					self.transformAutomations(newProg.automations);

				
					self.setSystemProgramation(newProg, timeToLastUpdate);
				} else if (
					self.internalAjaxInProgress.value === "grabandoAutomation6"
				) {
					//seteo la programación del sistemaElegido para que quede actualizada
					if(res.data.message.programation){
						self.sistemaElegido.programation = res.data.message.programation;
					}
					self.internalAjaxInProgress.value = "grabandoAutomation7";
					setTimeout(function() {
						var ajax = system.sendExitProgramation(
							self.sistemaElegido,
							seqService.seq()
						);
						ajax.then(handleRequest, handleRequest);
						ajaxInProgress.setState({
							ajax: ajax
						});
					}, 200);
				} else if (
					self.internalAjaxInProgress.value === "grabandoAutomation7"
				) {
					editMode.state.value = false;
					self.setEditAction();
					self.internalAjaxInProgress.value = false;
					ajaxInProgress.setState({
						value: false
					});
				}
				self.$scope.mainController.message = res.data.message;
			} else {
				if (res.status == -1) {
					alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");

					ajaxInProgress.setState({
						value: false
					});
					return;
				}
				alertService.showAlert("Atención", res.data.message, "OK");
				ajaxInProgress.setState({
					value: false
				});
			}
		}

		self.canAddMoreAutomations = function() {
			if (
				self.automationList.reduce(function(accumulator, automation) {
					return automation.isVisible() ? accumulator + 1 : accumulator;
				}, 0) < 20
			) {
				return true;
			}
			return false;
		};

		self.addNewAutomation = function() {
			self.automationList.some(function(automation) {
				if (
					automation.edited.hours === 48 &&
					automation.edited.minutes === 48
				) {
					automation.edited.hours = 0;
					automation.edited.minutes = 0;
					automation.edited.action = 1;
					automation.edited.option = 1;
					automation.edited.enabled = true;
					automation.edited.days = [true, true, true, true, true, true, true];
					// automation.isVisible = true;
					return true;
				}
			});
		};

		// Tareas para cuando el controller se instancia.

		self.editMode = editMode;

		self.setSaveAction = function() {
			actionBarService.setActionBarActions("SystemAutomationController", [
				{
					label: "Guardar",
					aria: "Guardar",
					icon: "save",
					onClickHandler: function() {
						// Secuencia de guardado :D

						self.toSaveAutomations = [];

						self.automationList.forEach(function(automation) {
							self.toSaveAutomations.push(
								automation.edited || {
									number: automation.number,
									hours: automation.hours,
									minutes: automation.minutes,
									action: automation.action,
									option: automation.option,
									days: automation.days,
									enabled: automation.enabled
								}
							);
						});

						var byteArray = [];

						self.toSaveAutomations.forEach(function(automation) {
							var byte1 = 0;

							if (automation.enabled) {
								byte1 |= 128;
							}

							automation.days.forEach(function(day, index) {
								if (day) {
									byte1 |= Math.pow(2, 6 - index);
								}
							});

							var byte4 = 0;

							byte4 |= automation.action * 16;
							byte4 |= automation.option;

							byteArray.push(byte1);
							byteArray.push(automation.hours);
							byteArray.push(automation.minutes);
							byteArray.push(byte4);
						});

						self.byteArray = byteArray;

						self.internalAjaxInProgress.value = "grabandoAutomation1";

						var ajax = system.sendStartProgramation(
							self.sistemaElegido,
							seqService.seq()
						);
						ajax.then(handleRequest, handleRequest);
						ajaxInProgress.setState({
							value: "grabandoAutomation1",
							label: "Grabando Automatizaciones",
							ajax: ajax
						});
					}
				},
				{
					label: "Cancelar",
					aria: "Cancelar",
					icon: "clear",
					onClickHandler: function() {
						editMode.state.value = false;
						self.setEditAction();
					}
				}
			]);
		};

		self.onAddClick = function() {
			editMode.state.value = true;
			self.setSaveAction();
			self.addNewAutomation();
		};

		self.onEditClick = function() {
			editMode.state.value = true;
			self.setSaveAction();
		};

		self.setEditAction = function() {
			actionBarService.setActionBarActions("SystemAutomationController", [
				{
					label: "Editar",
					aria: "Editar",
					icon: "mode_edit",
					onClickHandler: self.onEditClick
				}
			]);
		};

		self.pedirProgramacion = function() {
			ajaxInProgress.setState({
				value: "querySystemProgramation",
				cancelable: false
			});
			system
				.querySystemProgramation(self.sistemaElegido, seqService.seq(), "busC485")
				.then(handleRequest, handleRequest);
		};

		self.procesarProgramacion = function(
			newProgramationData,
			oldProgramationData
		) {
			var programationData = {
				zones: [],
				outputs: [],
				automations: [],
				partitions: []
			};


			newProgramationData.zones.forEach(function(newZone) {
				var oldZone =
					oldProgramationData &&
					oldProgramationData.zones.find(function(zone) {
						return zone.number === newZone.number;
					});

				programationData.zones.push({
					name: oldZone && oldZone.name,
					number: newZone.number,
					icon: (oldZone && oldZone.icon) || "0",
					configuration: newZone.configuration,
					attributes: newZone.attributes,
					enabled: newZone.enabled,
					isPresentZone: newZone.isPresentZone
				});
			});

			newProgramationData.partitions.forEach(function(newPartition) {
				if (newPartition) {
					var oldPartition =
						oldProgramationData &&
						oldProgramationData.partitions &&
						oldProgramationData.partitions.find(function(partition) {
							return partition.number == newPartition.number;
						});
					programationData.partitions.push({
						name: oldPartition && oldPartition.name,
						number: newPartition.number,
						enabled: newPartition.enabled
					});
				}
			});

			newProgramationData.outputs.forEach(function(newOutput) {
				var oldOutput =
					oldProgramationData &&
					oldProgramationData.outputs.find(function(output) {
						return output.number === newOutput.number;
					});

				programationData.outputs.push({
					name: oldOutput && oldOutput.name,
					number: newOutput.number,
					icon: (oldOutput && oldOutput.icon) || "0",
					configuration: newOutput.configuration,
					attributes: newOutput.attributes,
					enabled: newOutput.enabled
				});
			});

			newProgramationData.automations.forEach(function(newAutomation) {
	

				programationData.automations.push({
					number: newAutomation.number,
					hours: newAutomation.hours,
					minutes: newAutomation.minutes,
					action: newAutomation.action,
					option: newAutomation.option,
					days: newAutomation.days,
					enabled: newAutomation.enabled
				});
			});

			if(oldProgramationData && oldProgramationData.alarmPanel){
				programationData.alarmPanel = oldProgramationData.alarmPanel
			}

			return programationData;
		};

		self.setSystemProgramation = function(programationData, timeToLastUpdate) {
			ajaxInProgress.setState({
				value: "setSystemProgramation"
			});
			system
				.setSystemProgramation(
					self.sistemaElegido,
					programationData,
					timeToLastUpdate
				)
				.then(handleRequest, handleRequest);
			// }
		};

		self.evaluateRepeatedAutomations = function(automationList){

			// Filtro el array para que solo tenga en cuenta PGMW (AHORA)
			self.newAutomationListFiltered = automationList.filter(function(automation){
				return automation.option > 8 
			})

			//Obtengo array de horas
			self.hoursArray = self.newAutomationListFiltered.map(function(automation){
				return automation.hours
			});

			//Obtengo array de horas repetidas y distintas al default.
			self.hoursRepeated = self.hoursArray.filter(function(automation, index){
				 return (self.hoursArray.indexOf(automation) != index && automation.hours != 48)
			});

			self.repeatedArray = [];

			//Obtengo array de las automatizaciones que tienen la hora repetida.
			self.newAutomationListFiltered.map(function(automation){
					if( self.hoursRepeated[0] == automation.hours){
						self.repeatedArray.push(automation)
				}
			})

			//Obtengo array de minutos en base a las horas repetidas
			self.minutesArray = self.repeatedArray.map(function(automation){
				return automation.minutes
			});

			//Obtengo array de minutos repetidos en base a las horas repetidas
			self.minutesRepeated = self.minutesArray.filter(function(automation, index){
				return (self.minutesArray.indexOf(automation) != index && automation.minutes != 48)
		   });

			self.repeatedFinal = [];
		   //Obtengo que automatizaciones tienen hora y minutos repetidos.
			self.newAutomationListFiltered.map(function(automation){
				if( automation.minutes == self.minutesRepeated[0]){
						self.repeatedFinal.push(automation)
				}
			})

			//Filtro que esten habilitadas.
			if(self.repeatedFinal.length > 1){
				self.repeatedFinalFiltered = self.repeatedFinal.map(function(automation){
					if(automation.enabled == true){
						return automation
					}
				})
			}

			//Me fijo si comparten al menos un dia.
			if(self.repeatedFinalFiltered && self.repeatedFinalFiltered.length > 1){
				if(self.repeatedFinalFiltered[0].days[0] == self.repeatedFinalFiltered[1].days[0]){
					self.repeatedAutomations = true;
				}
				else if(self.repeatedFinalFiltered[0].days[1] == self.repeatedFinalFiltered[1].days[1]){
					self.repeatedAutomations = true;
				}
				else if(self.repeatedFinalFiltered[0].days[2] == self.repeatedFinalFiltered[1].days[2]){
					self.repeatedAutomations = true;
				}
				else if(self.repeatedFinalFiltered[0].days[3] == self.repeatedFinalFiltered[1].days[3]){
					self.repeatedAutomations = true;
				}
				else if(self.repeatedFinalFiltered[0].days[4] == self.repeatedFinalFiltered[1].days[4]){
					self.repeatedAutomations = true;
				}
				else if(self.repeatedFinalFiltered[0].days[5] == self.repeatedFinalFiltered[1].days[5]){
					self.repeatedAutomations = true;
				}
				else if(self.repeatedFinalFiltered[0].days[6] == self.repeatedFinalFiltered[1].days[6]){
					self.repeatedAutomations = true;
				}
				else{
					self.repeatedAutomations = false;
				}
			}
		};

		self.transformAutomations = function(newAutomations) {
			self.automationList = newAutomations;
			self.automationList.forEach(function(automation) {
				automation.isVisible = function() {
					if (
						!editMode.state.value &&
						((automation.hours === 48 && automation.minutes === 48) ||
							!automation.enabled)
					) {
						return false;
					} else if (
						editMode.state.value &&
						automation.edited &&
						automation.edited.hours === 48 &&
						automation.edited.minutes === 48
					) {
						return false;
					}
					return true;
				};

				automation.edited = automation.edited || {
					number: automation.number,
					hours: automation.hours,
					minutes: automation.minutes,
					action: automation.action,
					option: automation.option,
					days: automation.days,
					enabled: automation.enabled
				};

				self.viewInfo = !self.automationList.some(function(e){
					return e.enabled == true;
				});
			});
		};

		function handleLastUpdate(res){
			if (res.data && res.data.success) {
				self.lastUpdate = res.data.message.lastUpdate;
				self.lastEvent = res.data.message.lastEvent;

				if (self.lastEvent && new Date(self.lastEvent).getTime() > new Date(self.lastUpdate).getTime()) {
				return $rootScope.pedirProgramacionDesdeSystem();
				}
			} else {
				if (res.status == -1) {
				ajaxInProgress.setState({
					value: false
				});
				// $timeout(function() {
					alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
				// });
				return;
				}
				if (res.status == -2) {
				ajaxInProgress.setState({
					value: false
				});
				return;
				}
				ajaxInProgress.setState({
				value: false
				});
				// $timeout(function() {
				alertService.showAlert("Atención", res.data.message, "OK");
			}
		};
	  
		self.getLastUpdate = function() {
			var ajax = system.getLastUpdate(self.sistemaElegido);
			ajax.then(handleLastUpdate, handleLastUpdate);
		};

		system.getSystem($stateParams.idSistemaElegido).then(function(sistemaElegido) {
			self.sistemaElegido = sistemaElegido;
			  //Seteo el timeout de los comandos en función de lo que devuelva
			  system.setCommandTimeout(sistemaElegido.id);
			  // if (self.lastEvent && self.lastEvent > self.sistemaElegido.programation.lastUpdate) {
			  if (self.lastEvent && new Date(self.lastEvent).getTime() > new Date(self.sistemaElegido.programation.lastUpdate).getTime()) {
				return $rootScope.pedirProgramacionDesdeSystem();
			  }
				if (self.sistemaElegido.estadoDeCarga === "verificado") {
					if (
						self.sistemaElegido.programation.data &&
						self.sistemaElegido.programation.data.automations
					) {
				
						self.transformAutomations(self.sistemaElegido.programation.data.automations);
						self.outputs = self.sistemaElegido.programation.data.outputs;
						self.partitions = self.sistemaElegido.programation.data.partitions;

					}
					// $rootScope.focusingTab("/system/automation");
					self.setEditAction();
					self.getLastUpdate();
				}
		});
	}

	angular
		.module("app")
		.controller("SystemAutomationController", SystemAutomationController);
})();
