(function() {
  function AccountConfigController(
    $scope,
    user,
    ajaxInProgress,
    BUILD_MODE,
    __env,
    BUILD_PRODUCT,
    buildProducts,
    $window,
    $state,
    $rootScope,
    $timeout,
    alertService,
    userDataService,
    ymSidenavService,
    auth,
    editMode,
    deviceService,
    actionBarService
  ) {
    var self = this;
    editMode.state.value = false;

    self.editModeValue = function() {
      return editMode.state.value;
    };

    if(BUILD_PRODUCT == "titanium_control"){
      self.productName = "Alarm Home";
    }
    if(BUILD_PRODUCT == "garnet_control"){
      self.productName = "Garnet Control";
    }

    self.actionBarActions = actionBarService.actionBarActions;

    $rootScope.wifiIconToShow = "any-network"
    $rootScope.systemPlanCounter = 'system-plan-counter-hidden'

    self.availableAvatars = [
      // {
      //   value: "0",
      //   img: "img/assets/icon-avatar/icon-avatar-0-circle.svg"
      // },
      {
        value: "1",
        img: "img/assets/icon-avatar/icon-avatar-1-circle.svg"
      },
      {
        value: "2",
        img: "img/assets/icon-avatar/icon-avatar-2-circle.svg"
      },
      {
        value: "3",
        img: "img/assets/icon-avatar/icon-avatar-3-circle.svg"
      },
      {
        value: "4",
        img: "img/assets/icon-avatar/icon-avatar-4-circle.svg"
      },
      {
        value: "5",
        img: "img/assets/icon-avatar/icon-avatar-5-circle.svg"
      },
      {
        value: "6",
        img: "img/assets/icon-avatar/icon-avatar-6-circle.svg"
      },
      {
        value: "7",
        img: "img/assets/icon-avatar/icon-avatar-7-circle.svg"
      },
      {
        value: "8",
        img: "img/assets/icon-avatar/icon-avatar-8-circle.svg"
      }
    ];

    self.$scope = $scope;

    self.userData = {};

    self.actionBarTitle = "Mi cuenta";

    self.showedCodigoEmpresa = "Sin Customizar"

    function handleRequest(res) {
      if (res.data && res.data.success || ajaxInProgress.state.value == "fetchingAppbar") {
        if (ajaxInProgress.state.value == "gotoAccountConfig") {
          userDataService.procesarUserData(res.data.message.user);
          self.userData = userDataService.getUserData();

          self.accountEditedAvatarClass = "icon-avatar-" + self.userData.avatar; //"icon-system-0";
          self.editedAvatar = self.userData.avatar;
          self.codigoEmpresa = self.userData.codigoEmpresa;
          self.editedCodigoEmpresa = "";
          self.editedCodigoEmpresaUpdated = self.userData.codigoEmpresaUpdated;
          self.editedNombre = self.userData.nombre;
          self.editedApellido = self.userData.apellido;

          if(self.userData.codigoEmpresa != "Alarm Home" && self.userData.codigoEmpresa != "Garnet" && self.userData.codigoEmpresa != "Garnet Control" 
          && self.userData.codigoEmpresa != "garnet" && self.userData.codigoEmpresa != "garnetcontrol" && self.userData.codigoEmpresa != "GarnetControl" && self.userData.codigoEmpresa != "alarmhome"){
           self.showedCodigoEmpresa = "Customizado"
          };

          ajaxInProgress.setState({
            value: false
          });
        } else if (ajaxInProgress.state.value == "savingChanges") {
          editMode.state.value = false;
          self.setEditAction();

          self.preCodigoEmpresa = self.userData.codigoEmpresa
          
          userDataService.procesarUserData(res.data.message.user);
          self.userData = userDataService.getUserData();

          self.accountEditedAvatarClass = "icon-avatar-" + self.userData.avatar; //"icon-system-0";
          self.editedAvatar = self.userData.avatar;
          self.editedNombre = self.userData.nombre;
          self.codigoEmpresa = self.userData.codigoEmpresa;
          self.editedCodigoEmpresa = "";
          self.editedCodigoEmpresaUpdated = self.userData.editedCodigoEmpresaUpdated;
          self.editedApellido = self.userData.apellido;

          if(self.preCodigoEmpresa == self.userData.codigoEmpresa){
            ajaxInProgress.setState({
              value: false
            });
            return
          } 

          ajaxInProgress.setState({
              value: false
          });
          
          return self.fetchSplash(self.userData.codigoEmpresa);
        } else if (ajaxInProgress.state.value == "deletingDevice") {
          alertService.showToast("Eliminaste el dispositivo con éxito");
          ajaxInProgress.setState({
            value: false
          });

          userDataService.procesarUserData(res.data.message.user);
          self.userData = userDataService.getUserData();
        } else if (ajaxInProgress.state.value == "deletingDeviceForLogout") {
          ajaxInProgress.setState({
            value: false
          });
          auth.logout();
          ymSidenavService.openYmSidenav();
        } else if (ajaxInProgress.state.value == "fetchingSplash") {
          user.setSplashImageLS(res.data.message.data);
          if(self.userData.codigoEmpresa == "Alarm Home" || self.userData.codigoEmpresa == "Garnet" || self.userData.codigoEmpresa == "Garnet Control" 
          || self.userData.codigoEmpresa == "garnet" || self.userData.codigoEmpresa == "garnetcontrol" || self.userData.codigoEmpresa == "GarnetControl" || self.userData.codigoEmpresa == "alarmhome"){
            ajaxInProgress.setState({
              value: false
            });
            return self.cerrarSesionForzado();
          };
          ajaxInProgress.setState({
            value: false
          });
          return self.fetchAppbar(self.userData.codigoEmpresa);
        } else if (ajaxInProgress.state.value == "fetchingAppbar"){
          user.setAppbarImageLS(res.data.message.data);
          ajaxInProgress.setState({
            value: false
          });
          return self.fetchSystemList(self.userData.codigoEmpresa);
        } else if (ajaxInProgress.state.value == "fetchingSystemlist") {
          user.setSystemlistImageLS(res.data.message.data);
          ajaxInProgress.setState({
            value: false
          });
          return self.fetchAttributes(self.userData.codigoEmpresa);
        }
        else if (ajaxInProgress.state.value == "fetchingAttributes") {
          user.setCustomizationAttributesLS(res.data.message.attributes);
          ajaxInProgress.setState({
            value: false
          });
          return self.cerrarSesionForzado();
        }
        self.$scope.mainController.message = res.data.message;
      } else {
        if (res.status == -1) {
          alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
        alertService.showAlert("Atención", res.data.message, "OK");
        ajaxInProgress.setState({
          value: false
        });
      }
    }

    self.fetchSplash = function(codigoEmpresa){
      if (!ajaxInProgress.state.value) {
        ajaxInProgress.setState({
          value: "fetchingSplash",
          label: "Customizando Inicio"
        });
        user.fetchSplash(codigoEmpresa).then(handleRequest, handleRequest);
      }    
    };

    self.fetchAppbar = function(codigoEmpresa){
      if (!ajaxInProgress.state.value) {
        ajaxInProgress.setState({
          value: "fetchingAppbar",
          label: "Customizando Navegación"
        });
        user.fetchAppbarImage(codigoEmpresa).then(handleRequest, handleRequest);
      }    
    };

    self.fetchSystemList = function(codigoEmpresa){
      if (!ajaxInProgress.state.value) {
        ajaxInProgress.setState({
          value: "fetchingSystemlist",
          label: "Customizando Textos"
        });
        user.fetchSystemlistImage(codigoEmpresa).then(handleRequest, handleRequest);
      }    
    };

    self.fetchAttributes = function(codigoEmpresa){
      if (!ajaxInProgress.state.value) {
        ajaxInProgress.setState({
          value: "fetchingAttributes",
          label: "Customizando Estilos"
        });
        user.fetchCustomizationAttributes(codigoEmpresa).then(handleRequest, handleRequest);
      }    
    };

    self.selectAvatar = function(value) {
      self.editedAvatar = value;
      self.accountEditedAvatarClass = "icon-avatar-" + value;
    };

    self.anythingChanged = function() {
      // if (!self.userData.avatar) return false;
      if (self.editedAvatar != self.userData.avatar) return true;
      if (self.editedNombre != self.userData.nombre) return true;
      if (self.editedApellido != self.userData.apellido) return true;
      return false;
    };

    self.setUserAvatar = function() {
      ajaxInProgress.setState({
        value: "settingUserAvatar",
        label: "Guardando configuración"
      });
      user.setUserAvatar(self.editedAvatar).then(handleRequest, handleRequest);
    };

    self.saveChanges = function() {
      if (!ajaxInProgress.state.value) {
        ajaxInProgress.setState({
          value: "savingChanges",
          label: "Guardando configuración"
        });

        if(self.productName == "Alarm Home" && self.codigoEmpresa ==  "Garnet" || self.codigoEmpresa == "Garnet Control" 
        || self.codigoEmpresa == "garnet" || self.codigoEmpresa == "garnetcontrol" || self.codigoEmpresa == "GarnetControl")
        {
          self.codigoEmpresa = "Alarm Home"
        };

        if(self.productName == "Garnet Control" &&
          self.codigoEmpresa ==  "Alarm Home" ||
          self.codigoEmpresa == "alarmhome")
        {
          self.codigoEmpresa = "Garnet Control"
        };

        user.editUserData(self.editedNombre, self.editedApellido, self.editedAvatar, null, null, null, null, self.editedCodigoEmpresa || self.codigoEmpresa, self.editedCodigoEmpresaUpdated).then(handleRequest, handleRequest);
      }
    };

    self.eliminarDevice = function(device) {
      if (!ajaxInProgress.state.value) {
        ajaxInProgress.setState({
          value: "deletingDevice",
          label: "Eliminando dispositivo"
        });
        deviceService.delete(device.id).then(handleRequest, handleRequest);
      }
    };

    self.cerrarSesion = function() {
      if (!ajaxInProgress.state.value) {
        alertService
          .showConfirmAlert("Cerrar sesión", "Estás seguro de cerrar sesión en este dispositivo?", "¡Sí, estoy seguro!", "NO")
          .then(function() {
            if (BUILD_MODE == "MOBILE") {
              __env.getDeviceData().then(
                function(deviceData) {
                  $timeout(function() {
                    ajaxInProgress.setState({
                      value: "deletingDeviceForLogout",
                      label: "Borrando dispositivo"
                    });
                    deviceService.delete(deviceData.id).then(handleRequest, handleRequest);
                  }, 0);
                },
                function() {
                  // console.log("Local storage rejection");
                }
              );
            } else {
              auth.logout();
              ymSidenavService.openYmSidenav();
            }
          })
          .catch(function(error) {
            // console.log("AccountConfigController::cerrarSesion(error)", error);
          });
      }
    };

    self.eliminarCuenta = function() {
      if (!ajaxInProgress.state.value) {
        alertService
          .showConfirmAlert("Eliminar cuenta", "Estás a punto de eliminar tu cuenta de forma permanente. Ya no estarán disponibles los dispositivos y miembros de la comunidad asociados a la misma. ¿Estas Seguro?", "¡Sí, estoy seguro!", "NO")
          .then(function() {
            return deviceService.deleteAccount();
          }).then(function(){
            auth.logout();
          })
          .catch(function(error) {
            console.log("AccountConfigController::cerrarSesion(error)", error);
          });
      }
    };

    self.cerrarSesionEquisD = function() {
      if (!ajaxInProgress.state.value) {
        alertService
          .showConfirmAlert("Cerrar sesión", "Cerraremos sesión para finalizar la customización de la app", "¡Sí, cerrar sesión!", "NO")
          .then(function() {
            if (BUILD_MODE == "MOBILE") {
              __env.getDeviceData().then(
                function(deviceData) {
                  $timeout(function() {
                    ajaxInProgress.setState({
                      value: "deletingDeviceForLogout",
                      label: "Borrando dispositivo"
                    });
                    deviceService.delete(deviceData.id).then(handleRequest, handleRequest);
                  }, 0);
                },
                function() {
                  // console.log("Local storage rejection");
                }
              );
            } else {
              auth.logout();
              ymSidenavService.openYmSidenav();
            }
          })
          .catch(function(error) {
            // console.log("AccountConfigController::cerrarSesion(error)", error);
          });
      }
    };

    self.cerrarSesionForzado = function() {
      // alertService.hideProgressDialog();
      auth.logout();
      ajaxInProgress.setState({
        value: false
      });
      $timeout(function() {
        alertService.showAlert("Atención", "Operación Exitosa!", "OK");
      }, 3000);

      // $timeout(function() {
        // alertService.showAlert("Atención", res.data.message, "OK");
      // }, 3000);
      // alertService.showAlert("Atención", "Se cerrará sesión para customizar la app.", "OK");
      //   if (BUILD_MODE == "MOBILE") {
      //     __env.getDeviceData()
      //     .then(
      //       function(deviceData) {
      //         $timeout(function() {
      //           // ajaxInProgress.setState({
      //           //   value: "deletingDeviceForLogout",
      //           //   label: "Borrando dispositivo"
      //           // });
      //           deviceService.delete(deviceData.id).then(handleRequest, handleRequest);
      //         }, 0);
      //       },
      //       function() {
      //         // console.log("Local storage rejection");
      //       }
      //     )
      //     // .catch(function(error) {
      //       // console.log("AccountConfigController::cerrarSesion(error)", error);
      //     // });
      //   } else {
      //     auth.logout();
      //     ymSidenavService.openYmSidenav();
      //   }
    };

    self.setSaveAction = function() {
      actionBarService.setActionBarActions("AccountConfigController", [
        {
          label: "Guardar",
          aria: "Guardar",
          icon: "save",
          onClickHandler: function() {
            // Secuencia de guardado :D
            self.saveChanges();
          }
        },
        {
          label: "Cancelar",
          aria: "Cancelar",
          icon: "clear",
          onClickHandler: function() {
            editMode.state.value = false;
            self.setEditAction();
          }
        }
      ]);
    };

    self.setEditAction = function() {
      var buttons = [
        {
          label: "Actualizar",
          aria: "Actualizar",
          icon: "refresh",
          onClickHandler: function() {
            self.actualizarData();
          }
        }
      ];

      buttons.push({
        label: "Editar",
        aria: "Editar",
        icon: "mode_edit",
        onClickHandler: function() {
          editMode.state.value = true;
          self.setSaveAction();
        }
      });

      actionBarService.setActionBarActions("AccountConfigController", buttons);
    };

    self.setEditAction();

    self.actualizarData = function() {
      ajaxInProgress.setState({
        value: "gotoAccountConfig"
      });
      user.getUserData().then(handleRequest, handleRequest);
    };

    self.actualizarData();
  }

  angular.module("app").controller("AccountConfigController", AccountConfigController);
})();
