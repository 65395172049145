(function () {
  function statusCacheService(alertService) {
    var self = this;

    self.cache = {};

    self.storeStatus = function (sistema, status, checkStatus) {
      var alertDSC = function () {
        alertService.showAlert(
          "Atención",
          "La clave con la que intentas controlar tu alarma es incorrecta. Por favor presiona la rueda de configuración para verificar tus claves ",
          "OK"
        );
      };
      var processedStatus = processStatus(
        status,
        sistema.programation.data.alarmPanel.brand,
        alertDSC,
        checkStatus
      );
      self.cache[sistema.id] = processedStatus;
      return processedStatus;
    };

    self.getStatus = function (sistema) {
      if (self.cache[sistema.id]) {
        return self.cache[sistema.id];
      }

      return undefined;
    };
  }
  var prevStatus;

  function processStatus(status, brand, alertDSC, checkStatus) {
    var processedStatus = {};
    if (status.length > 37) {
      //Si es DSC evalúo si el estado cambió y en caso que no haya cambiado muestro una Alerta.
      if (brand == 1) {
        if (checkStatus && prevStatus == status) {
          alertDSC();
        }
        prevStatus = status;
      }

      processedStatus.isOldForm = false;

      processedStatus.registroProblemas1 = parseInt(status.slice(1, 3), 16);
      processedStatus.registroProblemas2 = parseInt(status.slice(3, 5), 16);

      processedStatus.estadoDeParticiones1 = parseInt(status.slice(5, 7), 16);
      processedStatus.estadoDeParticiones2 = parseInt(status.slice(35, 37), 16);
      processedStatus.demorasPanel = parseInt(status.slice(37, 39), 16);

      processedStatus.partitionStatus = [];
      for (var i = 0; i < 4; i++) {
        if (processedStatus.estadoDeParticiones1 & Math.pow(2, 7 - i)) {
          processedStatus.partitionStatus[i] = "LISTO";
        } else {
          processedStatus.partitionStatus[i] = "NO_LISTO";
        }

        if (
          processedStatus.estadoDeParticiones1 & Math.pow(2, 3 - i) ||
          processedStatus.demorasPanel & Math.pow(2, 7 - i)
        ) {
          processedStatus.partitionStatus[i] = "ARMADO";
        }

        if (
          processedStatus.estadoDeParticiones2 & Math.pow(2, 3 - i) &&
          processedStatus.partitionStatus[i] === "ARMADO"
        ) {
          // DEMORADO
          processedStatus.partitionStatus[i] = "DEMORADO";
        } else if (
          processedStatus.estadoDeParticiones2 & Math.pow(2, 7 - i) &&
          processedStatus.partitionStatus[i] === "ARMADO"
        ) {
          // INSTANTANEO
          processedStatus.partitionStatus[i] = "INSTANTANEO";
        }
      }

      processedStatus.estadoDeSalidas = parseInt(status.slice(7, 9), 16);

      processedStatus.zonasAbiertas = [];
      processedStatus.zonasAbiertas.push(parseInt(status.slice(9, 11), 16));
      processedStatus.zonasAbiertas.push(parseInt(status.slice(11, 13), 16));
      processedStatus.zonasAbiertas.push(parseInt(status.slice(13, 15), 16));
      processedStatus.zonasAbiertas.push(parseInt(status.slice(15, 17), 16));

      processedStatus.zonasEnAlarma = [];
      processedStatus.zonasEnAlarma.push(parseInt(status.slice(17, 19), 16));
      processedStatus.zonasEnAlarma.push(parseInt(status.slice(19, 21), 16));
      processedStatus.zonasEnAlarma.push(parseInt(status.slice(21, 23), 16));
      processedStatus.zonasEnAlarma.push(parseInt(status.slice(23, 25), 16));

      processedStatus.zonasInhibidas = [];
      processedStatus.zonasInhibidas.push(parseInt(status.slice(25, 27), 16));
      processedStatus.zonasInhibidas.push(parseInt(status.slice(27, 29), 16));
      processedStatus.zonasInhibidas.push(parseInt(status.slice(29, 31), 16));
      processedStatus.zonasInhibidas.push(parseInt(status.slice(31, 33), 16));

      processedStatus.estadoDeSalidasInalambricas = parseInt(
        status.slice(33, 35),
        16
      );

      processedStatus.lastTimeUpdate = new Date();
      return processedStatus;
    } else {
      processedStatus.isOldForm = true;
      // processedStatus.particion = status;
      processedStatus.registroProblemas1 = parseInt(status.slice(1, 3), 16);
      processedStatus.registroProblemas2 = parseInt(status.slice(3, 5), 16);

      processedStatus.estadoDeParticiones1 = parseInt(status.slice(5, 7), 16);
      // processedStatus.estadoDeParticiones2 = parseInt(status.slice(35, 37), 16); // NO VA
      // processedStatus.demorasPanel = parseInt(status.slice(37, 39), 16); // NO VA

      processedStatus.partitionStatus = [];
      //FOR DONDE DECIDO EL ESTADO DE LAS PARTICONES.
      for (var i = 0; i < 4; i++) {
        if (processedStatus.estadoDeParticiones1 & Math.pow(2, 3 - i)) {
          processedStatus.partitionStatus[i] = "ARMADO";
        } else {
          if (processedStatus.estadoDeParticiones1 & Math.pow(2, 7 - i)) {
            processedStatus.partitionStatus[i] = "LISTO";
          } else {
            processedStatus.partitionStatus[i] = "NO_LISTO";
          }
        }
      }
      // Necesito saber la partición de la zona, necesito saber el sistema elegido para saber que tiene programado?

      processedStatus.estadoDeSalidas = parseInt(status.slice(7, 9), 16);

      processedStatus.zonasAbiertas = [];
      processedStatus.zonasAbiertas.push(parseInt(status.slice(9, 11), 16));
      processedStatus.zonasAbiertas.push(parseInt(status.slice(11, 13), 16));
      processedStatus.zonasAbiertas.push(parseInt(status.slice(13, 15), 16));
      processedStatus.zonasAbiertas.push(parseInt(status.slice(15, 17), 16));

      processedStatus.zonasEnAlarma = [];
      processedStatus.zonasEnAlarma.push(parseInt(status.slice(17, 19), 16));
      processedStatus.zonasEnAlarma.push(parseInt(status.slice(19, 21), 16));
      processedStatus.zonasEnAlarma.push(parseInt(status.slice(21, 23), 16));
      processedStatus.zonasEnAlarma.push(parseInt(status.slice(23, 25), 16));

      processedStatus.zonasInhibidas = [];
      processedStatus.zonasInhibidas.push(parseInt(status.slice(25, 27), 16));
      processedStatus.zonasInhibidas.push(parseInt(status.slice(27, 29), 16));
      processedStatus.zonasInhibidas.push(parseInt(status.slice(29, 31), 16));
      processedStatus.zonasInhibidas.push(parseInt(status.slice(31, 33), 16));

      processedStatus.estadoDeSalidasInalambricas = parseInt(
        status.slice(33, 35),
        16
      );

      processedStatus.lastTimeUpdate = new Date();

      // processedStatus.estadoDeEntradasIp = parseInt(status.slice(35, 37), 16);
      // processedStatus.estadoDeSalidasIp = parseInt(status.slice(37, 39), 16);

      return processedStatus;
    }
  }

  angular.module("app").service("statusCacheService", statusCacheService);
})();
