(function() {
  var EditableDayController = function($scope, $element) {
    var ctrl = this;

    ctrl.$onInit = function() {
      // console.log("EditableDayController $onInit");
      ctrl.day = Object.assign(ctrl.initialDay);
    };

    // ctrl.$onChanges = function() {
      // console.log("EditableDayController $onChanges");
    // };

    ctrl.dayClick = function() {
      ctrl.day.enabled = !ctrl.day.enabled;
      ctrl.changesHandler(ctrl.day);
    };
  };

  angular.module("app").component("editableday", {
    templateUrl: "components/EditableDay/editableDay.html",
    controller: EditableDayController,
    bindings: {
      initialDay: "<",
      changesHandler: "="
    }
  });
})();
