(function() {
	function SystemUnauthorizedController($scope, editMode,$rootScope, $window,buildModes, ymSidenavService, BUILD_MODE) {
	editMode.state.value = false;
	// console.log("INICIO EL CONTROLLER UNAUTH");

	if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
		ymSidenavService.closeYmSidenav();
	}

	var self = this;

	self.$scope = $scope;

	self.viewInfo = false;

	// $rootScope.focusingTab("/system/unauthorized");
	
	self.changeViewInfo = function(){
		self.viewInfo = !self.viewInfo
	};

	}
	angular.module("app").controller("SystemUnauthorizedController", SystemUnauthorizedController);
})();
