(function() {
  function liveVideoBridgeService($q, $window, BUILD_PLATFORM, deferredOperations) {
    var self = this;

    self.scanQRCode = function() {
      if (BUILD_PLATFORM === "Android") {
        return deferredOperations.do(function(operationId) {
          $window.HostInterface.scanQRCode(operationId);
        });
      }

      if (BUILD_PLATFORM === "iOS") {
        return deferredOperations.do(function(operationId) {
          window.webkit.messageHandlers.HostInterface.postMessage('scanQRCode("' + operationId + '")');
        });
      }
    };

    self.triggerLivePlay = function(appKey, accessToken, cameraId, validateCode) {
      // console.log("triggerLivePlay(" + appKey + ", " + accessToken + ", " + cameraId + ", " + validateCode + ")");
      var deferred = $q.defer();

      if (BUILD_PLATFORM === "Android") {
        deferred.resolve($window.HostInterface.playLiveVideo(appKey, accessToken, cameraId, validateCode));
        return;
      }

      if (BUILD_PLATFORM === "iOS") {
        return deferredOperations.do(function(operationId) {
          window.webkit.messageHandlers.HostInterface.postMessage('playLiveVideo("' + appKey + '", "' + accessToken + '", "' + cameraId + '", "' + validateCode +'")');
        });
      }
    };

    self.configWifi = function(appKey, accessToken, wifi_ssid, wifi_password, cameraId, validateCode, deviceModel) {
      // var deferred = $q.defer();

      if (BUILD_PLATFORM === "Android") {
        return deferredOperations.do(function(operationId) {
          $window.HostInterface.configWifi(appKey, accessToken, wifi_ssid, wifi_password, cameraId, validateCode, deviceModel, operationId);
        });
      }

      if (BUILD_PLATFORM === "iOS") {
        return deferredOperations.do(function(operationId) {
          window.webkit.messageHandlers.HostInterface.postMessage(
            'configWifi("' +
              appKey +
              '", "' +
              accessToken +
              '", "' +
              wifi_ssid +
              '", "' +
              wifi_password +
              '", "' +
              cameraId +
              '", "' +
              validateCode +
              '", "' +
              deviceModel +
              '", "' +
              operationId +
              '")'
          );
        });
      }
    };
  }

  angular.module("app").service("liveVideoBridgeService", liveVideoBridgeService);
})();
