(function() {
  function seqService(customFormat) {
    var self = this;

    var seq = 0;

    self.seq = function() {
      seq++;
      if (seq > 255) {
        seq = 0;
      }
      return customFormat.format(3, seq.toString());
    };
  }
  angular.module("app").service("seqService", seqService);
})();
