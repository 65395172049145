(function() {
  function actionBarService() {
    var self = this;

    var actionBarActions; // = "ym-systems-list-open";

    self.setActionBarActions = function(componentName, actions) {
      // console.log("Seteando las actions de la ActionBar desde: " + componentName + " " + JSON.stringify(actions));
      actionBarActions = actions;
    };

    self.actionBarActions = function() {
      return actionBarActions;
    };
  }
  angular.module("app").service("actionBarService", actionBarService);
})();
