(function() {
	var passwordToCheck;

	function jkPasswordDirective() {
		// var PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.])(?=.{8,20})/; ///^\w{7,20}$/;
		var PASSWORD_LOWWORD_REGEXP = /([a-z])+/;
		var PASSWORD_HIGHWORD_REGEXP = /([A-Z])+/;
		var PASSWORD_NUMBER_REGEXP = /([0-9])+/;
		var PASSWORD_SPECIAL_REGEXP = /([!@#\$%\^&\*\.])+/;

		return {
			require: "ngModel",
			link: function(scope, elm, attrs, ctrl) {
				ctrl.$validators.jkpassword = function(modelValue, viewValue) {
					var level = 0;
					if (!viewValue || viewValue.length > 20 || viewValue.length < 8) {
						return false;
					}
					passwordToCheck = viewValue;
					if (PASSWORD_LOWWORD_REGEXP.test(viewValue)) {
						level = level + 25;
					}
					if (PASSWORD_HIGHWORD_REGEXP.test(viewValue)) {
						level = level + 25;
					}
					if (PASSWORD_NUMBER_REGEXP.test(viewValue)) {
						level = level + 25;
					}
					if (PASSWORD_SPECIAL_REGEXP.test(viewValue)) {
						level = level + 25;
					}
					if (level > 25) {
						return true;
					}
					return false;
				};
			}
		};
	}

	function jkPasswordConfirmDirective() {
		return {
			require: "ngModel",
			link: function(scope, elm, attrs, ctrl) {
				ctrl.$validators.jkpasswordconfirm = function(modelValue, viewValue) {
					if (passwordToCheck != undefined && viewValue == passwordToCheck) {
						return true;
					}
					return false;
				};
			}
		};
	}

	angular
		.module("app")
		.directive("jkpassword", jkPasswordDirective)
		.directive("jkpasswordconfirm", jkPasswordConfirmDirective);
})();
