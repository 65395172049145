(function() {
  function editMode() {
    var self = this;

    self.state = {
      value: false //true //false
    };
  }
  angular.module("app").service("editMode", editMode);
})();
