(function() {
  function DevicesListController(__env, $q, ajaxInProgress, $state, userDataService, ymSidenavService, user, alertService, deviceService) {
    var self = this;

    self.phoneLanguage;

    self.devicesTexts = {
      spa:{
        devicesTitle: "Dispositivos",
        devicesMax: "Alcanzaste el máximo de dispositivos disponibles para tu cuenta. Podés borrar uno para reemplazarlo con este.",
        notDelete: "No quiero borrar ningun dispositivo",
        addingDevice: "Agregando nuevo dispositivo",
				actionFail: "No se pudo completar la acción, vuelva a intentarlo.",
        addingDeviceTitle: "Iniciando"
      },
      eng:{
        devicesTitle: "Devices",
        devicesMax: "You have reached the maximum number of devices available for your account. You can delete one to replace it with this one.",
        notDelete: "I don't want to delete any device",
        addingDevice: "Adding new device",
				actionFail: "The action could not be completed, please try again.",
        addingDeviceTitle: "Starting"
      },
      por:{
        devicesTitle: "Dispositivos",
        devicesMax: "Você atingiu o número máximo de dispositivos disponíveis para sua conta. Você pode excluir um para substituí-lo por este.",
        notDelete: "Não quero apagar nenhum dispositivo",
        addingDevice: "Adicionando novo dispositivo",
				actionFail: "Não foi possível concluir a ação. Tente novamente.",
        addingDeviceTitle: "Iniciando"
      }
    }

    $q.all([userDataService.getPhoneLenguage()])
		.then(function(result){
			if(result[0] == "pt"){
				self.phoneLanguage  = "por"
			  } else if(result[0] == "en"){
				self.phoneLanguage  = "eng"
			  } else if(result[0] == "es"){
				self.phoneLanguage  = "spa"
			  } else {
				self.notProcessedLanguage = window.navigator.userLanguage || window.navigator.language;

				if(self.notProcessedLanguage.includes("en")){
					self.phoneLanguage =  "eng";
				} else if(self.notProcessedLanguage.includes("pt")){
					self.phoneLanguage =  "por";
				} else {
					self.phoneLanguage =  "spa";
				}
			  }
		});

    self.getTexts = function(type){
      return self.phoneLanguage ? self.devicesTexts[self.phoneLanguage][type] : self.devicesTexts["eng"][type];
    };

    self.ajaxInProgressValue = function() {
      return ajaxInProgress.state.value;
    };

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value == "deletingDevice") {
          var devices = res.data.message.devices;
          deviceService.setDevicesList(devices);

          if (devices.length < 1) {
            return self.addDevice();
          }

          ajaxInProgress.setState({
            value: false
          });
        } else if (ajaxInProgress.state.value == "addingDevice") {
          ajaxInProgress.setState({
            value: false
          });

          var accessToken = res.data ? res.data.accessToken : null;

          if (accessToken) {
            var newData = res.data ? res.data.userData : null;

            if (newData) {
              //true item to partial processData
              userDataService.procesarUserData(newData, true);
            }

            ymSidenavService.openYmSidenav();

            $state.go("controlpanel", null, { location: "replace" });

            user.clearEmailAndPassword();
          }
        }
      } else {
        if (res.status == -1) {
          alertService.showAlert("Atención", self.getTexts("actionFail"), "OK");
          // ajaxInProgress.state.value = false;
          ajaxInProgress.setState({
            value: false
          });
          return;
        }

        alertService.showAlert("Atención", res.data.message, "OK");
        // ajaxInProgress.state.value = false;
        ajaxInProgress.setState({
          value: false
        });
      }
    }

    self.eliminarDevice = function(device) {
      if (!ajaxInProgress.state.value) {
        ajaxInProgress.setState({
          value: "deletingDevice",
          label: self.getTexts("addingDevice"),
          title: self.getTexts("addingDeviceTitle"),
        });
        deviceService.deleteUnauth(user.getEmail(), user.getPassword(), device.id).then(handleRequest, handleRequest);
      }
    };

    self.cancelar = function() {
      user.clearEmailAndPassword();
      $state.go("login");
    };

    self.addDevice = function() {
      // if (!ajaxInProgress.state.value) {
      __env.getDeviceData().then(
        function(deviceData) {
          ajaxInProgress.setState({
            value: "addingDevice",
            label: self.getTexts("addingDevice"),
            title: self.getTexts("addingDeviceTitle"),
          });
          deviceService.add(user.getEmail(), user.getPassword(), deviceData).then(handleRequest, handleRequest);
        },
        function() {
          // console.log("Local storage rejection");
        }
      );
      // }
    };

    self.devices = deviceService.getDevicesList();
  }

  angular.module("app").controller("DevicesListController", DevicesListController);
})();
