(function () {
  function SystemHistoryController(
    $scope,
    // $rootScope,
    ajaxInProgress,
    customFormat,
    system,
    eventDecoder,
    eventCache,
    $window,
    $timeout,
    alertService,
    // seqService,
    actionBarService,
    buildModes,
    $stateParams,
    ymSidenavService,
    editMode,
    BUILD_MODE,
    BUILD_PLATFORM
  ) {
    console.log("HISTORY_CONTROLLER: SystemHistoryController created");
    editMode.state.value = false;

    if (
      BUILD_MODE == "MOBILE" ||
      (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)
    ) {
      ymSidenavService.closeYmSidenav();
    }

    var CANTIDAD_EVENTOS = 50;

    $scope.$on("$destroy", function () {
      console.log("HISTORY_CONTROLLER: SystemHistoryController destroyed");
    });

    var toISOLocal = function (d) {
      var z = function (n) {
        return (n < 10 ? "0" : "") + n;
      };
      // var off = self.sistemaElegido.timeZone.substring(3); Saco el offset en la última actualización.
      var off = 0;
      var sign = off * 60 < 0 ? "+" : "-";
      off = Math.abs(off);

      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        sign +
        z((off / 60) | 0) +
        z(off % 60)
      );
    };

    var self = this;

    self.viewInfo = false;

    $scope = $scope;

    self.internalAjaxInProgress = {
      value: false,
    };

    self.newInitPos;

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (self.internalAjaxInProgress.value == "consultandoEventos") {
          // console.log("Request Data: ",res.data, "Sistema Elegido: ", self.sistemaElegido);
          // console.log(JSON.stringify(res.data.message.response, null, 2));

          if (!self.sistemaElegido.events) {
            self.sistemaElegido.events = {};
          }

          self.sistemaElegido.events.eventList = self.sistemaElegido.events
            .eventList
            ? self.sistemaElegido.events.eventList
            : [];

          // console.log("TIEMPO DEL SISTMEA", self.sistemaElegido.timeZone.substring(3,6))

          self.lastTimeUpdate = new Date();

          // self.lastTimeUpdate = new Date(
          //   new Date().getTime() +
          //     3600 * 1000 * (new Date().getTimezoneOffset() / 60) +
          //     3600 * 1000 * self.sistemaElegido.timeZone.substring(3, 6)
          // );
          // console.log("SELF LASTTIMEUPDATE", self.lastTimeUpdate);
          self.sistemaElegido.events.lastTimeUpdate = self.lastTimeUpdate;

          self.sistemaElegido.events.eventList =
            res.data.message.response.eventosArray.map(function (evento) {
              self.eventRealDate = new Date(evento.date).getTime();

              // self.offset = 3600 * 1000 * (new Date().getTimezoneOffset() / 60);
              // self.expectedOffset =
              //   3600 * 1000 * self.sistemaElegido.timeZone.substring(3, 6);

              // self.totalDate =
              //   self.eventRealDate + self.offset + self.expectedOffset;
              // self.eventTemporalDate = new Date(self.totalDate);
              self.totalDate = self.eventRealDate;
              self.eventTemporalDate = new Date(self.eventRealDate);

              evento.dia = self.eventTemporalDate.getDate();
              evento.mes = self.eventTemporalDate.getMonth() + 1;
              evento.anio = self.eventTemporalDate.getFullYear();
              evento.horas = self.eventTemporalDate.getHours();
              evento.minutos = self.eventTemporalDate.getMinutes();
              evento.segundos = self.eventTemporalDate.getSeconds();
              evento.date = new Date();
              evento.date.setMonth(evento.mes - 1);
              evento.date.setDate(evento.dia);
              evento.date.setFullYear(
                "20" + customFormat.format(2, evento.anio)
              );
              evento.date.setHours(evento.horas);
              evento.date.setMinutes(evento.minutos);
              evento.date.setSeconds(evento.segundos);

              var decodedEvent = eventDecoder.getDecodedEvent(
                evento,
                self.sistemaElegido
              );

              evento.nombre = decodedEvent.name;
              evento.icono = decodedEvent.icon;
              evento.color = decodedEvent.color;
              evento.residential = decodedEvent.residential;

              return evento;
            });

          return renderizarYCachearEventos();
        }
      } else {
        if (res.status == -1) {
          ajaxInProgress.setState({
            value: false,
          });
          $timeout(function () {
            alertService.showAlert(
              "Atención",
              "No se pudo completar la acción, vuelva a intentarlo.",
              "OK"
            );
          });
          return;
        }
        if (res.status == -2) {
          ajaxInProgress.setState({
            value: false,
          });
          return;
        }
        ajaxInProgress.setState({
          value: false,
        });
        $timeout(function () {
          alertService.showAlert("Atención", res.data.message, "OK");
        });
      }
    }

    function renderizarYCachearEventos() {
      //Filtrar Eventos Repetidos
      var uniquePositions = [];
      self.sistemaElegido.events.eventList =
        self.sistemaElegido.events.eventList.filter(function (event) {
          if (event.code === undefined) {
            return true;
          }
          if (uniquePositions.indexOf(event._id) < 0) {
            uniquePositions.push(event._id);
            return true;
          }
          // console.log("Evento repetido filtrado!");
          return false;
        });

      //Quitar Eventos Más allá de CANTIDAD_EVENTOS
      if (self.sistemaElegido.events.eventList.length > CANTIDAD_EVENTOS) {
        self.sistemaElegido.events.eventList =
          self.sistemaElegido.events.eventList.slice(
            self.sistemaElegido.events.eventList.length - CANTIDAD_EVENTOS
          );
      }

      var sectionsTitles = [],
        sections = [];

      var today = new Date(); //Date.now();
      var sectionHoy =
        today.getDate() +
        "/" +
        (today.getMonth() + 1) +
        "/" +
        today.getFullYear();

      var yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);

      var sectionAyer =
        yesterday.getDate() +
        "/" +
        (yesterday.getMonth() + 1) +
        "/" +
        yesterday.getFullYear(); //"20" + customFormat.format(2, today.getFu);

      //Filtrar eventos residenciales
      self.eventListFiltered = self.sistemaElegido.events.eventList.filter(
        function (event) {
          return event.residential === true;
        }
      );

      // console.log("EVENT LIST FILTRADO RESIDENCIAL", self.eventListFiltered);

      self.eventListFiltered.forEach(function (event) {
        if (event.code === undefined) {
          return;
        }

        var sectionTitle =
          event.dia +
          "/" +
          event.mes +
          "/" +
          "20" +
          customFormat.format(2, event.anio);

        sectionTitle = sectionTitle !== sectionHoy ? sectionTitle : "Hoy";
        sectionTitle = sectionTitle !== sectionAyer ? sectionTitle : "Ayer";

        var indexOfSection = sectionsTitles.indexOf(sectionTitle);
        var section;
        if (indexOfSection > -1) {
          section = sections[indexOfSection];
        } else {
          section = {
            title: sectionTitle,
            events: [],
          };
          sectionsTitles.push(sectionTitle);
          sections.push(section);
        }

        // console.log("SECTIONS", sections);

        // Vieja forma
        // section.events.push({
        //   mensaje: event.nombre,
        //   timestamp: customFormat.format(2, event.horas) + ":" + customFormat.format(2, event.minutos)
        // });

        // Nueva forma
        var partitionName = "";

        if (
          self.sistemaElegido.programation.data.partitions.length > 0 &&
          event.particion != 0
        ) {
          var partitionData =
            self.sistemaElegido.programation.data.partitions.find(function (
              partition
            ) {
              return partition.number == event.particion;
            });

          if (partitionData && partitionData.name) {
            partitionName = partitionData.name + " ";
          } else if (partitionData && partitionData.number) {
            partitionName = "Partición " + partitionData.number + " ";
          }
        } else if (partitionName.length < 1 && event.particion) {
          partitionName = "Partición " + event.particion + " ";
        }

        var splittedEvent = event.nombre.split("\n");
        var eventTimestamp =
          customFormat.format(2, event.horas) +
          ":" +
          customFormat.format(2, event.minutos);

        if (partitionName) {
          if (
            event.code == "1" ||
            event.code == "3" ||
            event.code == "4" ||
            event.code == "31" ||
            event.code == "34" ||
            event.code == "63" ||
            event.code == "28" ||
            event.code == "76" ||
            event.code == "77" ||
            event.code == "32" ||
            event.code == "25" ||
            event.code == "26"
          ) {
            if (
              splittedEvent[1] &&
              splittedEvent[1] != "0" &&
              splittedEvent[1] != undefined
            ) {
              splittedEvent[1] = partitionName.concat(splittedEvent[1]);
            } else {
              splittedEvent[1] = partitionName;
            }
          }
          if (event.code == "95" || event.code == "94") {
            splittedEvent[1] = partitionName;
          }
        }

        if (splittedEvent[1] == "0") {
          splittedEvent[1] = "Evento General";
        }

        if (event.code === 54 || event.code === 97) {
          splittedEvent[1] = event.eventText;
        }

        section.events.push({
          firstLine: splittedEvent[0],
          secondLine: splittedEvent[1],
          icono: event.icono,
          color: event.color,
          timestamp: eventTimestamp,
        });
      });

      if (sections[0] && sections[0].events && sections[0].events.length > 0) {
        self.viewInfo = false;
      } else {
        self.viewInfo = true;
      }

      /* <Invertir las secciones> */
      sections = sections.reverse();

      /* <Invertir el orden de los eventos> */
      sections.forEach(function (section) {
        section.events = section.events.reverse();
      });

      var sectionsWithout2000 = sections.filter(function (section) {
        return section.title.indexOf("2000") < 0;
      });

      var sectionsWith2000 = sections.filter(function (section) {
        return section.title.indexOf("2000") > -1;
      });

      sections = [];

      sectionsWithout2000.forEach(function (section) {
        sections.push(section);
      });

      sectionsWith2000.forEach(function (section) {
        sections.push(section);
      });

      //Array final que muestro al front.
      self.renderizableEventList = sections;

      //Guardo eventos en LocalStorage.
      eventCache.storeEvents(self.sistemaElegido);

      ajaxInProgress.setState({
        value: false,
      });
    }

    self.getlastTimeUpdateStringfy = function () {
      // console.log("LAST TIME UPDATED!!!", self.lastTimeUpdate);
      if (self.lastTimeUpdate) {
        var fechaHoy = new Date();
        var mensajeHoy =
          fechaHoy.getDate() +
          "/" +
          (fechaHoy.getMonth() + 1) +
          "/" +
          fechaHoy.getFullYear();

        var fechaAyer = new Date(fechaHoy.getTime() - 24 * 60 * 60 * 1000);
        var mensajeAyer =
          fechaAyer.getDate() +
          "/" +
          (fechaAyer.getMonth() + 1) +
          "/" +
          fechaAyer.getFullYear(); //"20" + customFormat.format(2, today.getFu);

        var lastUpdateMessage =
          self.lastTimeUpdate.getDate() +
          "/" +
          (self.lastTimeUpdate.getMonth() + 1) +
          "/" +
          self.lastTimeUpdate.getFullYear();

        lastUpdateMessage =
          lastUpdateMessage !== mensajeHoy ? lastUpdateMessage : "Hoy";
        lastUpdateMessage =
          lastUpdateMessage !== mensajeAyer ? lastUpdateMessage : "Ayer";

        return (
          "Última actualización: " +
          lastUpdateMessage +
          " " +
          toISOLocal(self.lastTimeUpdate).substring(11, 16)
        );
      } else {
        return "Obteniendo últimos eventos";
      }
    };

    self.consultarEventos = function () {
      self.internalAjaxInProgress.value = "consultandoEventos";

      var ajax = system.consultarEventosNuevo(self.sistemaElegido);
      ajaxInProgress.setState({
        ajax: ajax,
      });

      ajax.then(handleRequest, handleRequest);
    };

    // Ejecución al iniciar el controlador
    system
      .getSystem($stateParams.idSistemaElegido)
      .then(function (sistemaElegido) {
        // console.log("HISTORY_CONTROLLER: iniciando SystemHistoryController");
        self.sistemaElegido = sistemaElegido;
        // Tareas para cuando el controller se instancia.
        if (self.sistemaElegido.estadoDeCarga === "verificado") {
          actionBarService.setActionBarActions("SystemHistoryController", [
            {
              label: "Actualizar",
              aria: "Actualizar",
              icon: "refresh",
              onClickHandler: function () {
                self.consultarEventos();
              },
            },
          ]);
        }
        system.setSelectedIndex("/system/history");
        if (BUILD_PLATFORM == "iOS" && $rootScope.focusingTab) {
          $rootScope.focusingTab("/system/history");
        }
        //Seteo el timeout de los comandos en función de lo que devuelva
        return system.setCommandTimeout(sistemaElegido.id);
      })
      .then(function (res) {
        return system.getLastEventReport(self.sistemaElegido);
      })
      .then(function (res) {
        if (res.data && res.data.success) {
          self.sistemaElegido.lastEventReport = res.data.message;
          // console.log('HISTORY_CONTROLLER: LAST EVENT REPORT PEDIDO', res.data.message);
          return system.getTimeZone(self.sistemaElegido.id);
        } else {
          if (res.status == -1) {
            ajaxInProgress.setState({
              value: false,
            });
            $timeout(function () {
              alertService.showAlert(
                "Atención",
                "No se pudo completar la acción, vuelva a intentarlo.",
                "OK"
              );
            });
            return;
          }
          if (res.status == -2) {
            ajaxInProgress.setState({
              value: false,
            });
            return;
          }
          ajaxInProgress.setState({
            value: false,
          });
          $timeout(function () {
            alertService.showAlert("Atención", res.data.message, "OK");
          });
        }
      })
      .then(function (res) {
        if (res.data && res.data.success) {
          // console.log('HISTORY_CONTROLLER: TIMEZONE PEDIDA', res.data.message);
          self.sistemaElegido.timeZone = res.data.message;
        } else {
          if (res.status == -1) {
            ajaxInProgress.setState({
              value: false,
            });
            $timeout(function () {
              alertService.showAlert(
                "Atención",
                "No se pudo completar la acción, vuelva a intentarlo.",
                "OK"
              );
            });
            return;
          }
          if (res.status == -2) {
            ajaxInProgress.setState({
              value: false,
            });
            return;
          }
          ajaxInProgress.setState({
            value: false,
          });
          $timeout(function () {
            alertService.showAlert("Atención", res.data.message, "OK");
          });
        }

        if (!self.sistemaElegido.events) {
          return eventCache.getEvents(self.sistemaElegido);
        }

        self.lastTimeUpdate = self.sistemaElegido.events.lastTimeUpdate;

        self.lastEventReportUpdated =
          self.sistemaElegido.lastEventReport +
          3600 * 1000 * (new Date().getTimezoneOffset() / 60) +
          3600 * 1000 * self.sistemaElegido.timeZone.substring(3, 6);

        // Si llego un evento posterior al update, pedir eventos.

        return self.consultarEventos();
      })
      .then(function (eventos) {
        if (eventos) {
          self.sistemaElegido.events = eventos;
          self.lastTimeUpdate = eventos.lastTimeUpdate;

          self.lastEventReportUpdated =
            self.sistemaElegido.lastEventReport +
            3600 * 1000 * (new Date().getTimezoneOffset() / 60) +
            3600 * 1000 * self.sistemaElegido.timeZone.substring(3, 6);

          // Si llego un evento posterior al update de los mismos pedir eventos.
          if (
            self.lastTimeUpdate &&
            self.lastEventReportUpdated > self.lastTimeUpdate.getTime()
          ) {
            return self.consultarEventos();
          }

          // Si no existe lista de eventos o ésta esta vacía pido eventos.
          if (
            !self.sistemaElegido.events.eventList ||
            self.sistemaElegido.events.eventList.length < 1
          ) {
            return self.consultarEventos();
          }

          self.consultarEventos();
        }
      })
      .catch(function (res) {
        if (res && res.data) {
          ajaxInProgress.setState({
            value: false,
          });
          $timeout(function () {
            alertService.showAlert("Atención", res.data.message, "OK");
          });
        }
      });
  }
  angular
    .module("app")
    .controller("SystemHistoryController", SystemHistoryController);
})();
