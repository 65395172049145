(function() {
  function SystemOutputsController($scope, $state, $rootScope, buildModes, $window, ymSidenavService, system, BUILD_MODE, statusCacheService, ajaxInProgress, $timeout, editMode, alertService, seqService, actionBarService, $stateParams) {
    console.log("SystemOutputsController created");

    $scope.$on("$destroy", function() {
      console.log("SystemOutputsController destroyed");
    });

    var self = this;

    self.$scope = $scope;

    self.viewInfo;

    editMode.state.value = false;

    self.editModeValue = function() {
      return editMode.state.value;
    };

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value == "consultandoEstado") {
          ajaxInProgress.setState({
            value: false
          });
        } else if (ajaxInProgress.state.value == "encendiendoOutput") {
          if (res.data.message.response == " OK. PGM ACTIVADA") {
            alertService.showToast("OK. SALIDA ENCENDIDA");
          } else {
            alertService.showToast(res.data.message.response);
          }
          ajaxInProgress.setState({
            value: false
          });
          // setTimeout(function() {
          //   self.consultarEstadoSilencioso();
          // }, 200);
        } else if (ajaxInProgress.state.value == "apagandoOutput") {
          if (res.data.message.response == " OK. PGM DESACTIVADA") {
            alertService.showToast("OK. SALIDA APAGADA");
          } else {
            alertService.showToast(res.data.message.response);
          }
          ajaxInProgress.setState({
            value: false
          });
          // setTimeout(function() {
          //   self.consultarEstadoSilencioso();
          // }, 200);
        }

        if (res.data.message.status) {
          self.sistemaElegido.status = statusCacheService.storeStatus(self.sistemaElegido, res.data.message.status);
        }

        // self.$scope.mainController.message = res.data.message;
      } else {
        if (res.status == -1) {
          ajaxInProgress.setState({
            value: false
          });
          $timeout(function() {
            alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
          });
          return;
        }
        if (res.status == -2) {
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
        ajaxInProgress.setState({
          value: false
        });
        $timeout(function() {
          alertService.showAlert("Atención", res.data.message, "OK");
        });
      }
    }

    self.loguearEstado = function() {
      // console.log(self.sistemaElegido);
      // console.log(self.$scope.mainController.sistemas);
      // console.log(self.$scope.mainController.sistemasCompartidos);
    };

    self.getLastEventReport = function() {
      var ajax = system.getLastEventReport(self.sistemaElegido);
      ajax.then(handleLastTimeReport, handleLastTimeReport);
    };

    function handleLastTimeReport(res) {
      if (res.data && res.data.success) {
        self.sistemaElegido.lastEventReport = res.data.message;

        if (self.sistemaElegido.lastEventReport > self.sistemaElegido.status.lastTimeUpdate.getTime()) {
          self.consultarEstado();
        }
      }
    }

    self.consultarEstadoSilencioso = function() {
      var ajax = system.consultarEstado(self.sistemaElegido, seqService.seq());
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "consultandoEstado",
        ajax: ajax
      });
    };

    self.consultarEstado = function() {
      var ajax = system.consultarEstado(self.sistemaElegido, seqService.seq());
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "consultandoEstado",
        label: "Consultando Estado",
        ajax: ajax
      });
    };

    self.encenderOutput = function(numero) {
      var ajax = system.encenderOutput(self.sistemaElegido, seqService.seq(), numero);
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "encendiendoOutput",
        label: "Encendiendo Salida",
        ajax: ajax
      });
    };

    self.apagarOutput = function(numero) {
      var ajax = system.apagarOutput(self.sistemaElegido, seqService.seq(), numero);
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "apagandoOutput",
        label: "Apagando Salida",
        ajax: ajax
      });
    };
    // Tareas para cuando el controller se instancia

    self.editMode = editMode;

    self.setSaveAction = function() {
      actionBarService.setActionBarActions("SystemOutputsController", [
        {
          label: "Guardar",
          aria: "Guardar",
          icon: "save",
          onClickHandler: function() {
            // Secuencia de guardado :D

            var toSaveOutputs = [];

            self.outputList.forEach(function(output) {
              var toSaveOutput = Object.assign({}, output);
              toSaveOutput.name = toSaveOutput.editedName;
              toSaveOutput.icon = toSaveOutput.editedIcon;
              delete toSaveOutput.editedName;
              delete toSaveOutput.editedIcon;
              toSaveOutputs.push(toSaveOutput);
            });

            toSaveOutputs.forEach(function(output) {
            });

            var programation = Object.assign({}, self.sistemaElegido.programation);

            programation.data.outputs = toSaveOutputs;

            $scope.systemController.setSystemProgramation(programation.data, "outputs");

            editMode.state.value = false;
            self.setEditAction();

            // $state.go("system");
          }
        },
        {
          label: "Cancelar",
          aria: "Cancelar",
          icon: "clear",
          onClickHandler: function() {
            editMode.state.value = false;
            self.setEditAction();
          }
        }
      ]);
    };

    self.setEditAction = function() {
      var buttons = [
        {
          label: "Actualizar",
          aria: "Actualizar",
          icon: "refresh",
          onClickHandler: function() {
            self.consultarEstado();
          }
        }
      ];

      if (self.sistemaElegido.owner && typeof self.sistemaElegido.owner.userType == "number" && self.sistemaElegido.owner.userType == 0) {
        buttons.push({
          label: "Editar",
          aria: "Editar",
          icon: "mode_edit",
          onClickHandler: function() {
            editMode.state.value = true;
            self.setSaveAction();
          }
        });
      }

      actionBarService.setActionBarActions("SystemOutputsController", buttons);
    };

    function handleLastUpdate(res){
			if (res.data && res.data.success) {
			  self.lastUpdate = res.data.message.lastUpdate;
        self.lastEvent = res.data.message.lastEvent;
	  
			  if (self.lastEvent && new Date(self.lastEvent).getTime() > new Date(self.lastUpdate).getTime()) {
				return $rootScope.pedirProgramacionDesdeSystem();
			  } else{
          if (!self.sistemaElegido.status) {
            return self.consultarEstado();
          }
          self.getLastEventReport();
        }
			} else {
			  if (res.status == -1) {
				ajaxInProgress.setState({
				  value: false
				});
				// $timeout(function() {
					alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
				// });
				return;
			  }
			  if (res.status == -2) {
				ajaxInProgress.setState({
				  value: false
				});
				return;
			  }
			  ajaxInProgress.setState({
				value: false
			  });
			  // $timeout(function() {
			  alertService.showAlert("Atención", res.data.message, "OK");
			}
		  }

		  self.getLastUpdate = function() {
			var ajax = system.getLastUpdate(self.sistemaElegido);
			ajax.then(handleLastUpdate, handleLastUpdate);
		  };

    system.getSystem($stateParams.idSistemaElegido).then(function(sistemaElegido) {
      self.sistemaElegido = sistemaElegido;
      //Seteo el timeout de los comandos en función de lo que devuelva
      system.setCommandTimeout(sistemaElegido.id);
			// $rootScope.focusingTab("/system/outputs");
      if (self.sistemaElegido.estadoDeCarga === "verificado") {
        if (self.sistemaElegido.programation.data) {
          self.outputList = self.sistemaElegido.programation.data.outputs;
        }

        // self.getLastEventReport();
        self.setEditAction();

        self.sistemaElegido.status = self.sistemaElegido.status || statusCacheService.getStatus(self.sistemaElegido);

        // if (!self.sistemaElegido.status && !ajaxInProgress.state.value) {
        //   self.consultarEstado();
        // }
        self.viewInfo = !self.outputList.some(function(e){
					return e.enabled == true;
				});
      }
      self.getLastUpdate();
    });
  }

  angular.module("app").controller("SystemOutputsController", SystemOutputsController);
})();
