(function() {
  function ajaxInProgress($timeout, alertService) {
    var self = this;

    self.state = {
      value: false,
      label: "",
      cancelable: false
    };

    self.setState = function(newState) {
      // $timeout(function() {

      self.evaluateNewDialog(newState, self.state);

      if (newState.value) {
        // Hacer algo si value es true
        return (self.state = {
          value: newState.value,
          label: newState.label || false,
          cancelable: newState.cancelable || false,
          ajax: newState.ajax
        });
      }

      if (newState.ajax) {
        // Hacer algo si value es false pero ajax es un objeto
        return (self.state = {
          value: self.state.value,
          label: self.state.label,
          cancelable: self.state.cancelable,
          ajax: newState.ajax
        });
      }

      // Hacer algo si value es false y no vino ajax
      self.state = {
        value: false,
        label: false,
        cancelable: false,
        ajax: undefined
      };
      // });
    };

    // $scope.$watch(
    //   function() {
    //     return ajaxInProgress.state;
    //   },
    self.evaluateNewDialog = function(newVal, oldVal) {
      console.log("AjaxInProgress::evaluateNewDialog()", oldVal.label, newVal.label);

      if (newVal.value === false) {
        console.log("AjaxInProgress::hideProgressDialog()");
        return alertService.hideProgressDialog();
      }

      if (newVal.label && newVal.label !== oldVal.label) {
        console.log("AjaxInProgress::showProgressDialog()");
        alertService
          .showProgressDialog(newVal.title || "Comando en progreso", newVal.label, newVal.cancelable)
          .then(function(result) {
            if (result === "CANCEL") {
              // $timeout(function() {
              alertService
                .showAlert("Atención", "Has cancelado el comando", "OK")
                .then(function() {})
                .catch(function(error) {
                  console.log("Era acá...", error);
                });
              // });
              newVal.ajax && newVal.ajax.cancelRetry();
            }
            console.log("Se cerró solo");
          })
          .catch(function(error) {
            console.log("Lo cerró alguien más");
            // console.log("AjaxInProgress::showProgressDialog(error)", error);
          });
      }
    };
    // );
  }
  angular.module("app").service("ajaxInProgress", ajaxInProgress);
})();
