(function() {
	function partKeyDirective() {
		var PASSWORD_NUMBER_REGEXP = /([0-9])+/;

		return {
			require: "ngModel",
			link: function(scope, elm, attrs, ctrl) {
				ctrl.$validators.partkey = function(modelValue, viewValue) {
					var level = 0;
					if (!viewValue || viewValue.length != 4) {
						return false;
					}
					if (PASSWORD_NUMBER_REGEXP.test(viewValue)) {
						return true;
					}
					return false;
				};
			}
		};
	}

	angular
		.module("app")
		.directive("partkey", partKeyDirective)
})();