(function() {
  function userDataService(localStorageService, BUILD_PRODUCT) {
    var self = this;

    var userData = {};

    var appIsCustomized, appCustomizedCode;

    if(BUILD_PRODUCT == "titanium_control"){
      self.productName = "Alarm Home";
    }
    if(BUILD_PRODUCT == "garnet_control"){
      self.productName = "Garnet Control";
    }

    self.setAppCustomizedStateLS = function(bool){
      appIsCustomized = bool;
      localStorageService.setItem("appIsCustomized", bool);
    };

    self.setAppCustomizedCode = function(code){
      if(self.productName == "Alarm Home" && code ==  "Garnet" || code == "Garnet Control" 
        || code == "garnet" || code == "garnetcontrol" || code == "GarnetControl")
        {
          code = "Alarm Home"
        };

        if(self.productName == "Garnet Control" &&
          code ==  "Alarm Home" ||
          code == "alarmhome")
        {
          code = "Garnet Control"
        };
      appCustomizedCode = code;
      localStorageService.setItem("customizationCode", code);
    };

    self.getPhoneLenguage = function(){
      return localStorageService.getItem("phoneLanguage");
    };

    self.getAppIsCustomized = function(){
      return appIsCustomized;
    };

    self.getAppCustomizationCode = function(){
      return appCustomizedCode;
    };

    self.procesarUserData = function(data, partial) {
      userData = Object.assign({}, data);
      if(!partial){
        if(userData.codigoEmpresa != "Alarm Home" && userData.codigoEmpresa != "Garnet" && userData.codigoEmpresa != "Garnet Control" 
        && userData.codigoEmpresa != "garnet" && userData.codigoEmpresa != "garnetcontrol" && userData.codigoEmpresa != "GarnetControl" && userData.codigoEmpresa != "alarmhome")
        {
          if(!userData.codigoEmpresa){
            self.setAppCustomizedStateLS(false);
          } else {
          self.setAppCustomizedCode(userData.codigoEmpresa);
          self.setAppCustomizedStateLS(true);
          }
        } else {
          if(!userData.codigoEmpresa){
            self.setAppCustomizedStateLS(false);
          } else{
            self.setAppCustomizedCode(userData.codigoEmpresa);
            self.setAppCustomizedStateLS(false);
          }
        }
      }
    };

    self.getUserData = function() {
      return userData;
    };
  }

  angular.module("app").service("userDataService", userDataService);
})();
