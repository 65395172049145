(function () {
  var eventTable = {
    1: {
      key: "medical",
      code: 1,
      cid: 100,
      residential: true,
      eventGroup: 1,
      searchByUser: true,
      qualifier: {
        0: {
          text: "Restauración de Emergencia\nmédica",
          icon: 0,
          color: 0,
        },
        1: {
          text: "Emergencia médica \n${zonaUsuario}",
          icon: 0,
          color: 1,
        },
      },
    },
    2: {
      key: "fire",
      code: 2,
      cid: 110,
      eventGroup: 1,
      residential: true,
      searchByZone: true,
      qualifier: {
        0: {
          text: "Restauración de Alarma de incendio\nen ${zonaUsuario}",
          icon: 1,
          color: 0,
        },
        1: {
          text: "Alarma de incendio\nen ${zonaUsuario}",
          icon: 1,
          color: 2,
        },
      },
    },
    3: {
      key: "pull_station",
      code: 3,
      cid: 115,
      eventGroup: 1,
      residential: true,
      searchByUser: true,
      qualifier: {
        0: {
          text: "Restauración de emergencia\nde incendio",
          icon: 1,
          color: 0,
        },
        1: {
          text: "Emergencia de incendio \n${zonaUsuario}",
          icon: 1,
          color: 2,
        },
      },
    },
    4: {
      key: "panic",
      code: 4,
      cid: 120,
      eventGroup: 1,
      residential: true,
      searchByUser: true,
      qualifier: {
        0: {
          text: "Restauración de emergencia\nde pánico",
          icon: 2,
          color: 0,
        },
        1: {
          text: "Emergencia de pánico \n${zonaUsuario}",
          icon: 2,
          color: 3,
        },
      },
    },
    5: {
      key: "duress",
      code: 5,
      cid: 121,
      eventGroup: 1,
      residential: true,
      qualifier: {
        1: {
          text: "Desarmado por coacción",
          icon: 3,
          color: 4,
        },
      },
    },
    6: {
      key: "burglary",
      code: 6,
      cid: 130,
      eventGroup: 1,
      residential: true,

      searchByZone: true,
      qualifier: {
        0: {
          text: "Restauración de alarma\nen ${zonaUsuario}",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Alarma\nen ${zonaUsuario}",
          icon: 6,
          color: 4,
        },
      },
    },
    7: {
      key: "perimeter",
      code: 7,
      cid: 131,
      eventGroup: 1,
      residential: true,

      searchByZone: true,
      qualifier: {
        0: {
          text: "Restauración de alarma perimetral\nen ${zonaUsuario}",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Alarma perimetral\nen ${zonaUsuario}",
          icon: 6,
          color: 4,
        },
      },
    },
    8: {
      key: "interior",
      code: 8,
      cid: 132,
      eventGroup: 1,
      residential: true,

      searchByZone: true,
      qualifier: {
        0: {
          text: "Restauración de alarma interior\nen ${zonaUsuario}",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Alarma interior\nen ${zonaUsuario}",
          icon: 6,
          color: 4,
        },
      },
    },
    9: {
      key: "twenty_four_hs",
      code: 9,
      cid: 133,
      eventGroup: 1,
      residential: true,

      searchByZone: true,
      qualifier: {
        0: {
          text: "Restauración de alarma 24Hs\nen ${zonaUsuario}",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Alarma 24Hs\nen ${zonaUsuario}",
          icon: 6,
          color: 4,
        },
      },
    },
    10: {
      key: "tamper",
      code: 10,
      cid: 137,
      eventGroup: 1,
      residential: true,

      searchByZone: true,
      qualifier: {
        0: {
          text: "Restauración de alarma de tamper\nen ${zonaUsuario}",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Alarma de tamper\nen ${zonaUsuario}",
          icon: 6,
          color: 4,
        },
      },
    },
    11: {
      key: "near_alarm",
      code: 11,
      cid: 138,
    },
    12: {
      key: "intrusion_verifier",
      code: 12,
      cid: 139,
      eventGroup: 1,
      residential: true,
      qualifier: {
        1: {
          text: "Alarma verificada",
          icon: 6,
          color: 4,
        },
      },
    },
    13: {
      key: "silent_burglary",
      code: 13,
      cid: 146,
      eventGroup: 1,
      searchByZone: true,
      residential: true,

      qualifier: {
        0: {
          text: "Restauración de alarma silenciosa\nen ${zonaUsuario}",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Alarma silenciosa\nen ${zonaUsuario}",
          icon: 6,
          color: 4,
        },
      },
    },
    14: {
      key: "ac_loss",
      code: 14,
      cid: 301,
      eventGroup: 3,
      residential: true,

      qualifier: {
        0: {
          text: "Restauración de energía eléctrica",
          icon: 7,
          color: 0,
        },
        1: {
          text: "Falla energía eléctrica",
          icon: 7,
          color: 5,
        },
      },
    },
    15: {
      key: "low_system_battery",
      code: 15,
      cid: 302,
      eventGroup: 3,
      residential: true,
      qualifier: {
        0: {
          text: "Batería cargada",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Batería baja",
          icon: 6,
          color: 5,
        },
      },
    },
    16: {
      key: "system_reset",
      code: 16,
      cid: 305,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "Reset de sistema",
          icon: 6,
          color: 5,
        },
      },
    },
    17: {
      key: "panel_programming_changed",
      code: 17,
      cid: 306,
      eventGroup: 4,
      residential: true,
      qualifier: {
        1: {
          text: "Programación cambiada",
          icon: 6,
          color: 5,
        },
      },
    },
    18: {
      key: "system_shutdown",
      code: 18,
      cid: 308,
      eventGroup: 4,
      residential: true,
      qualifier: {
        1: {
          text: "Sistema apagado",
          icon: 6,
          color: 5,
        },
      },
    },
    19: {
      key: "power_supply_overcurrent",
      code: 19,
      cid: 312,
      eventGroup: 3,
      residential: true,
      qualifier: {
        0: {
          text: "Salida auxiliar restaurada",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Salida auxiliar sobrecargada",
          icon: 6,
          color: 5,
        },
      },
    },
    20: {
      key: "bell1",
      code: 20,
      cid: 321,
      eventGroup: 4,
      residential: true,
      qualifier: {
        0: {
          text: "Sirena restaurada",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Fallo de sirena",
          icon: 6,
          color: 5,
        },
      },
    },
    21: {
      key: "expansion_module_failure",
      code: 21,
      cid: 333,
      eventGroup: 4,
      zoneUser: [
        {
          regex: "^0$",
          qualifier: {
            0: {
              text: "Restauración de \nteclado ${zonaUsuario}",
              icon: 6,
              color: 0,
            },
            1: {
              text: "Falla de \nteclado ${zonaUsuario}",
              icon: 6,
              color: 5,
            },
          },
        },
        {
          regex: "^[1-8]$",
          qualifier: {
            0: {
              text: "Restauración de módulo\nteclado ${zonaUsuario}",
              icon: 6,
              color: 0,
            },
            1: {
              text: "Falla de módulo\nteclado ${zonaUsuario}",
              icon: 6,
              color: 5,
            },
          },
        },
        {
          regex: "^9|(1[01])$",
          qualifier: {
            0: {
              text: "Restauración de módulo\nexpansor ${zonaUsuario}",
              icon: 6,
              color: 0,
            },
            1: {
              text: "Falla de módulo\nexpansor ${zonaUsuario}",
              icon: 6,
              color: 5,
            },
          },
        },
        {
          regex: "^12$",
          qualifier: {
            0: {
              text: "Restauración de Fuente auxiliar",
              icon: 6,
              color: 0,
            },
            1: {
              text: "Falla de fuente auxiliar",
              icon: 6,
              color: 5,
            },
          },
        },
      ],
    },
    22: {
      key: "module_reset",
      code: 22,
      cid: 339,
    },
    23: {
      key: "comunication_trouble",
      code: 23,
      cid: 350,
      eventGroup: 4,
      qualifier: {
        0: {
          text: "Restauración de comunicación",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Falla de comunicación",
          icon: 6,
          color: 5,
        },
      },
    },
    24: {
      key: "telco1_fault",
      code: 24,
      cid: 351,
      eventGroup: 4,
      residential: true,
      qualifier: {
        0: {
          text: "Línea telefónica restaurada",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Falla de línea telefónica",
          icon: 6,
          color: 5,
        },
      },
    },
    25: {
      key: "open_close",
      code: 25,
      cid: 400,
      eventGroup: 2,
      residential: true,
      qualifier: {
        0: {
          text: "Sistema armado",
          icon: 4,
          color: 6,
        },
        1: {
          text: "Sistema desarmado",
          icon: 3,
          color: 7,
        },
      },
    },
    26: {
      key: "open_close_user",
      code: 26,
      cid: 401,
      eventGroup: 2,
      residential: true,
      zoneUser: [
        {
          regex: "^0$",
          qualifier: {
            0: {
              text: "Armado rápido",
              icon: 4,
              color: 6,
            },
            1: {
              text: "Desarmado rápido",
              icon: 3,
              color: 7,
            },
          },
        },
        {
          // regex: "^([0-9][0-9])|\\D[1-9]$",
          regex: "^[1-9][0-9]*$",
          searchByUser: true,
          qualifier: {
            0: {
              text: "Armado\npor usuario ${zonaUsuario}",
              icon: 4,
              color: 6,
            },
            1: {
              text: "Desarmado\npor usuario ${zonaUsuario}",
              icon: 3,
              color: 7,
            },
          },
        },
      ],
    },
    27: {
      key: "group_open_close",
      code: 27,
      cid: 402,
      eventGroup: 2,
      residential: true,
      qualifier: {
        0: {
          text: "Sistema armado",
          icon: 4,
          color: 6,
        },
        1: {
          text: "Sistema desarmado",
          icon: 3,
          color: 7,
        },
      },
    },
    28: {
      key: "automatic_open_close",
      code: 28,
      cid: 403,
      eventGroup: 2,
      residential: true,
      qualifier: {
        0: {
          text: "Armado automático",
          icon: 4,
          color: 6,
        },
        1: {
          text: "Desarmado automático",
          icon: 3,
          color: 7,
        },
      },
    },
    29: {
      key: "late_to_open_close",
      eventGroup: 2,
      code: 29,
      cid: 404,
    },
    30: {
      key: "cancel",
      code: 30,
      cid: 406,
      eventGroup: 1,
      qualifier: {
        1: {
          text: "Cancelación",
          icon: 6,
          color: 5,
        },
      },
    },
    31: {
      key: "remote_arm_disarm",
      code: 31,
      cid: 407,
      eventGroup: 2,
      residential: true,
      zoneUser: [
        {
          regex: "^0$",
          qualifier: {
            0: {
              text: "Armado\npor llavero ${zonaUsuario}",
              icon: 4,
              color: 6,
            },
            1: {
              text: "Desarmado\npor llavero ${zonaUsuario}",
              icon: 3,
              color: 7,
            },
          },
        },
        {
          regex: "^(6[0-5])|([0-5][0-9])|\\D[0-9]$",
          qualifier: {
            0: {
              text: "Armado\npor llavero ${zonaUsuario}",
              icon: 4,
              color: 6,
            },
            1: {
              text: "Desarmado\npor llavero ${zonaUsuario}",
              icon: 3,
              color: 7,
            },
          },
        },
        {
          regex: "^70$",
          qualifier: {
            0: {
              text: "Armado\npor SMS",
              icon: 4,
              color: 6,
            },
            1: {
              text: "Desarmado\npor SMS",
              icon: 3,
              color: 7,
            },
          },
        },
        {
          regex: "^7[1-4]$",
          qualifier: {
            0: {
              text: "Armado\npor control telefónico",
              icon: 4,
              color: 6,
            },
            1: {
              text: "Desarmado\npor control telefónico",
              icon: 3,
              color: 7,
            },
          },
        },
        {
          regex: "^75$",
          qualifier: {
            0: {
              text: "Armado\npor PC-LINK",
              icon: 4,
              color: 6,
            },
            1: {
              text: "Desarmado\npor PC-LINK",
              icon: 3,
              color: 7,
            },
          },
        },
        {
          regex: "^76$",
          qualifier: {
            0: {
              text: "Armado\npor Modem",
              icon: 4,
              color: 6,
            },
            1: {
              text: "Desarmado\npor Modem",
              icon: 3,
              color: 7,
            },
          },
        },
        {
          regex: "^77$",
          qualifier: {
            1: {
              text: "Armado\npor inactividad",
              icon: 4,
              color: 6,
            },
          },
          qualifier: {
            0: {
              text: "Armado\npor inactividad",
              icon: 4,
              color: 6,
            },
          },
        },
        {
          regex: "^78$",
          qualifier: {
            0: {
              text: "Armado\npor AC4",
              icon: 4,
              color: 6,
            },
            1: {
              text: "Desarmado\npor AC4",
              icon: 3,
              color: 7,
            },
          },
        },
        {
          regex: "^79$",
          qualifier: {
            0: {
              text: "Armado\npor WatchDog",
              icon: 4,
              color: 6,
            },
            1: {
              text: "Desarmado\npor WatchDog",
              icon: 3,
              color: 7,
            },
          },
        },
        {
          regex: "^[8-9]\\d$",
          searchByUser: true,
          qualifier: {
            0: {
              text: "Armado\ndesde App por usuario ${zonaUsuario}",
              icon: 4,
              color: 6,
            },
            1: {
              text: "Desarmado\ndesde App por usuario ${zonaUsuario}",
              icon: 3,
              color: 7,
            },
          },
        },
      ],
    },
    32: {
      key: "quick_arm_disarm",
      code: 32,
      cid: 408,
      eventGroup: 2,
      residential: true,
      qualifier: {
        0: {
          text: "Armado rápido",
          icon: 4,
          color: 6,
        },
        1: {
          text: "Desarmado rápido",
          icon: 3,
          color: 7,
        },
      },
    },
    33: {
      key: "keyswitch_open_close",
      code: 33,
      cid: 409,
      eventGroup: 2,
      residential: true,
      qualifier: {
        0: {
          text: "Armado\npor keyswitch",
          icon: 4,
          color: 6,
        },
        1: {
          text: "Desarmado\npor keyswitch",
          icon: 3,
          color: 7,
        },
      },
    },
    34: {
      key: "armed_stay_dem",
      code: 34,
      cid: 441,
      eventGroup: 2,
      residential: true,
      zoneUser: [
        {
          regex: "^0$",
          // searchByUser: true,
          qualifier: {
            1: {
              text: "Armado presente",
              icon: 4,
              color: 8,
            },
            0: {
              text: "Armado presente",
              icon: 4,
              color: 8,
            },
          },
        },
        {
          regex: "^(3[0-2])|([0-2][0-9])|\\D[0-9]$",
          searchByUser: true,
          qualifier: {
            0: {
              text: "Armado presente\npor usuario ${zonaUsuario}",
              icon: 4,
              color: 8,
            },
          },
        },
        {
          regex: "^[8-9]\\d$",
          searchByUser: true,
          qualifier: {
            0: {
              text: "Armado presente\ndesde App por usuario ${zonaUsuario}",
              icon: 4,
              color: 8,
            },
          },
        },
      ],
    },
    35: {
      key: "early_open_close",
      code: 35,
      cid: 451,
    },
    36: {
      key: "walk_test_mode_stop",
      code: 36,
      cid: 607,
    },
    37: {
      key: "auto_arm_failed",
      code: 37,
      cid: 455,
      eventGroup: 2,
      residential: true,
      qualifier: {
        1: {
          text: "Falla de autoarmado",
          icon: 3,
          color: 5,
        },
      },
    },
    38: {
      key: "partial_arm",
      code: 38,
      cid: 456,
    },
    39: {
      key: "recent_close",
      code: 39,
      cid: 459,
      eventGroup: 2,
      qualifier: {
        1: {
          text: "Cierre reciente",
          icon: 4,
          color: 5,
        },
      },
    },
    40: {
      key: "successful_access",
      code: 40,
      cid: 412,
    },
    41: {
      key: "successful_upload",
      code: 41,
      cid: 412,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "Programación exitosa",
          icon: 8,
          color: 5,
        },
      },
    },
    42: {
      key: "zone_sensor_bypass",
      code: 42,
      cid: 570,
      eventGroup: 4,
      residential: true,
      searchByZone: true,
      qualifier: {
        0: {
          text: "Zona desinhibida\n${zonaUsuario}",
          icon: 6,
          color: 10,
        },
        1: {
          text: "Zona inhibida\n${zonaUsuario}",
          icon: 6,
          color: 9,
        },
      },
    },
    43: {
      key: "group_bypass",
      code: 43,
      cid: 574,
      eventGroup: 4,
      residential: true,
      qualifier: {
        0: {
          text: "Desinhibición grupal",
          icon: 9,
          color: 10,
        },
        1: {
          text: "Inhibición grupal",
          icon: 9,
          color: 9,
        },
      },
    },
    44: {
      key: "swinger_bypass",
      code: 44,
      cid: 575,
    },
    45: {
      key: "manual_trigger_test_report",
      code: 45,
      cid: 601,
      eventGroup: 4,
      residential: true,
      qualifier: {
        1: {
          text: "Informe de test manual",
          icon: 6,
          color: 0,
        },
      },
    },
    46: {
      key: "periodic_test_report",
      code: 46,
      cid: 602,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "Test periódico",
          icon: 6,
          color: 0,
        },
      },
    },
    47: {
      key: "status_report_to_follow",
      code: 47,
      cid: 605,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "Test seguidor",
          icon: 6,
          color: 0,
        },
      },
    },
    48: {
      key: "walk_test_mode_start",
      code: 48,
      cid: 607,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "Modo test de paseo",
          icon: 6,
          color: 0,
        },
      },
    },
    49: {
      key: "intrusion_zone_walk_tested",
      code: 49,
      cid: 613,
    },
    50: {
      key: "fire_zone_walk_tested",
      code: 50,
      cid: 614,
    },
    51: {
      key: "panic_zone_walk_tested",
      code: 51,
      cid: 615,
    },
    52: {
      key: "time_date_inaccurate",
      code: 52,
      cid: 626,
      eventGroup: 4,
      residential: true,

      qualifier: {
        1: {
          text: "Sistema fuera de hora",
          icon: 6,
          color: 5,
        },
      },
    },
    53: {
      key: "programm_mode_entry",
      code: 53,
      cid: 627,
      eventGroup: 4,
      residential: true,

      qualifier: {
        1: {
          text: "Ingreso a programación",
          icon: 8,
          color: 5,
        },
      },
    },
    54: {
      key: "programm_mode_exit",
      code: 54,
      cid: 628,
      eventGroup: 4,
      residential: true,
      qualifier: {
        1: {
          text: "Programación Cambiada \n Se modificó la programación del sistema",
          icon: 8,
          color: 0,
        },
      },
    },
    55: {
      key: "system_inactivity",
      code: 55,
      cid: 654,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "Programación Cambiada \n Se modificó la programación del sistema",
          icon: 8,
          color: 0,
        },
      },
    },
    56: {
      key: "entry_exit",
      code: 56,
      cid: 134,
      eventGroup: 1,
      searchByZone: true,
      residential: true,
      qualifier: {
        0: {
          text: "Restauración de alarma\nen ${zonaUsuario}",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Alarma\nen ${zonaUsuario}",
          icon: 6,
          color: 4,
        },
      },
    },
    57: {
      key: "sensor_tamper",
      code: 57,
      cid: 144,
      eventGroup: 1,
      searchByZone: true,
      residential: true,
      qualifier: {
        0: {
          text: "Tamper restaurado\nen ${zonaUsuario}",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Alarma de tamper\nen ${zonaUsuario}",
          icon: 6,
          color: 4,
        },
      },
    },
    58: {
      key: "sensor_supervision_failure",
      code: 58,
      cid: 381,
      eventGroup: 4,
      searchByZone: true,
      residential: true,
      qualifier: {
        0: {
          text: "Restauración de Supervisión\nen ${zonaUsuario}",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Falla de supervisión\nen ${zonaUsuario}",
          icon: 6,
          color: 5,
        },
      },
    },
    59: {
      key: "programm_time_date",
      code: 59,
      cid: 306,
      eventGroup: 4,
      residential: true,
      qualifier: {
        1: {
          text: "Fecha y hora sincronizada",
          icon: 10,
          color: 5,
        },
      },
    },
    60: {
      key: "asalto",
      code: 60,
      cid: 122,
      eventGroup: 1,
      searchByZone: true,
      residential: true,
      qualifier: {
        0: {
          text: "Restauración de alarma de asalto\nen ${zonaUsuario}",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Alarma de asalto\nen ${zonaUsuario}",
          icon: 6,
          color: 4,
        },
      },
    },
    61: {
      key: "emerg_medica",
      code: 61,
      cid: 100,
      eventGroup: 1,
      searchByZone: true,
      residential: true,
      qualifier: {
        0: {
          text: "Restauración de emergencia médica\nen ${zonaUsuario}",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Alarma de emergencia médica\nen ${zonaUsuario}",
          icon: 6,
          color: 4,
        },
      },
    },
    62: {
      key: "inundacion",
      code: 62,
      cid: 154,
      eventGroup: 1,
      searchByZone: true,
      residential: true,
      qualifier: {
        0: {
          text: "Restauración de alarma en zona\nde inundación ${zonaUsuario}",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Alarma en zona\nde inundación ${zonaUsuario}",
          icon: 6,
          color: 4,
        },
      },
    },
    63: {
      key: "armed_stay_inst",
      code: 63,
      cid: 441,
      eventGroup: 2,
      residential: true,
      zoneUser: [
        {
          regex: "^0$",
          qualifier: {
            0: {
              text: "Armado presente instantáneo",
              icon: 5,
              color: 8,
            },
          },
        },
        {
          regex: "^(3[0-2])|([0-2][0-9])|\\D[0-9]$",
          searchByUser: true,
          qualifier: {
            0: {
              text: "Armado presente instantáneo\npor usuario ${zonaUsuario}",
              icon: 5,
              color: 8,
            },
          },
        },
        {
          regex: "^[8-9]\\d$",
          searchByUser: true,
          qualifier: {
            0: {
              text: "Armado presente instantáneo\ndesde App por usuario ${zonaUsuario}",
              icon: 5,
              color: 8,
            },
          },
        },
      ],
    },
    64: {
      key: "burglary_not_verifier",
      code: 64,
      cid: 378,
      eventGroup: 1,
      residential: true,
      qualifier: {
        1: {
          text: "Alarma no verificada",
          icon: 6,
          color: 5,
        },
      },
    },
    65: {
      key: "comunication_trouble_tel1",
      code: 65,
      cid: 354,
      eventGroup: 4,
      qualifier: {
        0: {
          text: "Restauración de comunicación\npor teléfono 1",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Falla de comunicación\npor teléfono 1",
          icon: 6,
          color: 5,
        },
      },
    },
    66: {
      key: "comunication_trouble_tel2",
      code: 66,
      cid: 354,
      eventGroup: 4,
      qualifier: {
        0: {
          text: "Restauración de comunicación\npor teléfono 2",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Falla de comunicación\npor teléfono 2",
          icon: 6,
          color: 5,
        },
      },
    },
    67: {
      key: "comunication_trouble_gprs",
      code: 67,
      cid: 354,
      eventGroup: 4,
      qualifier: {
        0: {
          text: "Restauración de comunicación\npor GPRS",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Falla de comunicación\npor GPRS",
          icon: 6,
          color: 5,
        },
      },
    },
    68: {
      key: "comunication_trouble_internet",
      code: 68,
      cid: 354,
      eventGroup: 4,
      qualifier: {
        0: {
          text: "Restauración de comunicación\npor internet",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Falla de comunicación\npor internet",
          icon: 6,
          color: 5,
        },
      },
    },
    69: {
      key: "comunication_trouble_sms_resid",
      code: 69,
      cid: 354,
      eventGroup: 4,
      qualifier: {
        0: {
          text: "Restauración de comunicación\npor SMS",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Falla de comunicación\npor SMS",
          icon: 6,
          color: 5,
        },
      },
    },
    70: {
      key: "gprs_module_failure",
      code: 70,
      cid: 330,
      eventGroup: 4,
      qualifier: {
        0: {
          text: "Restauración de módulo\nGPRS",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Falla de módulo\nGPRS",
          icon: 6,
          color: 5,
        },
      },
    },
    71: {
      key: "internet_module_failure",
      code: 71,
      cid: 330,
      eventGroup: 4,
      qualifier: {
        0: {
          text: "Restauración de módulo\ninternet",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Falla de módulo\ninternet",
          icon: 6,
          color: 5,
        },
      },
    },
    72: {
      key: "fallo_link_gprs",
      code: 72,
      cid: 350,
      eventGroup: 4,
      qualifier: {
        0: {
          text: "Restauración de link\nGPRS",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Falla de link\nGPRS",
          icon: 6,
          color: 5,
        },
      },
    },
    73: {
      key: "fallo_link_internet",
      code: 73,
      cid: 350,
      eventGroup: 4,
      qualifier: {
        0: {
          text: "Restauración de link\ninternet",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Falla de link\ninternet",
          icon: 6,
          color: 5,
        },
      },
    },
    74: {
      key: "keypad_tamper",
      code: 74,
      cid: 145,
      eventGroup: 1,
      qualifier: {
        0: {
          text: "Restauración en tamper\nde teclado",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Alarma en tamper\nde teclado",
          icon: 6,
          color: 4,
        },
      },
    },
    75: {
      key: "exp_module_ac_loss",
      code: 75,
      cid: 342,
      eventGroup: 3,
      residential: true,
      qualifier: {
        0: {
          text: "Restauración energía\nen fuente auxiliar",
          icon: 7,
          color: 0,
        },
        1: {
          text: "Falla de energía AC\nen fuente auxiliar",
          icon: 7,
          color: 5,
        },
      },
    },
    76: {
      key: "exp_module_low_bat",
      code: 76,
      cid: 338,
      eventGroup: 3,
      residential: true,
      qualifier: {
        0: {
          text: "Batería cargada\nen fuente auxiliar",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Batería baja\nen fuente auxiliar",
          icon: 6,
          color: 5,
        },
      },
    },
    77: {
      key: "rf_low_battery",
      code: 77,
      cid: 384,
      eventGroup: 3,
      searchByZone: true,
      residential: true,
      qualifier: {
        0: {
          text: "Batería ok\nen zona ${zonaUsuario}",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Baja batería\nen zona ${zonaUsuario}",
          icon: 6,
          color: 5,
        },
      },
    },
    78: {
      key: "fire_troble",
      code: 78,
      cid: 373,
      eventGroup: 4,
      searchByZone: true,
      qualifier: {
        0: {
          text: "Restauración de falla en zona\nde incendio ${zonaUsuario}",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Falla en zona\nde incencio ${zonaUsuario}",
          icon: 6,
          color: 5,
        },
      },
    },
    79: {
      key: "evento_sup_sensor",
      code: 79,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "Falla de supervisión en zona", //
          icon: 6,
          color: 5,
        },
      },
    },
    80: {
      key: "evento_reenvio_fallo_red",
      code: 80,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "Reenvío de fallo de alimentación AC",
          icon: 6,
          color: 5,
        },
      },
    },
    81: {
      key: "evento_reenvio_rest_red",
      code: 81,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "Reenvío de restauración de alimentación AC",
          icon: 6,
          color: 5,
        },
      },
    },
    82: {
      key: "evento_prog_textos_teclados",
      code: 82,
      cid: 306,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "Programación de textos",
          icon: 8,
          color: 5,
        },
      },
    },
    83: {
      key: "evento_power_up",
      code: 83,
      eventGroup: 4,
      residential: true,
      qualifier: {
        1: {
          text: "Sistema encendido",
          icon: 11,
          color: 5,
        },
      },
    },
    84: {
      key: "evento_watchdog_reset",
      code: 84,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "Reset por watchdog",
          icon: 11,
          color: 5,
        },
      },
    },
    85: {
      key: "evento_brown_out_reset",
      code: 85,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "Reset por Brown Out",
          icon: 11,
          color: 5,
        },
      },
    },
    86: {
      key: "evento_mclr_reset",
      code: 86,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "Reset por MCLR",
          icon: 11,
          color: 5,
        },
      },
    },
    87: {
      key: "evento_stack_full_reset",
      code: 87,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "Reset por stack full",
          icon: 11,
          color: 5,
        },
      },
    },
    88: {
      key: "evento_stack_underflow_reset",
      code: 88,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "Reset por stack underflow",
          icon: 11,
          color: 5,
        },
      },
    },
    89: {
      key: "evento_cancelacion_modulo_gprs",
      code: 89,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "GPRS Cancelado",
          icon: 6,
          color: 5,
        },
      },
    },
    90: {
      key: "evento_cancelacion_sms",
      code: 90,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "SMS Cancelado",
          icon: 6,
          color: 5,
        },
      },
    },
    91: {
      key: "evento_cancelacion_modulo_ip",
      code: 91,
      eventGroup: 4,
      qualifier: {
        1: {
          text: "Módulo IP Cancelado",
          icon: 6,
          color: 5,
        },
      },
    },
    92: {
      key: "comunication_trouble_ip_resid",
      code: 92,
      eventGroup: 4,
      qualifier: {
        0: {
          text: "Restauración de comunicación\nIP-Residencial",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Falla de comunicación\nIP-Residencial",
          icon: 6,
          color: 5,
        },
      },
    },
    93: {
      key: "salida_pgmw_encendida_apagada",
      code: 93,
      eventGroup: 4,
      searchByOutput: true,
      residential: true,
      qualifier: {
        0: {
          text: "Salida apagada \n ${zonaUsuario}",
          icon: 11,
          color: 0,
        },
        1: {
          text: "Salida encendida \n ${zonaUsuario}",
          icon: 11,
          color: 11,
        },
      },
    },
    94: {
      key: "evento_llego_ok",
      code: 94,
      eventGroup: 6,
      residential: true,
      // cid: 100,
      searchByUser: true,
      qualifier: {
        1: {
          text: "${zonaUsuario} Llegó ok.",
          icon: 6,
          color: 7,
        },
      },
    },
    95: {
      key: "evento_esta_llegando",
      code: 95,
      eventGroup: 6,
      residential: true,
      // cid: 100,
      searchByUser: true,
      qualifier: {
        1: {
          text: "${zonaUsuario} Está llegando",
          icon: 6,
          color: 3,
        },
      },
    },
    96: {
      key: "evento_jamming",
      code: 96,
      eventGroup: 5,
      residential: true,
      qualifier: {
        1: {
          text: "Detección de Jamming",
          icon: 6,
          color: 5,
        },
        0: {
          text: "Restauración de Jamming",
          icon: 6,
          color: 5,
        },
      },
    },
    97: {
      key: "fallo_supervision",
      code: 97,
      residential: true,
      cid: 350,
      eventGroup: 4,
      qualifier: {
        0: {
          text: "Restauración de conexión",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Falla de conexión",
          icon: 6,
          color: 5,
        },
      },
    },
    98: {
      key: "life_clock_supervision",
      code: 96,
      eventGroup: 5,
      residential: true,
      qualifier: {
        0: {
          text: "Restauración de supervisión en reloj de vida ${zonaUsuario}",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Falla de supervisión en reloj de vida ${zonaUsuario}",
          icon: 6,
          color: 5,
        },
      },
    },
    99: {
      key: "audible_panic",
      code: 96,
      eventGroup: 1,
      residential: true,
      qualifier: {
        1: {
          text: "Emergencia de pánico",
          icon: 2,
          color: 3,
        },
      },
    },
    100: {
      key: "life_clock_low_battery",
      code: 96,
      eventGroup: 5,
      residential: true,
      qualifier: {
        0: {
          text: "Batería restaurada en reloj de vida",
          icon: 6,
          color: 0,
        },
        1: {
          text: "Batería baja en reloj de vida",
          icon: 6,
          color: 5,
        },
      },
    },
    101: {
      key: "life_clock_medical_emergency",
      code: 96,
      eventGroup: 5,
      residential: true,
      qualifier: {
        0: {
          text: "Restauración de emergencia médica",
          icon: 0,
          color: 0,
        },
        1: {
          text: "Emergencia médica",
          icon: 0,
          color: 1,
        },
      },
    },
    102: {
      key: "life_clock_silent_panic",
      code: 96,
      eventGroup: 5,
      residential: true,
      qualifier: {
        0: {
          text: "Emergencia de pánico silenciosa",
          icon: 2,
          color: 0,
        },
        1: {
          text: "Emergencia de pánico silenciosa",
          icon: 2,
          color: 3,
        },
      },
    },
    400: {
      key: "evento_cancelacion_modulo_ip400",
      code: 400,
      eventGroup: 4,
    },
  };

  function eventDecoder() {
    return {
      getName: function (codedEvent, system) {
        var event = eventTable[codedEvent.code];

        // console.log("CODED EVENT", codedEvent);

        if (!event) {
          return "Evento desconocido " + codedEvent.code;
        }

        if (event.text) {
          if (event.searchByUser && system && system.users) {
            var systemUser =
              codedEvent.zonaUsuario == 80
                ? system.owner
                : system.users.find(function (user) {
                    return user.number + 80 == codedEvent.zonaUsuario;
                  });
            if (systemUser) {
              return es6TemplateStrings(
                event.text[codedEvent.qualifier],
                Object.assign({}, codedEvent, {
                  zonaUsuario:
                    systemUser.nombre + " " + systemUser.apellido ||
                    systemUser.email,
                })
              );
            }
          }
          if (
            event.searchByZone &&
            system &&
            system.programation &&
            system.programation.data &&
            system.programation.data.zones
          ) {
            var systemZone = system.programation.data.zones.find(function (
              zone
            ) {
              return zone.number == codedEvent.zonaUsuario;
            });
            if (systemZone) {
              return es6TemplateStrings(
                event.text[codedEvent.qualifier],
                Object.assign({}, codedEvent, { zonaUsuario: systemZone.name })
              );
            }
          }
          return es6TemplateStrings(
            event.text[codedEvent.qualifier],
            codedEvent
          );
        }

        if (event.zoneUser) {
          var globalText;

          event.zoneUser.forEach(function (text) {
            if (
              !globalText &&
              codedEvent.zonaUsuario.toString().match(new RegExp(text.regex))
            ) {
              globalText = text; //[codedEvent.qualifier];
            }
          });

          if (globalText) {
            if (globalText.searchByUser && system && system.users) {
              var systemUser =
                codedEvent.zonaUsuario == 80
                  ? system.owner
                  : system.users.find(function (user) {
                      return user.number + 80 == codedEvent.zonaUsuario;
                    });
              if (systemUser) {
                return es6TemplateStrings(
                  globalText[codedEvent.qualifier],
                  Object.assign({}, codedEvent, {
                    zonaUsuario:
                      systemUser.nombre + " " + systemUser.apellido ||
                      systemUser.email,
                  })
                );
              }
            }
            if (
              globalText.searchByZone &&
              system &&
              system.programation &&
              system.programation.data &&
              system.programation.data.zones
            ) {
              var systemZone = system.programation.data.zones.find(function (
                zone
              ) {
                return zone.number == codedEvent.zonaUsuario;
              });
              if (systemZone) {
                return es6TemplateStrings(
                  globalText[codedEvent.qualifier],
                  Object.assign({}, codedEvent, {
                    zonaUsuario: systemZone.name,
                  })
                );
              }
            }
            return es6TemplateStrings(
              globalText[codedEvent.qualifier],
              codedEvent
            );
          }

          return es6TemplateStrings(
            event.zoneUser.find(function (text) {
              return text.regex === "^0$";
            })[codedEvent.qualifier],
            codedEvent
          );
        }

        return "Evento desconocido " + code;
      },
      getDecodedEvent: function (codedEvent, system, userData) {
        var decodedEvent = {
          name: "",
          icon: "",
          color: "",
          residential: "",
        };

        var event = eventTable[codedEvent.code];

        // console.log("EVENTO A EVALUAR", event);

        // console.log(codedEvent);

        if (!event) {
          decodedEvent.name = "Evento desconocido " + codedEvent.code;
          decodedEvent.color = "color-0";
          decodedEvent.icon = "icon-6";
          decodedEvent.residential = false;
          return decodedEvent;
        }

        decodedEvent.residential = event.residential;

        if (event.qualifier) {
          if (event.searchByUser && system && system.users) {
            var systemUser =
              codedEvent.zonaUsuario == 80
                ? system.owner
                : system.users.find(function (user) {
                    return user.number + 80 == codedEvent.zonaUsuario;
                  });
            if (systemUser) {
              // console.log("SYSTEM USER", systemUser);
              if (systemUser.nombre) {
                systemUser.nombre = systemUser.nombre + " ";
              }
              decodedEvent.name = es6TemplateStrings(
                event.qualifier[codedEvent.qualifier].text,
                Object.assign({}, codedEvent, {
                  zonaUsuario:
                    systemUser.nombre + systemUser.apellido || "Dueño",
                })
              );
              decodedEvent.color =
                "color-" + event.qualifier[codedEvent.qualifier].color;
              decodedEvent.icon =
                "icon-" + event.qualifier[codedEvent.qualifier].icon;
              // console.log("DECODED EVENT NAME", codedEvent.zonaUsuario);
              return decodedEvent;
            }
          }
          if (
            event.searchByZone &&
            system &&
            system.programation &&
            system.programation.data &&
            system.programation.data.zones
          ) {
            var systemZone = system.programation.data.zones.find(function (
              zone
            ) {
              return zone.number == codedEvent.zonaUsuario;
            });
            if (systemZone) {
              // console.log("SYSTEM USER", systemUser);
              decodedEvent.name = es6TemplateStrings(
                event.qualifier[codedEvent.qualifier].text,
                Object.assign({}, codedEvent, {
                  zonaUsuario: systemZone.name || "Zona " + systemZone.number,
                })
              );
              decodedEvent.color =
                "color-" + event.qualifier[codedEvent.qualifier].color;
              decodedEvent.icon = "icon-zone-" + systemZone.icon;
              return decodedEvent;
            }
          }
          if (
            event.searchByOutput &&
            system &&
            system.programation &&
            system.programation.data &&
            system.programation.data.outputs
          ) {
            var systemOutput = system.programation.data.outputs.find(function (
              output
            ) {
              return output.number == codedEvent.zonaUsuario;
            });
            // console.log("SYSTEM OUTPUT", systemOutput);
            if (systemOutput) {
              if (systemOutput.number > 4) {
                systemOutput.nameToShow =
                  "PGM inalámbrica " + (systemOutput.number - 8);
              } else {
                systemOutput.nameToShow = "PGM cableada " + systemOutput.number;
              }
              decodedEvent.name = es6TemplateStrings(
                event.qualifier[codedEvent.qualifier].text,
                Object.assign({}, codedEvent, {
                  zonaUsuario: systemOutput.name || systemOutput.nameToShow,
                })
              );
              decodedEvent.color =
                "color-" + event.qualifier[codedEvent.qualifier].color;
              decodedEvent.icon = "icon-output-" + systemOutput.icon;
              return decodedEvent;
            }
          }
          decodedEvent.name = es6TemplateStrings(
            event.qualifier[codedEvent.qualifier].text,
            codedEvent
          );
          decodedEvent.color =
            "color-" + event.qualifier[codedEvent.qualifier].color;
          decodedEvent.icon =
            "icon-" + event.qualifier[codedEvent.qualifier].icon;
          return decodedEvent;
        }

        if (event.zoneUser) {
          var globalEvent;
          // console.log("llego aca con los eventos de armado por usuario 1,2,3,4 del teclado");
          event.zoneUser.forEach(function (kindOfEvent) {
            if (
              !globalEvent &&
              codedEvent.zonaUsuario
                .toString()
                .match(new RegExp(kindOfEvent.regex))
            ) {
              // console.log("Zonausuario ", codedEvent.zonaUsuario, " regex que cumplió ", kindOfEvent);
              globalEvent = kindOfEvent; //[codedEvent.qualifier];
            }
          });

          if (globalEvent) {
            if (globalEvent.searchByUser && system && system.users) {
              var systemUser =
                codedEvent.zonaUsuario == 80
                  ? system.owner
                  : system.users.find(function (user) {
                      return user.number + 80 == codedEvent.zonaUsuario;
                    });
              if (systemUser) {
                // console.log("SYSTEM USER", systemUser);
                if (systemUser.nombre) {
                  systemUser.nombre = systemUser.nombre + " ";
                }
                decodedEvent.name = es6TemplateStrings(
                  globalEvent.qualifier[codedEvent.qualifier].text,
                  Object.assign({}, codedEvent, {
                    zonaUsuario:
                      systemUser.nombre + systemUser.apellido || "Dueño",
                  })
                );
                decodedEvent.color =
                  "color-" + globalEvent.qualifier[codedEvent.qualifier].color;
                decodedEvent.icon =
                  "icon-" + globalEvent.qualifier[codedEvent.qualifier].icon;
                return decodedEvent;
              }
            }
            if (
              globalEvent.searchByZone &&
              system &&
              system.programation &&
              system.programation.data &&
              system.programation.data.zones
            ) {
              var systemZone = system.programation.data.zones.find(function (
                zone
              ) {
                return zone.number == codedEvent.zonaUsuario;
              });
              if (systemZone) {
                decodedEvent.name = es6TemplateStrings(
                  globalEvent.qualifier[codedEvent.qualifier].text,
                  Object.assign({}, codedEvent, {
                    zonaUsuario: systemZone.name || "Zona " + systemZone.number,
                  })
                );
                decodedEvent.color =
                  "color-" + globalEvent.qualifier[codedEvent.qualifier].color;
                decodedEvent.icon = "icon-zone-" + systemZone.icon;
                return decodedEvent;
              }
            }
            if (
              event.searchByOutput &&
              system &&
              system.programation &&
              system.programation.data &&
              system.programation.data.outputs
            ) {
              var systemOutput = system.programation.data.outputs.find(
                function (output) {
                  return output.number == codedEvent.zonaUsuario;
                }
              );
              // console.log("SYSTEM OUTPUT", systemOutput);
              if (systemOutput) {
                // console.log("BUSCA POR OUTPUT", systemOutput.number)
                if (systemOutput.number > 4) {
                  systemOutput.nameToShow =
                    "PGM inalámbrica " + (systemOutput.number - 8);
                } else {
                  systemOutput.nameToShow =
                    "PGM cableada " + systemOutput.number;
                }
                decodedEvent.name = es6TemplateStrings(
                  globalEvent.qualifier[codedEvent.qualifier].text,
                  Object.assign({}, codedEvent, {
                    zonaUsuario: systemOutput.name || systemOutput.nameToShow,
                  })
                );
                decodedEvent.color =
                  "color-" + globalEvent.qualifier[codedEvent.qualifier].color;
                decodedEvent.icon = "icon-output-" + systemOutput.icon;
                return decodedEvent;
              }
            }

            decodedEvent.name = es6TemplateStrings(
              globalEvent.qualifier[codedEvent.qualifier].text,
              codedEvent
            );
            decodedEvent.color =
              "color-" + globalEvent.qualifier[codedEvent.qualifier].color;
            decodedEvent.icon =
              "icon-" + globalEvent.qualifier[codedEvent.qualifier].icon;
            return decodedEvent;
          }

          var finalEvent = event.zoneUser.find(function (mEvent) {
            return mEvent.regex === "^0$";
          });

          decodedEvent.name = es6TemplateStrings(
            finalEvent.qualifier[codedEvent.qualifier].text,
            codedEvent
          );
          decodedEvent.color =
            "color-" + finalEvent.qualifier[codedEvent.qualifier].color;
          decodedEvent.icon =
            "icon-" + finalEvent.qualifier[codedEvent.qualifier].icon;
          return decodedEvent;
        }

        decodedEvent.name = "Evento desconocido " + codedEvent.code;
        decodedEvent.color = "color-0";
        decodedEvent.icon = "icon-6";
        return decodedEvent;
      },
    };
  }

  angular.module("app").factory("eventDecoder", eventDecoder);
})();
