(function(angular) {
  function YmActionBarController($scope, $element, user, userDataService, ajaxInProgress) {
    var ctrl = this;

    ctrl.$onInit = function() {};

    if(userDataService.getAppIsCustomized()){
      ctrl.attributes = user.getCustomizationAttributes();
    }

    ctrl.$onChanges = function(changesObj) {};

    ctrl.openMenu = function($mdMenu) {
      $mdMenu.open();
    };
  }

  angular.module("app").component("ymActionBar", {
    templateUrl: "components/YmActionBar/YmActionBar.html",
    controller: YmActionBarController,
    bindings: {
      title: "<",
      leftButton: "<",
      rightButtons: "<"
    }
  });
})(window.angular);

/*

leftButton : {
  onClickHandler: Function,
  disabled: Function,
  aria: String,
  icon: String
}

title: String

rightButtons : [{
  onClickHanlder: Function,
  aria: String,
  label: String,
  icon: String
}]


*/
