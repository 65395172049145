(function(window) {
  angular
    // .module("app", ["ui.router", "ngMaterial", "ngMessages", "credit-cards", "vcRecaptcha"]) //, "credit-cards", "vc-recaptcha"
    .module("app", ["ui.router", "ngMaterial", "ngMessages"]) //, "credit-cards", "vc-recaptcha"
    // .config(function() {
    //   console.log("WEB");
    //   if ("WEB" === "MOBILE") {
    //     try {
    //       //we replace default localStorage with our Android Database one
    //       window.localStorage = window.LocalStorage;
    //     } catch (e) {
    //       //LocalStorage class was not found. be sure to add it to the webview
    //       return console.log("LocalStorage ERROR : can't find android class LocalStorage. switching to raw localStorage");
    //     }
    //   }
    // })
    .config(function($mdThemingProvider /*, $mdIconProvider*/) {
      $mdThemingProvider
        .theme("default")
        .primaryPalette("deep-purple")
        .accentPalette("orange");
    })
    .config(function($mdThemingProvider) {
      var greenTheme = $mdThemingProvider.theme("greenTheme", "default");
      var greenPalette = $mdThemingProvider.extendPalette("light-green", {
        "500": "#6aa043"
      });
      $mdThemingProvider.definePalette("greenPalette", greenPalette);
      greenTheme.primaryPalette("greenPalette");
    })
    .constant("APP_VERSION", "1.0.2")
    .constant("BASE_API", "/users_api/v1") // "/users_api/v1"
    .constant("BUILD_MODE", "WEB") // WEB || MOBILE
    .constant("BUILD_PLATFORM", ",") // undefined || Android || iOS
    .constant("BUILD_PRODUCT", "garnet_control") // yo_monitoreo || garnet_control || titanium_control
    .constant("SYSTEMS_API", "/systems")
    .constant("PAYMENTS_API", "/payments")
    .constant("EVENTS_ADDRESS_MIN", parseInt("7000", 16))
    .constant("EVENTS_ADDRESS_MAX", parseInt("7FFF", 16))
    .constant("EVENTS_ADDRESSES_SIZE", 4096)
    .filter("yesNo", function() {
      return function(boolean) {
        return boolean ? "Yes" : "No";
      };
    });
})(window);
