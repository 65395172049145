(function() {
  function jkCameraId() {
    // var PASSWORD_REGEXP = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*\.])(?=.{8,20})/; ///^\w{7,20}$/;
    var CAMERA_ID_REGEXP = /^[\dA-Z]{9}$/;

    return {
      require: "ngModel",
      link: function(scope, elm, attrs, ctrl) {
        ctrl.$validators["jk-camera-id"] = function(modelValue, viewValue) {
          return CAMERA_ID_REGEXP.test(viewValue);
        };
      }
    };
  }

  function jkCameraValidateCode() {
    var CAMERA_VALIDATE_CODE_REGEXP = /^[\dA-Z]{6}$/;

    return {
      require: "ngModel",
      link: function(scope, elm, attrs, ctrl) {
        ctrl.$validators["jk-camera-validate-code"] = function(modelValue, viewValue) {
          return CAMERA_VALIDATE_CODE_REGEXP.test(viewValue);
        };
      }
    };
  }

  angular
    .module("app")
    .directive("jkCameraId", jkCameraId)
    .directive("jkCameraValidateCode", jkCameraValidateCode);
})();
