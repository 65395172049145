(function() {
  function customFormat() {
    return {
      format: function(cZeros, cadena) {
        var cZerosStr = "";

        for (var i = 0; i < cZeros; i++) {
          cZerosStr += "0";
        }

        return (cZerosStr + cadena).slice(-cZeros);
      }
    };
  }

  angular.module("app").factory("customFormat", customFormat);
})();
