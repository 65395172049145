(function() {
  var PLAN_TYPES = {
    MONTHLY: 1,
    ANNUAL: 2,
    FREE_SUB: 3,
    SUPER_USER: 4
  };

  angular.module("app").constant("planTypes", PLAN_TYPES);
})();
