(function() {
  function jkWifiSsid() {
    return {
      require: "ngModel",
      link: function(scope, elm, attrs, ctrl) {
        ctrl.$validators["jk-wifi-ssid"] = function(modelValue, viewValue) {
          return viewValue && viewValue.length > 0;
        };
      }
    };
  }

  function jkWifiPassword() {
    return {
      require: "ngModel",
      link: function(scope, elm, attrs, ctrl) {
        ctrl.$validators["jk-wifi-password"] = function(modelValue, viewValue) {
          return viewValue && viewValue.length > 3;
        };
      }
    };
  }

  angular
    .module("app")
    .directive("jkWifiSsid", jkWifiSsid)
    .directive("jkWifiPassword", jkWifiPassword);
})();
