(function() {
  function SystemBellController($scope, $state, buildModes, $window, system, statusCacheService, ajaxInProgress, ymSidenavService, BUILD_MODE, $timeout, editMode, alertService, seqService, actionBarService, $stateParams) {
    console.log("SystemBellController created");

    $scope.$on("$destroy", function() {
      console.log("SystemBellController destroyed");
    });

    var self = this;

    self.$scope = $scope;

    editMode.state.value = false;

    if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
			ymSidenavService.closeYmSidenav();
		  }

    self.editModeValue = function() {
      return editMode.state.value;
    };

    self.getLastEventReport = function() {
      var ajax = system.getLastEventReport(self.sistemaElegido);
      ajax.then(handleLastTimeReport, handleLastTimeReport);
    };

    function handleLastTimeReport(res) {
      if (res.data && res.data.success) {
        self.sistemaElegido.lastEventReport = res.data.message;

        if (self.sistemaElegido.lastEventReport > self.sistemaElegido.status.lastTimeUpdate.getTime()) {
          self.consultarEstado();
        }
      }
    }

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value == "consultandoEstado") {
          ajaxInProgress.setState({
            value: false
          });
        } else if (ajaxInProgress.state.value == "encendiendoBell") {
          alertService.showToast(res.data.message.response);
          ajaxInProgress.setState({
            value: false
          });
        } else if (ajaxInProgress.state.value == "apagandoBell") {
          alertService.showToast(res.data.message.response);
          ajaxInProgress.setState({
            value: false
          });
        }
        if (res.data.message.status) {
          self.sistemaElegido.status = statusCacheService.storeStatus(self.sistemaElegido, res.data.message.status);
        }
      } else {
        if (res.status == -1) {
          ajaxInProgress.setState({
            value: false
          });
          $timeout(function() {
            alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
          });
          return;
        }
        if (res.status == -2) {
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
        ajaxInProgress.setState({
          value: false
        });
        $timeout(function() {
          alertService.showAlert("Atención", res.data.message, "OK");
        });
      }
    }

    self.loguearEstado = function() {
      // console.log(self.sistemaElegido);
      // console.log(self.$scope.mainController.sistemas);
      // console.log(self.$scope.mainController.sistemasCompartidos);
    };

    self.consultarEstado = function() {
      var ajax = system.consultarEstado(self.sistemaElegido, seqService.seq());
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "consultandoEstado",
        label: "Consultando Estado",
        ajax: ajax
      });
    };

    self.encenderBell = function(numero) {
      var ajax = system.encenderBell(self.sistemaElegido, seqService.seq());
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "encendiendoBell",
        label: "Encendiendo Sirena",
        ajax: ajax
      });
    };

    self.apagarBell = function(numero) {
      var ajax = system.apagarBell(self.sistemaElegido, seqService.seq());
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "apagandoBell",
        label: "Apagando Sirena",
        ajax: ajax
      });
    };

    // Tareas para cuando el controller se instancia

    self.editMode = editMode;

    system.getSystem($stateParams.idSistemaElegido).then(function(sistemaElegido) {
      self.sistemaElegido = sistemaElegido;
      // $rootScope.focusingTab("/system/bell");
      //Seteo el timeout de los comandos en función de lo que devuelva
      system.setCommandTimeout(sistemaElegido.id);

      if (self.sistemaElegido.estadoDeCarga === "verificado") {
        actionBarService.setActionBarActions("SystemBellController", [
          {
            label: "Actualizar",
            aria: "Actualizar",
            icon: "refresh",
            onClickHandler: function() {
              self.consultarEstado();
            }
          }
        ]);

        self.sistemaElegido.status = self.sistemaElegido.status || statusCacheService.getStatus(self.sistemaElegido);

        // self.getLastUpdate();

        // if (!self.sistemaElegido.status && !ajaxInProgress.state.value) {
        //   self.consultarEstado();
        // } else {
        //   self.getLastEventReport();
        // }

      }
    });
  }

  angular.module("app").controller("SystemBellController", SystemBellController);
})();
