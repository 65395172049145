(function(angular) {
  function CamaraLinkController($scope, $rootScope, $element, $timeout, ajaxInProgress, BUILD_MODE, BUILD_PLATFORM, system) {
    var ctrl = this;

    ctrl.ajaxInProgress = ajaxInProgress;

    ctrl.editCamMode = false;
    ctrl.editedNombre = "";

    ctrl.$onInit = function() {
      ctrl.BUILD_MODE = BUILD_MODE;

      ctrl.isWeb = BUILD_MODE == "WEB"

      if(BUILD_PLATFORM === "iOS"){
        ctrl.isiOS = true;
      } 

      var sistemaElegido = ctrl.sistema;

      if (sistemaElegido.owner && typeof sistemaElegido.owner.userType == "number" && sistemaElegido.owner.userType == 0) {
        ctrl.puedeBorrar = true;
      } else {
        ctrl.puedeBorrar = false;
      }
    };


    ctrl.eliminarCamara = function() {
      ctrl.onEliminarCamara(ctrl.camera.appName);
    };

    ctrl.editarCamara = function() {
      ctrl.onEditarCamara();
    }
  }

  angular.module("app").component("camaralink", {
    templateUrl: "components/CamaraLink/camaralink.html",
    controller: CamaraLinkController,
    bindings: {
      camera: "<",
      onEliminarCamara: "=",
      onEditarCamara: "=",
      onVerLive: "=",
      sistema: "<"
    }
  });
})(window.angular);
