(function() {
  function SubscriptionPlansController($scope, system, $state, ajaxInProgress, editMode, payments, $timeout, alertService) {
    console.log("SubscriptionPlansController created");

    $scope.$on("$destroy", function() {
      console.log("SubscriptionPlansController destroyed");
    });

    var self = this;

    self.sistemaElegido = system.getSistemaElegido();

    self.availablePlans = [];

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value === "getPlans") {
          self.availablePlans = res.data.message.plans;
          ajaxInProgress.setState({
            value: false
          });
          self.plan = self.availablePlans.find(function(plan) {
            return plan.id === self.sistemaElegido.subscriptionInfo.planId;
          });
        }
      } else {
        if (res.status == -1) {
					alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
      }
    }

    self.getPlans = function() {
      $timeout(function() {
        ajaxInProgress.setState({
          value: "getPlans",
          label: "Obteniendo planes"
        });
        payments.getPlans().then(handleRequest, handleRequest);
      }, 1);
    };

    self.getPlans();

    self.onPlanSelect = function(selectedPlan) {
      payments.setPlanElegido(selectedPlan);
      $state.go("subscription.payment");
    };
  }
  angular.module("app").controller("SubscriptionPlansController", SubscriptionPlansController);
})();
