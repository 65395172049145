(function() {
	function SystemUsersController($scope, system,buildModes,$window, ymSidenavService, $mdSidenav, ajaxInProgress, BUILD_MODE, alertService, actionBarService, $stateParams, BUILD_PRODUCT, buildProducts, editMode) {
		console.log("SystemUsersController created");

		$scope.$on("$destroy", function() {
			console.log("SystemUsersController destroyed");
		});

		editMode.state.value = false;

		// LO QUE ES
		// sharedPartitions[partition.number]
		// sharedPartitionsToEvents[partition.number]
		// puedeVerCamaras	

		var self = this;

		self.$scope = $scope;

		if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
			ymSidenavService.closeYmSidenav();
		}

		  self.isMobile = BUILD_MODE == "MOBILE";
		  self.isWeb = BUILD_MODE == "WEB";
		  
		self.viewInfo;

		if (BUILD_PRODUCT === buildProducts.TITANIUM_CONTROL) {
			self.isTitanium = true
		}

		self.changeViewInfo = function(){
		  self.viewInfo = !self.viewInfo
		};

		self.estado = "mostrandoLista";

		self.selectTab = function(newTab) {
			if (self.selectedTab != newTab) {
				self.selectedTab = newTab;
			}
		};

		self.avatarSrc = function() {
			return "img/assets/icon-avatar/icon-avatar-" + self.usuarioElegido.avatar + "-circle.svg";
		};

		self.initAvatarSrc = function() {
			self.sistemaElegido.users.forEach(function(user) {
				user.avatarSrc = "img/assets/icon-avatar/icon-avatar-" + user.avatar + "-circle.svg";
			});
		};

		self.puedeVerCamaras = false;
		self.puedeArmar = false;
		self.puedeDesarmar = false;
		self.puedeInhibirZonas = false;
		self.puedeInteractuarConSalidas = false;
		self.puedeInteractuarConSirena = false;

		self.sharedPartitions = { 0: false, 1: false, 2: false, 3: false, 4: false };
		self.sharedPartitionsToEvents = { 0: false, 1: false, 2: false, 3: false, 4: false };

		self.puedeControlar = false;
		self.puedeRecibirEventos = false;
		self.puedeGuardarPorControl = false;
		self.puedeGuardarPorEventos = false;

		self.typeOfUserToAdd;

		function handleRequest(res) {
			if (res.data && res.data.success) {
				if (ajaxInProgress.state.value == "addSystemUser") {
					self.typeOfUserToAdd = undefined;
					if (res.data.message.users !== undefined) {
						self.sistemaElegido.users = res.data.message.users;
					}
					alertService.showToast("Usuario Agregado");
					self.procesarUsuarioDeRespuesta(res.data.message.user);
					self.initAvatarSrc();
					self.estado = "mostrandoLista";
					ajaxInProgress.setState({
						value: false
					});
					// $mdSidenav("left-sidenav").close();
				} else if (ajaxInProgress.state.value == "seteandoAtributosUsuario") {
					if (res.data.message.users !== undefined) {
						self.sistemaElegido.users = res.data.message.users;
					}
					alertService.showToast("Atributos guardados");
					self.usuarioElegido = undefined;
					self.initAvatarSrc();
					self.estado = "mostrandoLista";
					ajaxInProgress.setState({
						value: false
					});
					// $mdSidenav("left-sidenav").close();
				} else if (ajaxInProgress.state.value == "removeSystemUser") {
					if (res.data.message.users !== undefined) {
						self.sistemaElegido.users = res.data.message.users;
					}
					self.usuarioElegido = undefined;
					self.estado = "mostrandoLista";
					alertService.showToast("Usuario eliminado");
					ajaxInProgress.setState({
						value: false
					});
					// $mdSidenav("left-sidenav").close();
				}
				self.$scope.mainController.message = res.data.message;
			} else {
				if (res.status == -1) {
					alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
					ajaxInProgress.setState({
						value: false
					});
					return;
				}
				alertService.showAlert("Atención", res.data.message, "OK");
				ajaxInProgress.setState({
					value: false
				});
			}
		}

		self.askForCameraAccess = function() {
			self.estado = "editandoUsuario";
		};

		// self.pedirConfirmacionVerCamaras = function() {
		//   if (self.puedeVerCamaras) {
		//     return alertService
		//       .showConfirmAlert(
		//         "Acceso a cámaras",
		//         "Estás seguro de que este usuario vea las cámaras de tu sistema?",
		//         "Sí, estoy seguro!",
		//         "NO"
		//       )
		//       .then(function() {
		//         self.setEmailUsuarioSistema();
		//       });
		//   }

		//   self.setEmailUsuarioSistema();
		// };

		// self.getTextoConfirmacionCamaras = function() {
		//   if (self.puedeVerCamaras) {
		//     return "Estoy seguro que el nuevo usuario vea mis cámaras y quiero continuar!";
		//   } else {
		//     return "Continuar";
		//   }
		// };

		self.agregarNuevoUsuario = function(type) {
			self.usuarioElegido = undefined;
			self.estado = "agregandoNuevoUsuario";
			self.typeOfUserToAdd = type;
		};

		self.setEmailUsuarioSistema = function() {
			if (!ajaxInProgress.state.value) {
				ajaxInProgress.setState({
					value: "addSystemUser"
				});

				self.atributos = {}
				self.eventos = {}

				self.atributos.puedeArmar = false;
				self.atributos.puedeDesarmar = false;
				self.atributos.puedeInhibirZonas = false;
				self.atributos.puedeInteractuarConSalidas = false;
				self.atributos.puedeInteractuarConSirena = false;

				self.eventos.recibeAlarmas = false;
				self.eventos.recibeAperturasYCierres = false;
				self.eventos.recibeEventosDeEnergia = false;
				self.eventos.recibeEventosDePanico = false;
				self.eventos.recibeOtrosEventos = false;

				if (self.typeOfUserToAdd == 1) {
					// Si es un familiar
					for (var i = 1; i < 5; i++) {
						if (self.sharedPartitions[i]) {
							self.atributos.puedeArmar = true;
							self.atributos.puedeDesarmar = true;
							self.atributos.puedeInhibirZonas = true;
							self.atributos.puedeInteractuarConSalidas = true;
							self.atributos.puedeInteractuarConSirena = true;
							break;
						}
					}
					// Siempre le doy partición 0 al control.
					self.sharedPartitions[0] = true;

					for (var i = 1; i < 5; i++) {
						if (self.sharedPartitionsToEvents[i]) {
							self.eventos.recibeOtrosEventos = true;
							self.eventos.recibeEventosDePanico = true;
							self.eventos.recibeEventosDeEnergia = true;
							self.eventos.recibeAperturasYCierres = true;
							self.eventos.recibeAlarmas = true;
							break;
						}
					}
					self.sharedPartitionsToEvents[0] = true;
					//Como es Yo-Monitoreo Siempre le doy partición 0 para que reciba los eventos de la misma.
				}

				if (self.typeOfUserToAdd == 2) {
					// Si es un vecino
					// Por ahora fuerzo sharedPartitions[1] en true para que reciba eventos siempre
					for (var i = 1; i < 5; i++) {
						if (self.sharedPartitions[i]) {
							self.atributos.puedeArmar = false;
							self.atributos.puedeDesarmar = false;
							self.atributos.puedeInhibirZonas = false;
							self.atributos.puedeInteractuarConSalidas = false;
							self.atributos.puedeInteractuarConSirena = false;
							break;
						}
					}
					self.sharedPartitions[0] = true;

					for (var i = 1; i < 5; i++) {
						if (self.sharedPartitionsToEvents[i]) {
							self.eventos.recibeOtrosEventos = false;
							self.eventos.recibeEventosDePanico = true;
							self.eventos.recibeEventosDeEnergia = true;
							self.eventos.recibeAperturasYCierres = false;
							self.eventos.recibeAlarmas = true;
							break;
						}
					}
					self.sharedPartitionsToEvents[0] = true;
					//Como es Yo-Monitoreo Siempre le doy partición 0 para que reciba los eventos de la misma.
				}

				if (self.puedeControlar) {
					self.atributos.sharedPartitions = self.sharedPartitions;
				} else {
					self.atributos.sharedPartitions = { 0: true, 1: false, 2: false, 3: false, 4: false };
					self.atributos.puedeArmar = false;
					self.atributos.puedeDesarmar = false;
					self.atributos.puedeInhibirZonas = false;
					self.atributos.puedeInteractuarConSalidas = false;
					self.atributos.puedeInteractuarConSirena = false;
				}
				if (self.puedeRecibirEventos) {
					self.eventos.sharedPartitions = self.sharedPartitionsToEvents;
				} else {
					self.eventos.sharedPartitions = { 0: false, 1: false, 2: false, 3: false, 4: false };
					self.eventos.recibeAlarmas = false;
					self.eventos.recibeAperturasYCierres = false;
					self.eventos.recibeEventosDeEnergia = false;
					self.eventos.recibeEventosDePanico = false;
					self.eventos.recibeOtrosEventos = false;
				}

				self.atributos.puedeVerCamaras = self.puedeVerCamaras;

				system
					.addSystemUser(self.sistemaElegido, {
						email: self.usuarioElegido.email,
						type: self.typeOfUserToAdd
					},
					self.atributos,
					self.eventos
					)
					.then(handleRequest, handleRequest);
			}

			//TODO: volver los switchs a la normalidad.
			self.sharedPartitions = { 0: false, 1: false, 2: false, 3: false, 4: false };
			self.sharedPartitionsToEvents = { 0: false, 1: false, 2: false, 3: false, 4: false };
			self.puedeVerCamaras = false;
			self.puedeRecibirEventos = false;
			self.puedeControlar = false;
		};

		self.setUsuarioElegido = function(usuario) {
			self.usuarioElegido = usuario;
			self.estado = "editandoUsuario";
		};
		
		self.puedeGuardarCambios = true;

		self.enviarAtributosUsuario = function(type) {
			self.typeOfUserToAdd = type;

			if (!ajaxInProgress.state.value) {
				ajaxInProgress.setState({
					value: "seteandoAtributosUsuario"
				});

				self.usuarioElegido.atributos.puedeArmar = false;
				self.usuarioElegido.atributos.puedeDesarmar = false;
				self.usuarioElegido.atributos.puedeInhibirZonas = false;
				self.usuarioElegido.atributos.puedeInteractuarConSalidas = false;
				self.usuarioElegido.atributos.puedeInteractuarConSirena = false;

				self.usuarioElegido.eventos.recibeAlarmas = false;
				self.usuarioElegido.eventos.recibeAperturasYCierres = false;
				self.usuarioElegido.eventos.recibeEventosDeEnergia = false;
				self.usuarioElegido.eventos.recibeEventosDePanico = false;
				self.usuarioElegido.eventos.recibeOtrosEventos = false;

				if (self.typeOfUserToAdd == 1) {
					// Si es un familiar
					for (var i = 1; i < 5; i++) {
						if (self.sharedPartitions[i]) {
							self.usuarioElegido.atributos.puedeArmar = true;
							self.usuarioElegido.atributos.puedeDesarmar = true;
							self.usuarioElegido.atributos.puedeInhibirZonas = true;
							self.usuarioElegido.atributos.puedeInteractuarConSalidas = true;
							self.usuarioElegido.atributos.puedeInteractuarConSirena = true;
							break;
						}
					}
					// Siempre le doy partición 0 al control.
					self.sharedPartitions[0] = true;

					for (var i = 1; i < 5; i++) {
						if (self.sharedPartitionsToEvents[i]) {
							self.usuarioElegido.eventos.recibeOtrosEventos = true;
							self.usuarioElegido.eventos.recibeEventosDePanico = true;
							self.usuarioElegido.eventos.recibeEventosDeEnergia = true;
							self.usuarioElegido.eventos.recibeAperturasYCierres = true;
							self.usuarioElegido.eventos.recibeAlarmas = true;
							break;
						}
					}
					self.sharedPartitionsToEvents[0] = true;
					//Como es Yo-Monitoreo Siempre le doy partición 0 para que reciba los eventos de la misma.
				}

				if (self.typeOfUserToAdd == 2) {
					// Si es un vecino
					// Por ahora fuerzo sharedPartitions[1] en true para que reciba eventos siempre
					for (var i = 1; i < 5; i++) {
						if (self.sharedPartitions[i]) {
							self.usuarioElegido.atributos.puedeArmar = false;
							self.usuarioElegido.atributos.puedeDesarmar = false;
							self.usuarioElegido.atributos.puedeInhibirZonas = false;
							self.usuarioElegido.atributos.puedeInteractuarConSalidas = false;
							self.usuarioElegido.atributos.puedeInteractuarConSirena = false;
							break;
						}
					}
					self.sharedPartitions[0] = true;

					for (var i = 1; i < 5; i++) {
						if (self.sharedPartitionsToEvents[i]) {
							self.usuarioElegido.eventos.recibeOtrosEventos = true;
							self.usuarioElegido.eventos.recibeEventosDePanico = true;
							self.usuarioElegido.eventos.recibeEventosDeEnergia = true;
							self.usuarioElegido.eventos.recibeAperturasYCierres = false;
							self.usuarioElegido.eventos.recibeAlarmas = true;
							break;
						}
					}
					self.sharedPartitionsToEvents[0] = true;
					//Como es Yo-Monitoreo Siempre le doy partición 0 para que reciba los eventos de la misma.
				}

				if (self.puedeControlar) {
					self.usuarioElegido.atributos.sharedPartitions = self.sharedPartitions;
				} else {
					self.usuarioElegido.atributos.sharedPartitions = { 0: true, 1: false, 2: false, 3: false, 4: false };
					self.usuarioElegido.atributos.puedeArmar = false;
					self.usuarioElegido.atributos.puedeDesarmar = false;
					self.usuarioElegido.atributos.puedeInhibirZonas = false;
					self.usuarioElegido.atributos.puedeInteractuarConSalidas = false;
					self.usuarioElegido.atributos.puedeInteractuarConSirena = false;
				}
				if (self.puedeRecibirEventos) {
					self.usuarioElegido.eventos.sharedPartitions = self.sharedPartitionsToEvents;
				} else {
					self.usuarioElegido.eventos.sharedPartitions = { 0: false, 1: false, 2: false, 3: false, 4: false };
					self.usuarioElegido.eventos.recibeAlarmas = false;
					self.usuarioElegido.eventos.recibeAperturasYCierres = false;
					self.usuarioElegido.eventos.recibeEventosDeEnergia = false;
					self.usuarioElegido.eventos.recibeEventosDePanico = false;
					self.usuarioElegido.eventos.recibeOtrosEventos = false;
				}

				self.usuarioElegido.atributos.puedeVerCamaras = self.puedeVerCamaras;

				// self.puedeGuardarCambios = false;

				// // evaluo si tiene algun permiso de ver eventos para guardar.
				// for(var k = 0; k < 4; k++){
				// 	if(self.usuarioElegido.eventos.sharedPartitions[k]){
				// 		self.puedeGuardarCambios = true;
				// 	}
				// }
				// // evaluo si tiene algun permiso de control para guardar.
				// for(var j = 0; j < 4; j++){
				// 	if(self.usuarioElegido.atributos.sharedPartitions[j] && j != 0){
				// 		self.puedeGuardarCambios = true;
				// 	}
				// }
				// // evaluo si tiene algun permiso de camara para guardar.
				// if(self.puedeVerCamaras){
				// 	self.puedeGuardarCambios = true;
				// }

				// if(!self.puedeGuardarCambios) {
				// 	alertService.showToast("Atención estás intentando invitar a alguien sin permisos");
				// 	return self.enviarAtributosUsuario(self.typeOfUserToAdd);
				// }
					
				system.setSystemUserAtributos(self.sistemaElegido, self.usuarioElegido).then(handleRequest, handleRequest);
				
			}
		};

		self.cerrarEditandoUsuario = function() {
			self.usuarioElegido = undefined;
			self.estado = "mostrandoLista";
		};

		self.removerUsuario = function(user) {
			alertService
				.showConfirmAlert("Eliminar usuario", "¿Estás seguro de eliminar a " + user.email + " de tu sistema?", "¡Sí, estoy seguro!", "NO")
				.then(function() {
					ajaxInProgress.setState({
						value: "removeSystemUser"
					});
					system.removeSystemUser(self.sistemaElegido, user.email).then(handleRequest, handleRequest);
				})
				.catch(function(error) {
					// console.log("SystemUsersController::removerUsuario(error)", error);
				});
		};

		self.editarUsuario = function(user) {
			
			self.usuarioElegido = user;
			self.estado = "editandoUsuario";
			self.puedeVerCamaras = user.atributos.puedeVerCamaras;
			self.puedeArmar = user.atributos.puedeArmar;
			self.puedeDesarmar = user.atributos.puedeDesarmar;
			self.puedeInhibirZonas = user.atributos.puedeInhibirZonas;
			self.puedeInteractuarConSalidas = user.atributos.puedeInteractuarConSalidas;
			self.puedeInteractuarConSirena = user.atributos.puedeInteractuarConSirena;
			self.puedeControlar = user.atributos.puedeArmar;
			self.puedeRecibirEventos = user.eventos.recibeOtrosEventos;
			if (self.puedeControlar) {
				self.sharedPartitions = user.atributos.sharedPartitions;
			} else {
				self.sharedPartitions = { 0: false, 1: false, 2: false, 3: false, 4: false };
			}
			if (self.puedeRecibirEventos) {
				self.sharedPartitionsToEvents = user.eventos.sharedPartitions;
			} else {
				self.sharedPartitionsToEvents = { 0: false, 1: false, 2: false, 3: false, 4: false };
			}

			// self.puedeGuardarCambios = false;

			// 	// evaluo si tiene algun permiso de ver eventos para guardar.
			// 	for(var k = 0; k < 4; k++){
			// 		if(self.sharedPartitionsToEvents[k]){
			// 			self.puedeGuardarCambios = true;
			// 		}
			// 	}
			// 	// evaluo si tiene algun permiso de control para guardar.
			// 	for(var j = 0; j < 4; j++){
			// 		if(self.sharedPartitions[j] && j != 0){
			// 			self.puedeGuardarCambios = true;
			// 		}
			// 	}
		
				// evaluo si tiene algun permiso de camara para guardar.
				if(self.puedeVerCamaras){
					self.puedeGuardarCambios = false;
				}
		};

		self.procesarUsuarioDeRespuesta = function(usuario) {
			self.usuarioElegido = usuario;
			// $rootScope.focusingTab("/system/users");

			// self.estado = "editandoUsuario";
		};

		self.usersOfType = function(type, userList) {
			return userList
				? userList.filter(function(user) {
						return user.userType === type;
				  })
				: [];
		};

		self.evaluarGuardado = function () { 
		  }

	
		actionBarService.setActionBarActions("SystemUsersController", []);

		system.getSystem($stateParams.idSistemaElegido).then(function(sistemaElegido) {
			self.sistemaElegido = sistemaElegido;
			//Seteo el timeout de los comandos en función de lo que devuelva
			system.setCommandTimeout(sistemaElegido.id);
			self.initAvatarSrc();
			self.selectedTab = 1;
			// $rootScope.focusingTab("/system/users");
		});
	}

	angular.module("app").controller("SystemUsersController", SystemUsersController);
})();
