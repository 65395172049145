(function () {
  function RegisterConfirmedController(
    $scope,
    $q,
    ajaxInProgress,
    user,
    userDataService,
    BUILD_MODE,
    BUILD_PRODUCT,
    $state,
    alertService
  ) {
    var self = this;
    self.$scope = $scope;
    self.$state = $state;

    self.appIsCustomized = userDataService.getAppIsCustomized();

    self.phoneLanguage;

    self.registerTexts = {
      spa: {
        registerConfirmedTitle: "Registro exitoso",
        registerRejectedTitle: "Atención",
        registerConfirmed:
          "Has confirmado tu cuenta, ya puedes ingresar desde la app",
        registerRejected:
          "El proceso de registro para esta cuenta no se encuentra activo.",
      },
      eng: {
        registerConfirmedTitle: "Successful registration",
        registerRejectedTitle: "Attention",
        registerConfirmed:
          "You have confirmed your account, you can now enter from the app",
        registerRejected:
          "The registration process for this account is not active.",
      },
      por: {
        registerConfirmedTitle: "Registro bem-sucedido",
        registerRejectedTitle: "Atenção",
        registerConfirmed:
          "Você confirmou sua conta, agora você pode entrar pelo aplicativo",
        registerRejected:
          "O processo de registro para esta conta não está ativo.",
      },
    };

    $q.all([userDataService.getPhoneLenguage()]).then(function (result) {
      if (result[0] == "pt") {
        self.phoneLanguage = "por";
      } else if (result[0] == "en") {
        self.phoneLanguage = "eng";
      } else if (result[0] == "es") {
        self.phoneLanguage = "spa";
      } else {
        self.notProcessedLanguage =
          window.navigator.userLanguage || window.navigator.language;

        if (self.notProcessedLanguage.includes("en")) {
          self.phoneLanguage = "eng";
        } else if (self.notProcessedLanguage.includes("pt")) {
          self.phoneLanguage = "por";
        } else {
          self.phoneLanguage = "spa";
        }
      }
    });

    self.getTexts = function (type) {
      return self.phoneLanguage
        ? self.registerTexts[self.phoneLanguage][type]
        : self.registerTexts["eng"][type];
    };

    if (self.appIsCustomized) {
      self.appbarImageData = user.getAppBarImage();
      self.attributes = user.getCustomizationAttributes();
    }

    if (BUILD_PRODUCT == "titanium_control") {
      self.productName = "Alarm Home";
    }

    if (BUILD_PRODUCT == "garnet_control") {
      self.productName = "Garnet Control";
    }

    if (BUILD_PRODUCT == "yo_monitoreo") {
      self.productName = "Yo Monitoreo";
    }

    self.ajaxInProgressValue = function () {
      return ajaxInProgress.state.value;
    };
  }
  angular
    .module("app")
    .controller("RegisterConfirmedController", RegisterConfirmedController);
})();
