(function() {
  function LoginController(__env, $scope, ajaxInProgress, $location, system, $state, userDataService,$rootScope, BUILD_MODE,user, alertService, deviceService, $q, auth, $timeout, BUILD_PRODUCT, routerService, APP_VERSION) {
    
    var self = this;
    
    if(BUILD_PRODUCT == "titanium_control"){
      self.productName = "Alarm Home";
    }
    if(BUILD_PRODUCT == "garnet_control"){
      self.productName = "Garnet Control";
    }
    if(BUILD_PRODUCT == "yo_monitoreo"){
      self.productName = "Yo Monitoreo";
    }

    self.isMobile = BUILD_MODE == "MOBILE";
    self.isWeb = BUILD_MODE == "WEB";

    self.ajaxInProgressValue = function() {
      return ajaxInProgress.state.value;
    };

    self.loginTexts = {
			spa:{
				actionFail: "No se pudo completar la acción, vuelva a intentarlo.",
				attention: "Atención",
        createAccount: "Crear cuenta",
        login: "Iniciá sesión con tu cuenta",
        createAnAccount: "Crea una",
        accountAsk: "Si todavía no tienes una cuenta de ",
        email: "Email",
        password: "Contraseña",
        loginButton: "Iniciar sesión",
        passwordForgot: "Olvidé mi contraseña",
			},
			eng:{
				actionFail: "The action could not be completed, please try again.",
				attention: "Attention",
        createAccount: "Create Account",
        login: "Sign in with your account",
        createAnAccount: "Create an account",
        accountAsk: "you don't have an account of",
        email: "Email",
        password: "Password",
        loginButton: "Login",
        passwordForgot: "Forgot my password",
			},
			por:{
				actionFail: "Não foi possível concluir a ação. Tente novamente.",
				attention: "Atenção",
        createAccount: "Criar uma conta",
        login: "Faça login com sua conta",
        createAnAccount: "Crie a sua conta aqui",
        accountAsk: "Se você ainda não tem uma conta",
        email: "Email",
        password: "Senha",
        loginButton: "Iniciar sessão",
        passwordForgot: "Esqueci minha senha",
			}
		}

		self.phoneLanguage;

    $q.all([userDataService.getPhoneLenguage()])
		.then(function(result){
			if(result[0] == "pt"){
				self.phoneLanguage  = "por"
			  } else if(result[0] == "en"){
				self.phoneLanguage  = "eng"
			  } else if(result[0] == "es"){
				self.phoneLanguage  = "spa"
			  } else {
				self.notProcessedLanguage = window.navigator.userLanguage || window.navigator.language;

				if(self.notProcessedLanguage.includes("en")){
					self.phoneLanguage =  "eng";
				} else if(self.notProcessedLanguage.includes("pt")){
					self.phoneLanguage =  "por";
				} else {
					self.phoneLanguage =  "spa";
				}
			  }
		});

		self.getTexts = function(type){
			return self.phoneLanguage ? self.loginTexts[self.phoneLanguage][type] : self.loginTexts["eng"][type];
		}
    
    self.deviceData;

    self.login = function() {
      if (!ajaxInProgress.state.value) {
        __env.getDeviceData()
        .then(
          function(deviceData) {
            if (deviceData.id) {
              return self.deviceLogin();
            }
            self.webLogin();
          },
          function(){
            console.log("Local Storage rejection in Login Function");
          }
        )
      }
    };

    self.deviceLogin = function() {
      if (!ajaxInProgress.state.value) {
        ajaxInProgress.setState({
          value: "listingDevices"
        });
        deviceService.list(self.email, self.password).then(handleRequest, handleRequest);
      }
    };

    self.webLogin = function() {
      if (!ajaxInProgress.state.value) {
        // ajaxInProgress.state.value = "login";
        ajaxInProgress.setState({
          value: "login"
        });
        user.login(self.email, self.password).then(handleRequest, handleRequest);
      }
    };

    self.addDevice = function() {
      // if (!ajaxInProgress.state.value) {
      __env.getDeviceData().then(
        function(deviceData) {
          ajaxInProgress.setState({
            value: "addingDevice"
          });
          deviceService.add(self.email, self.password, deviceData).then(handleRequest, handleRequest);
        },
        function() {
          console.log("Local Storage rejection in addDevice Function");
        }
      );
      // }
    };

    self.gotoRegister = function() {
      $state.go("register");
    };

    self.gotoRegisterConfirmed = function() {
      $state.go("register-confirmed", null, { location: "replace" });
    };

    self.gotoRegisterRejected = function() {
      $state.go("register-rejected");
    };

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value == "login") {
          var accessToken = res.data ? res.data.accessToken : null;
          if (accessToken) {
            var newData = res.data ? res.data.userData : null;
            if (newData) {
              ///Pregunto lo de local
              if(newData.codigoEmpresa != userDataService.getAppCustomizationCode()){
                ajaxInProgress.setState({
                  value: false
                });
               userDataService.procesarUserData(newData, true);
               self.actionLogin = true;
               return self.startFunction()
              }
            }

            $state.go("controlpanel", null, { location: "replace" });

            ajaxInProgress.setState({
              value: false
            });
          }
        } else if (ajaxInProgress.state.value == "obtainingToken") {
          var accessToken = res.data ? res.data.accessToken : null;
          if (accessToken) {
            var newData = res.data ? res.data.userData : null;

            if (newData) {
              userDataService.procesarUserData(newData, true);
            }

            $state.go("controlpanel", null, { location: "replace" });

            // system.listarSistemas().then(handleRequest, handleRequest);

            // // ajaxInProgress.state.value = "list";
            // ajaxInProgress.setState({
            //   value: "list"
            // });

            ajaxInProgress.setState({
              value: false
            });
          }
        } else if (ajaxInProgress.state.value === "listingDevices") {
          var devices = res.data.message.devices;
          deviceService.setDevicesList(devices);

          if (devices.length < 1) {
            return self.addDevice();
          }
          //Acá hacer alguna locurita
          return __env.getDeviceData().then(
            function(deviceData) {
              if (
                devices.find(function(device) {
                  return device.id === deviceData.id;
                })
              ) {
                self.addDevice();
              } else {
                user.setEmail(self.email);
                user.setPassword(self.password);

                $state.go("deviceslist", null, { location: "replace" });

                ajaxInProgress.setState({
                  value: false
                });
              }
            },
            function() {
              // console.log("Local storage rejection");
            }
          );
        } else if (ajaxInProgress.state.value === "addingDevice") {
          var accessToken = res.data ? res.data.accessToken : null;
          if (accessToken) {
            var newData = res.data ? res.data.userData : null;

            if (newData) {
              userDataService.procesarUserData(newData, true);
              
            }

            $state.go("controlpanel", null, { location: "replace" });
          }

          ajaxInProgress.setState({
            value: false
          });
        }

        self.message = res.data.message;
      } else {
        if (res.status == -1) {
          alertService.showAlert( self.getTexts("attention"),  self.getTexts("actionFail"), "OK");
          // ajaxInProgress.state.value = false;
          ajaxInProgress.setState({
            value: false
          });
          return;
        }

        alertService.showAlert(self.getTexts("attention"), res.data.message, "OK");
        // ajaxInProgress.state.value = false;
        ajaxInProgress.setState({
          value: false
        });
      }
    }

    self.codeVerify;

    //prueba para que funcione el
    self.checkScreen = function(){
    if(self.codeVerify && self.codeVerify.includes("resetMobile")){
            $timeout(function() {
              var verifyAccount = self.codeVerify.substring(self.codeVerify.length -40, self.codeVerify.length);

              if(!user.getCode() || user.getCode() != verifyAccount){
                
                user.setCode(verifyAccount);
                
                $state.go("resetMobile", null, { location: "replace" });
              }
                
            }, 0);
      return
      }
    };

		$rootScope.$on("appLifeCycle.onResume", function() {
      __env.viewContentLoaded();
    });

    // $scope.$watch(function(){
    //   return __env.getDeviceData().urlToRedirect;
    // }, function(newCodes, oldCodes){
    //   self.codeVerify = newCodes;
    //   self.checkScreen();
    // });
    self.startFunction = function(){
      $q.all([__env.getDeviceData(), auth.getUserEmail(), auth.getRefreshToken(), auth.getAccessToken()])
      .then(function(result) {
          self.deviceData = result[0];
          var userEmail = result[1];
          self.refreshToken = result[2];
          var accessToken = result[3];

          //Si tengo appbar y ésta es distinta de undefined, seteo el appbar, indico que la app está customizada y seteo el customization code.
        if(result[0].customizationAppbar && result[0].customizationAppbar != "undefined") {
          //Seteo localmente si la app está customizada o no, y su códgigo.
          userDataService.setAppCustomizedStateLS(result[0].customizationCode != self.productName ? true : false);
          self.appIsCustomized = userDataService.getAppIsCustomized();
          self.customizationCode = result[0].customizationCode;
          // self.customizationCode = userDataService.getAppCustomizationCode();
                    
          self.appbarImageData = result[0].customizationAppbar;
          self.splashImage = result[0].splashImage;
          self.setSystemlistImage = result[0].customizationSystemlist;
          //
          return
        }

        // Se setea el codigo de empresa en LocalStorage y el estado local.
        userDataService.setAppCustomizedCode(result[0].customizationCode && result[0].customizationCode != "undefined" ? result[0].customizationCode : self.productName);
        //

        //Setear que la app está customizada o no. Para ello me fijo si es igual al nombre de empresa o no.
        userDataService.setAppCustomizedStateLS(result[0].customizationCode != self.productName ? true : false);
        //
        }
      )
      .then(function(){
        self.customizationCode = self.deviceData.customizationCode && self.deviceData.customizationCode != "undefined" ? self.deviceData.customizationCode : self.productName;
        return user.fetchSplash(self.customizationCode);
      })
      .then(function(res){
      if(!res || !res.data) return
        self.splashImage = res.data.message.data;
        user.setSplashImageLS(res.data.message.data);
        user.setSplashImage(res.data.message.data);
  
        return user.fetchAppbarImage(self.customizationCode);
      })
      .then(function(res){
        if(!res || !res.data) return
          self.appbarImageData = res.data.message.data;
          user.setAppbarImageLS(res.data.message.data);
          user.setAppbarImage(res.data.message.data);
          return user.fetchSystemlistImage(self.customizationCode);
      })
      .then(function(res){
        if(!res || !res.data) return
          user.setSystemlistImageLS(res.data.message.data);
          user.setSystemlistImage(res.data.message.data);
          return user.fetchCustomizationAttributes(self.customizationCode);
      })
      .then(function(res){
        if(!res || !res.data) return
          user.setCustomizationAttributesLS(res.data.message.attributes);
          user.setCustomizationAttributes(res.data.message.attributes);
          self.attributes = res.data.message.attributes;
      })
      .then(function(){
        if(self.actionLogin){
          self.actionLogin = false;
          $state.go("controlpanel", null, { location: "replace" });
        }
        // Cosas de Refresh Token
        if (self.refreshToken) {
          if(self.deviceData.urlToRedirect && self.deviceData.urlToRedirect.includes("notifications")){
            $timeout(function() {
              //AJAX IN PROGRESS
              $state.go("notifications", null, { location: "replace" });
            }, 0);
            return
          }

          if(self.deviceData.urlToRedirect && self.deviceData.urlToRedirect.includes("history")){
            //TODO ACA VERIFICO SI EL CODIGO ES VALIDO GARNET FALTA PARA ALARM HOME
            system.setSelectedIndex('/system/history');
            $timeout(function() {
              $state.go("systemlistred", { idSistemaElegido:  self.deviceData.urlToRedirect.substring(20,32)});
            }, 0);
          return
          }

          $timeout(function() {
            $state.go("controlpanel", null, { location: "replace" });
          }, 0);

          // __env.viewContentLoaded();
          // return;
        }else{
          if(self.deviceData.urlToRedirect && self.deviceData.urlToRedirect.includes("users_api")){
            //TODO ACA VERIFICO SI EL CODIGO ES VALIDO GARNET FALTA PARA ALARM HOME
            $timeout(function() {
              var verifyAccount = self.deviceData.urlToRedirect.substring(self.deviceData.urlToRedirect.length -48, self.deviceData.urlToRedirect.length);

              if(!user.getCode() || user.getCode() != verifyAccount){
                
                user.setCode(verifyAccount);
                
                $state.go("registerverify", null, { location: "replace" });
              }
                
            }, 0);
          return
          } 
      
          if(self.deviceData.urlToRedirect && self.deviceData.urlToRedirect.includes("reset")){
                $timeout(function() {
                  var verifyAccount = self.deviceData.urlToRedirect.substring(self.deviceData.urlToRedirect.length -40, self.deviceData.urlToRedirect.length);
    
                  if(!user.getCode() || user.getCode() != verifyAccount){
                    user.setCode(verifyAccount);
                    $state.go("resetMobile", null, { location: "replace" });
                  }
                    
                }, 0);
          return
          }

          ajaxInProgress.setState({
            value: false
          });
        }
        __env.viewContentLoaded();
      })
      .catch(function(){
          __env.viewContentLoaded();
          // console.log("Local storage rejection");
      });

      $scope.$on("$viewContentLoaded", function() {
        // Here your view content is fully loaded !!
        // console.log("LoginController::viewContentLoaded()");
        __env.viewContentLoaded();
      });
    }
    self.startFunction()
  }

  angular.module("app").controller("LoginController", LoginController);
})();
