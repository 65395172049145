(function() {
  function SystemAntiPanicController(
    $scope,
    $rootScope,
    $state,
    system,
    statusCacheService,
    eventCache,
    ajaxInProgress,
    $timeout,
    ymSidenavService,
    editMode,
    alertService,
    seqService,
    actionBarService,
    buildModes,
    $stateParams,
    $rootScope,
    $window,
    BUILD_MODE
  ) {
    console.log("SystemAntiPanicController created");

    $scope.$on("$destroy", function() {
      console.log("SystemAntiPanicController destroyed");
    });

    var self = this;

    self.$scope = $scope;

    editMode.state.value = false;

    if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
      ymSidenavService.closeYmSidenav();
    }

    self.isMobile = BUILD_MODE == "MOBILE";
    self.isWeb = BUILD_MODE == "WEB";

    self.viewInfo;

    self.changeViewInfo = function(){
      self.viewInfo = !self.viewInfo
    };

    self.editModeValue = function() {
      return editMode.state.value;
    };

    self.mostrarSetPanic = [
      true, true, true, true
    ]

    self.cantidad = false;

    self.sistemaElegido;

    function handleLastTimeReport(res) {
      if (res.data && res.data.success) {
        self.sistemaElegido.lastEventReport = res.data.message;

        self.consultarPanicos(self.sistemaElegido.id);
      } else {
        if (res.status == -1) {
          ajaxInProgress.setState({
            value: false
          });
          // $timeout(function() {
          ajaxInProgress.setState({
            value: false
          });

					alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
          // });
          return;
        }
        if (res.status == -2) {
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
        ajaxInProgress.setState({
          value: false
        });
        // $timeout(function() {
        alertService.showAlert("Atención", res.data.message, "OK");
      }
    }

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if(res.data.message.response == "COMANDO INCOMPATIBLE CON EL PANEL ACTUAL"){
        alertService.showAlert("Atención", "Intentaste controlar un panel que cambio de modelo, ingresa nuevamente a tu sistema y se actualizará de forma automatica","OK");        
        $state.go("system", { idSistemaElegido: self.sistemaElegido.id },  { reload: "system" });
        }
        if (ajaxInProgress.state.value == "consultandoEstado") {
          ajaxInProgress.setState({
            value: false
          });
          // self.consultarPanicos();
        } else if (ajaxInProgress.state.value == "armandoSistema") {
          alertService.showToast(res.data.message.response);
          ajaxInProgress.setState({
            value: false
          });
        } else if (ajaxInProgress.state.value == "desarmandoSistema") {
          alertService.showToast(res.data.message.response);
          ajaxInProgress.setState({
            value: false
          });
        }
        else if (ajaxInProgress.state.value == "consultandoPanicos") {
          self.mostrarSetPanic = res.data.message.pendingPanics;
          ajaxInProgress.setState({
            value: false
          });
        }
        else if (ajaxInProgress.state.value == "seteandoTiempoDePanico") {
          //TODO PEDIR ESTADO.
          if(res.data.message.response == "RESPUESTA OK"){
            self.changePanicState(res.data.message.action.partNumber, true);
          }
          if(res.data.message.pendingPanics == "SETTED"){
            self.changePanicState(res.data.message.particion, true);
          }
          self.changePanicState(res.data.message.action.partNumber, false);
          alertService.showToast("PANICO PROG. OK");
          ajaxInProgress.setState({
            value: false
          });
        }
        else if (ajaxInProgress.state.value == "retrasandoTiempoDePanico") {
          //TODO PEDIR ESTADO.
          if(res.data.message.pendingPanics == "SETTED"){
            self.changePanicState(res.data.message.particion, true);
          }
          self.changePanicState(res.data.message.particion, false);
          alertService.showToast("PANICO POSPUESTO");
          ajaxInProgress.setState({
            value: false
          });
        }
        else if (ajaxInProgress.state.value == "Reseteando Panico") {
          if(res.data.message.response == "RESPUESTA OK"){
            self.changePanicState(res.data.message.action.partNumber, false);
          }
          self.changePanicState(res.data.message.action.partNumber, true);
          alertService.showToast("PANICO DESACT OK");
          ajaxInProgress.setState({
            value: false
          });
        }
        if (res.data.message.status) {
          self.sistemaElegido.status = statusCacheService.storeStatus(self.sistemaElegido, res.data.message.status);
        }
      } else {
        if (res.status == -1) {
          ajaxInProgress.setState({
            value: false
          });
          // $timeout(function() {
            alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
            // });
          return;
        }
        if (res.status == -2) {
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
        ajaxInProgress.setState({
          value: false
        });
        // $timeout(function() {
          if(res.data.message == "Ya existe un pánico pendiente"){
            self.consultarPanicos();
          }else{
            alertService.showAlert("Atención", res.data.message, "OK");
          }
        // });
      }
    }

    self.consultarEstado = function() {
      var ajax = system.consultarEstado(self.sistemaElegido, seqService.seq());
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "consultandoEstado",
        label: "Consultando Estado ",
        ajax: ajax
      });
    };

    self.consultarPanicos = function() {
      var ajax = system.consultarPanicos(self.sistemaElegido.id);
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "consultandoPanicos",
        label: "Sincronizando Botonera",
        ajax: ajax
      });
    };

    // self.getLastEventReport = function() {
    //   var ajax = system.getLastEventReport(self.sistemaElegido);
    //   ajax.then(handleLastTimeReport, handleLastTimeReport);
    // };

    self.editarSistema = function() {
      $state.go("systemconfig", { idSistemaElegido: $stateParams.idSistemaElegido });
    };

    self.panicoTemporizadoUno = function(partition){
      system.setSystemTimeToPanic(self.sistemaElegido, Date.now() + 1000 * 60 * 5, partition, seqService.seq()).then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "seteandoTiempoDePanico",
        label: "Programando Pánico",
      });
    };

    self.delayTemporizedPanic = function(partition){
      system.delaySystemTimeToPanic(self.sistemaElegido, partition).then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "retrasandoTiempoDePanico",
        label: "Retrasando Pánico",
      });
    };

    self.panicoTemporizadoDos = function(partition){
      system.setSystemTimeToPanic(self.sistemaElegido, Date.now() + 1000 * 60 * 10, partition, seqService.seq()).then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "seteandoTiempoDePanico",
        label: "Programando Pánico",
      });
    };

    self.resetPanico = function(partition){
      system.resetSystemTimeToPanic(self.sistemaElegido, partition, seqService.seq()).then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "Reseteando Panico",
        label: "Reseteando Panico",
      });
    };

    self.setEditAction = function() {
      var buttons = [
        {
          label: "Actualizar",
          aria: "Actualizar",
          icon: "refresh",
          onClickHandler: function() {
            self.consultarPanicos();
          }
        }
      ];

      buttons.push({
        label: "Configurar",
        aria: "Configurar",
        icon: "settings",
        onClickHandler: function() {
          self.editarSistema();
        }
      });

      actionBarService.setActionBarActions("SystemAntiPanicController", buttons);
    };

    // Tareas para cuando el controller se instancia.
    // self.setEditAction();

    self.changePanicState = function(particion, state){
      self.mostrarSetPanic[particion - 1] = state;
    };


    function handleLastUpdate(res){
      if (res.data && res.data.success) {
        self.lastUpdate = res.data.message.lastUpdate;
        self.lastEvent = res.data.message.lastEvent;

        if (self.lastEvent && new Date(self.lastEvent).getTime() > new Date(self.lastUpdate).getTime()) {

          $timeout(function() {
            return $rootScope.pedirProgramacionDesdeSystem();
          });
        
        }else{
            return self.consultarPanicos();
        }
      } else {
        if (res.status == -1) {
          ajaxInProgress.setState({
            value: false
          });
          // $timeout(function() {
            alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
            // });
          return;
        }
        if (res.status == -2) {
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
        ajaxInProgress.setState({
          value: false
        });
        // $timeout(function() {
        alertService.showAlert("Atención", res.data.message, "OK");
      }
    }

    self.getLastUpdate = function() {
      var ajax = system.getLastUpdate(self.sistemaElegido);
      ajax.then(handleLastUpdate, handleLastUpdate);
    };

    system.getSystem($stateParams.idSistemaElegido)
    .then(function(sistemaElegido) {
			// $rootScope.focusingTab("/system/antipanic");
      self.sistemaElegido = sistemaElegido;
      //Seteo el timeout de los comandos en función de lo que devuelva
      system.setCommandTimeout(sistemaElegido.id);
      if (self.sistemaElegido.estadoDeCarga === "verificado") {
        if (self.sistemaElegido.programation.data) {
          self.partitionList = self.sistemaElegido.programation.data.partitions;
          self.cantidad = self.partitionList.length > 1;
        }

        self.setEditAction();

        self.sistemaElegido.status = self.sistemaElegido.status || statusCacheService.getStatus(self.sistemaElegido);

        // if (!self.sistemaElegido.status) {
        //   return self.consultarEstado();
        // }

        self.getLastUpdate();
        // self.getLastEventReport();
      }
    });
  }

  angular.module("app").controller("SystemAntiPanicController", SystemAntiPanicController);
})();
