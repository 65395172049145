(function(angular) {
  function antiPanicController($scope, $element,BUILD_MODE, ajaxInProgress) {
    var ctrl = this;
    // ctrl.$scope = $scope;
    ctrl.ajaxInProgress = ajaxInProgress;

    ctrl.$onChanges = function(changesObj) {
      ctrl.setStatus(ctrl.panic);
      ctrl.setPartView(ctrl.cantidad);
    };

    ctrl.isMobile = BUILD_MODE == "MOBILE";
    ctrl.isWeb = BUILD_MODE == "WEB";

    ctrl.panicoUno = function(){
      ctrl.panicoTemporizadoUno();
    }

    ctrl.panicoDos = function(){
      ctrl.panicoTemporizadoDos();
    }

    ctrl.resetP = function(){
      ctrl.resetPanico();
    }

    ctrl.delayPanic = function(){
      ctrl.delayTemporizedPanic();
    }

    ctrl.setStatus = function(panic){
      if (panic) {
      ctrl.status = panic[ctrl.particion.number -1] ? "NORMAL": "PANICO";
    } else {
      ctrl.partitionStatusColor = "NO";
    }
  }

  ctrl.setPartView = function(cantidad){
    ctrl.Mostrar = cantidad
  }
  // ctrl.isUnicaVista = ctrl.isMoreThanOne()
}

  angular.module("app").component("antipanic", {
    templateUrl: "components/AntiPanic/antiPanic.html",
    controller: antiPanicController,
    bindings: {
      particion: "<",
      panic: "<",
      cantidad : "<",
      panicoTemporizadoUno: "&",
      panicoTemporizadoDos: "&",
      delayTemporizedPanic: "&",
      resetPanico: "&",
    }
  });
})(window.angular);
