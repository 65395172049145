(function(angular) {
  function CreditCardController($scope, $element, ajaxInProgress, customFormat, creditcards) {
    var ctrl = this;

    ctrl.$onInit = function() {
      ctrl.parseChanges();
    };

    ctrl.$onChanges = function(changesObj) {
      ctrl.parseChanges();
    };

    ctrl.paymentMethodSrc = function() {
      if (ctrl.paymentMethodId == "amex") {
        return "/img/assets/payment-method/amex.png";
      } else if (ctrl.paymentMethodId == "visa") {
        return "/img/assets/payment-method/visa.png";
      }
    };

    ctrl.parseChanges = function() {
      ctrl.card = {};

      if (ctrl.number) {
        ctrl.card.number = creditcards.card.format(ctrl.number);

        if (ctrl.holderName) {
          ctrl.card.holderName = ctrl.holderName;
        }
      }

      if (ctrl.lastFourDigits) {
        ctrl.card.number = "**** **** **** " + ctrl.lastFourDigits;

        if (ctrl.vendor) {
          ctrl.card.vendor = ctrl.vendor;
        }

        if (ctrl.issuerName) {
          ctrl.card.issuerName = ctrl.issuerName;
        }

        ctrl.card.holderName = "******* * *****";
      }

      ctrl.card.expiry = (ctrl.expiryMonth ? customFormat.format(2, ctrl.expiryMonth) : "**") + "/" + (ctrl.expiryYear ? customFormat.format(2, ctrl.expiryYear) : "**");
    };
  }

  angular.module("app").component("creditCard", {
    templateUrl: "components/CreditCard/creditCard.html",
    controller: CreditCardController,
    bindings: {
      // Datos de una tarjeta nueva
      number: "<",
      holderName: "<",
      paymentMethodId: "<",
      // Datos de una tarjeta cargada previamente
      vendor: "<",
      lastFourDigits: "<",
      issuerName: "<",
      // Datos genéricos
      expiryMonth: "<",
      expiryYear: "<"
    }
  });
})(window.angular);
