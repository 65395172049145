(function() {
  angular.module("app").directive("cardFormFlow", [
    "$document",
    function($document) {
      return {
        link: function(scope, element, attrs) {
          element.on("keypress", function(event) {
            console.log("cardFormFlow::keyPress", element, attrs, event);
            // if (event.keyCode == 13) {
            //   event.preventDefault();
            //   event.stopPropagation();
            //   var myEl = document.getElementById('circleTimeWordListEditText');
            //   var angularEl = angular.element(myEl);

            //   angularEl.focus();
            //   // element[0].form[6].parentElement.focus();
            // }
          });
        }
      };
    }
  ]);
})();
