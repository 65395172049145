(function () {
  function SystemControlController(
    $scope,
    $rootScope,
    $state,
    system,
    statusCacheService,
    eventCache,
    ajaxInProgress,
    $timeout,
    $window,
    editMode,
    alertService,
    seqService,
    actionBarService,
    ymSidenavService,
    buildModes,
    $stateParams,
    BUILD_MODE,
    BUILD_PLATFORM,
    userDataService
  ) {
    console.log("SystemControlController created");

    $scope.$on("$destroy", function () {
      console.log("SystemControlController destroyed");
    });

    var self = this;

    self.$scope = $scope;

    editMode.state.value = false;

    self.editModeValue = function () {
      return editMode.state.value;
    };

    if (
      BUILD_MODE == "MOBILE" ||
      (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)
    ) {
      ymSidenavService.closeYmSidenav();
    }

    self.sistemaElegido;
    self.partitionData;
    self.checkStatus = false;
    self.indexZoneToBypass = 0;
    self.userData = userDataService.getUserData();

    self.tabsToSwipe = system.getTabsToSwipe();

    self.zoneGroupArray = [];
    self.finalZoneStatus = [];
    self.clockColour = "clock-icon-green";

    function handleLastTimeReport(res) {
      if (res.data && res.data.success) {
        self.sistemaElegido.lastEventReport = res.data.message;

        if (
          self.sistemaElegido.lastEventReport >
          self.sistemaElegido.status.lastTimeUpdate.getTime()
        ) {
          // console.log("consulto estado por que cambio la programación");
          return self.consultarEstado();
        }
        // self.getLastUpdate();
      } else {
        if (res.status == -1) {
          ajaxInProgress.setState({
            value: false,
          });
          // $timeout(function() {
          alertService.showAlert(
            "Atención",
            "No se pudo completar la acción, vuelva a intentarlo.",
            "OK"
          );
          // });
          return;
        }
        if (res.status == -2) {
          ajaxInProgress.setState({
            value: false,
          });
          return;
        }
        ajaxInProgress.setState({
          value: false,
        });
        // $timeout(function() {
        alertService.showAlert("Atención", res.data.message, "OK");
      }
    }

    function handleLastUpdate(res) {
      if (res.data && res.data.success) {
        self.lastUpdate = res.data.message.lastUpdate;
        self.lastEvent = res.data.message.lastEvent;

        if (
          self.lastEvent &&
          new Date(self.lastEvent).getTime() >
            new Date(self.lastUpdate).getTime()
        ) {
          return $rootScope.pedirProgramacionDesdeSystem();
        } else {
          if (!self.sistemaElegido.status) {
            // console.log("consulto estado por que no tengo estado");
            return self.consultarEstado();
          }
          self.getLastEventReport();
        }
      } else {
        if (res.status == -1) {
          ajaxInProgress.setState({
            value: false,
          });
          // $timeout(function() {
          alertService.showAlert(
            "Atención",
            "No se pudo completar la acción, vuelva a intentarlo.",
            "OK"
          );
          // });
          return;
        }
        if (res.status == -2) {
          ajaxInProgress.setState({
            value: false,
          });
          return;
        }
        ajaxInProgress.setState({
          value: false,
        });
        // $timeout(function() {
        alertService.showAlert("Atención", res.data.message, "OK");
      }
    }

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (
          res.data.message.response ==
          "COMANDO INCOMPATIBLE CON EL PANEL ACTUAL"
        ) {
          alertService.showAlert(
            "Atención",
            "Intentaste controlar un panel que cambio de modelo, ingresa nuevamente a tu sistema y se actualizará de forma automatica",
            "OK"
          );
          $state.go("systemlist", { idSistemaElegido: self.sistemaElegido.id });
        }
        if (ajaxInProgress.state.value == "consultandoEstado") {
          self.checkStatus = false;
          ajaxInProgress.setState({
            value: false,
          });
          // self.getLastUpdate();
        } else if (ajaxInProgress.state.value == "armandoSistemaOld") {
          self.indexZoneToBypass = 0;
          self.checkStatus = true;
          alertService.showToast(res.data.message.response);
          ajaxInProgress.setState({
            value: false,
          });
          // self.consultarEstado();
        } else if (ajaxInProgress.state.value == "armandoSistema") {
          self.checkStatus = true;
          alertService.showToast(res.data.message.response);
          ajaxInProgress.setState({
            value: false,
          });
        } else if (ajaxInProgress.state.value == "desarmandoSistema") {
          self.checkStatus = true;
          alertService.showToast(res.data.message.response);
          ajaxInProgress.setState({
            value: false,
          });
        } else if (ajaxInProgress.state.value == "inhibiendoZonas") {
          self.indexZoneToBypass = self.indexZoneToBypass + 1;
          if (self.zonesToBypass[self.indexZoneToBypass]) {
            self.checkStatus = true;
            alertService.showToast(res.data.message.response);
            ajaxInProgress.setState({
              value: false,
            });
            return self.massiveBypass(
              self.zonesToBypass[self.indexZoneToBypass]
            );
          }

          self.checkStatus = true;
          alertService.showToast(res.data.message.response);
          ajaxInProgress.setState({
            value: false,
          });
          self.armarSistemaPresOld({
            modo: "away",
            partition: self.partitionData.partition,
          });
        }
        if (res.data.message.status) {
          self.sistemaElegido.status = statusCacheService.storeStatus(
            self.sistemaElegido,
            res.data.message.status,
            self.checkStatus
          );
          if (self.sistemaElegido.status.isOldForm) {
            self.parseStatus(self.sistemaElegido.status);
            self.zoneGroupArray.map(function (partObj, idx) {
              self.armedPresent = false;
              partObj.zones.forEach(function (zone) {
                if (zone.isPresentZone) {
                  if (self.finalZoneStatus[zone.number - 1] == "INHIBIDA") {
                    self.armedPresent = true;
                  } else {
                    self.armedPresent = false;
                  }
                }
              });
              if (
                self.sistemaElegido.status.partitionStatus[idx] == "ARMADO" &&
                self.armedPresent
              ) {
                self.sistemaElegido.status.partitionStatus[idx] = "DEMORADO";
              }
            });
          }
          ajaxInProgress.setState({
            value: false,
          });
        }
      } else {
        if (res.status == -1) {
          ajaxInProgress.setState({
            value: false,
          });
          // $timeout(function() {
          alertService.showAlert(
            "Atención",
            "No se pudo completar la acción, vuelva a intentarlo.",
            "OK"
          );
          // });
          return;
        }
        if (res.status == -2) {
          ajaxInProgress.setState({
            value: false,
          });
          return;
        }
        ajaxInProgress.setState({
          value: false,
        });
        // $timeout(function() {
        alertService.showAlert("Atención", res.data.message, "OK");
        // });
      }
    }

    self.consultarEstado = function () {
      var ajax = system.consultarEstado(self.sistemaElegido, seqService.seq());
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "consultandoEstado",
        label: "Consultando Estado",
        ajax: ajax,
      });
    };

    self.getLastEventReport = function () {
      var ajax = system.getLastEventReport(self.sistemaElegido);
      ajax.then(handleLastTimeReport, handleLastTimeReport);
    };

    self.getLastUpdate = function () {
      var ajax = system.getLastUpdate(self.sistemaElegido);
      ajax.then(handleLastUpdate, handleLastUpdate);
    };

    self.armarSistemaPresOld = function (partitionData) {
      var ajax = system.armarSistema(
        self.sistemaElegido,
        seqService.seq(),
        partitionData.modo,
        partitionData.partition.toString()
      );
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "armandoSistemaOld",
        label: "Armando Sistema Pres",
        ajax: ajax,
      });
    };

    self.armarSistema = function (partitionData) {
      self.partitionData = partitionData;
      self.partitionsArr;

      if (
        self.sistemaElegido.status.isOldForm &&
        partitionData.modo == "delayed"
      ) {
        self.indexZoneToBypass = 0;
        self.zonesToBypass = self.sistemaElegido.programation.data.zones.filter(
          (zone) => {
            if (zone.partitions) {
              partitionsArr = zone.partitions.filter(function (partNum) {
                return partNum == partitionData.partition;
              });
            }
            return zone.isPresentZone && partitionsArr.length > 0;
          }
        );
        if (self.zonesToBypass.length > 0) {
          self.massiveBypass(self.zonesToBypass[self.indexZoneToBypass]);
        }
      } else {
        var ajax = system.armarSistema(
          self.sistemaElegido,
          seqService.seq(),
          partitionData.modo,
          partitionData.partition.toString()
        );
        ajax.then(handleRequest, handleRequest);
        ajaxInProgress.setState({
          value: "armandoSistema",
          label: "Armando Sistema",
          ajax: ajax,
        });
      }
    };

    self.massiveBypass = function (zona) {
      var ajax = system.inhibirZona(
        self.sistemaElegido,
        seqService.seq(),
        zona.number,
        zona.partitions[0]
      );
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "inhibiendoZonas",
        label: "Inhibiendo Zonas",
        ajax: ajax,
      });
    };

    self.desarmarSistema = function (partitionNumber) {
      var ajax = system.desarmarSistema(
        self.sistemaElegido,
        seqService.seq(),
        partitionNumber.partition.toString()
      );
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "desarmandoSistema",
        label: "Desarmando Sistema",
        ajax: ajax,
      });
    };

    self.editMode = editMode;

    self.editarSistema = function () {
      $state.go("systemconfig", {
        idSistemaElegido: $stateParams.idSistemaElegido,
      });
    };

    self.setSaveAction = function () {
      actionBarService.setActionBarActions("SystemZonesController", [
        {
          label: "Guardar",
          aria: "Guardar",
          icon: "save",
          onClickHandler: function () {
            // Secuencia de guardado :D
            var toSavePartitions = [];

            self.partitionList.forEach(function (partition) {
              var toSavePartition = Object.assign({}, partition);
              toSavePartition.name = toSavePartition.editedName;
              delete toSavePartition.editedName;
              toSavePartitions.push(toSavePartition);
            });

            var programation = Object.assign(
              {},
              self.sistemaElegido.programation
            );

            programation.data.partitions = toSavePartitions;

            $scope.systemController.setSystemProgramation(programation.data);

            editMode.state.value = false;
            self.setEditAction();

            $state.go("system");
          },
        },
        {
          label: "Cancelar",
          aria: "Cancelar",
          icon: "clear",
          onClickHandler: function () {
            editMode.state.value = false;
            self.setEditAction();
          },
        },
      ]);
    };

    self.setEditAction = function () {
      var buttons = [
        {
          label: "Actualizar",
          aria: "Actualizar",
          icon: "refresh",
          onClickHandler: function () {
            self.consultarEstado();
          },
        },
      ];

      // if (self.sistemaElegido.owner && typeof self.sistemaElegido.owner.userType == "number" && self.sistemaElegido.owner.userType == 0) {
      //   buttons.push({
      //     label: "Editar",
      //     aria: "Editar",
      //     icon: "mode_edit",
      //     onClickHandler: function() {
      //       editMode.state.value = true;
      //       self.setSaveAction();
      //     }
      //   });
      // }

      buttons.push({
        label: "Configurar",
        aria: "Configurar",
        icon: "settings",
        onClickHandler: function () {
          self.editarSistema();
        },
      });

      actionBarService.setActionBarActions("SystemControlController", buttons);
    };

    self.getPartitionNames = function (sistemas, sistemasCompartidos) {
      self.sistemaObtenido = sistemas.find(function (sistema) {
        return sistema.id == self.sistemaElegido.id;
      });

      if (!self.sistemaObtenido) {
        self.sistemaObtenido = sistemasCompartidos.find(function (sistema) {
          return sistema.id == self.sistemaElegido.id;
        });
      }
      return [
        self.sistemaObtenido.estados[1].nombre,
        self.sistemaObtenido.estados[2].nombre,
        self.sistemaObtenido.estados[3].nombre,
        self.sistemaObtenido.estados[4].nombre,
      ];
    };

    self.swipeScreenLeft = function () {
      // self.thisTabIndex = self.tabsToSwipe.indexOf(self.tabsToSwipe.find(function(tab){return tab.title = "Control"}))
      // if(self.tabsToSwipe[self.thisTabIndex + 1] != -1){
      //   //CAMBIAR A PANTALLA SIGUIENT
      //   $scope.tabIndex = self.thisTabIndex + 1;
      //   $state.go(self.tabsToSwipe[self.thisTabIndex + 1].mdNavSref, null, { location: "replace" });
      // }
    };

    // PARCEAR ZONAS UNA POR UNA, UNA LOCURA.

    self.parseStatus = function (status) {
      self.finalZoneStatus = [];
      for (var zoneI = 1; zoneI < 33; zoneI++) {
        if (status) {
          var arrayIndex = Math.ceil(zoneI / 8) - 1;
          var exponent = (zoneI % 8) - 1;
          var mask = Math.pow(2, exponent > -1 ? exponent : 7);

          if (status.zonasEnAlarma[arrayIndex] & mask) {
            // Zona en alarma
            self.estadoZona = "ALARMA";
            self.estadoZonaClass = "zone-class-alarma";
            self.estadoZonaText = "En alarma";
            self.estadoZonaColor = "rojo";
          } else {
            //La zona no está en alarma
            if (status.zonasInhibidas[arrayIndex] & mask) {
              //La zona está inhibida
              self.estadoZona = "INHIBIDA";
              self.estadoZonaClass = "zone-class-inhibida";
              self.estadoZonaText = "Inhibida";
              self.estadoZonaColor = "verde";
            } else {
              //La zona no está inhibida
              if (status.zonasAbiertas[arrayIndex] & mask) {
                //La zona está abierta
                self.estadoZona = "ABIERTA";
                self.estadoZonaClass = "zone-class-abierta";
                self.estadoZonaText = "Abierta";
                self.estadoZonaColor = "naranja";
              } else {
                //La zona está cerrada
                self.estadoZona = "CERRADA";
                self.estadoZonaClass = "zone-class-cerrada";
                self.estadoZonaText = "Cerrada";
                self.estadoZonaColor = "gris";
              }
            }
          }
        } else {
          self.estadoZona = "DESCONOCIDO";
          self.estadoZonaClass = "zone-class-desconocido";
          self.estadoZonaText = "";
          self.estadoZonaColor = "gris";
        }
        self.finalZoneStatus.push(self.estadoZona);
      }
    };

    // Tareas para cuando el controller se instancia
    self.setEditAction();

    system
      .getSystem($stateParams.idSistemaElegido)
      .then(function (sistemaElegido) {
        self.sistemaElegido = sistemaElegido;
        // if(self.sistemaElegido.owner.email == self.userData.email){
        $rootScope.systemPlanCounter = "system-plan-counter";
        // } else{
        // $rootScope.systemPlanCounter = 'system-plan-counter-hidden'
        // }
        if (
          self.sistemaElegido.subscriptionInfo &&
          self.sistemaElegido.subscriptionInfo.validThru
        ) {
          var daysLeftTimestamp =
            new Date(self.sistemaElegido.subscriptionInfo.validThru).getTime() -
            Date.now();
          if (daysLeftTimestamp < 1) {
            $rootScope.daysLeft = 0;
          } else {
            $rootScope.daysLeft = parseInt(
              daysLeftTimestamp / (24 * 1000 * 3600)
            );
          }
          if ($rootScope.daysLeft < 30 && $rootScope.daysLeft > 7) {
            self.clockColour = "clock-icon-yellow";
          } else if ($rootScope.daysLeft < 8) {
            self.clockColour = "clock-icon-red";
          }
          $rootScope.clockIconToShow = self.clockColour;
        } else {
          $rootScope.daysLeft = 0;
        }
        //Seteo el timeout de los comandos en función de lo que devuelva
        system.setSelectedIndex("/system/control");
        system.setCommandTimeout(sistemaElegido.id);

        if (self.sistemaElegido.estadoDeCarga === "verificado") {
          // console.log("SISTEMA VERIFICADO")
          if (self.sistemaElegido.programation.data) {
            self.partitionList =
              self.sistemaElegido.programation.data.partitions;
            self.cantidad = self.partitionList.length > 1;
          }

          self.setEditAction();

          self.sistemaElegido.status =
            self.sistemaElegido.status ||
            statusCacheService.getStatus(self.sistemaElegido);

          //Agrupo zonas por partición.
          for (var i = 0; i < 4; i++) {
            var partition =
              self.sistemaElegido.programation.data.partitions.find(function (
                partition
              ) {
                return partition.number == i + 1;
              });
            if (!partition) continue;

            var zoneGroup = {
              title: partition.name || "Partición " + partition.number,
              zones: [],
            };

            self.sistemaElegido.programation.data.zones.forEach(function (
              zone
            ) {
              if (zone.attributes.substring(2 + i, 3 + i) == 1) {
                if (!zone.partitions) {
                  zone.partitions = [];
                }
                zone.partitions.push(partition.number);
                zoneGroup.zones.push(zone);
              }
            });

            self.zoneGroupArray.push(zoneGroup);
          }

          self.partitionNames = [];
          self.partitionList.forEach(function (part) {
            self.partitionNames.push(part.name);
          });

          self.partitionList.forEach(function (partition, idx) {
            self.partitionNames[idx] = partition.name;
          });

          self.getLastUpdate();
        }
      });
  }
  angular
    .module("app")
    .controller("SystemControlController", SystemControlController);
})();
