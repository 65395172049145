(function() {
  function RegisterStateController(__env, ajaxInProgress, $state, user, alertService, userDataService, BUILD_MODE, BUILD_PRODUCT) {
    var self = this;

    if(BUILD_PRODUCT == "titanium_control"){
      self.productName = "Alarm Home";
    }
    if(BUILD_PRODUCT == "garnet_control"){
      self.productName = "Garnet Control";
    }
    if(BUILD_PRODUCT == "yo_monitoreo"){
      self.productName = "Yo Monitoreo";
    }

    self.isMobile = BUILD_MODE == "MOBILE";
    self.isWeb = BUILD_MODE == "WEB";

    self.gotoLogin = function() {
      $state.go("login");
    };

    self.verifyRegister = function() {
      if (!ajaxInProgress.state.value) {
        ajaxInProgress.setState({
          value: "registerVerify"
        });
        user.verifyUserRegistration()
          .then(handleRequest, handleRequest);
      }
    };


		self.appIsCustomized = userDataService.getAppIsCustomized();
	
		if(self.appIsCustomized){
		  self.attributes = user.getCustomizationAttributes();
		}


    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value == "registerVerify") {
          // user.resetCode();
          $state.go("login", null , { custom: { forced: true } })
          alertService.showAlert("Proceso de Registro", res.data.message, "OK");
          // ajaxInProgress.state.value = false;
          ajaxInProgress.setState({
            value: false
          });
        }
        self.message = res.data.message;
      } else {
        if (res.status == -1) {
          alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
          // ajaxInProgress.state.value = false;
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
        alertService.showAlert("Atención", res.data.message, "OK");
        ajaxInProgress.setState({
          value: false
        });
      }
    }
  }

  angular.module("app").controller("RegisterStateController", RegisterStateController);
})();
