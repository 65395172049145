(function() {
  function SubscriptionSystemListController(
    $scope,
    $state,
    $window,
    $timeout,
    BUILD_MODE,
    system,
    ajaxInProgress,
    ymSidenavService,
    editMode,
    alertService,
    actionBarService,
    routerService
  ) {
    console.log("SubscriptionSystemListController created");

    $scope.$on("$destroy", function() {
      console.log("SubscriptionSystemListController destroyed");
    });

    var self = this;

    self.$scope = $scope;

    editMode.state.value = false;

    self.editModeValue = function() {
      return editMode.state.value;
    };

    self.actionBarTitle = "Sucripción y planes";

    self.actionBarActions = actionBarService.actionBarActions;

    self.ready = false;

    self.setSistemaElegido = function(id) {
      // IN USE system.setIdSistemaElegido(id);
      $state.go("subscription", { idSistemaElegido: id });
    };

    // Expongo las funciones para utilizarlas desde la view directamente
    self.sistemas = system.getSistemas;

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value == "list") {
          system.procesarListaDeSistemasYCompartidos(res.data.message);

          if (routerService.getLastState().search(/subscription\./) > -1) {
            ajaxInProgress.setState({
              value: false
            });

            self.ready = true;
            return;
          }

          if (self.sistemas().length === 1) {
            self.setSistemaElegido(self.sistemas()[0].id);
            return;
          }

          ajaxInProgress.setState({
            value: false
          });

          self.ready = true;
        }
        self.message = res.data.message;
      } else {
        if (res.status == -1) {
					alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
          // ajaxInProgress.state.value = false;
          ajaxInProgress.setState({
            value: false
          });
          return;
        }

        alertService.showAlert("Atención", res.data.message, "OK");
        // ajaxInProgress.state.value = false;
        ajaxInProgress.setState({
          value: false
        });
      }
    }

    actionBarService.setActionBarActions("SubscriptionSystemListController", []);

    system.listarSistemas().then(handleRequest, handleRequest);

    ajaxInProgress.setState({
      value: "list"
    });
  }

  angular.module("app").controller("SubscriptionSystemListController", SubscriptionSystemListController);
})();
