(function () {
  function ForgotController(
    __env,
    $q,
    ajaxInProgress,
    $state,
    user,
    userDataService,
    alertService,
    BUILD_MODE,
    BUILD_PRODUCT
  ) {
    var self = this;

    if (BUILD_PRODUCT == "titanium_control") {
      self.productName = "Alarm Home";
    }
    if (BUILD_PRODUCT == "garnet_control") {
      self.productName = "Garnet Control";
    }
    if (BUILD_PRODUCT == "yo_monitoreo") {
      self.productName = "Yo Monitoreo";
    }

    self.isMobile = BUILD_MODE == "MOBILE";
    self.isWeb = BUILD_MODE == "WEB";

    self.user = {};

    self.appIsCustomized = userDataService.getAppIsCustomized();

    if (self.appIsCustomized) {
      self.attributes = user.getCustomizationAttributes();
      self.appbarImageData = user.getAppBarImage();
    }

    self.phoneLanguage;

    self.forgotTexts = {
      spa: {
        registerConfirmed:
          "Has confirmado tu cuenta, ya puedes ingresar desde la app",
        cancel: "Cancelar",
        passswordReset: "Restablecer Contraseña",
        email: "Email",
        requiredField: "Este campo es obligatorio",
        emailError: "Es necesario un email válido",
        reset: "Restablecer",
        attention: "Atención",
        resetEmail:
          "En instantes recibirás un correo con un enlace para resetear tu contraseña",
        actionFail: "No se pudo completar la acción, vuelva a intentarlo.",
      },
      eng: {
        registerConfirmed:
          "You have confirmed your account, you can now enter from the app",
        cancel: "Cancel",
        passswordReset: "Restore password",
        email: "Email",
        requiredField: "This field is required",
        emailError: "A valid email is required",
        reset: "Restore",
        attention: "Atención",
        resetEmail:
          "In moments you will receive an email with a link to reset your password",
        actionFail: "The action could not be completed, please try again.",
      },
      por: {
        registerConfirmed:
          "Você confirmou sua conta, agora você pode fazer login no aplicativo",
        cancel: "Cancelar",
        passswordReset: "Redefinir senha",
        email: "E-mail",
        requiredField: "Este campo é obrigatório",
        emailError: "É necessário um e-mail válido",
        reset: "Redefinir",
        attention: "Atenção",
        resetEmail:
          "Em instantes você receberá um e-mail com um link para redefinir sua senha",
        actionFail: "A ação não pôde ser concluída, tente novamente.",
      },
    };

    $q.all([userDataService.getPhoneLenguage()]).then(function (result) {
      if (result[0] == "pt") {
        self.phoneLanguage = "por";
      } else if (result[0] == "en") {
        self.phoneLanguage = "eng";
      } else if (result[0] == "es") {
        self.phoneLanguage = "spa";
      } else {
        self.notProcessedLanguage =
          window.navigator.userLanguage || window.navigator.language;

        if (self.notProcessedLanguage.includes("en")) {
          self.phoneLanguage = "eng";
        } else if (self.notProcessedLanguage.includes("pt")) {
          self.phoneLanguage = "por";
        } else {
          self.phoneLanguage = "spa";
        }
      }
    });

    self.getTexts = function (type) {
      return self.phoneLanguage
        ? self.forgotTexts[self.phoneLanguage][type]
        : self.forgotTexts["eng"][type];
    };

    self.forgotPassword = function () {
      if (!ajaxInProgress.state.value) {
        ajaxInProgress.setState({
          value: "forgot",
        });
        user
          //RECAPTCHA
          // .forgot(self.user.email, self.user.reCaptchaToken)
          .forgot(self.user.email, self.phoneLanguage)
          .then(handleRequest, handleRequest);
      }
    };

    self.gotoLogin = function () {
      $state.go("login");
    };

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value == "forgot") {
          $state.go("login");
          self.user = {};
          alertService.showAlert(
            self.getTexts("passwordReset"),
            self.getTexts("resetEmail"),
            "OK"
          );
          // ajaxInProgress.state.value = false;
          ajaxInProgress.setState({
            value: false,
          });
        }
        self.message = res.data.message;
      } else {
        if (res.status == -1) {
          alertService.showAlert(
            self.getTexts("attention"),
            self.getTexts("resetEmail"),
            "OK"
          );
          // ajaxInProgress.state.value = false;
          ajaxInProgress.setState({
            value: false,
          });
          return;
        }
        alertService.showAlert(
          self.getTexts("attention"),
          res.data.message,
          "OK"
        );
        ajaxInProgress.setState({
          value: false,
        });
      }
    }
  }

  angular.module("app").controller("ForgotController", ForgotController);
})();
