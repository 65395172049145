(function() {
  function stateObserver($rootScope, $state, $stateParams, auth, __env, deviceService) {
    $rootScope.$on("$stateChangeStart", function(event, toState, toStateParams) {
      // console.log($state);
      // console.log(toState);
      // if (toState.authenticate && !auth.isAuthed()) {
      //   // User isn’t authenticated
      //   var authData = __env.getAuthData();
      //   var deviceData = __env.getDeviceData();
      //   if (!deviceData || !authData) {
      //     $state.transitionTo("login");
      //     event.preventDefault();
      //     return;
      //   }
      // }
    });
  }

  angular.module("app").run(["$rootScope", "$state", "$stateParams", "auth", "__env", "deviceService", stateObserver]);
})();
