(function() {
  var SUBSCRIPTION_STATUS = {
    NONE: 0,
    FREE_TRIAL: 1,
    PENDING: 2,
    ACTIVE: 3,
    CANCELLED: 4,
    FINISHED: 5,
    REJECTED: 6,
    PENDING_REJECTED: 7
  };

  angular.module("app").constant("subscriptionStatus", SUBSCRIPTION_STATUS);
})();
