(function() {
  angular.module("app").directive("ymNoValidate", [
    "$document",
    function($document) {
      return {
        link: function(scope, element, attrs) {
          element.on("invalid", function(event) {
            // console.log("ym-no-validate", element, attrs, event);
            event.preventDefault();
            element[0].focus();
          });
        }
      };
    }
  ]);
})();
