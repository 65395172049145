(function() {
	function notificationHookService($q, $http, BASE_API, $rootScope, alertService) {
		var self = this;

		// self.getQuote = function() {
		//   return $http.get(BASE_API + "/auth/quote");
		// };

		self.notificationsData = {};

		//Variable modificada por los Hooks que decide si se muestran o no notificaciones.
		self.notificationPanelStatus;

		$rootScope.$on("notificationsClear", function() {
			self.notificationsData.notificationPanelStatus = false;
		});

		$rootScope.$on("appLifeCycle.onResume", function() {
			$http
				.get(BASE_API + "/user_data/notifications")
				.then(function(res) {
					self.notificationsData.lastRead = res.data.message.lastRead;
					self.notificationsData.notificationMessages = res.data.message.notificationMessages.map(function(notification) {
						var mNotification = Object.assign({}, notification);
						mNotification.isNew = new Date(notification.timestamp).getTime() > self.notificationsData.lastRead;
						return mNotification;
					});
					//Configuro Date y agrego la variable isNew.
					self.notificationsData.notificationPanelStatus = self.notificationsData.notificationMessages.some(function(notification) {
						return notification.isNew;
					});
				})
				.catch(function(err) {
					// alertService.showAlert("Error en pedir las notificaciones");
					console.log("Error en pedir las notificaciones");
				});
		});

		// $rootScope.$on("appLifeCycle.onPause", function() {});

		if (!window.$rootScope) {
			window.$rootScope = $rootScope;
		}

		// self.checkNotificationsStatus = function() {
		// 	self.notificationPanelStatus = self.notifications.notificationMessages.map(function(notification) {
		// 		return notification.isNew;
		// 	});
		// };

		var timeToWords = function(time, lang) {
			lang = lang || {
				postfixes: {
					"<": " ",
					">": " "
				},
				60000: {
					singular: "1 min",
					plural: "# min"
				},
				3600000: {
					singular: "1 hora",
					plural: "# horas"
				},
				86400000: {
					singular: "1 día",
					plural: "# dias"
				},
				31540000000: {
					singular: "1 año ",
					plural: "# años"
				}
			};

			var timespans = [60000, 3600000, 86400000, 31540000000];
			var parsedTime = Date.parse(time.replace(/\-00:?00$/, ""));

			if (parsedTime && Date.now) {
				var timeAgo = parsedTime - Date.now();
				var diff = Math.abs(timeAgo);
				var postfix = lang.postfixes[timeAgo < 0 ? "<" : ">"];
				var timespan = timespans[0];

				for (var i = 1; i < timespans.length; i++) {
					if (diff > timespans[i]) {
						timespan = timespans[i];
					}
				}

				var n = Math.round(diff / timespan);

				return postfix + lang[timespan][n > 1 ? "plural" : "singular"].replace("#", n);
			}
		};

		//Get user notifications o devolver las cacheadas
		self.getNotifications = function() {
			if (self.notificationMessages && self.lastRead) {
				var deferred = $q.defer();
				deferred.resolve({ data: { success: true, message: self.notifications } });
				return deferred.promise;
			} else {
				return $http
					.get(BASE_API + "/user_data/notifications")
					.then(function(res) {
						self.notifications = res.data.message;
						//Configuro Date y agrego la variable isNew.
						self.notifications.notificationMessages = self.notifications.notificationMessages
							.map(function(notification) {
								notification.isNew = new Date(notification.timestamp).getTime() > self.notifications.lastRead;
								notification.timestamp = timeToWords(notification.timestamp);
								return notification;
							})
							.reverse();
						return res;
					})
					.catch(function(err) {
						// console.log(err);
					});
			}
		};

		//Set notifications Last Read
		self.getNotificationsLastRead = function() {
			return $http
				.post(BASE_API + "/user_data/notifications/readed")
				.then(function(res) {
					self.lastRead = res.data.message;
					return res;
				})
				.catch(function(err) {
					// console.log(err);
				});
		};

		self.acceptInvitation = function(notificationId) {
			return $http.post(BASE_API + "/user_data/invitation_confirmed", {
				notificationId: notificationId
			});
		};

		self.rejectInvitation = function(notificationId) {
			return $http.post(BASE_API + "/user_data/invitation_rejected", {
				notificationId: notificationId
			});
		};
	}
	angular.module("app").service("notificationHookService", notificationHookService);
})();
