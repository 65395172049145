(function() {
  function localStorageService($q, $window, BUILD_PLATFORM, deferredOperations) {
    var self = this;

    self.getItem = function(key) {
      var deferred = $q.defer();

      if (BUILD_PLATFORM === "Android") {
        deferred.resolve($window.LocalStorage.getItem(key));
        return deferred.promise;
      }

      if (BUILD_PLATFORM === "iOS") {
          return deferredOperations.do(function(operationId) {
            window.webkit.messageHandlers.HostInterface.postMessage('localStorageGetItem("' + key + '", "' + operationId + '")');
          });
      }

      deferred.resolve($window.localStorage.getItem(key));
      return deferred.promise;
    };

    self.removeItem = function(key) {
      var deferred = $q.defer();

      if (BUILD_PLATFORM === "Android") {
        $window.LocalStorage.removeItem(key);
        deferred.resolve();
        return deferred.promise;
      }

      if (BUILD_PLATFORM === "iOS") {
          return deferredOperations.do(function(operationId) {
            window.webkit.messageHandlers.HostInterface.postMessage('localStorageRemoveItem("' + key + '", "' + operationId + '")');
          });
      }

      $window.localStorage.removeItem(key);
      deferred.resolve();
      return deferred.promise;
    };

    self.setItem = function(key, value) {
      var deferred = $q.defer();

      if (BUILD_PLATFORM === "Android") {
        $window.LocalStorage.setItem(key, value);
        deferred.resolve();
        return deferred.promise;
      }

      if (BUILD_PLATFORM === "iOS") {
          return deferredOperations.do(function(operationId) {
            window.webkit.messageHandlers.HostInterface.postMessage('localStorageSetItem("' + key + '", "' + value + '", "' + operationId + '")');
          });
      }

      $window.localStorage.setItem(key, value);
      deferred.resolve();
      return deferred.promise;
    };
  }

  angular.module("app").service("localStorageService", localStorageService);
})();
