(function() {
  function authInterceptor(BASE_API, auth, $location, $q, $injector, __env) {
    var inFlightAuthRequest = null;

    return {
      // automatically attach Authorization header
      request: function(config) {
        var deferred = $q.defer();
        auth.getAccessToken().then(
          function(accessToken) {
            if (config.url.indexOf(BASE_API) === 0 && accessToken) {
              // console.log(config.headers);
              config.headers["x-access-token"] = accessToken;
            }
            deferred.resolve(config);
          },
          function() {
            console.log("Local storage rejection");
          }
        );
        return deferred.promise;
      },

      // If a accessToken was sent back, save it
      response: function(res) {
        if (res.config.url.indexOf(BASE_API) === 0) {
          if (res.data.accessToken) {
            auth.saveAccessToken(res.data.accessToken);
            if (res.config.data.email) {
              auth.saveUserEmail(res.config.data.email);
            }
            if (res.data.refreshToken) {
              auth.saveRefreshToken(res.data.refreshToken);
            }
          }
        }
        return res;
      },

      responseError: function(response) {
        if (response.status == 401) {
          var deferred = $q.defer();

          $q.all([__env.getDeviceData(), auth.getUserEmail(), auth.getRefreshToken()]).then(
            function(result) {
              var deviceData = result[0];
              var userEmail = result[1];
              var refreshToken = result[2];
              if (deviceData.id && userEmail && refreshToken) {
                if (!inFlightAuthRequest) {
                  inflightAuthRequest = $injector.get("$http").post(BASE_API + "/devices/refresh", { refreshToken: refreshToken, email: userEmail, deviceId: deviceData.id });
                }
                return inflightAuthRequest.then(
                  function(r) {
                    inflightAuthRequest = null;
                    if (r.data.accessToken && r.data.refreshToken) {
                      $q.all([auth.saveAccessToken(r.data.accessToken), auth.saveRefreshToken(r.data.refreshToken)]).then(
                        function() {
                          $injector
                            .get("$http")(response.config)
                            .then(
                              function(resp) {
                                deferred.resolve(resp);
                              },
                              function(resp) {
                                deferred.reject(resp);
                              }
                            );
                        },
                        function() {
                          console.log("Local storage rejection");
                        }
                      );
                    } else {
                      inflightAuthRequest = null;
                      deferred.reject({ data: { success: false, message: "Sesión expirada" } });
                      auth.logout();
                    }
                  },
                  function(response) {
                    inflightAuthRequest = null;
                    deferred.reject({ data: { success: false, message: "Sesión expirada" } });
                    auth.logout();
                  }
                );
              }
              auth.logout();
              deferred.resolve({ data: { success: false, message: "Sesión expirada" } });
            },
            function() {
              console.log("Local storage rejection");
            }
          );

          return deferred.promise;
        }

        if (response.status == 403) {
          auth.logout();
          return { data: { success: false, message: "Si sesión expiró, por favor vuelva a ingresar" } };
        }

        return response || $q.when(response);
      }
    };
  }

  angular.module("app").factory("authInterceptor", authInterceptor);
})();
