(function() {
  function SystemEmergencyPanelController(
    $scope,
    $rootScope,
    $state,
    system,
    statusCacheService,
    eventCache,
    ajaxInProgress,
    buildModes,
    $timeout,
    editMode,
    alertService,
    seqService,
    actionBarService,
    $window,
    ymSidenavService,
    $stateParams,
    BUILD_MODE
  ) {
    console.log("SystemEmergencyPanelController created");

    $scope.$on("$destroy", function() {
      console.log("SystemEmergencyPanelController destroyed");
    });

    var self = this;

    self.$scope = $scope;

    self.viewInfo;

    editMode.state.value = false;

    if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
      ymSidenavService.closeYmSidenav();
    }

    self.changeViewInfo = function(){
      self.viewInfo = !self.viewInfo
    };

    self.isMobile = BUILD_MODE == "MOBILE";
    self.isWeb = BUILD_MODE == "WEB";

    self.editModeValue = function() {
      return editMode.state.value;
    };

    self.mostrarSetPanic = [
      true, true, true, true
    ]


    self.sistemaElegido;

    function handleLastTimeReport(res) {
      if (res.data && res.data.success) {
        self.sistemaElegido.lastEventReport = res.data.message;

        if (self.sistemaElegido.lastEventReport > self.sistemaElegido.status.lastTimeUpdate.getTime()) {
          // console.log("PIDO ESTADO");
          self.consultarEstado();
        }
        // console.log("NO PIDO ESTADO");
      } else {
        if (res.status == -1) {
          ajaxInProgress.setState({
            value: false
          });
          // $timeout(function() {
            alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
            // });
          return;
        }
        if (res.status == -2) {
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
        ajaxInProgress.setState({
          value: false
        });
        // $timeout(function() {
        alertService.showAlert("Atención", res.data.message, "OK");
      }
    }

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if(res.data.message.response == "COMANDO INCOMPATIBLE CON EL PANEL ACTUAL"){
        alertService.showAlert("Atención", "Intentaste controlar un panel que cambio de modelo, ingresa nuevamente a tu sistema y se actualizará de forma automatica","OK");        
        $state.go("systemlist", { idSistemaElegido: self.sistemaElegido.id });
        }
        if (ajaxInProgress.state.value == "consultandoEstado") {
          ajaxInProgress.setState({
            value: false
          });
          // self.consultarPanicos();
        } 
        // else if (ajaxInProgress.state.value == "armandoSistema") {
        //   alertService.showToast(res.data.message.response);
        //   ajaxInProgress.setState({
        //     value: false
        //   });
        // } else if (ajaxInProgress.state.value == "desarmandoSistema") {
        //   alertService.showToast(res.data.message.response);
        //   ajaxInProgress.setState({
        //     value: false
        //   });
        // }
        // else if (ajaxInProgress.state.value == "consultandoPanicos") {
        //   self.mostrarSetPanic = res.data.message.pendingPanics;
        //   console.log(self.mostrarSetPanic, "ARRAY QUE VINO DEL SERVER");
        //   ajaxInProgress.setState({
        //     value: false
        //   });
        // }
        else if (ajaxInProgress.state.value == "generandoEmergencia") {
          // console.log("RES DATA", res.data);
          alertService.showToast(res.data.message.response);
          ajaxInProgress.setState({
            value: false
          });
        }
        if (res.data.message.status) {
          self.sistemaElegido.status = statusCacheService.storeStatus(self.sistemaElegido, res.data.message.status);
        }
      } else {
        if (res.status == -1) {
          ajaxInProgress.setState({
            value: false
          });
          // $timeout(function() {
            alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
            // });
          return;
        }
        if (res.status == -2) {
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
        ajaxInProgress.setState({
          value: false
        });
        // $timeout(function() {
        alertService.showAlert("Atención", res.data.message, "OK");
        // });
      }
    }

    self.consultarEstado = function() {
      var ajax = system.consultarEstado(self.sistemaElegido, seqService.seq());
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "consultandoEstado",
        label: "Consultando Estado",
        ajax: ajax
      });
    };

    self.getLastEventReport = function() {
      var ajax = system.getLastEventReport(self.sistemaElegido);
      ajax.then(handleLastTimeReport, handleLastTimeReport);
    };

    self.editarSistema = function() {
      $state.go("systemconfig", { idSistemaElegido: $stateParams.idSistemaElegido });
    };

    self.panicEmergencyGenerator = function(partition){
      // console.log("GENERE PANICO PART: ", partition);
      system.generateEmergency(self.sistemaElegido, 4, partition).then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "generandoEmergencia",
        label: "Generando Emergencia",
      });
    };

    self.medicEmergencyGenerator = function(partition){
      // console.log("GENERE PANICO PART: ", partition);
      system.generateEmergency(self.sistemaElegido, 1, partition).then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "generandoEmergencia",
        label: "Generando Emergencia",
      });
    };

    self.fireEmergencyGenerator = function(partition){
      // console.log("GENERE PANICO PART: ", partition);
      system.generateEmergency(self.sistemaElegido, 3, partition).then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "generandoEmergencia",
        label: "Generando Emergencia",
      });
    };

    self.setEditAction = function() {
      var buttons = [
        {
          label: "Actualizar",
          aria: "Actualizar",
          icon: "refresh",
          onClickHandler: function() {
            self.consultarEstado();
          }
        }
      ];

      buttons.push({
        label: "Configurar",
        aria: "Configurar",
        icon: "settings",
        onClickHandler: function() {
          self.editarSistema();
        }
      });

      actionBarService.setActionBarActions("SystemEmergencyPanelController", buttons);
    };

  //   // Tareas para cuando el controller se instancia
  //   self.setEditAction();

    // self.changePanicState = function(particion, state){
    //   self.mostrarSetPanic[particion - 1] = state;
    // }

    function handleLastUpdate(res){
      if (res.data && res.data.success) {
        self.lastUpdate = res.data.message.lastUpdate;
        self.lastEvent = res.data.message.lastEvent;

        if (self.lastEvent && new Date(self.lastEvent).getTime() > new Date(self.lastUpdate).getTime()) {
          // console.log("PIDO PROGRAMACION", new Date(self.lastEvent).getTime() - new Date(self.lastUpdate).getTime());
          return $rootScope.pedirProgramacionDesdeSystem();
        }
        // console.log("NO PIDO PROGRAMACION", new Date(self.lastEvent).getTime() - new Date(self.lastUpdate).getTime());
      } else {
        if (res.status == -1) {
          ajaxInProgress.setState({
            value: false
          });
          // $timeout(function() {
            alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
            // });
          return;
        }
        if (res.status == -2) {
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
        ajaxInProgress.setState({
          value: false
        });
        // $timeout(function() {
        alertService.showAlert("Atención", res.data.message, "OK");
      }
    }

    self.getLastUpdate = function() {
      var ajax = system.getLastUpdate(self.sistemaElegido);
      ajax.then(handleLastUpdate, handleLastUpdate);
    };

    system.getSystem($stateParams.idSistemaElegido)
    .then(function(sistemaElegido) {
			// $rootScope.focusingTab("/system/emergencypanel");
      self.sistemaElegido = sistemaElegido;
      //Seteo el timeout de los comandos en función de lo que devuelva
      system.setCommandTimeout(sistemaElegido.id);
      if (self.sistemaElegido.estadoDeCarga === "verificado") {
        if (self.sistemaElegido.programation.data) {
          self.partitionList = self.sistemaElegido.programation.data.partitions;
          self.cantidad = self.partitionList.length > 1;
          // console.log(self.partitionList, "PARTITION LIST");
        }
        self.setEditAction();
        self.getLastUpdate();

        // self.sistemaElegido.status = self.sistemaElegido.status || statusCacheService.getStatus(self.sistemaElegido);

        // if (!self.sistemaElegido.status) {
        //   return self.consultarEstado();
        // }

        // self.getLastEventReport();
        //TODO: HACER EL REFRESCO DEL ARRAY
      }
    });
  }

  angular.module("app").controller("SystemEmergencyPanelController", SystemEmergencyPanelController);
})();
