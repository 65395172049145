(function(angular) {
	function AutomationItemController(
		$scope,
		$element,
		ajaxInProgress,
		customFormat,
		editMode,
		automationActions
	) {
		var ctrl = this;

		// ctrl.$scope = $scope;
		ctrl.ajaxInProgress = ajaxInProgress;
		ctrl.editMode = editMode;
		ctrl.partitionsEnabled;

		ctrl.$onInit = function() {
			ctrl.existenOutputs = ctrl.outputs.some(function(output, idx) {
				ctrl.indexOutput = idx;
				return output.enabled == true;
			});
			ctrl.outputsCorrected = ctrl.outputs.map(function(output){
				if(output.number > 8){
					output.editedName = output.name || "PGM-W " + (output.number - 8);
					output.type = "Salida inalámbrica "
					output.showedNumber = output.number -8
				} else{
					output.editedName = output.name || "PGM  " + (output.number);
					output.type = "Salida cableada "
					output.showedNumber = output.number
				}
				return output
			})

			ctrl.partitionsEnabled =  ctrl.partitions.map(function(partition){
				if(partition.enabled) 
				{
					return partition.number
				}
			});
			if (ctrl.existenOutputs) {
				ctrl.actions = automationActions;
			} else {
				ctrl.actions = automationActions.filter(function(action) {
					var actionState = action.value !== 3 && action.value !== 4;
					return actionState;
				});
			}

			ctrl.salidas = [1, 2, 3, 4];

			ctrl.output = {};

			ctrl.days = [
				{
					value: 0,
					name: "L"
				},
				{
					value: 1,
					name: "M"
				},
				{
					value: 2,
					name: "M"
				},
				{
					value: 3,
					name: "J"
				},
				{
					value: 4,
					name: "V"
				},
				{
					value: 5,
					name: "S"
				},
				{
					value: 6,
					name: "D"
				}
			];

			ctrl.days.forEach(function(day, index) {
				day.enabled = ctrl.automation.days[index];
			});

			// ctrl.automation.edited.action = ctrl.actions.find(function(constantAction) {
			//   return constantAction.value === ctrl.automation.action;
			// });

			// console.log("Estas son las Outputs", ctrl.outputsCorrected);
		};

		// ctrl.$onChanges = function(changesObj) {
		// 	console.log("AutomationItem $onChanges");
		// 	// ctrl.parseStatus(ctrl.status);
		// };

		// EDITO LAS OPTION EN FUNCION DE LA ACTION SELECCIONADA.
		ctrl.onActionChange = function() {
			if (
				ctrl.automation.edited.action == 3 ||
				ctrl.automation.edited.action == 4
			) {
				ctrl.automation.edited.option = ctrl.outputsCorrected[ctrl.indexOutput].number;
				// console.log("Options del Action Salida", ctrl.outputsCorrected[ctrl.indexOutput].number);
			} 
			if(ctrl.automation.edited.action == 1 || ctrl.automation.edited.action == 2 || ctrl.automation.edited.action == 5) {
				ctrl.automation.edited.option = ctrl.partitionsEnabled[0];
				// console.log("Options del Action Armados", ctrl.partitionsEnabled);
			}
		};

		ctrl.getDisplayableAction = function() {
			return es6TemplateStrings(
				ctrl.actions.find(function(item) {
					return item.value === ctrl.automation.action;
				}).name,
				ctrl.automation
			);
		};

		ctrl.getFirstLine = function() {
			return es6TemplateStrings(
				ctrl.actions.find(function(item) {
					return item.value === ctrl.automation.action;
				}).name,
				ctrl.automation
			).split("\n")[0];
		};

		ctrl.getSecondLine = function() {
			// console.log("Se llamó a getSecondLine");
			var result;

			if (ctrl.automation.action == 3 || ctrl.automation.action == 4) {
				// console.log("la automation que pidio Second Line es: ", ctrl.automation.action);
				var output = ctrl.outputsCorrected.find(function(output) {
					return output.number == ctrl.automation.option;
				});

				if(output && output.number > 8){
					output.editedName = output.name || "PGM-W " + (output.number - 8);
					// output.type = "Salida inalámbrica "
				} else{
					output.editedName = output.name || "PGM  " + (output.number);
					// output.type = "Salida cableada "
				}

				result = es6TemplateStrings(
					ctrl.actions.find(function(item) {
						return item.value === ctrl.automation.action;
					}).name,
					{
						// option: output.name
						// 	? output.name
						// 	: "Salida " + output.number
						option: output.editedName
					}
				).split("\n");
			} else {
				// console.log("la automation que pidio Second Line es: ", ctrl.automation.action);
				var partition = ctrl.partitions.find(function(partition) {
					return partition.number == ctrl.automation.option;
				});

				result = es6TemplateStrings(
					ctrl.actions.find(function(item) {
						return item.value === ctrl.automation.action;
					}).name,
					{
						option: partition.editedName
					}
				).split("\n");
			}
			// console.log("Esta es la Second Line Result: ", result);
			return result.length > 1 ? result[1] : "No Seleccionado";
		};

		ctrl.getDisplayableHour = function() {
			return (
				customFormat.format(2, ctrl.automation.hours) +
				":" +
				customFormat.format(2, ctrl.automation.minutes)
			);
		};

		ctrl.hourPickerChangeHandler = function(data) {
			ctrl.automation.edited.hours = data.hours;
			ctrl.automation.edited.minutes = data.minutes;

		};

		ctrl.editedDays = function() {
			return ctrl.days.map(function(day, index) {
				var toReturn = Object.assign(day);
				toReturn.enabled = ctrl.automation.edited.days[index];
				return toReturn;
			});
		};

		ctrl.editableDayChangeHandler = function(day) {
			// ctrl.automation.edited.days.push(data);
			ctrl.automation.edited.days[day.value] = day.enabled;
		};

		ctrl.isOptionVisible = function() {
			if (ctrl.automation.edited.action === 3) {
				return true;
			}
			if (ctrl.automation.edited.action === 4) {
				return true;
			}
			return false;
		};

		ctrl.isPartitionOptionVisible = function() {
			if (ctrl.automation.edited.action === 1) {
				return true;
			}
			if (ctrl.automation.edited.action === 2) {
				return true;
			}
			if (ctrl.automation.edited.action === 5) {
				return true;
			}
			return false;
		};

		ctrl.removeAutomation = function() {
			ctrl.automation.edited = {
				hours: 48,
				minutes: 48,
				enabled: false,
				days: [false, false, false, false, false, true, true],
				action: 3,
				option: 0
			};
		};
	}

	angular.module("app").component("automation", {
		templateUrl: "components/AutomationItem/automationItem.html",
		controller: AutomationItemController,
		bindings: {
			automation: "<",
			outputs: "<",
			partitions: "<"
			// onRemoveAutomation: "="
		}
	});
})(window.angular);
