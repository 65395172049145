(function() {
  function routerService($transitions) {
    var self = this;

    var lastState = "";

    self.setLastState = function(state) {
      lastState = state;
    };

    self.getLastState = function() {
      return lastState;
    };

    // Prevent invalid transitions to login

    var validLoginTransitionsFromStates = ["account_config", "deviceslist", "forgot", "register", "register-rejected", "reset","resetMobile", "register-confirmed","registerVerify"];

    $transitions.onStart({ to: "login" }, function(transition) {
      console.log("Starting transition from " + transition.from().name + " to " + transition.to().name + " options: ", transition.options());
      var options = transition.options();

      if (options.custom && options.custom.forced) {
        return true;
      }

      if (transition.to().name === "login" && !validLoginTransitionsFromStates.includes(transition.from().name)) {
        return false;
      }
    });
    
    var invalidControlPanelTransitionsFromStates = ['systemlist', 'account_config', 'notifications'];

    $transitions.onStart({ to: "controlpanel" }, function(transition) {
      console.log("Starting transition from " + transition.from().name + " to " + transition.to().name + " options: ", transition.options());
      var options = transition.options();

      if (options.custom && options.custom.forced) {
        return true;
      }

      if (transition.to().name === "controlpanel" && invalidControlPanelTransitionsFromStates.includes(transition.from().name)) {
        return false;
      }
      
    });

    $transitions.onSuccess({}, function(transition) {
      // console.log("Successful Transition from " + transition.from().name + " to " + transition.to().name + " options: ", transition.options());
      self.setLastState(transition.from().name);
    });
  }

  angular.module("app").service("routerService", routerService);
})();
