(function () {
  var tabArray = [];

  function SystemController(
    $scope,
    $state,
    $window,
    system,
    ajaxInProgress,
    buildModes,
    $rootScope,
    editMode,
    BUILD_MODE,
    alertService,
    seqService,
    actionBarService,
    ymSidenavService,
    $stateParams,
    $transitions,
    $timeout,
    userDataService,
    alarmPanelBrands
  ) {
    console.log("SystemController created");

    $scope.$on("$destroy", function () {
      console.log("SystemController destroyed");
    });

    var self = this;
    self.$scope = $scope;
    self.$state = $state;

    editMode.state.value = false;

    self.editModeValue = function () {
      return editMode.state.value;
    };

    if (
      BUILD_MODE == "MOBILE" ||
      (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)
    ) {
      ymSidenavService.closeYmSidenav();
    }

    self.tabs = [];

    self.selectedTab = "";

    self.modoFunc;
    self.isNewVersion = true;

    self.actionBarActions = actionBarService.actionBarActions;

    self.actionBarLeftButton = {
      onClickHandler: function () {
        $state.go("systemlist");
      },
      disabled: function () {
        return self.editModeValue();
      },
      icon: "chevron_left",
      aria: "home",
    };

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value == "queryCommunicatorProgramation") {
          self.programacionComunicador = res.data.message.response;
          self.modoFunc = res.data.message.response.modoFunc;
          self.isYoMonitoreo = res.data.message.response.isYoMonitoreo;

          if (self.modoFunc == "busDSC") {
            self.isNewVersion = false;
            return self.pedirProgramacionPanel(self.modoFunc);
          }

          if (self.isYoMonitoreo) {
            self.isNewVersion = false;
            return self.pedirProgramacionPanel(
              self.modoFunc,
              self.isYoMonitoreo
            );
          }

          self.pedirVersionDelPanel();
        } else if (ajaxInProgress.state.value == "querySystemVersion") {
          // ajaxInProgress.setState({
          //   value: false
          // });

          self.isNewVersion = res.data.message.response.isNewVersion;
          self.isYoMonitoreo = res.data.message.response.isYoMonitoreo;

          self.pedirProgramacionPanel(self.modoFunc, self.isYoMonitoreo);
        } else if (ajaxInProgress.state.value == "querySystemProgramation") {
          console.log("Esta es la programación del panel :D -> ");
          ajaxInProgress.setState({
            value: false,
          });
          // console.log("Esta es la programación del panel :D -> ");
          self.setSystemProgramation(
            self.procesarProgramacion(
              res.data.message.response,
              self.sistemaElegido.programation.data
            )
          );
          // ajaxInProgress.state.value = false;
        } else if (ajaxInProgress.state.value == "setSystemProgramation") {
          ajaxInProgress.setState({
            value: false,
          });

          self.sistemaElegido.programation = res.data.message.programation;

          $state.go("systemlisttocontrol", {
            idToControl: self.sistemaElegido.id,
          });
        }
      } else {
        if (res.status == -1) {
          alertService.showAlert(
            "Atención",
            "No se pudo completar la acción, vuelva a intentarlo.",
            "OK"
          );
          // ajaxInProgress.state.value = false;
          ajaxInProgress.setState({
            value: false,
          });
          return;
        }
        alertService.showAlert("Atención", res.data.message, "OK");
        // ajaxInProgress.state.value = false;
        ajaxInProgress.setState({
          value: false,
        });
      }
    }

    self.openMenu = function ($mdMenu) {
      $mdMenu.open();
    };

    self.pedirProgramacion = function () {
      // if (!ajaxInProgress.state.value) {
      // ajaxInProgress.state.value = "querySystemProgramation";
      ajaxInProgress.setState({
        value: "queryCommunicatorProgramation",
        label: "Obteniendo programación",
        cancelable: false,
      });
      system
        .queryCommunicatorProgramation(self.sistemaElegido, seqService.seq())
        .then(handleRequest, handleRequest);
      // }
    };

    self.pedirProgramacionPanel = function (modoFunc, isYoMonitoreo) {
      // if (!ajaxInProgress.state.value) {
      // ajaxInProgress.state.value = "querySystemProgramation";
      ajaxInProgress.setState({
        value: "querySystemProgramation",
        label: "Procesando programación",
        cancelable: false,
      });
      system
        .querySystemProgramation(
          self.sistemaElegido,
          seqService.seq(),
          modoFunc,
          self.isYoMonitoreo
        )
        .then(handleRequest, handleRequest);
      // }
    };

    self.startProgrammation = function () {
      ajaxInProgress.setState({
        value: "startSystemProgrammation",
        label: "Ingresando en programación",
        cancelable: false,
      });

      system
        .sendStartProgramation(self.sistemaElegido, seqService.seq())
        .then(handleRequest, handleRequest);
    };

    self.exitProgrammation = function () {
      ajaxInProgress.setState({
        value: "endSystemProgrammation",
        label: "Saliendo de programación",
        cancelable: false,
      });

      system
        .sendExitProgramation(self.sistemaElegido, seqService.seq())
        .then(handleRequest, handleRequest);
    };

    self.pedirVersionDelPanel = function (modoFunc) {
      // if (!ajaxInProgress.state.value) {
      // ajaxInProgress.state.value = "querySystemProgramation";
      ajaxInProgress.setState({
        value: "querySystemVersion",
        label: "Obteniendo Versión",
        cancelable: false,
      });
      system
        .querySystemVersion(self.sistemaElegido, seqService.seq())
        .then(handleRequest, handleRequest);
      // }
    };

    self.procesarProgramacion = function (
      newProgramationData,
      oldProgramationData
    ) {
      console.log(
        "SYSTEM_CONTROLLER - ",
        newProgramationData,
        oldProgramationData
      );
      var brand = alarmPanelBrands.GARNET;
      var programationData = {};

      if (self.programacionComunicador.modoFunc == "busC485") {
        brand = alarmPanelBrands.GARNET;
        programationData.zonaHoraria =
          self.programacionComunicador.zonaHoraria.husoHorario;
        programationData.idHusoHorario =
          self.programacionComunicador.zonaHoraria.idHusoHorario || "";
      } else if (self.programacionComunicador.modoFunc == "busDSC") {
        brand = alarmPanelBrands.DSC;
        programationData.zonaHoraria =
          self.programacionComunicador.zonaHoraria.husoHorario;
        programationData.idHusoHorario =
          self.programacionComunicador.zonaHoraria.idHusoHorario || "";
      }

      if (
        self.programacionComunicador.modoFunc == "busC485" &&
        self.isYoMonitoreo
      ) {
        brand = alarmPanelBrands.YOMO;
        programationData.zonaHoraria =
          self.programacionComunicador.zonaHoraria.husoHorario;
        programationData.idHusoHorario =
          self.programacionComunicador.zonaHoraria.idHusoHorario || "";
      }

      programationData = Object.assign(programationData, {
        alarmPanel: {
          brand: brand,
          isNewVersion: self.isNewVersion,
        },
        zones: [],
        outputs: [],
        automations: [],
        partitions: [],
      });

      newProgramationData.zones.forEach(function (newZone) {
        var oldZone =
          oldProgramationData &&
          oldProgramationData.zones.find(function (zone) {
            return zone.number === newZone.number;
          });

        programationData.zones.push({
          name: oldZone && oldZone.name,
          number: newZone.number,
          icon: (oldZone && oldZone.icon) || "0",
          configuration: newZone.configuration,
          attributes: newZone.attributes,
          enabled: newZone.enabled,
          isPresentZone: newZone.isPresentZone,
        });
      });

      newProgramationData.partitions.forEach(function (newPartition) {
        if (newPartition) {
          var oldPartition =
            oldProgramationData &&
            oldProgramationData.partitions &&
            oldProgramationData.partitions.find(function (partition) {
              return partition.number == newPartition.number;
            });
          programationData.partitions.push({
            name: oldPartition && oldPartition.name,
            number: newPartition.number,
            enabled: newPartition.enabled,
          });
        }
      });

      newProgramationData.outputs.forEach(function (newOutput) {
        var oldOutput =
          oldProgramationData &&
          oldProgramationData.outputs.find(function (output) {
            return output.number === newOutput.number;
          });

        programationData.outputs.push({
          name: oldOutput && oldOutput.name,
          number: newOutput.number,
          icon: (oldOutput && oldOutput.icon) || "0",
          configuration: newOutput.configuration,
          attributes: newOutput.attributes,
          enabled: newOutput.enabled,
        });
      });

      newProgramationData.automations.forEach(function (newAutomation) {
        // var oldAutomation =
        //   oldProgramationData &&
        //   oldProgramationData.automations.find(function(automation) {
        //     return automation.number === newAutomation.number;
        //   });

        programationData.automations.push({
          // icon: (oldAutomation && oldAutomation.icon) || "default_icon",
          // name: oldAutomation && oldAutomation.name,
          number: newAutomation.number,
          hours: newAutomation.hours,
          minutes: newAutomation.minutes,
          action: newAutomation.action,
          option: newAutomation.option,
          days: newAutomation.days,
          enabled: newAutomation.enabled,
        });
      });

      console.log("SYSTEM_CONTROLLER: PROGRAMATION DATA", programationData);
      return programationData;
    };

    self.setSystemProgramation = function (programationData, redirect) {
      // if (!ajaxInProgress.state.value) {
      // ajaxInProgress.state.value = "setSystemProgramation";
      self.toRedirect = redirect;
      ajaxInProgress.setState({
        value: "setSystemProgramation",
      });
      system
        .setSystemProgramation(self.sistemaElegido, programationData)
        .then(handleRequest, handleRequest);
      // }
    };

    self.programacionAlDia = function () {
      ajaxInProgress.setState({
        value: false,
      });

      var currentState = $state.current.name;

      // console.log('SYSTEM_CONTROLLER: Current State dentro de prog al dia.', currentState);

      if (currentState) {
        if (currentState.search(/system\./) > -1) {
          // console.log('no se que pasa pero hago return');
          // Si el estado actual contiene un estado hijo (la sintaxis de punto), vuelvo sin redirigir
          return;
        }
      }
      // Sino evalúo donde redirigir
      if (
        self.sistemaElegido.userPermissions.atributos.puedeArmar ||
        self.sistemaElegido.userPermissions.atributos.puedeDesarmar
      ) {
        self.selectedTab = "/system/control";
        $state.go("system.control", null, { location: "replace" });
        return;
      }
      if (self.sistemaElegido.userPermissions.atributos.puedeVerCamaras) {
        self.selectedTab = "/system/video";
        $state.go("system.video", null, { location: "replace" });
        return;
      }
      if (
        // self.sistemaElegido.userPermissions.userType == "1" &&
        self.sistemaElegido.userPermissions.eventos.recibeAlarmas ||
        self.sistemaElegido.userPermissions.eventos.recibeAperturasYCierres ||
        self.sistemaElegido.userPermissions.eventos.recibeEventosDeEnergia ||
        self.sistemaElegido.userPermissions.eventos.recibeOtrosEventos
      ) {
        self.selectedTab = "/system/history";
        $state.go("system.history", null, { location: "replace" });
        return;
      }
      if (
        self.sistemaElegido.userPermissions.atributos.puedeInteractuarConSalidas
      ) {
        self.selectedTab = "/system/outputs";
        $state.go("system.outputs", null, { location: "replace" });
        return;
      }
      if (
        self.sistemaElegido.owner &&
        typeof self.sistemaElegido.owner.userType == "number" &&
        self.sistemaElegido.owner.userType == 0
      ) {
        self.selectedTab = "/system/users";
        $state.go("system.users", null, { location: "replace" });
        return;
      }
      if (self.sistemaElegido.userPermissions.atributos.puedeInhibirZonas) {
        self.selectedTab = "/system/zones";
        $state.go("system.zones", null, { location: "replace" });
        return;
      }
      if (
        self.sistemaElegido.userPermissions.atributos.puedeInteractuarConSirena
      ) {
        self.selectedTab = "/system/bell";
        $state.go("system.bell");
        return;
      }
      if (self.sistemaElegido.userPermissions.configuraciones.automations) {
        self.selectedTab = "/system/automation";
        $state.go("system.automation", null, { location: "replace" });
        return;
      }
      $state.go("system.unauthorized", null, { location: "replace" });
      // console.log('SYSTEM_CONTROLLER: llamo a focus tab desde programación al día.')
      self.focusTab("/system/" + self.toRedirect);
    };

    self.editarSistema = function () {
      $state.go("systemconfig", {
        idSistemaElegido: $stateParams.idSistemaElegido,
      });
    };

    self.setEditAction = function () {
      var buttons = [
        {
          label: "Configurar",
          aria: "Configurar",
          icon: "settings",
          onClickHandler: function () {
            self.editarSistema();
          },
        },
      ];

      actionBarService.setActionBarActions("SystemController", buttons);
    };

    self.setEditAction();

    if ($stateParams.idSistemaElegido != null) {
      self.pedirForzosamente = false;
    }

    self.pedirForzosamente = true;
    system
      .getSystem($stateParams.idSistemaElegido, self.pedirForzosamente)
      .then(
        function (sistemaElegido) {
          // userData
          self.sistemaElegido = sistemaElegido;

          if (!self.sistemaElegido.programation) {
            ajaxInProgress.setState({
              value: false,
            });
            return self.$state.go("systemconfig", {
              idSistemaElegido: $stateParams.idSistemaElegido,
            });
          }

          // console.log("SYSTEM_CONTROLLER: SISTEMA ELEGIDO", sistemaElegido);
          self.isNewVersion = self.sistemaElegido.programation.data
            ? self.sistemaElegido.programation.data.alarmPanel.isNewVersion
            : false;

          tabArray = [];

          // Verifico los permisos del usuario para saber que pantallas mostrar.

          self.userData = userDataService.getUserData();

          self.tabName = "Control";

          self.onTabSelected = function (tabId) {
            //you can add some loading before rendering
            // console.log(tabId);
            self.tabId = tabId;
          };

          self.isGarnet = true;

          if (
            self.sistemaElegido.programation.data &&
            self.sistemaElegido.programation.data.alarmPanel.brand == 1
          ) {
            self.isGarnet = false;
          } else {
            self.isGarnet = true;
          }

          if (
            self.sistemaElegido.programation.data &&
            self.sistemaElegido.programation.data.alarmPanel.brand == 2
          ) {
            self.isYoMonitoreo = true;
          } else {
            self.isYoMonitoreo = false;
          }

          if (
            self.isNewVersion &&
            (self.sistemaElegido.userPermissions.atributos.puedeArmar ||
              self.sistemaElegido.userPermissions.atributos.puedeDesarmar)
          ) {
            tabArray.push({
              mdNavSref: "system.antipanic",
              name: "/system/antipanic",
              iconName: "watch_later",
              title: "Aviso de Llegada",
            });
          }
          if (
            self.sistemaElegido.userPermissions.atributos.puedeArmar ||
            self.sistemaElegido.userPermissions.atributos.puedeDesarmar
          ) {
            tabArray.push({
              mdNavSref: "system.control",
              name: "/system/control",
              iconName: "play_arrow",
              title: "Control",
              selected: true,
            });
          }
          if (
            (self.sistemaElegido.userPermissions.eventos.recibeAlarmas ||
              self.sistemaElegido.userPermissions.eventos
                .recibeAperturasYCierres ||
              self.sistemaElegido.userPermissions.eventos
                .recibeEventosDeEnergia ||
              self.sistemaElegido.userPermissions.eventos.recibeOtrosEventos) &&
            self.sistemaElegido.programation.data
          ) {
            tabArray.push({
              mdNavSref: "system.history",
              name: "/system/history",
              iconName: "event_note",
              title: "Eventos",
            });
          }
          if (
            (self.sistemaElegido.userPermissions.atributos.puedeArmar ||
              self.sistemaElegido.userPermissions.atributos.puedeDesarmar) &&
            self.isGarnet &&
            !self.isYoMonitoreo
          ) {
            tabArray.push({
              mdNavSref: "system.emergencypanel",
              name: "/system/emergencypanel",
              iconName: "security",
              title: "Emergencias",
            });
          }
          if (
            self.sistemaElegido.userPermissions.atributos
              .puedeInteractuarConSalidas &&
            self.sistemaElegido.programation.data &&
            self.isGarnet
          ) {
            tabArray.push({
              mdNavSref: "system.outputs",
              name: "/system/outputs",
              iconName: "power",
              title: "Salidas Inteligentes",
            });
          }
          if (self.sistemaElegido.userPermissions.atributos.puedeInhibirZonas) {
            tabArray.push({
              mdNavSref: "system.zones",
              name: "/system/zones",
              iconName: "vibration",
              title: "Zonas",
            });
          }
          if (
            self.sistemaElegido.userPermissions.atributos
              .puedeInteractuarConSirena &&
            self.sistemaElegido.programation.data &&
            self.isGarnet
          ) {
            tabArray.push({
              mdNavSref: "system.bell",
              name: "/system/bell",
              iconName: "volume_up",
              title: "Sirena",
            });
          }
          if (
            self.isNewVersion &&
            self.sistemaElegido.userPermissions.configuraciones.automations &&
            self.sistemaElegido.programation.data &&
            self.isGarnet
          ) {
            tabArray.push({
              mdNavSref: "system.automation",
              name: "/system/automation",
              iconName: "schedule",
              title: "Automatización",
            });
          }
          if (self.sistemaElegido.userPermissions.atributos.puedeVerCamaras) {
            tabArray.push({
              mdNavSref: "system.video",
              name: "/system/video",
              iconName: "videocam",
              title: "Video",
            });
          }
          if (
            self.sistemaElegido.owner &&
            typeof self.sistemaElegido.owner.userType == "number" &&
            self.sistemaElegido.owner.userType == 0
          ) {
            tabArray.push({
              mdNavSref: "system.users",
              name: "/system/users",
              iconName: "people",
              title: "Comunidad",
            });
          }
          //Si el tabArray está vacío indico que no te otorgaron permisos para accionar el sistema.
          if (tabArray.length == 0) {
            tabArray.push({
              mdNavSref: "system.unauthorized",
              name: "/system/unauthorized",
              iconName: "people",
              title: "Comunidad Aviso",
            });
          }

          // al volver al esquema con códigos no es necesaria la pestaña instalador
          // if(self.sistemaElegido.owner.email == self.userData.email){
          //   tabArray.push({
          //     mdNavSref: "system.installer",
          //     name: "/system/installer",
          //     iconName: "settings_remote",
          //     title: "Instalador"
          //   });
          // }

          self.tabs = tabArray;

          $timeout(function () {
            // self.selectedIndex = 1;
            self.selectedIndex = self.tabs.indexOf(
              self.tabs.find(function (tab) {
                return tab.name == system.getSelectedIndex();
              })
            );
            // console.log("SYSTEM_CONTROLLER: SELECTED TAB POST TIME", self.selectedIndex);
          }, 100);

          if (self.selectedIndex == -1) {
            self.selectedIndex = self.tabs.indexOf(
              self.tabs.find(function (tab) {
                return tab.name == "/system/control";
              })
            );
            system.setSelectedIndex("/system/control");
          }

          // console.log("SYSTEM_CONTROLLER: FIRST TAB INDEX", self.selectedIndex);

          if (self.selectedIndex == -1) {
            self.selectedIndex = self.tabs.indexOf(
              self.tabs.find(function (tab) {
                return tab.name == "/system/history";
              })
            );
            system.setSelectedIndex("/system/history");
          }
          if (self.selectedIndex == -1) {
            self.selectedIndex = self.tabs.indexOf(
              self.tabs.find(function (tab) {
                return tab.name == "/system/video";
              })
            );
            system.setSelectedIndex("/system/video");
          }
          if (self.selectedIndex == -1) {
            self.selectedIndex = self.tabs.indexOf(
              self.tabs.find(function (tab) {
                return tab.name == "/system/unauthorized";
              })
            );
            system.setSelectedIndex("/system/unauthorized");
          }

          // console.log('SYSTEM_CONTROLLER: ahora ya tengo index', self.selectedIndex)

          $rootScope.pedirProgramacionDesdeSystem = function () {
            // console.log("SYSTEM_CONTROLLER: Pido programación desde System");
            self.pedirProgramacion();
          };

          // console.log("SYSTEM_CONTROLLER: estoy por declarar el RootScope");
          $rootScope.focusingTab = function (name, time) {
            // console.log('SYSTEM_CONTROLLER: llamo a focus desde otra pantalla', name, time);
            self.focusTab(name, time);
          };

          //Seteo los tabs que va a tener disponible la función swipe
          self.focusTab = function (name, time) {
            self.redirect = name;
            // console.log("SYSTEM_CONTROLLER: FOCUS TAB", name);

            self.thisTabIndex = self.tabs.indexOf(
              self.tabs.find(function (tab) {
                return tab.name == name;
              })
            );

            // console.log("SYSTEM_CONTROLLER: index del tab al que debo hacer focus", self.thisTabIndex)

            if (self.thisTabIndex != undefined && self.thisTabIndex != -1) {
              // if(self.tabs[self.thisTabIndex + 1] != -1){
              $timeout(function () {
                self.selectedIndex = self.thisTabIndex;
                // console.log("SYSTEM_CONTROLLER: ME LLEGO: ", self.thisTabIndex);
              }, time || 100);
              // $state.go(self.tabs[self.thisTabIndex + 1].mdNavSref, null, { location: "replace" });
              // }
            } else {
              $timeout(function () {
                self.selectedIndex = 1;
                // console.log("SYSTEM_CONTROLLER: El tab al que debía redirigir, ya no forma parte de los tabs, por lo tanto lo redirigo al tab 1", self.selectedIndex);
              }, time || 100);
            }
          };

          //Seteo los tabs que va a tener disponible la función swipe
          self.swipeLeft = function () {
            // console.log("SYSTEM_CONTROLLER Left swipe selected tab: ", self.selectedTab);
            self.thisTabIndex = self.tabs.indexOf(
              self.tabs.find(function (tab) {
                return tab.name == self.selectedTab;
              })
            );
            // console.log("SYSTEM_CONTROLLER Left swipe this tab index: ", self.thisTabIndex);
            if (
              self.tabs &&
              self.tabs[self.thisTabIndex + 1] != -1 &&
              self.thisTabIndex != -1
            ) {
              self.selectedIndex = self.thisTabIndex + 1;
              if (self.tabs[self.thisTabIndex + 1]) {
                $state.go(self.tabs[self.thisTabIndex + 1].mdNavSref, null, {
                  location: "replace",
                });
              }
            } else {
              // console.log("SYSTEM_CONTROLLER Right swipe tabs: ", self.tabs);
              self.historyTabIndex = self.tabs.indexOf(
                self.tabs.find(function (tab) {
                  return tab.name == "/system/history";
                })
              );
              // console.log("SYSTEM_CONTROLLER Right swipe this tab index: ", self.historyTabIndex);
              if (self.historyTabIndex + 1 > -1 && self.thisTabIndex != 0) {
                self.selectedIndex = self.historyTabIndex + 1;
                // console.log("SYSTEM_CONTROLLER Right swipe state go: ", self.tabs[self.historyTabIndex - 1].mdNavSref);
                $state.go(self.tabs[self.historyTabIndex + 1].mdNavSref, null, {
                  location: "replace",
                });
              }
            }
          };

          self.swipeRight = function () {
            // console.log("SYSTEM_CONTROLLER Right swipe selected tab: ", self.selectedTab);
            self.thisTabIndex = self.tabs.indexOf(
              self.tabs.find(function (tab) {
                return tab.name == self.selectedTab;
              })
            );
            // console.log("SYSTEM_CONTROLLER Right swipe this tab index: ", self.thisTabIndex);
            if (self.thisTabIndex - 1 > -1) {
              self.selectedIndex = self.thisTabIndex - 1;
              $state.go(self.tabs[self.thisTabIndex - 1].mdNavSref, null, {
                location: "replace",
              });
            } else {
              // console.log("SYSTEM_CONTROLLER Right swipe tabs: ", self.tabs);
              self.historyTabIndex = self.tabs.indexOf(
                self.tabs.find(function (tab) {
                  return tab.name == "/system/history";
                })
              );
              // console.log("SYSTEM_CONTROLLER Right swipe this tab index: ", self.historyTabIndex);
              if (self.historyTabIndex - 1 > -1 && self.thisTabIndex != 0) {
                self.selectedIndex = self.historyTabIndex - 1;
                // console.log("SYSTEM_CONTROLLER Right swipe state go: ", self.tabs[self.historyTabIndex - 1].mdNavSref);
                $state.go(self.tabs[self.historyTabIndex - 1].mdNavSref, null, {
                  location: "replace",
                });
              }
            }
          };

          self.isControl = function () {
            if (self.currentState == "system.control") {
              return 2;
            }
          };

          // $rootScope.changeTab = function(tab_){
          //   $rootScope.selectedIndex = tab_;
          // }

          // $scope.changeTab(2);

          $transitions.onSuccess({}, function (transition) {
            if (transition.to().name.search(/system\./) > -1) {
              // Si el estado actual contiene un estado hijo seteo la pestaña elegida correspondiente
              self.selectedTab = "/system" + transition.to().url;
              return;
            }
          });

          if (
            BUILD_MODE == "MOBILE" ||
            (BUILD_MODE == "WEB" && $window.innerWidth < 960)
          ) {
            ymSidenavService.closeYmSidenav();
          }

          if (self.sistemaElegido.user) {
            // console.log('SYSTEM_CONTROLLER: salte a pedir prog al dia?');
            return self.programacionAlDia();
          }

          if (!self.sistemaElegido.programation.lastUpdate) {
            // console.log("SYSTEM_CONTROLLER: Programation is undefined");
            return self.pedirProgramacion();
          }

          if (
            self.lastEvent &&
            self.lastEvent > self.sistemaElegido.programation.lastUpdate
          ) {
            // console.log("SYSTEM_CONTROLLER: Hay un evento de programación más nuevo del último update");
            return self.pedirProgramacion();
          }

          self.programacionAlDia();
        },
        function (error) {
          alertService.showAlert("Atención", error, "OK");
        }
      );
  }
  angular.module("app").controller("SystemController", SystemController);
})();
