(function(angular) {
  function SystemListItemController($scope, $element, ajaxInProgress) {
    var ctrl = this;
    // ctrl.$scope = $scope;
    ctrl.ajaxInProgress = ajaxInProgress;

    ctrl.$onInit = function() {
      // ctrl.zone.editedName = ctrl.zone.name || "Cámara " + ctrl.zone.number;
    };

    ctrl.editar = function(){
      ctrl.onEditarSistema(ctrl.system.id)
    }
    ctrl.eliminar = function(){
      ctrl.onEliminarSistema(ctrl.system.id)
    }

    ctrl.getSystemImageClass = function() {
      return "icon-system-" + ctrl.system.icono;
    };
  }

  angular.module("app").component("systemListItem", {
    templateUrl: "components/SystemListItem/SystemListItem.html",
    controller: SystemListItemController,
    bindings: {
      system: "<",
      onItemClick: "=",
      onEditarSistema: "=",
      onEliminarSistema: "="
    }
  });
})(window.angular);
