(function() {
  function SystemConfigController($scope, system, user, $rootScope, $window, buildModes, BUILD_MODE, ymSidenavService, $state, ajaxInProgress, editMode, alertService, seqService, $stateParams, BUILD_PRODUCT, buildProducts, alarmPanelBrands) {
    console.log("SystemConfigController created");

    $scope.$on("$destroy", function() {
      console.log("SystemConfigController destroyed");
    });

    var self = this;

    editMode.state.value = false;

    $rootScope.wifiIconToShow = "any-network";

    if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
      ymSidenavService.closeYmSidenav();
    };

    self.BUILD_PRODUCT = BUILD_PRODUCT;
    self.BUILD_PRODUCTS = buildProducts;

    var SUBSCRIPTION_STATUS = {
      NONE: 0,
      FREE_TRIAL: 1,
      PENDING: 2,
      ACTIVE: 3,
      CANCELLED: 4,
      FINISHED: 5,
      REJECTED: 6,
      PENDING_REJECTED: 7
    };

    self.isOwner;

    self.actionBarLeftButton = {
      onClickHandler: function() {
        if (self.sistemaElegido && self.sistemaElegido.estadoDeCarga == "cargandoVerificationCode") {
          self.sistemaElegido.estadoDeCarga = "cargandoBasicData";
          system.deleteSystem(self.sistemaElegido || { id: system.getIdSistemaElegido() })
        } else {
          $state.go("systemlist");
        }
      },
      disabled: function() {
        return self.editModeValue();
      },
      icon: "chevron_left",
      aria: "home"
    };

    self.availableIcons = [
      {
        value: "0",
        img: "img/assets/icon-system/icon-system-campo.svg "
      },
      {
        value: "1",
        img: "img/assets/icon-system/icon-system-casa.svg"
      },
      {
        value: "2",
        img: "img/assets/icon-system/icon-system-fabrica.svg"
      },
      {
        value: "3",
        img: "img/assets/icon-system/icon-system-oficina.svg"
      },
      {
        value: "4",
        img: "img/assets/icon-system/icon-system-casa-2.svg"
      },
      {
        value: "5",
        img: "img/assets/icon-system/icon-system-departamento.svg"
      },
      {
        value: "6",
        img: "img/assets/icon-system/icon-system-local.svg"
      },
      {
        value: "7",
        img: "img/assets/icon-system/icon-system-playa.svg"
      }
    ];

    self.$scope = $scope;

    self.$state = $state;

    self.partitionKeys;

    self.selectIcon = function(value) {
      self.editedIcon = value;
      self.systemEditedIconClass = "icon-system-" + value;
    };

    self.editModeValue = function() {
      return editMode.state.value;
    };

    self.actionBarRightButtons = [
      {
        onClickHandler: function() {
          self.eliminarSistema();
        },
        disabled: function() {
          return self.editModeValue();
        },
        icon: "delete",
        aria: "delete"
      }
    ];

    self.zonasHorarias = [];

    for (var i = -12; i < 13; i++) {
      self.zonasHorarias.push("GMT" + (i > -1 ? "+" + i : i));
    }

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value == "addSystem") {
          system.procesarListaDeSistemasYCompartidos(res.data.message);
          ajaxInProgress.setState({
            value: false
          });
          $state.go("systemconfig", { idSistemaElegido: self.sistemaElegido.id }, { reload: "systemconfig" }); //, { reload: "systemconfig" }
        } else if (ajaxInProgress.state.value == "verifySystem") {
          ajaxInProgress.setState({
            value: false
          });
          $state.go("systemconfig", { idSistemaElegido: self.sistemaElegido.id }, { reload: "systemconfig" });
        } else if (ajaxInProgress.state.value == "deleteSystem") {
          system.procesarListaDeSistemasYCompartidos(res.data.message);
          ajaxInProgress.setState({
            value: false
          });
          $state.go("systemlist", undefined); //, { reload: true }
        }
        else if(ajaxInProgress.state.value == "settingPartitionKeys"){
          ajaxInProgress.setState({
            value: false
          });
          self.setSystemName()
          // $state.go("systemlist", undefined); //, { reload: true }
        }        
        else if (ajaxInProgress.state.value == "settingTimeZone") {
          system.procesarListaDeSistemasYCompartidos(res.data.message);
          ajaxInProgress.setState({
            value: false
          });
          // self.configurarNuevaFecha(res.data.message.newSystemDate);
        // } else if (ajaxInProgress.state.value == "settingNewDate") {
          alertService.showToast("Configuraste exitosamente tu sistema"); //showAlert("Nueva hora", "Configuraste exitosamente la nueva hora en tu sistema", "OK");
          self.newTimeZone.changed = false;
          ajaxInProgress.setState({
            value: false
          });
          $state.go("system", { idSistemaElegido: self.sistemaElegido.id },  { reload: "system" });
          // $state.go("systemlist", { idSistemaElegido: self.sistemaElegido.id }); //, { reload: "systemconfig" }
        } else if (ajaxInProgress.state.value == "settingSystemName") {
          self.setSystemIcon();
        } else if (ajaxInProgress.state.value == "settingSystemIcon") {
          // if (self.sistemaElegido.timeZone) {
          //   self.setTimeZone();
          // } else {
            alertService.showToast("Configuración exitosa"); //showAlert("Nueva hora", "Configuraste exitosamente la nueva hora en tu sistema", "OK");
            ajaxInProgress.setState({
              value: false
            });
            system.procesarListaDeSistemasYCompartidos(res.data.message);
            // if (self.sistemaElegido && self.sistemaElegido.id) {
            //   // system.editarSistema(undefined, self.sistemaElegido.id);
            // $state.go("systemlist", { idSistemaElegido: self.sistemaElegido.id });  //, { reload: true }
            // }
        $state.go("system", { idSistemaElegido: self.sistemaElegido.id },  { reload: "system" });

          // $state.go("systemlist", undefined); //, { reload: true }
          // }
        }
        self.$scope.mainController.message = res.data.message;
        return;
      } else {
        if (res.status == -1) {
          if (ajaxInProgress.state.value == "settingNewDate") {
            alertService.showToast("Cambio exitoso, puede que tardes en ver la nueva hora en tu sistema"); //showAlert("Cambio exitoso", "Puede que tardes en ver la nueva hora en tu sistema", "OK");
            ajaxInProgress.setState({
              value: false
            });
            $state.go("systemconfig", undefined); //, { reload: "systemconfig" }
            return;
          }

					alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
        alertService.showAlert("Atención", res.data.message, "OK");
        ajaxInProgress.setState({
          value: false
        });
      }
    }

    self.setBasicDataSistema = function() {
      if (!ajaxInProgress.state.value) {
        self.sistemaElegido.id = self.sistemaElegido.id.toLowerCase();

        ajaxInProgress.setState({
          value: "addSystem",
          label: "Configurando datos básicos"
        });
        system.addSystem(self.sistemaElegido).then(handleRequest, handleRequest);
      }
    };

    self.setVerificationCode = function() {
      if (!ajaxInProgress.state.value) {
        ajaxInProgress.setState({
          value: "verifySystem",
          label: "Verificando sistema"
        });
        system.verifySystem(self.sistemaElegido).then(handleRequest, handleRequest);
      }
    };

    self.eliminarSistema = function() {
      alertService
        .showConfirmAlert("Eliminar sistema", "¿Estás seguro de eliminar?", "¡Sí, estoy seguro!", "NO")
        .then(function() {
          ajaxInProgress.setState({
            value: "deleteSystem"
          });
          system.deleteSystem(self.sistemaElegido || { id: system.getIdSistemaElegido() }).then(handleRequest, handleRequest);
        })
        .catch(function(error) {
          console.log("SystemConfigController::eliminarSistema(error)", error);
        });
    };

    self.checkNewTimeZoneChange = function() {
      self.newTimeZone.changed = self.newTimeZone.value !== self.sistemaElegido.timeZone;
    };

    self.setSystemName = function() {
      if (!ajaxInProgress.state.value) {
        ajaxInProgress.setState({
          value: "settingSystemName",
          label: "Guardando configuración"
        });
        user.setSystemName(self.sistemaElegido, self.systemEditedName).then(handleRequest, handleRequest);
      }
    };

    self.setSystemIcon = function() {
      ajaxInProgress.setState({
        value: "settingSystemIcon",
        label: "Guardando configuración"
      });
      user.setSystemIcon(self.sistemaElegido, self.editedIcon).then(handleRequest, handleRequest);
    };

    self.verificationCodeChange = function(args) {
      self.sistemaElegido.verificationCode = self.sistemaElegido.verificationCode || "";
      self.sistemaElegido.verificationCode = self.sistemaElegido.verificationCode.toUpperCase();
    };

    self.anythingChanged = function() {
      // condicion ante la cual se muestra el boton de guardar.
      if (!self.sistemaElegido) return false;
      if (self.systemEditedName && self.systemEditedName != self.sistemaElegido.nombre) return true;
      if (self.editedIcon && self.editedIcon != self.sistemaElegido.icono) return true;
      if (self.newTimeZone && self.newTimeZone.value != self.sistemaElegido.timeZone) return true;
      if (self.alarmPanelBrand && self.alarmPanelBrand != self.sistemaElegido.programation.data.alarmPanel.brand) return true;
      if(self.newPartitionKeys){
        if (self.newPartitionKeys['1'] && self.newPartitionKeys['2']&& self.newPartitionKeys['3'] && self.newPartitionKeys['4'] && self.newPartitionKeys['0'] != self.partitionKeys['0']) return true;
        if (self.newPartitionKeys['1'] && self.newPartitionKeys['2']&& self.newPartitionKeys['3'] && self.newPartitionKeys['4'] && self.newPartitionKeys['1'] != self.partitionKeys['1']) return true;
        if (self.newPartitionKeys['1'] && self.newPartitionKeys['2']&& self.newPartitionKeys['3'] && self.newPartitionKeys['4'] && self.newPartitionKeys['2'] != self.partitionKeys['2']) return true;
        if (self.newPartitionKeys['1'] && self.newPartitionKeys['2']&& self.newPartitionKeys['3'] && self.newPartitionKeys['4'] && self.newPartitionKeys['3'] != self.partitionKeys['3']) return true;
        if (self.newPartitionKeys['1'] && self.newPartitionKeys['2']&& self.newPartitionKeys['3'] && self.newPartitionKeys['4'] && self.newPartitionKeys['4'] != self.partitionKeys['4']) return true;
      }
      if(self.partitionNames) {
        if(self.partitionNamesData[0] != self.partitionNames[0]) return true
        if(self.partitionNamesData[1] != self.partitionNames[1]) return true
        if(self.partitionNamesData[2] != self.partitionNames[2]) return true
        if(self.partitionNamesData[3] != self.partitionNames[3]) return true
        return false
      }

      return false;
    };

    self.userData;

    self.setUserPartitionKeys = function(){
    
      self.partKeyInfo = {id: self.sistemaElegido.id, partitionKeys : self.newPartitionKeys};

      ajaxInProgress.setState({
        value: "settingPartitionKeys",
        label: "Guardando configuración"
      });
      
      user.editUserData(self.userData.nombre, self.userData.apellido, self.userData.avatar, self.partKeyInfo, self.partitionNames,self.sistemaElegido.id, self.editedIcon,self.userData.codigoEmpresa).then(handleRequest, handleRequest);
    }

    self.saveChanges = function() {
      if (!ajaxInProgress.state.value) {
        // if (self.systemEditedName && self.systemEditedName != self.sistemaElegido.nombre) {
        //     return self.setSystemName();
        //   } 
          // else if(self.editedIcon && self.editedIcon != self.sistemaElegido.icono) {
          //   return self.setSystemName();
          // } 
          // ESTA FUNCION EDITA TODO EN 3 PASOS, NAME, ICON Y EL RESTO
          return self.setUserPartitionKeys(); 
       }
    };

    self.setSistemaElegido = function(id) {
      if (!ajaxInProgress.state.value) {
        // IN USE system.setIdSistemaElegido(id);
        $state.go("system", { idSistemaElegido: id });
      }
    };

    self.alarmPanelBrandSwitchChange = function() {
      if (self.alarmPanelBrandSwitch) {
        self.alarmPanelBrand = alarmPanelBrands.GARNET;
      } else {
        self.alarmPanelBrand = alarmPanelBrands.DSC;
      }
    };

    self.getPartitionKeys = function(sistemas, sistemasCompartidos){

      self.sistemaObtenido = sistemas.find(function(sistema){
        return sistema.id == self.sistemaElegido.id
      })

      if(!self.sistemaObtenido){
        self.sistemaObtenido = sistemasCompartidos.find(function(sistema){
          return sistema.id == self.sistemaElegido.id
        })
      }
      return self.sistemaObtenido.partitionKeys
    }

    self.getPartitionNames = function(sistemas, sistemasCompartidos){

      self.sistemaObtenido = sistemas.find(function(sistema){
        return sistema.id == self.sistemaElegido.id
      })

      if(!self.sistemaObtenido){
        self.sistemaObtenido = sistemasCompartidos.find(function(sistema){
          return sistema.id == self.sistemaElegido.id
        })
      }
      return [self.sistemaObtenido.estados[1].nombre,self.sistemaObtenido.estados[2].nombre,self.sistemaObtenido.estados[3].nombre,self.sistemaObtenido.estados[4].nombre]
    }

    if ($stateParams.idSistemaElegido) {
      console.log("SystemConfigController::getSystem()");

      ajaxInProgress.setState({
        value: "getSystemToEdit",
        label: "Cargando datos del sistema"
      });

      system.getSystem($stateParams.idSistemaElegido, true)
      .then(
        function(sistemaElegido) {
          self.sistemaElegido = sistemaElegido;
          ajaxInProgress.setState({
            value: false
          });

          // console.log("SYSTEM CONFIG CONTROLLER SISTEMA ELEGIDO - ", sistemaElegido);

          self.systemEditedName = self.sistemaElegido.nombre;
          self.systemEditedIconClass = "icon-system-" + self.sistemaElegido.icono; //"icon-system-0";
          self.editedIcon = self.sistemaElegido.icono.toString();
          if(self.sistemaElegido.programation && self.sistemaElegido.programation.data){
            self.alarmPanelBrand = self.sistemaElegido.programation.data.alarmPanel.brand;
          }
        },
        function(error) {
          alertService.showAlert("Atención", error, "OK");
        }
      )
      .then(function(){
       return user.getUserData();
      })
      .then(function(user){
        self.sistemaElegidoOwnerEmail = self.sistemaElegido.owner ? self.sistemaElegido.owner.email: null;
        self.isOwner = user.data.message.user.email == self.sistemaElegidoOwnerEmail;
        self.userData = user.data.message.user;
        self.userSystems = user.data.message.user.sistemas;
        self.userSharedSystems = user.data.message.user.sistemasCompartidos;
        self.partitionKeys = self.getPartitionKeys(self.userSystems, self.userSharedSystems);
        self.newPartitionKeys = Object.assign({}, self.partitionKeys);
        self.partitionNames = self.getPartitionNames(self.userSystems, self.userSharedSystems);
        self.partitionNamesData =  Object.assign({}, self.partitionNames);
      });
    } else {
      self.sistemaElegido = {
        estadoDeCarga: "cargandoBasicData",
        programation: {}
      };
    }
  }
  angular.module("app").controller("SystemConfigController", SystemConfigController);
})();
