(function() {
  function SubscriptionController($scope, $state, $window, system, ajaxInProgress, $rootScope, editMode, BUILD_MODE, alertService, seqService, actionBarService, ymSidenavService) {
    console.log("SubscriptionController created");

    $scope.$on("$destroy", function() {
      console.log("SubscriptionController destroyed");
    });

    var self = this;
    self.$scope = $scope;
    self.$state = $state;

    editMode.state.value = false;

    self.editModeValue = function() {
      return editMode.state.value;
    };

    self.actionBarActions = actionBarService.actionBarActions;

    self.actionBarLeftButton = {
      onClickHandler: function() {
        $state.go("subscription_systemlist");
      },
      disabled: function() {
        return self.editModeValue();
      },
      icon: "chevron_left",
      aria: "home"
    };

    // self.selectedStep = 0;

    // self.onStepClick = function(step) {
    //   self.selectedStep = step;
    // };

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value == "getSystem") {
          system.procesarSistemaDeRespuesta(res.data.message.sistema);

          self.sistemaElegido = system.getSistemaElegido();

          if (!self.sistemaElegido.programation) {
            ajaxInProgress.setState({
              value: false
            });
            return self.$state.go("systemconfig");
          }

          if (self.sistemaElegido.subscriptionInfo.planType == 3) {
            ajaxInProgress.setState({
              value: false
            });
            $state.go("subscription.plans");
            return;
          }

          ajaxInProgress.setState({
            value: false
          });
          $state.go("subscription.info");
        }
      } else {
        if (res.status == -1) {
					alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
          // ajaxInProgress.state.value = false;
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
        alertService.showAlert("Atención", res.data.message, "OK");
        // ajaxInProgress.state.value = false;
        ajaxInProgress.setState({
          value: false
        });
      }
    }

    ajaxInProgress.setState({
      value: "getSystem",
      label: "Cargando suscripción"
    });

    system.getSystem(system.getIdSistemaElegido()).then(handleRequest, handleRequest);
  }

  angular.module("app").controller("SubscriptionController", SubscriptionController);
})();
