(function() {
  function alertService($mdDialog, $mdToast) {
    var self = { dialog: { instance: null, kind: undefined } };

    self.dialogs = [];

    // TOAST
    var toast = $mdToast.simple();
    toast.position("bottom center");

    self.showToast = function(content, position) {
      toast.textContent(content);
      $mdToast.show(toast);
    };

    // TOAST
    self.showAlert = function(title, textContent, okButtonLabel) {
      console.log("showAlertError", title, textContent, okButtonLabel);
      self.dialog.kind = "ALERT";
      $mdDialog
        .show(
          $mdDialog
            .alert()
            .parent(angular.element(document.querySelector("#popupContainer")))
            .clickOutsideToClose(true)
            .title(title)
            .textContent(textContent)
            .ariaLabel("Alerta")
            .ok(okButtonLabel)
        )
        .then(function() {})
        .catch(function(err) {
          console.log("showAlertError", err);
        });
    };

    self.showConfirmAlert = function(title, textContent, okButtonLabel, cancelButtonLabel) {
      // Appending dialog to document.body to cover sidenav in docs app
      self.dialog.kind = "CONFIRM";
      var confirm = $mdDialog
        .confirm()
        .parent(angular.element(document.querySelector("#popupContainer")))
        .title(title)
        .textContent(textContent)
        .ok(okButtonLabel)
        .cancel(cancelButtonLabel);

      return $mdDialog.show(confirm);
    };

    self.showProgressDialog = function(title, textContent, cancelable) {
      // console.log("showProgressDialog: ", title, textContent, cancelable);
      // console.log("AlertService::showProgressDialog()", self.dialog);

      if (self.dialog.instance !== null) {
        return $mdDialog
          .hide()
          .then(function() {
            return self.innerShowProgressDialog(title, textContent, cancelable);
          })
          .catch(function(error) {
            // console.log("AlertService::showProgressDialog(error)", error);
          });
      }

      return self
        .innerShowProgressDialog(title, textContent, cancelable)
        .then(function() {})
        .catch(function(error) {
          // console.log("AlertService::showProgressDialog(error)", error);
        });
    };

    self.innerShowProgressDialog = function(title, textContent, cancelable) {
      self.dialog.kind = "PROGRESS";

      self.dialog.instance = $mdDialog.show({
        controller: DialogControllerCreator(title, textContent, cancelable),
        templateUrl: "views/dialogs/progress-dialog.tmpl.html",
        parent: angular.element(document.querySelector("#popupContainer")),
        multiple: false,
        clickOutsideToClose: false,
        fullscreen: false, // Only for -xs, -sm breakpoints.
        customControllerOptions: {title: title, textContent: textContent, cancelable: cancelable}
      });

      self.dialogs.push(self.dialog.instance);

      return self.dialog.instance;
    };

    self.hideProgressDialog = function() {
      // console.log("AlertService::hideProgressDialog()", self.dialog);
      // if (self.dialog.instance && self.dialog.kind && self.dialog.kind === "PROGRESS") {
      // $mdDialog
      //   .hide()
      //   .then(function() {
      //     console.log("Se cerró bien");
      //     self.dialog.kind = undefined;
      //   })
      //   .catch(function(error) {
      //     console.log("ERROR AL CERRAR EL DIALOG DESDE HIDE PROGRESS DIALOG", error);
      //   });

      // Recursive function to hide dialogs secuentially (REAL FUNCTION)
      // var hideOneDialog = function() {
      //   var dialog = self.dialogs.pop();
      //   if (!dialog) {
      //     return;
      //   }
      //   $mdDialog.hide(dialog).then(
      //     function() {
      //       hideOneDialog();
      //     },
      //     function(error) {
      //       hideOneDialog();
      //     }
      //   );
      // };

      var hideOneDialog = function() {
        console.log("DIALOGS - ", self.dialogs);
        var dialog = self.dialogs.pop();
        if (!dialog) {
          return;
        }
        $mdDialog.hide().then(
          function() {
            hideOneDialog();
          },
          function(error) {
            hideOneDialog();
          }
        );
      };

      hideOneDialog();
    };

    return self;
  }

  function DialogControllerCreator(title, textContent, cancelable) {
    return function($scope, $mdDialog) {
      $scope.title = title;
      $scope.textContent = textContent;
      $scope.cancelable = cancelable;

      $scope.hide = function() {
        $mdDialog.hide().catch(function(error) {
          "DialogControllerCreator::hide(error)", error;
        });
      };

      $scope.cancel = function() {
        $mdDialog.cancel();
      };

      $scope.answer = function(answer) {
        $mdDialog.hide(answer);
      };
    };
  }

  angular.module("app").service("alertService", alertService);
})();
