(function(angular) {
	function BellController($scope, $element, ajaxInProgress, customFormat) {
		var ctrl = this;
		// ctrl.$scope = $scope;
		ctrl.ajaxInProgress = ajaxInProgress;

		ctrl.$onInit = function() {};

		ctrl.$onChanges = function(changesObj) {
			// ctrl.appear = false;
			ctrl.parseStatus(ctrl.status);
		};

		ctrl.encender = function() {
			ctrl.onEncender();
		};

		ctrl.apagar = function() {
			ctrl.onApagar();
		};

		ctrl.parseStatus = function(status) {
			if (status) {
				var mask = 1;

				if (status.estadoDeSalidas & mask) {
					// Salida encendida
					ctrl.estadoBell = "ENCENDIDA";
					ctrl.estadoBellText = "ENCENDIDO";
					ctrl.estadoBellColor = "rojo";
				} else {
					// Salida apagada
					ctrl.estadoBell = "APAGADA";
					ctrl.estadoBellText = "APAGADO";
					ctrl.estadoBellColor = "gris";
				}
			} else {
				ctrl.estadoBellText = "";
				ctrl.estadoBellColor = "gris";
			}
		};

		ctrl.parseStatus(ctrl.status);
	}

	angular.module("app").component("bell", {
		templateUrl: "components/Bell/bell.html",
		controller: BellController,
		bindings: {
			status: "<",
			bell: "<",
			onEncender: "=",
			onApagar: "=",
			editMode: "<"
		}
	});
})(window.angular);
