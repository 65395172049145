(function() {
  function SystemListController($scope, $state, $stateParams, $rootScope, $window, $timeout, BUILD_MODE, system, ajaxInProgress, ymSidenavService, editMode, alertService, actionBarService, routerService, userDataService, user) {
    console.log("SystemListController created");

    $scope.$on("$destroy", function() {
      console.log("SystemListController destroyed");
    });

    var self = this;

    $rootScope.wifiIconToShow = "any-network"
    $rootScope.systemPlanCounter = 'system-plan-counter-hidden'

    self.$scope = $scope;

    self.editModeValue = function() {
      return editMode.state.value;
    };

    self.isMobile = BUILD_MODE == "MOBILE";
    self.isWeb = BUILD_MODE == "WEB";

    self.actionBarTitle = "Sistemas de alarma";

    self.actionBarActions = actionBarService.actionBarActions;

    self.ready = false;

    editMode.state.value = false;

    self.setSistemaElegido = function(id) {
      $state.go("system", { idSistemaElegido: id });
    };

    self.verifySystemEnabled = function(sistema) {
      if (sistema.disabled) {
        return alertService.showAlert("Atención", "No podes acceder al sistema, contactate con el dueño del mismo para más información", "OK");
      } else {
        return self.setSistemaElegido(sistema.id);
      }
    };

    self.editarSistema = function(id) {
      // if ($event) $event.stopPropagation();
      // IN USE system.setIdSistemaElegido(id);
      $state.go("systemconfig", { idSistemaElegido: id });
    };

    self.eliminarSistema = function(id) {
      alertService
        .showConfirmAlert("Eliminar sistema", "¿Estás seguro de eliminar?", "¡Sí, estoy seguro!", "NO")
        .then(function() {
          ajaxInProgress.setState({
            value: "deleteSystem"
          });
          system.deleteSystem({ id: id}).then(handleRequest, handleRequest);
        })
        .catch(function(error) {
          // console.log("SystemConfigController::eliminarSistema(error)", error);
        });
    };

    self.agregarNuevoSistema = function() {
      // IN USE system.setIdSistemaElegido(undefined);
      $state.go("systemconfig", { idSistemaElegido: null }); //, null, { reload: true });
      if (BUILD_MODE == "MOBILE" || (BUILD_MODE == "WEB" && $window.innerWidth < 960)) {
        ymSidenavService.closeYmSidenav();
      }
    };

    // Expongo las funciones para utilizarlas desde la view directamente
    self.sistemas = system.getSistemas;
    self.sistemasCompartidos = system.getSistemasCompartidos;

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value == "list") {
          system.procesarListaDeSistemasYCompartidos(res.data.message);
          ajaxInProgress.setState({
            value: false
          });

          if($stateParams.idToControl){
            self.setSistemaElegido($stateParams.idToControl);
            return
          }

          if (routerService.getLastState().search(/system\./) > -1) {
            self.ready = true;
            return;
          }

          if($stateParams.idSistemaElegido){
            $state.go("system.history", { idSistemaElegido: $stateParams.idSistemaElegido });
            return
          }


          if (self.sistemas().length === 1 && self.sistemasCompartidos().length === 0) {
            self.setSistemaElegido(self.sistemas()[0].id);
            return;
          } else if (self.sistemasCompartidos().length === 1 && self.sistemas().length === 0) {
            self.setSistemaElegido(self.sistemasCompartidos()[0].id);
            return;
          }

          self.ready = true;
         
        } else if(ajaxInProgress.state.value == "deleteSystem"){
          system.procesarListaDeSistemasYCompartidos(res.data.message);
          ajaxInProgress.setState({
            value: false
          });
          $state.go("systemlist", undefined); //, { reload: true }
        }
        self.message = res.data.message;
      } else {
        if (res.status == -1) {
					alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
          // ajaxInProgress.state.value = false;
          ajaxInProgress.setState({
            value: false
          });
          return;
        }

        alertService.showAlert("Atención", res.data.message, "OK");
        // ajaxInProgress.state.value = false;
        ajaxInProgress.setState({
          value: false
        });
      }
    }

    self.appIsCustomized = userDataService.getAppIsCustomized();

    if(self.appIsCustomized){
      self.attributes = user.getCustomizationAttributes();
    }
    

    actionBarService.setActionBarActions("SystemListController", []);

    system.listarSistemas().then(handleRequest, handleRequest);

    ajaxInProgress.setState({
      value: "list"
    });
  }

  angular.module("app").controller("SystemListController", SystemListController);
})();
