(function(angular) {
  function CamaraController($scope, $rootScope, $element, $timeout, ajaxInProgress, BUILD_MODE, system) {
    var ctrl = this;
    // ctrl.$scope = $scope;

    ctrl.ajaxInProgress = ajaxInProgress;

    ctrl.editCamMode = false;
    ctrl.editedNombre = "";

    ctrl.$onInit = function() {
      ctrl.BUILD_MODE = BUILD_MODE;

      ctrl.count = 0;

      ctrl.isWeb = BUILD_MODE == "WEB"

      var sistemaElegido = ctrl.sistema;

      if (sistemaElegido.owner && typeof sistemaElegido.owner.userType == "number" && sistemaElegido.owner.userType == 0) {
        ctrl.puedeBorrar = true;
      } else {
        ctrl.puedeBorrar = false;
      }
    };


    ctrl.eliminarCamara = function() {
      ctrl.onEliminarCamara(ctrl.camera.deviceSerial);
    };

    ctrl.editarCamara = function() {
      ctrl.onEditarCamara(ctrl.editedNombre, ctrl.camera.deviceSerial);
      ctrl.editCamMode = false;
    }

    ctrl.enableEditCamMode = function(){
    ctrl.editCamMode = true;
  }

    ctrl.onImageLoaded = function() {
      ctrl.picLoaded = true;
    };

    ctrl.triggerLivePlay = function() {
      if (BUILD_MODE == "MOBILE") {
        if(ctrl.count == 0){
          ctrl.count = 1;
          ctrl.picLoaded = false;
          ctrl.onVerLive(ctrl.camera.deviceSerial, ctrl.camera.validateCode);
        }
        $timeout(function(){
          ctrl.picLoaded = true;
          ctrl.count = 0;
          // console.log("Reset del Botón por Timeout");
        }, 3000)
      }
    };

    $rootScope.$on('videoEvent', function () {
      //do stuff
      ctrl.picLoaded = true;
      ctrl.count = 0;
      // console.log("Reset del Botón");
    })
  }

  angular.module("app").component("camara", {
    templateUrl: "components/Camara/camara.html",
    controller: CamaraController,
    bindings: {
      camera: "<",
      editMode: "<",
      onEliminarCamara: "=",
      onEditarCamara: "=",
      onVerLive: "=",
      sistema: "<"
    }
  });
})(window.angular);
