(function() {
  function SystemZonesController($scope, $state,$rootScope, system,$window, ymSidenavService,buildModes, BUILD_MODE, statusCacheService, ajaxInProgress, $timeout, editMode, alertService, seqService, actionBarService, $stateParams) {
    console.log("SystemZonesController created");

    $scope.$on("$destroy", function() {
      console.log("SystemZonesController destroyed");
    });

    var self = this;

    self.$scope = $scope;
    self.checkStatus = false

    editMode.state.value = false;

    if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
      ymSidenavService.closeYmSidenav();
    }

    self.editModeValue = function() {
      return editMode.state.value;
    };

    function handleRequest(res) {
      if (res.data && res.data.success) {
        if (ajaxInProgress.state.value == "consultandoEstado") {
          self.checkStatus = false
          ajaxInProgress.setState({
            value: false
          });
        } else if (ajaxInProgress.state.value == "inhibiendoZona") {
          self.checkStatus = true
          alertService.showToast(res.data.message.response);
          ajaxInProgress.setState({
            value: false
          });
        } else if (ajaxInProgress.state.value == "desinhibiendoZona") {
          self.checkStatus = true
          alertService.showToast(res.data.message.response);
          ajaxInProgress.setState({
            value: false
          });
        }

        if (res.data.message.status) {
          self.sistemaElegido.status = statusCacheService.storeStatus(self.sistemaElegido, res.data.message.status, self.checkStatus);
        }

        // self.$scope.mainController.message = res.data.message;
      } else {
        if (res.status == -1) {
          ajaxInProgress.setState({
            value: false
          });
          $timeout(function() {
            alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
          });
          return;
        }
        if (res.status == -2) {
          ajaxInProgress.setState({
            value: false
          });
          return;
        }
        ajaxInProgress.setState({
          value: false
        });
        $timeout(function() {
          alertService.showAlert("Atención", res.data.message, "OK");
        });
      }
    }

    self.loguearEstado = function() {
      // console.log(self.sistemaElegido);
      // console.log(self.$scope.mainController.sistemas);
      // console.log(self.$scope.mainController.sistemasCompartidos);
    };

    self.consultarEstado = function() {
      var ajax = system.consultarEstado(self.sistemaElegido, seqService.seq());
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "consultandoEstado",
        label: "Consultando Estado",
        ajax: ajax
      });
    };

    self.inhibirZona = function(zona) {
      var ajax = system.inhibirZona(self.sistemaElegido, seqService.seq(), zona.number, zona.partitions[0]);
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "inhibiendoZona",
        label: "Inhibiendo Zona",
        ajax: ajax
      });
    };

    self.desinhibirZona = function(zona) {
      var ajax = system.desinhibirZona(self.sistemaElegido, seqService.seq(), zona.number, zona.partitions[0]);
      ajax.then(handleRequest, handleRequest);
      ajaxInProgress.setState({
        value: "desinhibiendoZona",
        label: "Desinhibiendo Zona",
        ajax: ajax
      });
    };

    self.editMode = editMode;

    self.setSaveAction = function() {
      actionBarService.setActionBarActions("SystemZonesController", [
        {
          label: "Guardar",
          aria: "Guardar",
          icon: "save",
          onClickHandler: function() {
            // Secuencia de guardado :D

            var toSaveZones = [];

            self.zoneList.forEach(function(zone) {
              var toSaveZone = Object.assign({}, zone);
              toSaveZone.name = toSaveZone.editedName;
              toSaveZone.icon = toSaveZone.editedIcon;
              delete toSaveZone.editedName;
              delete toSaveZone.editedIcon;
              toSaveZones.push(toSaveZone);
            });

            var programation = Object.assign({}, self.sistemaElegido.programation);

            programation.data.zones = toSaveZones;

            $scope.systemController.setSystemProgramation(programation.data, "zones");

            editMode.state.value = false;
            self.setEditAction();

            // $state.go("system");
          }
        },
        {
          label: "Cancelar",
          aria: "Cancelar",
          icon: "clear",
          onClickHandler: function() {
            editMode.state.value = false;
            self.setEditAction();
          }
        }
      ]);
    };

    function handleLastTimeReport(res) {
      if (res.data && res.data.success) {
        self.sistemaElegido.lastEventReport = res.data.message;

        if (self.sistemaElegido.lastEventReport > self.sistemaElegido.status.lastTimeUpdate.getTime()) {
          self.consultarEstado();
        }
      }
    }

    self.getLastEventReport = function() {
      var ajax = system.getLastEventReport(self.sistemaElegido);
      ajax.then(handleLastTimeReport, handleLastTimeReport);
    };

    self.setEditAction = function() {
      var buttons = [
        {
          label: "Actualizar",
          aria: "Actualizar",
          icon: "refresh",
          onClickHandler: function() {
            self.consultarEstado();
          }
        }
      ];

      if (self.sistemaElegido.owner && typeof self.sistemaElegido.owner.userType == "number" && self.sistemaElegido.owner.userType == 0) {
        buttons.push({
          label: "Editar",
          aria: "Editar",
          icon: "mode_edit",
          onClickHandler: function() {
            editMode.state.value = true;
            self.setSaveAction();
          }
        });
      }

      actionBarService.setActionBarActions("SystemZonesController", buttons);
    };

    function handleLastUpdate(res){
			if (res.data && res.data.success) {
			  self.lastUpdate = res.data.message.lastUpdate;
        self.lastEvent = res.data.message.lastEvent;
	  
			  if (self.lastEvent && new Date(self.lastEvent).getTime() > new Date(self.lastUpdate).getTime()) {
				return $rootScope.pedirProgramacionDesdeSystem();
			  } else {
          if (!self.sistemaElegido.status) {
            return self.consultarEstado();
          }
          self.getLastEventReport();
        }
			} else {
			  if (res.status == -1) {
				ajaxInProgress.setState({
				  value: false
				});
				// $timeout(function() {
					alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
				// });
				return;
			  }
			  if (res.status == -2) {
				ajaxInProgress.setState({
				  value: false
				});
				return;
			  }
			  ajaxInProgress.setState({
				value: false
			  });
			  // $timeout(function() {
			  alertService.showAlert("Atención", res.data.message, "OK");
			}
		  }

		  self.getLastUpdate = function() {
			var ajax = system.getLastUpdate(self.sistemaElegido);
			ajax.then(handleLastUpdate, handleLastUpdate);
		  };

    // Tareas para cuando el controller se instancia
    system.getSystem($stateParams.idSistemaElegido).then(function(sistemaElegido) {
      //Seteo el timeout de los comandos en función de lo que devuelva
      system.setCommandTimeout(sistemaElegido.id);
      self.sistemaElegido = sistemaElegido;
			// $rootScope.focusingTab("/system/zones");

      self.zoneGroupArray = [];

      self.allZones = self.sistemaElegido.programation.data.zones;

      self.partitionsLength = self.sistemaElegido.programation.data.partitions.length > 1;

      for (var i = 0; i < 4; i++) {
        var partition = self.sistemaElegido.programation.data.partitions.find(function(partition) {
          return partition.number == i + 1;
        });
        if (!partition) continue;

        var zoneGroup = {
          title: partition.name || "Partición " + partition.number,
          zones: []
        };

        self.sistemaElegido.programation.data.zones.forEach(function(zone) {
          if (zone.attributes.substring(2 + i, 3 + i) == 1) {
            if (!zone.partitions) {
              zone.partitions = [];
            }
            zone.partitions.push(partition.number);
            zoneGroup.zones.push(zone);
          }
        });

        self.zoneGroupArray.push(zoneGroup);
      }

      if (self.sistemaElegido.estadoDeCarga === "verificado") {
        if (self.sistemaElegido.programation.data) {
          self.zoneList = self.sistemaElegido.programation.data.zones;
        }

        self.setEditAction();
        // self.getLastEventReport();

        self.sistemaElegido.status = self.sistemaElegido.status || statusCacheService.getStatus(self.sistemaElegido);
        
        self.getLastUpdate();

        // if (!self.sistemaElegido.status && !ajaxInProgress.state.value) {
        //   self.consultarEstado();
        // }
      }
    });
  }

  angular.module("app").controller("SystemZonesController", SystemZonesController);
})();
