(function() {
	function RegisterController(
		__env,
		ajaxInProgress,
		$state,
		$scope,
		$mdDialog,
		user,
		alertService,
		BUILD_PRODUCT,
		userDataService,
		BUILD_MODE,
		$q
	) {
		var self = this;

		if(BUILD_PRODUCT == "titanium_control"){
			self.productName = "Alarm Home";
		  }
		  if(BUILD_PRODUCT == "garnet_control"){
			self.productName = "Garnet Control";
		  }
		  if(BUILD_PRODUCT == "yo_monitoreo"){
			self.productName = "Yo Monitoreo";
		  }

		var PASSWORD_LOWWORD_REGEXP = /([a-z])+/;
		var PASSWORD_HIGHWORD_REGEXP = /([A-Z])+/;
		var PASSWORD_NUMBER_REGEXP = /([0-9])+/;
		var PASSWORD_SPECIAL_REGEXP = /([\.\+\-])+/;

		self.isMobile = BUILD_MODE == "MOBILE";
		self.isWeb = BUILD_MODE == "WEB";

		self.registerTexts = {
			spa:{
				insecurePass: "Tu contraseña es insegura",
				lowSecurePass: "Tu contraseña es poco segura",
				securePass: "Tu contraseña es segura",
				highSecurePass:  "Tu contraseña es muy segura",
				registerTitle: "Regístrate",
				registerDescription: "Completá tus datos para que podamos crear tu cuenta",
				name: "Nombre",
				requiredField: "Este campo es obligatorio",
				lastname: "Apellido",
				email: "Email",
				emailErrorMessage: "Es necesario un email válido",
				password: "Contraseña",
				passwordErrorMessage: "La contraseña es muy débil. Probá agregar, mayúsculas, números o caracteres especiales.",
				confirmPassword: "Confirmar contraseña",
				passwordBeEqual: "Las contraseñas deben coincidir",
				acept: "Acepto los ",
				termsAndConditions: " Términos y Condiciones",
				register: "Registrarme",
				emailConfirmation: "Confirma tu email",
				emailConfirmationDescription: "Ya te enviamos un correo con un enlace para confirmar tu email, tené en cuenta que esto puede tardar varios minutos",
				cancel: "Cancelar",
				attention: "Atención",
				actionFail: "No se pudo completar la acción, vuelva a intentarlo.",
			},
			eng:{
				insecurePass: "Insecure password",
				lowSecurePass: "Low secure password",
				securePass: "More secure password",
				highSecurePass:  "High secure password",
				registerTitle: "Sign up",
				registerDescription: "Fill in your details so we can create your account",
				name: "Name",
				requiredField: "This field is required",
				lastname: "lastname",
				email: "Email",
				emailErrorMessage: "A valid email is required",
				password: "Password",
				passwordErrorMessage: "The password is very weak. Try adding, capital letters, numbers or special characters.",
				confirmPassword: "Confirm password",
				passwordBeEqual: "Passwords must match",
				acept: "I accept the ",
				termsAndConditions: " Terms and Conditions",
				register: "register",
				emailConfirmation: "Confirm your email",
				emailConfirmationDescription: "We have already sent you an email with a link to confirm your email, keep in mind that this may take several minutes",
				cancel: "Cancel",
				attention: "Attention",
				actionFail: "The action could not be completed, please try again.",
			},
			por:{
				insecurePass: "Sua senha não é segura",
				lowSecurePass: "sua senha é fraca",
				securePass: "Sua senha está segura",
				highSecurePass:  "Sua senha é muito segura",
				registerTitle: "cadastre-se",
				registerDescription: "Preencha seus dados para que possamos criar sua conta",
				name: "Nome",
				lastname: "Sobrenome",
				email: "Email",
				emailErrorMessage: "É necessário um e-mail válido",
				password: "Senha",
				passwordErrorMessage: "A senha é muito fraca. Tente adicionar letras maiúsculas, números ou caracteres especiais.",
				confirmPassword: "confirmar senha",
				passwordBeEqual: "As senhas devem corresponder",
				acept: "Eu aceito o",
				termsAndConditions: "Termos e Condições",
				register: "registro",
				emailConfirmation: "confirme seu email",
				emailConfirmationDescription: "Já lhe enviamos um e-mail com um link para confirmar seu e-mail, lembre-se de que isso pode levar alguns minutos",
				cancel: "Cancelar",
				attention: "Atenção",
				actionFail: "Não foi possível concluir a ação. Tente novamente.",
			}
		}

		self.phoneLanguage;

		$q.all([userDataService.getPhoneLenguage()])
		.then(function(result){
			if(result[0] == "pt"){
				self.phoneLanguage  = "por"
			  } else if(result[0] == "en"){
				self.phoneLanguage  = "eng"
			  } else if(result[0] == "es"){
				self.phoneLanguage  = "spa"
			  } else {
				self.notProcessedLanguage = window.navigator.userLanguage || window.navigator.language;

				if(self.notProcessedLanguage.includes("en")){
					self.phoneLanguage =  "eng";
				} else if(self.notProcessedLanguage.includes("pt")){
					self.phoneLanguage =  "por";
				} else {
					self.phoneLanguage =  "spa";
				}
			  }
		});

		self.getTexts = function(type){
			return self.phoneLanguage ? self.registerTexts[self.phoneLanguage][type] : self.registerTexts["eng"][type];
		}

		// self.$scope = $scope;
		self.user = {
		};

		self.conditionsAccepted;

		function DialogController($scope, $mdDialog) {
			$scope.hide = function() {
			  $mdDialog.hide();
			};
		
			$scope.cancel = function() {
			  $mdDialog.cancel();
			};
		  }

		self.showConditions = function(ev) {
			$mdDialog.show({
			  controller: DialogController,
			  templateUrl: 'views/desktop/conditions.html',
			  parent: angular.element(document.body),
			  clickOutsideToClose:true,
			  targetEvent: ev,
			  fullscreen: $scope.customFullscreen // Only for -xs, -sm breakpoints.
			})
			.then(function(answer) {
				if(answer == "aceptar"){
					self.conditionsAccepted = true;
					$scope.hide();
				}else{
					$scope.hide();
				}
			});
		}

		self.register = function() {
			// ajaxInProgress.state.value = "register";
			if (!ajaxInProgress.state.value) {
				ajaxInProgress.setState({
					value: "register"
				});
				user.register(self.user, self.phoneLanguage).then(handleRequest, handleRequest);
			}
		};


		self.appIsCustomized = userDataService.getAppIsCustomized();
	
		if(self.appIsCustomized){
			self.appbarImageData = user.getAppBarImage();
			self.attributes = user.getCustomizationAttributes();
		}

		var level;
		self.progressLinearText;

		self.getProgressBarClass = function() {
			if (level == 0 || level == 25) {
				self.progressLinearText = self.getTexts("insecurePass") || "Tu contraseña es insegura";
				return { "ym-bar-red": true };
			} else if (level == 50) {
				self.progressLinearText = self.getTexts("lowSecurePass") || "Tu contraseña es poco segura";
				return { "ym-bar-yellow": true };
			} else if (level == 75) {
				self.progressLinearText = self.getTexts("securePass") || "Tu contraseña es segura";
				return { "ym-bar-green": true };
			} else if (level == 100) {
				self.progressLinearText = self.getTexts("highSecurePass") || "Tu contraseña es muy segura";
				return { "ym-bar-green-success": true };
			}
		};

		self.checkPasswordLevel = function() {
			level = 0;
			if (
				!self.user.password ||
				self.user.password.length > 20 ||
				self.user.password.length < 8
			) {
				return level + 25;
			}
			if (PASSWORD_LOWWORD_REGEXP.test(self.user.password)) {
				level = level + 25;
			}
			if (PASSWORD_HIGHWORD_REGEXP.test(self.user.password)) {
				level = level + 25;
			}
			if (PASSWORD_NUMBER_REGEXP.test(self.user.password)) {
				level = level + 25;
			}
			if (PASSWORD_SPECIAL_REGEXP.test(self.user.password)) {
				level = level + 25;
			}
			return level;
		};

		self.gotoLogin = function() {
			$state.go("login");
		};

		self.ajaxInProgressValue = function() {
			return ajaxInProgress.state.value;
		};

		function handleRequest(res) {
			if (res.data && res.data.success) {
				if (ajaxInProgress.state.value == "register") {
					$state.go("login");
					self.user = {
					};
					alertService.showAlert(
						self.getTexts("emailConfirmation"),
						self.getTexts("emailConfirmationDescription"),
						"OK"
					);
					// ajaxInProgress.state.value = false;
					ajaxInProgress.setState({
						value: false
					});
				}
				self.message = res.data.message;
			} else {
				if (res.status == -1) {
					alertService.showAlert(self.getTexts("attention"), self.getTexts("actionFail"), "OK");
					// ajaxInProgress.state.value = false;
					ajaxInProgress.setState({
						value: false
					});
					return;
				}
				alertService.showAlert("attention", res.data.message, "OK");
				ajaxInProgress.setState({
					value: false
				});
			}
		}
	}

	angular.module("app").controller("RegisterController", RegisterController);
})();
