(function() {
  function eventCacheService(localStorageService, $q) {
    var self = this;

    self.cache = undefined;

    self.storeEvents = function(sistema) {
      var systemId = sistema.id;

      self.cache[systemId] = sistema.events;

      localStorageService.setItem("eventCache", JSON.stringify(self.cache));
    };

    self.getEvents = function(sistema) {
      var deferred = $q.defer();
      if (!self.cache) {
        localStorageService
          .getItem("eventCache")
          .then(function(events) {
            var eventsCache = JSON.parse(events);
            if (eventsCache != null) {
              Object.keys(eventsCache).forEach(function(sistemaId) {
                eventsCache[sistemaId].lastTimeUpdate = eventsCache[sistemaId].lastTimeUpdate ? new Date(eventsCache[sistemaId].lastTimeUpdate) : undefined;
                if (eventsCache[sistemaId].eventList) {
                  eventsCache[sistemaId].eventList = eventsCache[sistemaId].eventList.slice(-50);
                }
              });
            }

            self.cache = eventsCache || {};
            if (self.cache != null && self.cache[sistema.id]) {
              deferred.resolve(self.cache[sistema.id]);
            } else {
              deferred.resolve({});
            }
          })
          .catch(function(error) {
            self.cache = {};
            deferred.resolve({});
            // console.log(error);
          });
        return deferred.promise;
      }

      if (self.cache[sistema.id]) {
        // console.log("Devolviendo eventos: " + self.cache[sistema.id]);
        deferred.resolve(self.cache[sistema.id]);
        return deferred.promise;
      }

      deferred.resolve({});
      return deferred.promise;
    };
  }
  angular.module("app").service("eventCache", eventCacheService);
})();
