(function() {
	function NotificationsController(
		$scope,
		user,
		ajaxInProgress,
		BUILD_MODE,
		__env,
		$window,
		$rootScope,
		$state,
		$timeout,
		alertService,
		userDataService,
		notificationHookService,
		ymSidenavService,
		auth,
		editMode,
		buildModes,
		$mdDialog,
		deviceService,
		actionBarService
	) {
		var self = this;

		self.actionBarTitle = "Notificaciones";

		self.notificationsArray;

		self.closeYmSidenav = ymSidenavService.closeYmSidenav;

		editMode.state.value = false;

		if (BUILD_MODE == "MOBILE" || (BUILD_MODE == buildModes.WEB && $window.innerWidth < 960)) {
            self.closeYmSidenav();
		}
		
		$rootScope.wifiIconToShow = "any-network"
		$rootScope.systemPlanCounter = 'system-plan-counter-hidden'

		function handleRequest(res) {
			if (res.data && res.data.success) {
				if (ajaxInProgress.state.value == "actualizarData") {
					self.notificationsArray = res.data.message.notificationMessages;
					// Actualizo el lastRead de las notificaciones.
					self.getNotificationsLastRead();
					$rootScope && $rootScope.$emit("notificationsClear", {});
					ajaxInProgress.setState({
						value: false
					});
				} else if (ajaxInProgress.state.value == "acceptInvitation") {
					ajaxInProgress.setState({
						value: false
					});
					self.actualizarData();
				} else if (ajaxInProgress.state.value == "rejectInvitation") {
					ajaxInProgress.setState({
						value: false
					});
					self.actualizarData();
				} else if (ajaxInProgress.state.value == "lastRead") {
					//Emito evento para borrar el punto rojo
					ajaxInProgress.setState({
						value: false
					});
				}
			} else {
				if (res.status == -1) {
					alertService.showAlert("Atención", "No se pudo completar la acción, vuelva a intentarlo.", "OK");
					ajaxInProgress.setState({
						value: false
					});
					return;
				}
				alertService.showAlert("Atención", res.data.message, "OK");
				ajaxInProgress.setState({
					value: false
				});
			}
		}

		self.hideButtons = function(notificationType) {
			if (notificationType != 0) return true;
		};

		self.onAcceptInvitation = function(notificationId) {
			//Le pego a la API con el ID de la notificación y la acción para que decida que hacer.
			ajaxInProgress.setState({
				value: "acceptInvitation"
			});
			notificationHookService.acceptInvitation(notificationId).then(handleRequest, handleRequest);
		};

		self.onRejectInvitation = function(notificationId) {
			//Le pego a la API con el ID de la notificación y la acción para que decida que hacer.
			ajaxInProgress.setState({
				value: "rejectInvitation"
			});
			notificationHookService.rejectInvitation(notificationId).then(handleRequest, handleRequest);
		};

		self.actualizarData = function() {
			ajaxInProgress.setState({
				value: "actualizarData"
			});
			notificationHookService.getNotifications().then(handleRequest, handleRequest);
		};

		self.getNotificationsLastRead = function() {
			ajaxInProgress.setState({
				value: "lastRead"
			});
			notificationHookService.getNotificationsLastRead().then(handleRequest, handleRequest);
		};

		self.actualizarData();
	}
	angular.module("app").controller("NotificationsController", NotificationsController);
})();
