(function(angular) {
  function ZonaController($scope, $element, ajaxInProgress) {
    var ctrl = this;
    // ctrl.$scope = $scope;
    ctrl.ajaxInProgress = ajaxInProgress;

    ctrl.availableIcons = [
      {
        value: "0",
        img: "img/assets/icon-zone/icon-zone-movimiento.svg"
      },
      {
        value: "1",
        img: "img/assets/icon-zone/icon-zone-puerta.svg"
      },
      {
        value: "2",
        img: "img/assets/icon-zone/icon-zone-ventana.svg"
      },
      {
        value: "3",
        img: "img/assets/icon-zone/icon-zone-garaje.svg"
      },
      {
        value: "4",
        img: "img/assets/icon-zone/icon-zone-barrera.svg"
      },
      {
        value: "5",
        img: "img/assets/icon-zone/icon-zone-incendio.svg"
      },
      {
        value: "6",
        img: "img/assets/icon-zone/icon-zone-ir800.svg"
      },
      {
        value: "7",
        img: "img/assets/icon-zone/icon-zone-dgm300.svg"
      }
    ];

    ctrl.$onInit = function() {
      ctrl.zone.editedName = ctrl.zone.name || "Zona " + ctrl.zone.number;
      ctrl.zone.editedIcon = ctrl.zone.icon;
      ctrl.zonaIconClass = "icon-zone-" + ctrl.zone.icon;
      ctrl.zonaEditedIconClass = "icon-zone-" + ctrl.zone.icon;

      // ctrl.estadoDeInhibicion = ctrl.status.partitionStatus.some(function(
      //   partition
      // ) {});
    };

    ctrl.$onChanges = function(changesObj) {
      // console.log("$onChanges");
      ctrl.parseStatus(ctrl.status);
    };

    ctrl.inhibir = function() {
      ctrl.onInhibir(ctrl.zone);
    };

    ctrl.desinhibir = function() {
      ctrl.onDesinhibir(ctrl.zone);
    };

    ctrl.selectIcon = function(value) {
      ctrl.zone.editedIcon = value;
      ctrl.zonaEditedIconClass = "icon-zone-" + value;
    };

    // ng-if="!($ctrl.estadoZona == 'INHIBIDA' || ($ctrl.status.partitionStatus !== 'LISTO' && $ctrl.status.partitionStatus !== 'NO_LISTO'))"
    // ng-if="!($ctrl.estadoZona !== 'INHIBIDA' || ($ctrl.status.partitionStatus !== 'LISTO' && $ctrl.status.partitionStatus !== 'NO_LISTO'))"
    ctrl.inhibirFunction = function() {
      var particionesArray = ctrl.zone.partitions;
      var estadoParticionesArray = ctrl.status.partitionStatus;

      var statusList = particionesArray.map(function(partNumber) {
        return estadoParticionesArray[partNumber - 1];
      });

      var sePuedeInhibir = statusList.every(function(partStatus) {
        if (ctrl.estadoZona != "INHIBIDA") {
          if (partStatus == "LISTO" || partStatus == "NO_LISTO") return true;
        }
      });
      if (sePuedeInhibir) {
        return true;
      }
      return false;
    };

    ctrl.desinhibirFunction = function() {
      var particionesArray = ctrl.zone.partitions;
      var estadoParticionesArray = ctrl.status.partitionStatus;

      var statusList = particionesArray.map(function(partNumber) {
        return estadoParticionesArray[partNumber - 1];
      });

      var sePuedeInhibir = statusList.every(function(partStatus) {
        if (ctrl.estadoZona == "INHIBIDA") {
          if (partStatus != "LISTO" || partStatus != "NO_LISTO") return true;
        }
      });

      if (sePuedeInhibir) {
        return true;
      }
      return false;
    };

    ctrl.parseStatus = function(status) {
      if (status) {
        var arrayIndex = Math.ceil(ctrl.zone.number / 8) - 1;
        var exponent = (ctrl.zone.number % 8) - 1;
        var mask = Math.pow(2, exponent > -1 ? exponent : 7);

        if (status.zonasEnAlarma[arrayIndex] & mask) {
          // Zona en alarma
          ctrl.estadoZona = "ALARMA";
          ctrl.estadoZonaClass = "zone-class-alarma";
          ctrl.estadoZonaText = "En alarma";
          ctrl.estadoZonaColor = "rojo";
        } else {
          //La zona no está en alarma
          if (status.zonasInhibidas[arrayIndex] & mask) {
            //La zona está inhibida
            ctrl.estadoZona = "INHIBIDA";
            ctrl.estadoZonaClass = "zone-class-inhibida";
            ctrl.estadoZonaText = "Inhibida";
            ctrl.estadoZonaColor = "verde";
          } else {
            //La zona no está inhibida
            if (status.zonasAbiertas[arrayIndex] & mask) {
              //La zona está abierta
              ctrl.estadoZona = "ABIERTA";
              ctrl.estadoZonaClass = "zone-class-abierta";
              ctrl.estadoZonaText = "Abierta";
              ctrl.estadoZonaColor = "naranja";
            } else {
              //La zona está cerrada
              ctrl.estadoZona = "CERRADA";
              ctrl.estadoZonaClass = "zone-class-cerrada";
              ctrl.estadoZonaText = "Cerrada";
              ctrl.estadoZonaColor = "gris";
            }
          }
        }
      } else {
        ctrl.estadoZona = "DESCONOCIDO";
        ctrl.estadoZonaClass = "zone-class-desconocido";
        ctrl.estadoZonaText = "";
        ctrl.estadoZonaColor = "gris";
      }
    };
    ctrl.parseStatus(ctrl.status);
  }

  angular.module("app").component("zona", {
    templateUrl: "components/Zona/zona.html",
    controller: ZonaController,
    bindings: {
      status: "<",
      zone: "<",
      onInhibir: "=",
      onDesinhibir: "=",
      editMode: "<"
    }
  });
})(window.angular);
