(function() {
  function configUiRouter($stateProvider, $urlRouterProvider, BUILD_MODE) {
    //
    // For any unmatched url, redirect to /state1

    // if (BUILD_MODE === "MOBILE") {
    // $urlRouterProvider.otherwise("/login");
    $urlRouterProvider.otherwise(function($injector, $location) {
      $injector.invoke([
        "$state",
        function($state) {
          $state.go("login");
        }
      ]);
    });
    //
    // Now set up the states
    $stateProvider
      .state("login", {
        url: "/login",
        views: {
          "main-ui-view": {
            templateUrl: "views/desktop/login.html"
          }
        },
        authenticate: false
      })
      .state("register", {
        url: "/register",
        views: {
          "main-ui-view": {
            templateUrl: "views/desktop/register.html"
          }
        },
        authenticate: false
      })
      .state("register-confirmed", {
        url: "/register-confirmed",
        views: {
          "main-ui-view": {
            templateUrl: "views/desktop/register-confirmed.html"
          }
        },
        authenticate: false
      })
      .state("registerac4-confirmed", {
        url: "/registerac4-confirmed",
        views: {
          "main-ui-view": {
            templateUrl: "views/desktop/registerac4-confirmed.html"
          }
        },
        authenticate: false
      })
      .state("register-rejected", {
        url: "/register-rejected",
        views: {
          "main-ui-view": {
            templateUrl: "views/desktop/register-rejected.html"
          }
        },
        authenticate: false
      })
      .state("forgot", {
        url: "/forgot",
        views: {
          "main-ui-view": {
            templateUrl: "views/desktop/forgot.html"
          }
        },
        authenticate: false
      })
      .state("reset", {
        url: "/reset",
        views: {
          "main-ui-view": {
            templateUrl: "views/desktop/reset.html"
          }
        },
        authenticate: false
      })
      .state("resetMobile", {
        url: "/resetMobile",
        views: {
          "main-ui-view": {
            templateUrl: "views/desktop/resetMobile.html"
          }
        },
        authenticate: false
      })
      .state("deviceslist", {
        url: "/deviceslist",
        views: {
          "main-ui-view": {
            templateUrl: "views/desktop/deviceslist.html"
          }
        },
        authenticate: false
      })
      .state("controlpanel", {
        url: "/controlpanel",
        views: {
          "main-ui-view": {
            templateUrl: "views/desktop/controlpanel.html"
          },
          params: { urlToRedirect: null, }
        },
        authenticate: true
      })
      .state("notifications", {
        url: "/notifications",
        parent: "controlpanel",
        views: {
          "controlpanel-ui-view@controlpanel": {
            templateUrl: "views/desktop/notifications.html"
          }
        },
        authenticate: true
      })
      .state("registerverify", {
        url: "/register-verify",
        parent: "controlpanel",
        views: {
          "controlpanel-ui-view@controlpanel": {
            templateUrl: "views/desktop/registryVerify.html"
          }
        },
        authenticate: true
      })
      .state("account_config", {
        name: "account_config",
        url: "/account_config",
        parent: "controlpanel",
        views: {
          "controlpanel-ui-view@controlpanel": {
            templateUrl: "views/desktop/account_config.html"
          }
        },
        authenticate: true
      })
      .state("payment_data", {
        name: "payment_data",
        url: "/payment_data",
        parent: "controlpanel",
        views: {
          "controlpanel-ui-view@controlpanel": {
            templateUrl: "views/desktop/payment_data.html"
          }
        },
        authenticate: true
      })
      .state("about_device", {
        name: "about_device",
        url: "/about_device",
        parent: "controlpanel",
        views: {
          "controlpanel-ui-view@controlpanel": {
            templateUrl: "views/desktop/about_device.html"
          }
        },
        authenticate: true
      })
      .state("systemlist", {
        name: "systemlist",
        url: "/systemlist",
        parent: "controlpanel",
        views: {
          "controlpanel-ui-view@controlpanel": {
            templateUrl: "views/desktop/systemlist.html",
          },
        },
        authenticate: true
      })
      .state("systemlistred", {
        name: "systemlistred",
        url: "/systemlist:idSistemaElegido",
        parent: "controlpanel",
        views: {
          "controlpanel-ui-view@controlpanel": {
            templateUrl: "views/desktop/systemlist.html",
          },
        },
        authenticate: true
      })
      .state("systemlisttocontrol", {
        name: "systemlisttocontrol",
        url: "/systemlist:idToControl",
        parent: "controlpanel",
        views: {
          "controlpanel-ui-view@controlpanel": {
            templateUrl: "views/desktop/systemlist.html",
          },
        },
        authenticate: true
      })
      .state("subscription_systemlist", {
        name: "subscription_systemlist",
        url: "/subscription_systemlist",
        parent: "controlpanel",
        views: {
          "controlpanel-ui-view@controlpanel": {
            templateUrl: "views/desktop/subscription/systemlist.html"
          }
        },
        authenticate: true
      })
      .state("subscription", {
        name: "subscription",
        url: "/subscription",
        parent: "controlpanel",
        views: {
          "controlpanel-ui-view@controlpanel": {
            templateUrl: "views/desktop/subscription.html"
          }
        },
        authenticate: true
      })
      .state("subscription.info", {
        name: "subscription.info",
        url: "/info",
        views: {
          "subscription-ui-view": {
            templateUrl: "views/desktop/subscription/info.html"
          }
        },
        authenticate: true
      })
      .state("subscription.plans", {
        name: "subscription.plans",
        url: "/plans",
        views: {
          "subscription-ui-view": {
            templateUrl: "views/desktop/subscription/plans.html"
          }
        },
        authenticate: true
      })
      .state("subscription.payment", {
        name: "subscription.payment",
        url: "/payment",
        views: {
          "subscription-ui-view": {
            templateUrl: "views/desktop/subscription/payment.html"
          }
        },
        authenticate: true
      })
      .state("subscription.confirm", {
        name: "subscription.confirm",
        url: "/confirm",
        views: {
          "subscription-ui-view": {
            templateUrl: "views/desktop/subscription/confirm.html"
          }
        },
        authenticate: true
      })
      .state("subscription.success", {
        name: "subscription.success",
        url: "/success",
        views: {
          "subscription-ui-view": {
            templateUrl: "views/desktop/subscription/success.html"
          }
        },
        authenticate: true
      })
      .state("subscription.error", {
        name: "subscription.error",
        url: "/error",
        views: {
          "subscription-ui-view": {
            templateUrl: "views/desktop/subscription/plans.html"
          }
        },
        authenticate: true
      })
      .state("systemconfig", {
        name: "systemconfig",
        url: "/systemconfig/:idSistemaElegido",
        parent: "controlpanel",
        views: {
          "controlpanel-ui-view@controlpanel": {
            templateUrl: "views/desktop/systemconfig.html",
            controller: "SystemConfigController",
            controllerAs: "systemConfigController"
          }
        },
        authenticate: true
      })
      .state("system", {
        name: "system",
        url: "/system/:idSistemaElegido",
        parent: "controlpanel",
        views: {
          "controlpanel-ui-view@controlpanel": {
            templateUrl: "views/desktop/system.html",
            controller: "SystemController",
            controllerAs: "systemController"
          }
        },
        // redirectTo: "system.control",
        authenticate: true
      })
      //BORRAR SI NO FUNCIONA
      .state("history", {
        name: "/history",
        url: "controlpanel/system/:idSistemaElegido/history",
        // parent: "controlpanel",
        views: {
          "system-ui-view": {
            templateUrl: "views/desktop/system/history.html"
          }
        },
        authenticate: true
      })
      .state("system.users", {
        name: "system.users",
        url: "/users",
        views: {
          "system-ui-view": {
            templateUrl: "views/desktop/system/users.html"
          }
        },
        authenticate: true
      })
      .state("system.unauthorized", {
        name: "system.unauthorized",
        url: "/unauthorized",
        views: {
          "system-ui-view": {
            templateUrl: "views/desktop/system/unauthorized.html"
          }
        },
        authenticate: true
      })
      .state("system.control", {
        name: "system.control",
        url: "/control",
        views: {
          "system-ui-view": {
            templateUrl: "views/desktop/system/control.html"
          }
        },
        authenticate: true
      })
      .state("system.video", {
        name: "system.video",
        url: "/video",
        views: {
          "system-ui-view": {
            templateUrl: "views/desktop/system/video.html"
          }
        },
        authenticate: true
      })
      .state("system.zones", {
        name: "system.zones",
        url: "/zones",
        views: {
          "system-ui-view": {
            templateUrl: "views/desktop/system/zones.html"
          }
        },
        authenticate: true
      })
      .state("system.outputs", {
        name: "system.outputs",
        url: "/outputs",
        views: {
          "system-ui-view": {
            templateUrl: "views/desktop/system/outputs.html"
          }
        },
        authenticate: true
      })
      .state("system.bell", {
        name: "system.bell",
        url: "/bell",
        views: {
          "system-ui-view": {
            templateUrl: "views/desktop/system/bell.html"
          }
        },
        authenticate: true
      })
      .state("system.history", {
        name: "system.history",
        url: "/history",
        views: {
          "system-ui-view": {
            templateUrl: "views/desktop/system/history.html"
          }
        },
        authenticate: true
      })
      .state("system.automation", {
        name: "system.automation",
        url: "/automation",
        views: {
          "system-ui-view": {
            templateUrl: "views/desktop/system/automation.html"
          }
        },
        authenticate: true
      })
      .state("system.antipanic", {
        name: "system.antipanic",
        url: "/antipanic",
        views: {
          "system-ui-view": {
            templateUrl: "views/desktop/system/antipanic.html"
          }
        },
        authenticate: true
      })
      .state("system.emergencypanel", {
        name: "system.emergencypanel",
        url: "/emergencypanel",
        views: {
          "system-ui-view": {
            templateUrl: "views/desktop/system/emergencypanel.html"
          }
        },
        authenticate: true
      })
      // .state("system.installer", {
      //   name: "system.installer",
      //   url: "/installer",
      //   views: {
      //     "system-ui-view": {
      //       templateUrl: "views/desktop/system/installer.html"
      //     }
      //   },
      //   authenticate: true
      // });
  }

  angular.module("app").config(configUiRouter);
})();
