(function() {
  function configHttpProvider($httpProvider) {
    $httpProvider.interceptors.push("authInterceptor");
  }

  angular.module("app").config(configHttpProvider);

  //TODO: Sacar esto de acá
  angular.module("app").config(function($mdInkRippleProvider) {
    $mdInkRippleProvider.disableInkRipple();
  });
})();
